import {
  DOCTOR_LIST_FAILURE,
  DOCTOR_LIST_IN_PROGRESS,
  DOCTOR_LIST_SUCCESS,
  GET_DOCTOR_APPOINTMENT_LIST_IN_PROGRESS,
  GET_DOCTOR_ERROR,
  GET_DOCTOR_IN_PROGRESS,
  GET_DOCTOR_SUCCESS,
} from "../../../constants/ActionTypes";

export const doctorListInProgress = (payload) => {
  return {
    type: DOCTOR_LIST_IN_PROGRESS,
    payload: payload,
  };
};
export const doctorListSuccess = (payload) => {
  return {
    type: DOCTOR_LIST_SUCCESS,
    payload: payload,
  };
};
export const doctorListError = () => {
  return {
    type: DOCTOR_LIST_FAILURE,
  };
};

export const getDoctorInProgress = (payload) => {
  return {
    type: GET_DOCTOR_IN_PROGRESS,
    payload: payload,
  };
};
export const getDoctorSuccess = (payload) => {
  return {
    type: GET_DOCTOR_SUCCESS,
    payload: payload,
  };
};
export const getDoctorError = () => {
  return {
    type: GET_DOCTOR_ERROR,
  };
};
