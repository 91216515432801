// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  background-color: #FFFFFF;
}
.progress-container {
  padding-top: 50px !important;
}
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.icon-s {
  background-color: #eee;
  padding: 10px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: white;
}
.purple {
  background-color: #A066FF;
}
.orange {
  background-color: #FF8744;
}
.green {
  background-color: #44C27F;
}
.blue {
  background-color: #47B3FF;
}
.content {
  text-align: right;
}
.content h4 {
  margin: 0;
  color: #555;
}
.content h3 {
  margin: 0;
  color: #333;
}
.chart-container {
  margin-top: 10px;
  height: 60px;
}
`, "",{"version":3,"sources":["webpack://./src/screens/Dashboard/Dashboard.less"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,aAAa;EACb,yBAAyB;AAC3B;AACA;EACE,4BAA4B;AAC9B;AACA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;AACA;EACE,sBAAsB;EACtB,aAAa;EACb,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;EACf,YAAY;AACd;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,iBAAiB;AACnB;AACA;EACE,SAAS;EACT,WAAW;AACb;AACA;EACE,SAAS;EACT,WAAW;AACb;AACA;EACE,gBAAgB;EAChB,YAAY;AACd","sourcesContent":[".card {\n  border-radius: 10px;\n  overflow: hidden;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  padding: 10px;\n  background-color: #FFFFFF;\n}\n.progress-container {\n  padding-top: 50px !important;\n}\n.card-header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n.icon-s {\n  background-color: #eee;\n  padding: 10px;\n  border-radius: 50%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-size: 24px;\n  color: white;\n}\n.purple {\n  background-color: #A066FF;\n}\n.orange {\n  background-color: #FF8744;\n}\n.green {\n  background-color: #44C27F;\n}\n.blue {\n  background-color: #47B3FF;\n}\n.content {\n  text-align: right;\n}\n.content h4 {\n  margin: 0;\n  color: #555;\n}\n.content h3 {\n  margin: 0;\n  color: #333;\n}\n.chart-container {\n  margin-top: 10px;\n  height: 60px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
