import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";

import {
  showAuthMessage,
  userSignInSuccess,
  userSignUpSuccess,
  VideoCallError,
  videoCallSuccess,
} from "../actions/Auth";

import {
  SIGNUP_USER,
  SIGNIN_USER,
  REQUEST_VIDEO_CALL_IN_PROGRESS,
} from "../../../constants/ActionTypes";


import Message from "../../../components/Message/Message";
import { axiosInstance } from "../../../util/utilz";
import { axiosConfig } from "../../../util/axiosInstance";

const createUserWithEmailPasswordRequest = async (formData) => {
  const SigninData = axios({
    url: `${axiosInstance}/auth/register`,
    method: "POST",
    data: formData,
    headers: {
      "content-type": "application/x-www-form-urlencoded",
    },
  });

  return SigninData;
};

const signInUserWithEmailPasswordRequest = async (formData) => {
  const LoginData = axios({
    url: `${axiosInstance}/auth/login`,
    method: "POST",
    data: formData,
    headers: {
      "Content-Type": "application/json",
    },
  });
  return LoginData;
};
export const getAgoraToken = (params) => {
  return axios.get(`${axiosConfig.baseURL}/auth/generate-agora-token`, {
    params,
  });
};
function* createUserWithEmailPassword({ payload }) {
  try {
    const signUpUser = yield call(createUserWithEmailPasswordRequest, payload);

   
    yield put(userSignUpSuccess(signUpUser?.data?.userInfo));

  } catch (error) {
    yield put(showAuthMessage("invalid details"));
  }
}



function* signInUserWithEmailPassword({ payload }) {
  try {
    const signInUser = yield call(signInUserWithEmailPasswordRequest, payload);

    if (signInUser?.data?.message) {
  
    } else {
      localStorage.setItem("user_id", signInUser?.data?.token);
      localStorage.setItem("doctor_id", signInUser?.data?.user?.doctor_id);
      localStorage.setItem("patient_id", signInUser?.data?.user?.patient_id);
      localStorage.setItem("id", signInUser?.data?.user?.user_id);
      localStorage.setItem("pass", signInUser?.data?.user?.password);
      localStorage.setItem("role_id", signInUser?.data?.user?.role_id);
      localStorage.setItem("email", signInUser?.data?.user?.email);
      localStorage.settItem("role_permision", signInUser?.data?.user?.access);

      yield put(userSignInSuccess(signInUser?.data?.user));
      // yield call(Message, {
      //   type: "error",
      //   content: signInUser?.data?.message,
      // });
      yield call(Message, { type: "success", content: "Login Successfully" });
    }
  } catch (error) {
    // message.error(getErrorMessageFromAPI(error));
    // yield put(showAuthMessage(error));
  }
}


function* VideocallWatcher({ payload }) {
  try {
    let { data } = yield call(getAgoraToken, payload);

    yield put(
      videoCallSuccess({
        data: data,
        message: data["message"],
        videoSuccess: true,
      })
    );
  } catch (e) {
    yield put(
      VideoCallError({
        videoError: true,
        message: e["message"],
      })
    );
  }
}
export function* createUserAccount() {
  yield takeEvery(SIGNUP_USER, createUserWithEmailPassword);
}


export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

// export function* signOutUser() {
//   yield takeEvery(SIGNOUT_USER, signOut);
// }
export function* videoCallStart() {
  yield takeEvery(REQUEST_VIDEO_CALL_IN_PROGRESS, VideocallWatcher);
}
export default function* authSagas() {
  yield all([
    fork(signInUser),
    fork(createUserAccount),
    // fork(signInWithGoogle),
    // fork(signInWithFacebook),
    // fork(signInWithTwitter),
    // fork(signInWithGithub),
    // fork(signOutUser),
    fork(videoCallStart),
  ]);
}
