import React, { useEffect, useState } from "react";
import { Button, Form, Col, Row } from "antd";
import { useHistory, useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import { message } from "antd/lib/index";

import {
  hideAuthLoader,
  showAuthLoader,
  VerifyOtpInProgress,
} from "../appRedux/doctor/actions/Auth";
import CircularProgress from "../components/CircularProgress";
import OtpInput from "react-otp-input";
import IntlMessages from "../util/IntlMessages";
// import OtpInput from "react-otp-input";

const FormItem = Form.Item;

const OtpRegistration = (props) => {
  const [code, setCode] = useState("");

  const handleChange = (code) => setCode(code);
  const dispatch = useDispatch();
  let slug = useParams();
  const history = useHistory();
  const { loader, alertMessage, showMessage, authUser } = useSelector(
    ({ auth }) => auth
  );
  const verifyOtpData = useSelector((state) => state.register);
  const registerdata = useSelector((state) => state?.auth?.registerdata);
  const register = useSelector((state) => state?.authData?.registerdata);

  const onFinishFailed = (errorInfo) => {};

  const onFinish = (values) => {
    dispatch(showAuthLoader());

    let formData = new URLSearchParams();

    formData.append("email", registerdata?.email);
    formData.append("verification_code", values.verification_code);
    dispatch(VerifyOtpInProgress(formData));
  };
  useEffect(() => {
    dispatch(hideAuthLoader());
  }, []);
  // useEffect(() => {
  //   if (VerifyOtpSuccess === true) {
  //     history.push("/signin");
  //   }
  // }, [VerifyOtpSuccess]);
  useEffect(() => {
    if (register != null) {
      alert("registerdata", registerdata);
      // let formData = new URLSearchParams();
      // formData.append("email", registerdata?.email);
      // formData.append("subject_type", 1);
      // dispatch(OtpSendInProgress(formData));
    }
  }, [register]);
  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg">
              <img src={"https://via.placeholder.com/272x395"} alt="Neature" />
            </div>
            <div className="gx-app-logo-wid">
              <h1>
                <IntlMessages id="app.userAuth.otpRegistration" />
              </h1>
              {/* <p>
                <IntlMessages id="app.userAuth.enterotp" />
              </p> */}
              {/* <p>
                <IntlMessages id="app.userAuth.getAccount" />
              </p> */}
            </div>
            <div className="gx-app-logo">
              <img alt="example" src="/assets/images/logo.png" />
            </div>
          </div>

          <div className="gx-app-login-content">
            <Form
              initialValues={{ remember: true }}
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="gx-signin-form gx-form-row0"
            >
              <Row>
                <Col span={24}>
                  {" "}
                  <FormItem
                    name="verification_code"
                    rules={[
                      {
                        required: true,
                        type: "text",
                        message: "The input is not valid E-mail!",
                      },
                    ]}
                  >
                    <OtpInput
                      value={code}
                      onChange={handleChange}
                      numInputs={4}
                      separator={<span style={{ width: "8px" }}></span>}
                      isInputNum={true}
                      shouldAutoFocus={true}
                      inputStyle={{
                        border: "1px solid black",
                        borderRadius: "8px",
                        width: "70px",
                        height: "70px",
                        fontSize: "20px",
                        color: "#000",
                        fontWeight: "700",
                        caretColor: "blue",
                      }}
                      focusStyle={{
                        border: "1px solid #CFD3DB",
                        outline: "none",
                      }}
                    />
                  </FormItem>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  {" "}
                  <FormItem>
                    {" "}
                    <Button
                      type="primary"
                      className="gx-mb-4"
                      htmlType="submit"
                    >
                      verify otp
                    </Button>
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </div>
          {loader && (
            <div className="gx-loader-view">
              <CircularProgress />
            </div>
          )}
          {showMessage && message.error(alertMessage)}
        </div>
      </div>
    </div>
  );
};

export default OtpRegistration;
