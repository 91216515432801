import React, { useEffect, useState } from "react";

import {
  Alert,
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Spin,
  Typography,
} from "antd";
import {
  CreditCardOutlined,
  LoadingOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";

import { useDispatch, useSelector } from "react-redux";

import ButtonComponent from "../../components/Button/ButtonComponent";
import IntlMessages from "../../util/IntlMessages";
import FormInput from "../../components/Input/FormInput";
import moment from "moment";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import FormSelectBox from "../../components/SelectBox/SelectBox";
import {
  addInsulinInprogress,
  getInsulinListInprogress,
} from "../../appRedux/Patient/Action/Action";

const { Option } = Select;

const InsulinScreen = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [currentTime, setCurrentTime] = useState(moment().format("HH:mm"));
  const [selectedDate, setSelectedDate] = useState(null);
  const [languageValue, setLanguageValue] = useState("Units");
  const [disabled, setDisabled] = useState(false);
  const insulinListData = useSelector(
    (state) => state?.patientAppointment?.insulinList
  );
  const addInsulinData = useSelector(
    (state) => state?.patientAppointment?.addInsulin
  );
  const goBackHandler = () => {
    history.goBack();
  };
  const changeLanguage = (value) => {
    setLanguageValue(value);
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );
  const [loading, setLoading] = useState(false);

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinish = (data) => {
    console.log("datas", data);
    const payload = {
      select_date: moment(data?.date).format("YYYY/MM/DD"),
      time: data?.time,
      dosage: data?.dosage,
      insulin_brand: data?.insulin,
    };

    dispatch(addInsulinInprogress(payload));
  };
  console.log("addInsulinData?.inProgress", addInsulinData);
  const disabledDate = (current) => {
    const today = moment().startOf("day");
    const past7Days = moment().subtract(7, "days").endOf("day");
    return current && (current < past7Days || current > moment().endOf("day"));
  };
  useEffect(() => {
    const interval = setInterval(() => {
      const now = moment().format("HH:mm");
      setCurrentTime(now);

      form.setFieldsValue({ time: now });
    }, 1000);
    return () => clearInterval(interval);
  }, [form]);
  const insulinData = insulinListData?.data?.data;
  const handleDatePickerChange = (date) => {
    setSelectedDate(date);
    const selectedDateString = moment(date).format("YYYY-MM-DD");
    const selectedInsulinData = insulinData.find(
      (item) => item.select_date === selectedDateString
    );
    if (selectedInsulinData) {
      setDisabled(true);
      form.setFieldsValue({
        data_time: moment(selectedInsulinData.time, "HH:mm:ss").format("HH:mm"),
        dosage: selectedInsulinData.dosage,
        insulin: selectedInsulinData.insulin_brand,
        format: selectedInsulinData.format,
      });
      setLanguageValue(selectedInsulinData?.format);
    } else {
      setDisabled(false);
      form.resetFields(["time", "dosage", "insulin"]);
    }
  };
  useEffect(() => {
    dispatch(getInsulinListInprogress());
    form.setFieldsValue({ date: moment() });
  }, []);
  useEffect(() => {
    if (insulinListData?.success) {
      const currentDate = form.getFieldValue("date");
      handleDatePickerChange(currentDate);
    }
  }, [insulinListData]);
  useEffect(() => {
    if (addInsulinData?.inProgress) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [addInsulinData]);
  useEffect(() => {
    if (addInsulinData?.success) {
      history.push("/health-management");
    }
  }, [addInsulinData]);
  return (
    <Card>
      <Row className="p-10 Add-button">
        <Col>
          <Typography className="header-tag">
            {<IntlMessages id={"Addnew.insulin"} />}
          </Typography>
          {/* <Text disabled className="mt-30">
            Readings can be updated for last 7 days
          </Text> */}
        </Col>
        <Col>
          <ButtonComponent
            className="buttons"
            icon={<PlusCircleOutlined />}
            type="primary"
            label={<IntlMessages id={"back"} />}
            onClick={goBackHandler}
          />
        </Col>
      </Row>
      <Spin indicator={antIcon} spinning={loading}>
        <Row gutter={[16, 16]}>
          <Col xl={12} lg={12} md={12} xs={24} m={24}>
            <Alert
              message="Readings can be updated for last 7 days"
              type="info"
              showIcon
            />
          </Col>
          <Col xl={24} lg={24} md={24} xs={24} m={24}>
            <Form
              labelCol={{
                span: 24,
              }}
              wrapperCol={{
                span: 24,
              }}
              initialValues={{
                remember: true,
              }}
              colon={"true"}
              onFinish={onFinish}
              autoComplete="off"
              form={form}
              onFinishFailed={onFinishFailed}
            >
              <Row>
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                  <Form.Item
                    name="date"
                    label={<IntlMessages id={"Addnew.health.label.date"} />}
                    rules={[
                      {
                        required: true,
                        message: (
                          <IntlMessages id={"Addnew.health.validator.date"} />
                        ),
                      },
                    ]}
                  >
                    <DatePicker
                      disabledDate={disabledDate}
                      style={{ width: "100%" }}
                      format="DD/MM/YYYY"
                      onChange={handleDatePickerChange}
                    />
                  </Form.Item>
                </Col>
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                  {!disabled ? (
                    <FormInput
                      labelCol={"24"}
                      wrapperCol={"24"}
                      readOnly={true}
                      type="text"
                      name={"time"}
                      label={<IntlMessages id={"Addnew.health.label.time"} />}
                      rules={[
                        {
                          required: true,
                          message: (
                            <IntlMessages id={"Addnew.health.validator.time"} />
                          ),
                        },
                      ]}
                    />
                  ) : (
                    <FormInput
                      labelCol={"24"}
                      wrapperCol={"24"}
                      readOnly={true}
                      type="text"
                      name={"data_time"}
                      label={<IntlMessages id={"Addnew.health.label.time"} />}
                      rules={[
                        {
                          required: true,
                          message: (
                            <IntlMessages id={"Addnew.health.validator.time"} />
                          ),
                        },
                      ]}
                    />
                  )}
                </Col>
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                  <Form.Item
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    label={<IntlMessages id={"Addnew.health.label.dosage"} />}
                    name={"dosage"}
                    rules={[
                      {
                        required: true,
                        message: (
                          <IntlMessages id={"Addnew.health.validator.dosage"} />
                        ),
                      },
                    ]}
                  >
                    <InputNumber
                      style={{
                        width: "100%",
                      }}
                      readOnly={disabled}
                      addonAfter={
                        <Typography style={{ color: "#038FDE" }} strong>
                          units
                        </Typography>
                      }
                    />
                  </Form.Item>
                  <Row>
                    {/* <Col xl={18} lg={20} md={20} sm={20} xs={20}>
                      <FormInput
                        labelCol={"24"}
                        wrapperCol={"24"}
                        readOnly={disabled}
                        type="number"
                        name={"dosage"}
                        label={
                          <IntlMessages id={"Addnew.health.label.dosage"} />
                        }
                        rules={[
                          {
                            required: true,
                            message: (
                              <IntlMessages
                                id={"Addnew.health.validator.dosage"}
                              />
                            ),
                          },
                        ]}
                      />
                    </Col> */}
                    <Col xl={6} lg={6} md={6} sm={6} xs={6}></Col>
                  </Row>
                </Col>
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                  <FormInput
                    labelCol={"24"}
                    wrapperCol={"24"}
                    readOnly={disabled}
                    type="text"
                    name={"insulin"}
                    label={
                      <IntlMessages id={"Addnew.health.label.insulinBrand"} />
                    }
                    rules={[
                      {
                        required: true,
                        message: (
                          <IntlMessages
                            id={"Addnew.health.validator.insulinBrand"}
                          />
                        ),
                      },
                    ]}
                  />
                </Col>{" "}
              </Row>

              <Row>
                <Col
                  xl={23}
                  align="right"
                  className="button-alignment-pharmacy cancel-btn mr-10"
                >
                  <Link
                    to={{
                      pathname: "/health-management",
                    }}
                  >
                    <ButtonComponent
                      type="danger"
                      label="Cancel"
                      className="btn-cancel secondary-color"
                    />
                  </Link>
                  <ButtonComponent
                    icon={<CreditCardOutlined />}
                    label={"Update Insulin"}
                    htmlType="submit"
                    type="primary"
                    disabled={disabled}
                  />
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Spin>
    </Card>
  );
};

export default InsulinScreen;
