import React from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { useParams } from "react-router-dom";

const PdfViewer = () => {
  const { pdfUrl } = useParams();
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  return (
    <div>
      {/* <Viewer fileUrl={decodeURIComponent(pdfUrl)} /> */}
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
        <Viewer
          fileUrl={decodeURIComponent(pdfUrl)}
          //   plugins={[defaultLayoutPluginInstance]}
        />
      </Worker>
    </div>
  );
};

export default PdfViewer;
