import React from "react";
import { Col, Row, Divider } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { APP_ROUTES } from "../../../../constants/Routes";
import "../../../../styles/modules/patient.less";
import IntlMessages from "../../../../util/IntlMessages";
import ButtonComponent from "../../../../components/Button/ButtonComponent";

import { useDispatch } from "react-redux";
import { defaultStateAddUpdatePayment } from "../../../../appRedux/Pharmacy/PharmacyAction";
import { access } from "../../../../util/utilz";

const SalesHeader = () => {
  const dispatch = useDispatch();
  return (
    <div>
      <Row className="p-10 Add-button">
        <Col>
          <className className="header-tag">
            {<IntlMessages id={"label.sales"} />}
          </className>
        </Col>{" "}
        {access("sales", "add") && (
          <Col>
            <Link to={APP_ROUTES.ADDSALES}>
              <ButtonComponent
                type="primary"
                label={<IntlMessages id={"Addnew.patient.addnew"} />}
                icon={<PlusCircleOutlined />}
                onClick={() => {
                  dispatch(defaultStateAddUpdatePayment());
                }}
              />
            </Link>
          </Col>
        )}
      </Row>

      <Divider />
    </div>
  );
};

export default SalesHeader;
