import React from "react";
import { Col, Row, Divider, Typography } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { APP_ROUTES } from "../../../../constants/Routes";
import "../../../../styles/modules/patient.less";
import IntlMessages from "../../../../util/IntlMessages";
import ButtonComponent from "../../../../components/Button/ButtonComponent";
import { access } from "../../../../util/utilz";

const SalesReturnHeader = () => {
  return (
    <div>
      <Row className="p-10 Add-button">
        <Col>
          <Typography className="header-tag">
            {<IntlMessages id={"label.salesReturn"} />}
          </Typography>
        </Col>

        {access("salesReturn", "add") && (
          <Col>
            <Link to={APP_ROUTES.ADDSALESRETURN}>
              <ButtonComponent
                type="primary"
                label={<IntlMessages id={"Addnew.salesReturn"} />}
                icon={<PlusCircleOutlined />}
              />
            </Link>
          </Col>
        )}
      </Row>

      <Divider />
    </div>
  );
};

export default SalesReturnHeader;
