import React from "react";
import imageUrl from "../assets/images/DocMotez/2021-403-forbidden-2.png";
function AccessDenied() {
  return (
    <div>
      {" "}
      <img
        src={imageUrl}
        // alt="avatar"
        style={{
          width: "100%",
        }}
      />
    </div>
  );
}

export default AccessDenied;
