import React from "react";
import { Card } from "antd";

import Auxiliary from "../../util/Auxiliary";
import BillingHeader from "./component/BillingHeader";
import BillingTable from "./component/BillingTable";

const BillingScreen = () => {
  return (
    <Auxiliary>
      <Card>
        <BillingHeader />
        <BillingTable />
        {/* <BillingPackageHeader />
        <BillingpackageTable /> */}
      </Card>
    </Auxiliary>
  );
};
export default BillingScreen;
