import React, { useEffect, useState } from "react";

import {
  Alert,
  Card,
  Col,
  DatePicker,
  Form,
  Row,
  Spin,
  Typography,
} from "antd";
import {
  CreditCardOutlined,
  LoadingOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";

import { useDispatch, useSelector } from "react-redux";

import ButtonComponent from "../../components/Button/ButtonComponent";
import IntlMessages from "../../util/IntlMessages";
import FormInput from "../../components/Input/FormInput";
import moment from "moment";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  addVitalsInprogress,
  getVitalsListInprogress,
} from "../../appRedux/Patient/Action/Action";
import { currentDate } from "../../util/utilz";
const { Text } = Typography;

const AddVitals = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [currentTime, setCurrentTime] = useState(moment().format("HH:mm"));
  const [selectedDate, setSelectedDate] = useState(null);

  const addVitalData = useSelector(
    (state) => state?.patientAppointment?.addVitals
  );
  const goBackHandler = () => {
    history.goBack();
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );
  const [loading, setLoading] = useState(false);

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinish = (data) => {
    console.log("datas", data);
    const payload = {
      select_date: moment(data?.date).format("YYYY/MM/DD"),
      time: data?.time,
      bp: data?.bp,
      pulse: data?.pulse,
      temperature: data?.temperature,
      weight: data?.weight,
      spo2: data?.spo2,
      exercise: data?.exercise,
    };

    dispatch(addVitalsInprogress(payload));
    if (addVitalData?.inProgress) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  };
  const disabledDate = (current) => {
    const today = moment().startOf("day");
    const past7Days = moment().subtract(7, "days").endOf("day");
    return current && (current < past7Days || current > moment().endOf("day"));
  };
  useEffect(() => {
    const interval = setInterval(() => {
      const now = moment().format("HH:mm");
      setCurrentTime(now);

      form.setFieldsValue({ time: now });
    }, 1000);
    return () => clearInterval(interval);
  }, [form]);

  const handleDatePickerChange = (date) => {
    setSelectedDate(date);
  };
  useEffect(() => {
    form.setFieldsValue({ date: moment() });
  }, []);

  useEffect(() => {
    if (addVitalData?.success) {
      history.push("/health-management/vitals");
    }
  }, [addVitalData]);

  useEffect(() => {
    if (addVitalData?.inProgress) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [addVitalData]);
  return (
    <Card>
      <Row className="p-10 Add-button">
        <Col>
          <Typography className="header-tag">
            {<IntlMessages id={"Addnew.Vitals"} />}
          </Typography>
          {/* <Text disabled className="mt-30">
            Readings can be updated for last 7 days
          </Text> */}
        </Col>
        <Col>
          <ButtonComponent
            className="buttons"
            icon={<PlusCircleOutlined />}
            type="primary"
            label={<IntlMessages id={"back"} />}
            onClick={goBackHandler}
          />
        </Col>
      </Row>
      <Spin indicator={antIcon} spinning={loading}>
        <Row gutter={[16, 16]}>
          {/* <Col xl={12} lg={12} md={12} xs={24} m={24}>
            <Alert
              message="Readings can be updated for last 7 days"
              type="info"
              showIcon
            />
          </Col> */}
          <Col xl={24} lg={24} md={24} xs={24} m={24}>
            <Form
              labelCol={{
                span: 24,
              }}
              wrapperCol={{
                span: 24,
              }}
              initialValues={{
                remember: true,
              }}
              colon={"true"}
              onFinish={onFinish}
              autoComplete="off"
              form={form}
              onFinishFailed={onFinishFailed}
            >
              <Row>
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                  <Form.Item
                    name="date"
                    label={<IntlMessages id={"Addnew.health.label.date"} />}
                    rules={[
                      {
                        required: true,
                        message: (
                          <IntlMessages id={"Addnew.health.validator.date"} />
                        ),
                      },
                    ]}
                  >
                    <DatePicker
                      // disabledDate={disabledDate}
                      style={{ width: "100%" }}
                      format="DD/MM/YYYY"
                      onChange={handleDatePickerChange}
                    />
                  </Form.Item>
                </Col>
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                  <FormInput
                    labelCol={"24"}
                    wrapperCol={"24"}
                    readOnly={true}
                    type="text"
                    name={"time"}
                    label={<IntlMessages id={"Addnew.health.label.time"} />}
                    rules={[
                      {
                        required: true,
                        message: (
                          <IntlMessages id={"Addnew.health.validator.time"} />
                        ),
                      },
                    ]}
                  />
                </Col>
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                  <FormInput
                    labelCol={"24"}
                    wrapperCol={"24"}
                    type="number"
                    name={"bp"}
                    suffix={
                      <Text style={{ color: "#038FDE" }} strong>
                        mmHG
                      </Text>
                    }
                    label={<IntlMessages id={"Addnew.health.label.BP"} />}
                    rules={[
                      {
                        required: true,
                        message: (
                          <IntlMessages id={"Addnew.health.validator.BP"} />
                        ),
                      },
                    ]}
                  />
                </Col>
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                  <FormInput
                    labelCol={"24"}
                    wrapperCol={"24"}
                    type="number"
                    name={"pulse"}
                    suffix={
                      <Text style={{ color: "#038FDE" }} strong>
                        bpm
                      </Text>
                    }
                    label={<IntlMessages id={"Addnew.health.label.pulse"} />}
                    rules={[
                      {
                        required: true,
                        message: (
                          <IntlMessages id={"Addnew.health.validator.pulse"} />
                        ),
                      },
                    ]}
                  />
                </Col>{" "}
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                  <FormInput
                    labelCol={"24"}
                    wrapperCol={"24"}
                    type="number"
                    name={"temperature"}
                    suffix={
                      <Text style={{ color: "#038FDE" }} strong>
                        °F
                      </Text>
                    }
                    label={
                      <IntlMessages id={"Addnew.health.label.temperature"} />
                    }
                    rules={[
                      {
                        required: true,
                        message: (
                          <IntlMessages
                            id={"Addnew.health.validator.temperature"}
                          />
                        ),
                      },
                    ]}
                  />
                </Col>{" "}
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                  <FormInput
                    labelCol={"24"}
                    wrapperCol={"24"}
                    type="number"
                    name={"weight"}
                    suffix={
                      <Text style={{ color: "#038FDE" }} strong>
                        Kg
                      </Text>
                    }
                    label={<IntlMessages id={"Addnew.health.label.weight"} />}
                    rules={[
                      {
                        required: true,
                        message: (
                          <IntlMessages id={"Addnew.health.validator.weight"} />
                        ),
                      },
                    ]}
                  />
                </Col>{" "}
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                  <FormInput
                    labelCol={"24"}
                    wrapperCol={"24"}
                    type="number"
                    name={"spo2"}
                    suffix={
                      <Text style={{ color: "#038FDE" }} strong>
                        %
                      </Text>
                    }
                    label={<IntlMessages id={"Addnew.health.label.SPO2"} />}
                    rules={[
                      {
                        required: true,
                        message: (
                          <IntlMessages id={"Addnew.health.validator.SPO2"} />
                        ),
                      },
                    ]}
                  />
                </Col>{" "}
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                  <FormInput
                    labelCol={"24"}
                    wrapperCol={"24"}
                    type="number"
                    name={"exercise"}
                    suffix={
                      <Text style={{ color: "#038FDE" }} strong>
                        mins
                      </Text>
                    }
                    label={<IntlMessages id={"Addnew.health.label.exercise"} />}
                    rules={[
                      {
                        required: true,
                        message: (
                          <IntlMessages
                            id={"Addnew.health.validator.exercise"}
                          />
                        ),
                      },
                    ]}
                  />
                </Col>
              </Row>

              <Row>
                <Col
                  xl={23}
                  align="right"
                  className="button-alignment-pharmacy cancel-btn mr-10"
                >
                  <Link
                    to={{
                      pathname: "/health-management/vitals",
                    }}
                  >
                    <ButtonComponent
                      type="danger"
                      label="Cancel"
                      className="btn-cancel secondary-color"
                    />
                  </Link>
                  <ButtonComponent
                    icon={<CreditCardOutlined />}
                    label={"Add Vitals"}
                    htmlType="submit"
                    type="primary"
                  />
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Spin>{" "}
    </Card>
  );
};

export default AddVitals;
