import React, { useEffect, useState } from "react";
import { Card, Col, Form, Input, Row, Spin, Typography } from "antd";
import IntlMessages from "../../../util/IntlMessages";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import FormInput from "../../../components/Input/FormInput";
import FormItem from "antd/es/form/FormItem";
import "../../../styles/modules/pharmacy.less";
import { LoadingOutlined } from "@ant-design/icons";
import {
  editPharmacyProfileInProgress,
  getPharmacyProfileInProgress,
} from "../../../appRedux/Pharmacy/PharmacyAction";
import { useDispatch, useSelector } from "react-redux";
const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const PharmacyProfileScreen = () => {
  const [photo123, setphoto123] = useState(null);

  const [image, setImage] = useState(null);
  const [LoadingPage, setLoadingPage] = useState(false);
  const pharmacyId = localStorage.getItem("pharmacy_id");
  const dispatch = useDispatch();
  const profileData = useSelector((state) => state?.pharmacy?.pharmacyProfile);
  const EditProfileData = useSelector(
    (state) => state?.pharmacy?.editPharmacyProfile
  );
  const userData = profileData?.data?.data;

  const [form] = Form.useForm();

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const onFinish = (datas) => {
    const formData = {
      firstName: datas?.firstName,
      lastName: datas?.lastName,
      email: datas?.email,
      contact: datas?.contact,
      profile_image: image,
    };
    dispatch(editPharmacyProfileInProgress({ formData, id: pharmacyId }));
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );

  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();

  useEffect(() => {
    dispatch(getPharmacyProfileInProgress(pharmacyId));
  }, []);
  useEffect(() => {
    if (profileData?.success) {
      form.setFieldsValue(userData);
      if (userData?.profile_url) {
        setphoto123(userData.profile_image);
        form.setFieldValue("org_name", userData?.organization_name);
        form.setFieldValue("profile_url", userData?.profile_image);
      }
    }
  }, [profileData]);
  useEffect(() => {
    if (EditProfileData?.inProgress) {
      setLoadingPage(true);
    } else {
      setLoadingPage(false);
    }
  }, [EditProfileData]);
  function encodeImageFileAsURL(e) {
    var file = e.target.files[0];
    setphoto123(URL.createObjectURL(e.target.files[0]));
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = function () {
      var base64 = reader.result.replace(/^data:image\/\w+;base64,/, "");
      setImage(base64);
    };
  }
  return (
    <Card style={{ border: "2px solid blue" }}>
      <Spin indicator={antIcon} spinning={LoadingPage}>
        <Row>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Typography className="header-tag">
              {<IntlMessages id={"label.manageProfile"} />}
            </Typography>
          </Col>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Form
              labelCol={{
                span: 24,
              }}
              wrapperCol={{
                span: 24,
              }}
              initialValues={{
                remember: true,
              }}
              colon={"true"}
              onFinish={onFinish}
              autoComplete="off"
              form={form}
            >
              <Row className="register-row">
                <Col
                  xl={23}
                  lg={23}
                  md={23}
                  sm={23}
                  xs={23}
                  className="justify-center"
                >
                  <Col className="img">
                    <img className="img-btn" src={photo123} />
                  </Col>
                </Col>{" "}
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                  <FormItem
                    label="Profile"
                    rules={[
                      {
                        required: true,
                        message: "Please upload your image!",
                      },
                    ]}
                    name="profile_url"
                    valuePropName="fileList"
                  >
                    <Input
                      type={"file"}
                      accept="image/*"
                      name="profile_url"
                      onChange={(e) => encodeImageFileAsURL(e)}
                    />
                  </FormItem>
                </Col>
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                  <FormInput
                    labelCol={"24"}
                    wrapperCol={"24"}
                    type="text"
                    name={"firstName"}
                    label={
                      <IntlMessages id={"Addnew.pharmacy.label.firstName"} />
                    }
                    rules={[
                      {
                        required: true,
                        message: (
                          <IntlMessages
                            id={"Addnew.pharmacy.validator.firstName"}
                          />
                        ),
                      },
                    ]}
                  />
                </Col>
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                  <FormInput
                    labelCol={"24"}
                    wrapperCol={"24"}
                    type="text"
                    name={"lastName"}
                    label={
                      <IntlMessages id={"Addnew.pharmacy.label.lastName"} />
                    }
                    rules={[
                      {
                        required: true,
                        message: (
                          <IntlMessages
                            id={"Addnew.pharmacy.validator.lastName"}
                          />
                        ),
                      },
                    ]}
                  />
                </Col>
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                  <FormInput
                    labelCol={"24"}
                    wrapperCol={"24"}
                    type="text"
                    name={"email"}
                    label={<IntlMessages id={"Addnew.pharmacy.label.email"} />}
                    rules={[
                      {
                        required: true,
                        message: (
                          <IntlMessages
                            id={"Addnew.pharmacy.validator.email"}
                          />
                        ),
                      },
                      {
                        pattern: emailRegex,
                        message: "Please enter a valid email address",
                      },
                    ]}
                  />
                </Col>
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                  <FormInput
                    labelCol={"24"}
                    wrapperCol={"24"}
                    type="text"
                    name={"contact"}
                    label={<IntlMessages id={"Addnew.pharmacy.label.mobile"} />}
                    rules={[
                      {
                        required: true,
                        message: (
                          <IntlMessages
                            id={"Addnew.pharmacy.validator.mobile"}
                          />
                        ),
                      },
                      {
                        pattern: /^[0-9]{10}$/,
                        message: "Please enter a valid 10-digit mobile number!",
                      },
                    ]}
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <FormInput
                    type="text"
                    placeholder="Organization Name"
                    name="org_name"
                    labelCol={"24"}
                    wrapperCol={"24"}
                    label={
                      <IntlMessages id={"Profile.myprofile.label.orgName"} />
                    }
                    readOnly={true}
                  />
                </Col>
              </Row>

              <Row style={{ marginTop: "10px" }}>
                <Col
                  xl={23}
                  align="right"
                  className="button-alignment-pharmacy"
                >
                  <ButtonComponent
                    label="Update"
                    htmlType="submit"
                    type="primary"
                  />
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Spin>
    </Card>
  );
};

export default PharmacyProfileScreen;
