import {
  Card,
  Col,
  Form,
  Row,
  Divider,
  Checkbox,
  Spin,
  Modal,
  Typography,
  Radio,
} from "antd";
import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { addPrescriptionInProgress } from "../../../appRedux/doctor/actions/Prescription";

import ButtonComponent from "../../../components/Button/ButtonComponent";

import IntlMessages from "../../../util/IntlMessages";
import PrescriptionHeader from "./PrescriptionHeader";
import {
  DeleteOutlined,
  LoadingOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import DoctorSelectBox from "../../Doctor/component/DoctorSelectBox";
import Auxiliary from "../../../util/Auxiliary";

import FormInput from "../../../components/Input/FormInput";

import { getTabletsIdInProgress } from "../../../appRedux/Pharmacy/PharmacyAction";
import { appointmentStatusUpdateInProgress } from "../../../appRedux/doctor/actions/Appointment";
import { updateTrackerStatusProgress } from "../../../appRedux/doctor/actions/Trackers";

const PrescriptionAddForm = (props) => {
  const Appoinntment_details =
    props.location.state?.tracker === true
      ? props.location.state?.record?.Appointment
      : props.location.state;
  console.log("Appoinntment_details", props.location.state?.tracker);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );

  const [keys, setKeys] = useState();

  const [error, setError] = useState([]);
  const [prescription, setPrescription] = useState(0);
  const [navigate, setNavigate] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const details = useParams();
  const history = useHistory();

  const { addprescription } = useSelector((state) => state?.prescription);
  const { appointmentStatus } = useSelector((state) => state.appointment);
  const { getTabletsId } = useSelector((state) => state.pharmacy);
  const { updateTrackerStaut } = useSelector((state) => state?.tracker);
  console.log("updateTrackerStaut", updateTrackerStaut);

  useEffect(() => {
    const fromData = { pagination_required: false, page: 1 };
    dispatch(getTabletsIdInProgress(fromData));
  }, []);

  const TABLETS_LIST =
    getTabletsId &&
    getTabletsId?.data?.response?.data?.map((row) => ({
      label: `${row.medicine_name} (${
        row?.total_available_stock ? row?.total_available_stock : 0
      })`,
      value: row.medicine_name,
      id: row.id,
      type: row.product_type,
    }));
  function handleFinish(values) {
    if (prescription === 2) {
      if (props.location.state?.tracker === true) {
        values.status = 3;
        values.remarks = values?.special_instructions;
        const payload = {
          id: props.location.state.record?.id,
          data: values,
        };
        dispatch(updateTrackerStatusProgress(payload));
        setNavigate(true);
      } else {
        values.appointment_status = 4;
        values.remarks = values?.special_instructions;
        const payload = {
          id: Appoinntment_details?.id,
          data: values,
        };
        dispatch(appointmentStatusUpdateInProgress(payload));
        setNavigate(true);
      }
    } else {
      const pd = values?.prescription_details.map((value) => {
        return {
          ...value,
          morning: value?.morning === true ? 1 : 0,
          lunch: value?.lunch === true ? 1 : 0,
          evening: value?.evening === true ? 1 : 0,
          night: value?.night === true ? 1 : 0,
        };
      });
      values.prescription_details = pd;
      values.patient_id = Appoinntment_details?.patient_id;
      values.doctor_id = Appoinntment_details?.doctor_id;
      values.appointment_id = Appoinntment_details?.id;
      values.appointment_date = Appoinntment_details?.date;
      props.location.state?.record?.Patient?.firstName
        ? (values.patient_name =
            props.location.state?.record?.Patient?.firstName)
        : (values.patient_name = Appoinntment_details?.patient_name);
      props.location.state?.record?.refDoctor?.firstName
        ? (values.doctor_name =
            props.location.state?.record?.refDoctor?.firstName)
        : (values.doctor_name = Appoinntment_details?.doctor_name);
      props.location.state?.tracker === true &&
        (values.tracker_id = props.location.state.record?.id);

      dispatch(addPrescriptionInProgress(values));
      setNavigate(true);
    }
  }

  useEffect(() => {
    if (
      (addprescription?.success && navigate) ||
      (appointmentStatus?.success && navigate) ||
      (updateTrackerStaut?.success && navigate)
    ) {
      history.goBack();
    }
    setNavigate(false);
  }, [
    addprescription?.success,
    appointmentStatus?.success,
    updateTrackerStaut?.success,
  ]);

  const Special_info_value = [
    { value: "BF", label: "Before Food" },
    { value: "AF", label: "After Food" },
  ];
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const initialValues = {
    prescription_details: [{}],
  };
  const onChangeTablet = (value, index) => {
    form.setFieldValue(
      [`prescription_details`, index, "medicine_id"],
      value.id
    );
    form.setFieldValue(
      [`prescription_details`, index, "medicine_type"],
      value.type
    );
    setKeys(index);
  };

  useEffect(() => {
    const tabletMap = {};
    const repeatedIndices = [];

    if (Number(keys) >= 0) {
      const values = form.getFieldsValue();

      // const duplicet = values.prescription_details.map((val, i) => {
      //   console.log(val.medicine_id === value.id);
      //   if (val.medicine_id === value.id) {
      //     return {
      //       id: val.medicine_id,
      //       index: i,
      //     };
      //   }
      //   return;
      // });
      values?.prescription_details.forEach((item, index) => {
        if (!item) {
          return;
        } else if (!item.medicine_id) {
          return;
        }

        const tabletId = item.medicine_id;
        const batchNo = item.batch_no;

        const key = tabletId;

        if (tabletMap[key] !== undefined) {
          repeatedIndices.push(index, tabletMap[key]);
        } else {
          tabletMap[key] = index;
        }
      });

      if (repeatedIndices.length > 0) {
        setError(repeatedIndices);
      } else {
        setError(null);
      }
    }

    setKeys();
  }, [keys]);
  return (
    <Auxiliary>
      <Spin
        indicator={antIcon}
        spinning={
          addprescription?.progressing ||
          appointmentStatus?.progressing ||
          updateTrackerStaut?.inProgress
        }
      >
        <PrescriptionHeader />

        <Card>
          <Form
            form={form}
            onFinish={handleFinish}
            initialValues={initialValues}
          >
            <Form.Item label={"Prescription"} className="mb-10">
              <Radio.Group
                onChange={(e) => {
                  setPrescription(e?.target?.value);
                }}
                value={prescription}
              >
                <Radio value={1}>Appointment Complete With Prescription</Radio>
                <Radio value={2}>
                  Appointment Complete WithOut Prescription
                </Radio>
              </Radio.Group>
            </Form.Item>
            {prescription === 2 && (
              <>
                <FormInput
                  type="textarea"
                  placeholder="Special Instruction"
                  name={"special_instructions"}
                  rules={[
                    {
                      required: true,
                      message: (
                        <IntlMessages
                          id={
                            "Addnew.prescription.validator.special_instructions"
                          }
                        />
                      ),
                    },
                  ]}
                />
              </>
            )}
            {prescription === 1 && (
              <>
                <Form.List name="prescription_details">
                  {(fields, { add, remove }) => {
                    return (
                      <div>
                        {fields.map((field, index) => (
                          <Row key={field.key}>
                            <Divider>Tablet {index + 1}</Divider>
                            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                              <DoctorSelectBox
                                labelCol={"24"}
                                wrapperCol={"24"}
                                className="pr-20"
                                options={TABLETS_LIST}
                                label={"Medicaine"}
                                placeholder={"Tablet Name"}
                                rules={[
                                  {
                                    required: true,
                                    message: (
                                      <IntlMessages
                                        id={
                                          "Addnew.prescription.validator.tabletname"
                                        }
                                      />
                                    ),
                                  },
                                ]}
                                name={[index, "medicine_name"]}
                                editMode={true}
                                onChange={(value) =>
                                  onChangeTablet(value, index)
                                }
                              />
                              {error?.find((data) => data === index) === 0 ||
                              error?.find((data) => data === index) ? (
                                <Typography className="red-text mb-10 ">
                                  The selected medicine is already exists
                                </Typography>
                              ) : null}
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                              <FormInput
                                // labelCol={"24"}
                                // wrapperCol={"24"}
                                label={"Medicine Type"}
                                type="text"
                                placeholder="Medicine Type"
                                name={[index, "medicine_type"]}
                                readOnly={true}
                                rules={[
                                  {
                                    required: false,
                                    message: <IntlMessages id={" "} />,
                                  },
                                ]}
                              />
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                              <DoctorSelectBox
                                className="pr-20"
                                options={Special_info_value}
                                placeholder={"Special Info"}
                                label={"AF/BF"}
                                rules={[
                                  {
                                    required: true,
                                    message: (
                                      <IntlMessages
                                        id={
                                          "Addnew.prescription.validator.Special_info"
                                        }
                                      />
                                    ),
                                  },
                                ]}
                                name={[index, "type"]}
                                editMode={true}
                              />
                            </Col>{" "}
                            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                              <FormInput
                                // labelCol={"24"}
                                // wrapperCol={"24"}
                                label={"Dosage"}
                                type="text"
                                placeholder="Dosage"
                                name={[index, "dosage"]}
                                rules={[
                                  {
                                    required: true,
                                    message: (
                                      <IntlMessages
                                        id={
                                          "Addnew.prescription.validator.dosage"
                                        }
                                      />
                                    ),
                                  },
                                ]}
                              />
                            </Col>{" "}
                            <Col xs={12} sm={6} md={3} lg={3} xl={3}>
                              {" "}
                              <Form.Item
                                name={[index, "morning"]}
                                valuePropName="checked"
                              >
                                <Checkbox checked={false}>Morning</Checkbox>
                              </Form.Item>
                            </Col>{" "}
                            <Col xs={12} sm={6} md={3} lg={3} xl={3}>
                              {" "}
                              <Form.Item
                                name={[index, "lunch"]}
                                valuePropName="checked"
                              >
                                <Checkbox checked={false}> lunch</Checkbox>
                              </Form.Item>
                            </Col>{" "}
                            <Col xs={12} sm={6} md={3} lg={3} xl={3}>
                              {" "}
                              <Form.Item
                                name={[index, "evening"]}
                                valuePropName="checked"
                              >
                                <Checkbox checked={false}>Evening</Checkbox>
                              </Form.Item>
                            </Col>{" "}
                            <Col xs={12} sm={6} md={3} lg={3} xl={3}>
                              {" "}
                              <Form.Item
                                name={[index, "night"]}
                                valuePropName="checked"
                              >
                                <Checkbox checked={false}>Night</Checkbox>
                              </Form.Item>
                            </Col>{" "}
                            {index != 0 ? (
                              <>
                                <Col xs={24}>
                                  <Form.Item>
                                    <ButtonComponent
                                      type={"danger"}
                                      icon={<DeleteOutlined />}
                                      onClick={() => {
                                        remove(field.name);
                                        setKeys(index);
                                      }}
                                    />
                                  </Form.Item>
                                </Col>
                              </>
                            ) : (
                              ""
                            )}
                          </Row>
                        ))}

                        <Divider />

                        <Row>
                          {" "}
                          <Col xs={12} sm={6} md={3} lg={3} xl={3}>
                            <Form.Item>
                              <ButtonComponent
                                type="primary"
                                label={"Add New Tablet"}
                                icon={<PlusCircleOutlined />}
                                onClick={() => add()}
                              />
                            </Form.Item>
                          </Col>{" "}
                        </Row>
                      </div>
                    );
                  }}
                </Form.List>
                <FormInput
                  type="text"
                  placeholder="Special Instruction"
                  name={"special_instructions"}
                  rules={[
                    {
                      required: true,
                      message: (
                        <IntlMessages
                          id={
                            "Addnew.prescription.validator.special_instructions"
                          }
                        />
                      ),
                    },
                  ]}
                />

                <FormInput
                  type="number"
                  placeholder="Days"
                  name="days"
                  rules={[
                    {
                      required: true,
                      message: (
                        <IntlMessages
                          id={"Addnew.prescription.validator.days"}
                        />
                      ),
                    },
                  ]}
                />
              </>
            )}

            <ButtonComponent
              label={"Submit"}
              type="primary"
              htmlType="submit"
              disabled={error?.length > 0}
            />
          </Form>
        </Card>
      </Spin>{" "}
      <Modal
        title="Medicines"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>to view prescription</p>
      </Modal>
    </Auxiliary>
  );
};

export default PrescriptionAddForm;
