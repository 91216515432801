import {
  GET_DOCTOR_DASHBOARD_ERROR,
  GET_DOCTOR_DASHBOARD_PROGRESS,
  GET_DOCTOR_DASHBOARD_SUCCESS,
} from "../../../constants/ActionTypes";

const defaultStatus = {
  progressing: false,
  success: false,
  error: false,
  data: [],
};
const initialState = {
  doctorDashBoard: { ...defaultStatus },
};

const dashBoardReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_DOCTOR_DASHBOARD_PROGRESS:
      return {
        ...state,
        doctorDashBoard: {
          ...defaultStatus,
          ...payload,
          progressing: true,
        },
      };
    case GET_DOCTOR_DASHBOARD_SUCCESS:
      return {
        ...state,
        doctorDashBoard: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case GET_DOCTOR_DASHBOARD_ERROR:
      return {
        ...state,
        doctorDashBoard: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };

    default:
      return state;
  }
};

export default dashBoardReducer;
