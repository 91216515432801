import {
  ADD_DISEASES_ERROR,
  ADD_DISEASES_PROGRESS,
  ADD_DISEASES_SUCCESS,
  ADD_QUESTIONS_ANSWER_ERROR,
  ADD_QUESTIONS_ANSWER_PROGRESS,
  ADD_QUESTIONS_ANSWER_SUCCESS,
  DELETE_DISEASES_ERROR,
  DELETE_DISEASES_PROGRESS,
  DELETE_DISEASES_SUCCESS,
  DELETE_QUESTIONS_ANSWER_ERROR,
  DELETE_QUESTIONS_ANSWER_PROGRESS,
  DELETE_QUESTIONS_ANSWER_SUCCESS,
  GET_DISEASES_LIST_ERROR,
  GET_DISEASES_LIST_PROGRESS,
  GET_DISEASES_LIST_SUCCESS,
  GET_QUESTIONS_ANSWER_BY_DISEASES_LIST_ERROR,
  GET_QUESTIONS_ANSWER_BY_DISEASES_LIST_PROGRESS,
  GET_QUESTIONS_ANSWER_BY_DISEASES_LIST_SUCCESS,
  GET_QUESTIONS_ANSWER_LIST_ERROR,
  GET_QUESTIONS_ANSWER_LIST_PROGRESS,
  GET_QUESTIONS_ANSWER_LIST_SUCCESS,
  UPDATE_DISEASES_ERROR,
  UPDATE_DISEASES_PROGRESS,
  UPDATE_DISEASES_SUCCESS,
  UPDATE_QUESTIONS_ANSWER_ERROR,
  UPDATE_QUESTIONS_ANSWER_PROGRESS,
  UPDATE_QUESTIONS_ANSWER_SUCCESS,
} from "../../../constants/ActionTypes";

// add diseases Payment
export const addDiseasesInProgress = (payload) => ({
  type: ADD_DISEASES_PROGRESS,
  payload: payload,
});

export const addDiseasesInSuccess = (payload) => ({
  type: ADD_DISEASES_SUCCESS,
  payload: payload,
});

export const addDiseasesInError = (payload) => ({
  type: ADD_DISEASES_ERROR,
  payload: payload,
});

// GET diseases Payment
export const getDiseaseListInProgress = (payload) => ({
  type: GET_DISEASES_LIST_PROGRESS,
  payload: payload,
});

export const getDiseaseListInSuccess = (payload) => ({
  type: GET_DISEASES_LIST_SUCCESS,
  payload: payload,
});

export const getDiseaseListInError = (payload) => ({
  type: GET_DISEASES_LIST_ERROR,
  payload: payload,
});

// Update diseases Payment
export const updateDiseasesInProgress = (payload) => ({
  type: UPDATE_DISEASES_PROGRESS,
  payload: payload,
});

export const updateDiseasesInSuccess = (payload) => ({
  type: UPDATE_DISEASES_SUCCESS,
  payload: payload,
});

export const updateDiseasesInError = (payload) => ({
  type: UPDATE_DISEASES_ERROR,
  payload: payload,
});

// Delete diseases Payment
export const deleteDiseasesInProgress = (payload) => ({
  type: DELETE_DISEASES_PROGRESS,
  payload: payload,
});

export const deleteDiseasesInSuccess = (payload) => ({
  type: DELETE_DISEASES_SUCCESS,
  payload: payload,
});

export const deleteDiseasesInError = (payload) => ({
  type: DELETE_DISEASES_ERROR,
  payload: payload,
});

// add questions answer Payment
export const addQuestionAnswerInProgress = (payload) => ({
  type: ADD_QUESTIONS_ANSWER_PROGRESS,
  payload: payload,
});

export const addQuestionAnswerInSuccess = (payload) => ({
  type: ADD_QUESTIONS_ANSWER_SUCCESS,
  payload: payload,
});

export const addQuestionAnswerInError = (payload) => ({
  type: ADD_QUESTIONS_ANSWER_ERROR,
  payload: payload,
});

// GET questions answer Payment

export const getQuestionAnswerListInProgress = (payload) => ({
  type: GET_QUESTIONS_ANSWER_LIST_PROGRESS,
  payload: payload,
});

export const getQuestionAnswerListInSuccess = (payload) => ({
  type: GET_QUESTIONS_ANSWER_LIST_SUCCESS,
  payload: payload,
});

export const getQuestionAnswerListInError = (payload) => ({
  type: GET_QUESTIONS_ANSWER_LIST_ERROR,
  payload: payload,
});

// Update questions answer Payment

export const updateQuestionAnswerInProgress = (payload) => ({
  type: UPDATE_QUESTIONS_ANSWER_PROGRESS,
  payload: payload,
});

export const updateQuestionAnswerInSuccess = (payload) => ({
  type: UPDATE_QUESTIONS_ANSWER_SUCCESS,
  payload: payload,
});

export const updateQuestionAnswerInError = (payload) => ({
  type: UPDATE_QUESTIONS_ANSWER_ERROR,
  payload: payload,
});

// Delete questions answer Payment
export const deleteQuestionAnswerInProgress = (payload) => ({
  type: DELETE_QUESTIONS_ANSWER_PROGRESS,
  payload: payload,
});

export const deleteQuestionAnswerInSuccess = (payload) => ({
  type: DELETE_QUESTIONS_ANSWER_SUCCESS,
  payload: payload,
});

export const deleteQuestionAnswerInError = (payload) => ({
  type: DELETE_QUESTIONS_ANSWER_ERROR,
  payload: payload,
});

// GET questions answer  by diseases

export const getQuestionAnswerByDiseasesListInProgress = (payload) => ({
  type: GET_QUESTIONS_ANSWER_BY_DISEASES_LIST_PROGRESS,
  payload: payload,
});

export const getQuestionAnswerByDiseasesListInSuccess = (payload) => ({
  type: GET_QUESTIONS_ANSWER_BY_DISEASES_LIST_SUCCESS,
  payload: payload,
});

export const getQuestionAnswerByDiseasesListInError = (payload) => ({
  type: GET_QUESTIONS_ANSWER_BY_DISEASES_LIST_ERROR,
  payload: payload,
});
