import {
  Alert,
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Row,
  Spin,
  Table,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";

import { Link, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import ButtonComponent from "../../../components/Button/ButtonComponent";

import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { currentDate } from "../../../util/utilz";
import TableComponent from "../../../components/Table/TableComponent";
import { APP_ROUTES } from "../../../constants/Routes";
import IntlMessages from "../../../util/IntlMessages";
import moment from "moment";
import { getOutDoctorListInprogress } from "../../../appRedux/admin/Doctor/actions/Doctors";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
const { RangePicker } = DatePicker;
const OutDoctorTable = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const doctorData = useSelector((state) => state?.doctor?.outDoctorList);
  const [filteredUsers, setFilteredUsers] = useState("");
  const doctorTotalRecords = 2;
  const [date, setDate] = useState([]);
  const [dates, setDates] = useState(false);
  console.log("doctorData", doctorData);
  const outDoctorOrgData = doctorData?.data;
  const handleDoctorSelectChange = (value) => {
    if (value === "all") {
      setFilteredUsers(outDoctorOrgData);
    } else {
      const newData = outDoctorOrgData.slice(0, parseInt(value, 10));
      setFilteredUsers(newData);
    }
  };
  const handlePage = (page) => {
    // dispatch(getDoctorListInProgress(page));
  };
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );
  const columns = [
    { title: "Id", dataIndex: "doctor_serial_no" },
    {
      title: "Name",
      dataIndex: "firstName",
      sortDirections: ["ascend", "descend"],
      key: "firstName",
      render: (_, record) => `${record.firstName} ${record.lastName}`,
      sorter: (a, b) => a.firstName.localeCompare(b.firstName),
    },

    {
      title: "Qualification",
      dataIndex: "qualification",
      sortDirections: ["ascend", "descend"],
      key: "qualification",
    },

    {
      title: "Commission Fee",
      dataIndex: "doctor_commission",
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "ppointment Date",
      dataIndex: "appointmentCount",
      render: (_, record) => record.Appointments.length,
    },
    // {
    //   title: "Appointment Date",
    //   dataIndex: "Appointments",
    //   render: (Appointments) => {
    //       ? Appointments.map((app) =>
    //           moment(app.date).format("YYYY-MM-DD")
    //         ).join(", ")
    //       : "N/A";
    //   },
    // },
    {
      title: "Total Commission Fee",
      dataIndex: "totalEarnings",
      render: (_, record) => {
        const totalEarnings = record.Appointments.reduce((acc, appointment) => {
          return record.Appointments.length * record.doctor_commission;
        }, 0);
        return `₹${totalEarnings.toFixed(2)}`;
      },
      sortDirections: ["ascend", "descend"],
    },
  ];
  const handleReport = (dates) => {
    setDate(dates);
    const payload = {
      to_date: moment(date[1]).format("YYYY-MM-DD"),
      from_date: moment(date[0]).format("YYYY-MM-DD"),
    };
    dispatch(getOutDoctorListInprogress(payload));
  };

  const hadleDateSearch = () => {};

  useEffect(() => {
    if (location?.state?.startDate && location?.state?.endDate) {
      setDate([
        moment(location.state.startDate),
        moment(location.state.endDate),
      ]);
      setDates(true);
    } else if (location?.state === undefined) {
      setDates(false);
    } else {
      setDates(false);
    }
  }, [location.state]);
  console.log("date", date, location);
  useEffect(() => {
    if (doctorData?.success) {
      setDates(true);
    } else if (doctorData?.progressing || doctorData?.error) {
      setDates(false);
    }
  }, [doctorData, location.state]);
  useEffect(() => {
    if (location?.state === undefined) {
      setDates(false);
    }
  }, []);
  return (
    <Row className="p-10 Add-button">
      <Col>
        <p className="header-tag">{<IntlMessages id={"Addnew.outDoctor"} />}</p>
      </Col>
      <Col>
        <RangePicker size={"large"} onChange={handleReport} value={date} />
        {/* <ButtonComponent
          label={"Search"}
          className={"ml-10 mt-8"}
          onClick={hadleDateSearch}
        /> */}
        {dates && (
          <Link
            to={{
              pathname: "/out-doctor-report",
              state: {
                startDate: moment(date[0]).format("YYYY-MM-DD"),
                endDate: moment(date[1]).format("YYYY-MM-DD"),
              },
            }}
          >
            <Button type="primary" className="mt-8 ml-10">
              Out Doctor Report
            </Button>
          </Link>
        )}
      </Col>

      <Divider />
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <Spin indicator={antIcon} spinning={doctorData.progressing}>
          {dates ? (
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <TableComponent
                columns={columns}
                dataSource={
                  date.length === 0
                    ? []
                    : filteredUsers
                    ? filteredUsers
                    : outDoctorOrgData
                }
                pagination={{
                  total: doctorTotalRecords,
                  onChange: (page) => handlePage(page),
                }}
                doctorRowselectors={handleDoctorSelectChange}
                itemList={outDoctorOrgData}
                filteredUsers={filteredUsers}
                setFilteredUsers={setFilteredUsers}
                field={"firstName"}
                documentTitle={"Doctor"}
              />
            </Col>
          ) : (
            <Col
              xl={20}
              lg={20}
              md={20}
              sm={24}
              xs={24}
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Alert
                message="No data available"
                description="Please select a date range.."
                type="info"
                showIcon
              />
            </Col>
          )}
        </Spin>
      </Col>
    </Row>
  );
};

export default OutDoctorTable;
