import { Row, Col, Form, Spin } from "antd";
import React, { useEffect, useState } from "react";

import IntlMessages from "../../../util/IntlMessages";
import FormInput from "../../../components/Input/FormInput";

import FormSelectBox from "../../../components/SelectBox/SelectBox";

import ButtonComponent from "../../../components/Button/ButtonComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  addSubscriptionPaymentInProgress,
  getsubscriptionListInProgress,
} from "../../../appRedux/super-admin/SuperAdminAction";
import { getGstListInProgress } from "../../../appRedux/Pharmacy/PharmacyAction";
import { LoadingOutlined } from "@ant-design/icons";

const PaymentForm = ({
  selectedHospital,

  paymentModal,
  setPaymentModal,
  orgDetails,
}) => {
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const dispatch = useDispatch();
  const [packNames, setPackNames] = useState([]);
  const [gstValue, setGstValue] = useState("");
  const [Loading, setLoading] = useState(false);
  const [subscriptionValue, setSubscriptionValue] = useState("Yes");
  const subscriptionistData = useSelector(
    (state) => state.superAdmin?.subscriptionList
  );
  const subscriptionDetailsData = useSelector(
    (state) => state.superAdmin?.addSubscriptionDetails
  );
  const addPaymentData = useSelector(
    (state) => state.superAdmin?.addubscriptionPayment
  );
  const gstData = useSelector((state) => state.pharmacy?.gstList);

  useEffect(() => {
    const payload = { pagination: false, pageSize: "10", pageNo: 1 };
    dispatch(getsubscriptionListInProgress(payload));
  }, []);

  useEffect(() => {
    if (selectedHospital?.paymentCompleted === "No") {
      form2.setFieldsValue({
        pricing: selectedHospital?.Subscription?.toatl,
        period: selectedHospital?.Subscription?.periods,
        gst: selectedHospital?.Subscription?.gst,
        subscription: selectedHospital?.subscription_id,
      });
      setGstValue(selectedHospital?.Subscription?.gst);
    } else {
      console.log("subscriptionDetailsData", subscriptionDetailsData);
      if (subscriptionDetailsData?.success) {
        setPaymentModal(true);
        form2.setFieldsValue({
          pricing: subscriptionDetailsData?.data?.data?.pricing,
          period: subscriptionDetailsData?.data?.data?.periods,
          gst: "10",
          subscription: subscriptionDetailsData?.data?.data?.subscription_id,
        });
      }
    }
  }, [subscriptionDetailsData, selectedHospital]);
  const paymentConfirm = (datas) => {
    if (selectedHospital?.paymentCompleted === "No") {
      const payload = {
        subscriptiondetails_id: selectedHospital?.id,
        organization_id: selectedHospital?.Organization?.id,
        pricing: selectedHospital?.Subscription?.pricing,
        paid: datas?.paidAmount,
        gst_amount: datas?.gst_amount,
        date: selectedHospital?.start_date,
        status: "1",
      };

      dispatch(addSubscriptionPaymentInProgress(payload));
    } else {
      const payload = {
        subscriptiondetails_id: subscriptionDetailsData?.data?.data?.id,
        organization_id: subscriptionDetailsData?.data?.data?.organization_id,
        pricing: subscriptionDetailsData?.data?.data?.pricing,
        paid: datas?.paidAmount,
        gst_amount: datas?.gst_amount,
        date: subscriptionDetailsData?.data?.data?.start_date,
        status: "1",
      };

      dispatch(addSubscriptionPaymentInProgress(payload));
    }
  };

  useEffect(() => {
    const payload = { pagination: false, pageSize: "10", pageNo: 1 };
    dispatch(getsubscriptionListInProgress(payload));
  }, [dispatch]);

  useEffect(() => {
    if (subscriptionistData?.data?.data) {
      const filtered = subscriptionistData.data.data.filter(
        (pack) =>
          pack.id === subscriptionDetailsData?.data?.data?.subscription_id
      );

      setPackNames(filtered);
    }
  }, [subscriptionistData, subscriptionDetailsData]);
  useEffect(() => {
    if (packNames[0]?.name === "Free Trail ") {
      form2.setFieldsValue({ paidAmount: "0" });
    }
  }, [packNames]);
  useEffect(() => {
    if (addPaymentData?.success) {
      setPaymentModal(false);
    } else if (addPaymentData?.inProgress) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [addPaymentData]);
  useEffect(() => {
    if (paymentModal === false) form2.resetFields();
  }, [paymentModal]);
  useEffect(() => {
    dispatch(getGstListInProgress());
  }, []);

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );
  return (
    <Form
      initialValues={{
        remember: true,
      }}
      colon={"true"}
      onFinish={paymentConfirm}
      autoComplete="off"
      form={form2}
    >
      <Spin indicator={antIcon} spinning={Loading}>
        <Row>
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            <Form.Item
              labelCol={{
                span: 24,
              }}
              wrapperCol={{
                span: 24,
              }}
              label={<IntlMessages id={"Addnew.admin.label.subscription"} />}
              name={"subscription"}
              rules={[
                {
                  required: true,
                  message: (
                    <IntlMessages id={"Addnew.admin.validator.subscription"} />
                  ),
                },
              ]}
            >
              <FormSelectBox
                placeholder="Select Subscription"
                disabled={true}
                value={subscriptionValue}
                options={
                  subscriptionistData?.data?.data?.map((pack, i) => ({
                    label: pack.name,
                    value: pack.id,
                    key: pack.id,
                  })) || []
                }
              />
            </Form.Item>
          </Col>{" "}
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            <FormInput
              labelCol={"24"}
              wrapperCol={"24"}
              name={"pricing"}
              readOnly={true}
              label={<IntlMessages id={"Addnew.superAdmin.label.pricing"} />}
            />
          </Col>{" "}
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            <FormInput
              labelCol={"24"}
              wrapperCol={"24"}
              name={"period"}
              readOnly={true}
              label={<IntlMessages id={"Addnew.superAdmin.label.period"} />}
            />
          </Col>{" "}
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            {" "}
            <Form.Item
              labelCol={{
                span: 24,
              }}
              wrapperCol={{
                span: 24,
              }}
              label={<IntlMessages id={"Addnew.admin.label.gst"} />}
              name={"gst"}
              rules={[
                {
                  required: true,
                  message: <IntlMessages id={"Addnew.admin.validator.gst"} />,
                },
              ]}
            >
              <FormSelectBox
                placeholder="Select GST"
                value={gstValue}
                readOnly={true}
                options={
                  gstData?.data?.data?.map((pack, i) => ({
                    label: pack.gst,
                    value: pack.id,
                    key: pack.id,
                  })) || []
                }
                //   onChange={changeGst}
              />
            </Form.Item>
          </Col>
          {selectedHospital &&
          selectedHospital?.paymentCompleted === "No" &&
          selectedHospital?.subscriptionPayments.length === 0 ? (
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <FormInput
                labelCol={"24"}
                wrapperCol={"24"}
                name={"paidAmount"}
                readOnly={
                  orgDetails?.Subscription?.plans === 1
                    ? false
                    : packNames[0]?.name === "Free Trail"
                    ? true
                    : false
                }
                type="number"
                label={
                  <IntlMessages id={"Addnew.superAdmin.label.paidAmount"} />
                }
                rules={[
                  {
                    required: true,
                    message: (
                      <IntlMessages id={"superAdmin.validator.paidAmount"} />
                    ),
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (
                        !value ||
                        parseFloat(value) <=
                          parseFloat(getFieldValue("pricing"))
                      ) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          `Paid amount should be less than pricing - ${getFieldValue(
                            "pricing"
                          )}`
                        )
                      );
                    },
                  }),
                ]}
              />
            </Col>
          ) : (
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <FormInput
                labelCol={"24"}
                wrapperCol={"24"}
                name={"paidAmount"}
                readOnly={
                  orgDetails?.Subscription?.plans === 1
                    ? false
                    : packNames[0]?.name === "Free Trail"
                    ? true
                    : false
                }
                type="number"
                label={
                  <IntlMessages id={"Addnew.superAdmin.label.paidAmount"} />
                }
                rules={[
                  {
                    required: true,
                    message: (
                      <IntlMessages id={"superAdmin.validator.paidAmount"} />
                    ),
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (
                        !value ||
                        parseFloat(value) <=
                          parseFloat(
                            selectedHospital?.pendingAmount
                              ? selectedHospital?.pendingAmount
                              : selectedHospital?.pricing
                              ? selectedHospital?.pricing
                              : subscriptionDetailsData?.data?.data?.pricing
                          )
                      ) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          `Paid amount should be less than ${
                            selectedHospital?.pendingAmount
                              ? selectedHospital?.pendingAmount
                              : selectedHospital?.pricing
                              ? selectedHospital?.pricing
                              : subscriptionDetailsData?.data?.data?.pricing
                          }`
                        )
                      );
                    },
                  }),
                ]}
              />
            </Col>
          )}
          <Col xl={24} className="my-10 mx-10">
            <ButtonComponent
              label="Confirm Payment"
              htmlType={"submit"}
              type="primary"
            />
          </Col>
        </Row>
      </Spin>
    </Form>
  );
};

export default PaymentForm;
