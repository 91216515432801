import {
  ADD_SPECIALIZATION_FAILURE,
  ADD_SPECIALIZATION_IN_PROGRESS,
  ADD_SPECIALIZATION_SUCCESS,
  DELETE_SPECIALIZATION_FAILURE,
  DELETE_SPECIALIZATION_IN_PROGRESS,
  DELETE_SPECIALIZATION_SUCCESS,
  GETDATA_SPECIALIZATION_FAILURE,
  GETDATA_SPECIALIZATION_IN_PROGRESS,
  GETDATA_SPECIALIZATION_SUCCESS,
  GET_SPECIALIZATION_FAILURE,
  GET_SPECIALIZATION_IN_PROGRESS,
  GET_SPECIALIZATION_SUCCESS,
  UPDATE_SPECIALIZATION_FAILURE,
  UPDATE_SPECIALIZATION_IN_PROGRESS,
  UPDATE_SPECIALIZATION_SUCCESS,
} from "../../../constants/ActionTypes";

const defaultStatus = {
  inProgress: false,
  success: false,
  error: false,
  data: null,
};
const initialState = {
  Specialization: { ...defaultStatus },
  addSpecialization: { ...defaultStatus },
  deleteSpecialization: { ...defaultStatus },
  getSpecialization: { ...defaultStatus },
  updateSpecialization: { ...defaultStatus },
};

const SpecailizationReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_SPECIALIZATION_IN_PROGRESS:
      return {
        ...state,
        Specialization: { ...defaultStatus, inProgress: true, ...payload },
        updateSpecialization: { ...defaultStatus },
        addSpecialization: { ...defaultStatus },
      };
    case GET_SPECIALIZATION_SUCCESS:
      return {
        ...state,
        Specialization: { ...defaultStatus, ...payload, success: true },
      };
    case GET_SPECIALIZATION_FAILURE:
      return {
        ...state,
        Specialization: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case ADD_SPECIALIZATION_IN_PROGRESS:
      return {
        ...state,
        addSpecialization: { ...defaultStatus, ...payload, inProgress: true },
      };
    case ADD_SPECIALIZATION_SUCCESS:
      return {
        ...state,
        addSpecialization: { ...defaultStatus, ...payload, success: true },
      };
    case ADD_SPECIALIZATION_FAILURE:
      return {
        ...state,
        addSpecialization: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case DELETE_SPECIALIZATION_IN_PROGRESS:
      return {
        ...state,
        deleteSpecialization: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case DELETE_SPECIALIZATION_SUCCESS:
      return {
        ...state,
        deleteSpecialization: { ...defaultStatus, ...payload, success: true },
      };
    case DELETE_SPECIALIZATION_FAILURE:
      return {
        ...state,
        deleteSpecialization: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case GETDATA_SPECIALIZATION_IN_PROGRESS:
      return {
        ...state,
        getSpecialization: { ...defaultStatus, ...payload, inProgress: true },
      };
    case GETDATA_SPECIALIZATION_SUCCESS:
      return {
        ...state,
        getSpecialization: { ...defaultStatus, ...payload, success: true },
      };
    case GETDATA_SPECIALIZATION_FAILURE:
      return {
        ...state,
        getSpecialization: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };

    case UPDATE_SPECIALIZATION_IN_PROGRESS:
      return {
        ...state,
        updateSpecialization: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case UPDATE_SPECIALIZATION_SUCCESS:
      return {
        ...state,
        updateSpecialization: { ...defaultStatus, ...payload, success: true },
      };
    case UPDATE_SPECIALIZATION_FAILURE:
      return {
        ...state,
        updateSpecialization: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    default:
      return state;
  }
};

export default SpecailizationReducer;
