import { Card, Col, Divider, Row, Table, Typography } from "antd";
import React, { useEffect, useRef, useState } from "react";

import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import {
  ArrowLeftOutlined,
  DownloadOutlined,
  EnvironmentFilled,
  PhoneFilled,
} from "@ant-design/icons";
import moment from "moment";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import logo from "../../../assets/images/DocMotez/logo.png";
import TableComponent from "../../../components/Table/TableComponent";
import { useDispatch, useSelector } from "react-redux";
import { getOrgDetailsByIdInProgress } from "../../../appRedux/super-admin/SuperAdminAction";
const OutDoctorReports = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [totalAppointments, setTotalAppointments] = useState(0);
  const [totalCommissionFee, setTotalCommissionFee] = useState(0);
  const [CommissionFee, setCommissionFee] = useState(0);
  const doctorData = useSelector((state) => state?.doctor?.outDoctorList);

  const org_id = localStorage.getItem("org_id");

  const goBackHandler = () => {
    const selectedDates = location.state?.selectedDates;

    history.push({
      pathname: "/out-doctor",
      state: {
        startDate: location.state?.startDate,
        endDate: location.state?.endDate,
      },
    });
  };
  console.log("location", location?.state);
  const printRef = useRef();
  const orgData = useSelector((state) => state.profile?.getprofile?.data);
  const downloadPDF = async () => {
    const input = printRef.current;
    const canvas = await html2canvas(input, {
      scale: 2,
    });
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("portrait", "pt", "a4");
    const imgProps = pdf.getImageProperties(imgData);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("invoice.pdf");
  };
  const currentDate = moment().format("DD/MM/YYYY");
  const currentTime = moment().format("hh:mm A");

  const outDoctorOrgData = doctorData?.data;
  const columns = [
    { title: "Id", dataIndex: "doctor_serial_no" },
    {
      title: "Name",
      dataIndex: "firstName",
      sortDirections: ["ascend", "descend"],
      key: "firstName",
      render: (_, record) => `${record.firstName} ${record.lastName}`,
      sorter: (a, b) => a.firstName.localeCompare(b.firstName),
    },

    {
      title: "Qualification",
      dataIndex: "qualification",
    },

    {
      title: "Commission Fee",
      dataIndex: "doctor_commission",
    },
    {
      title: "No of Appointments",
      dataIndex: "appointmentCount",
      render: (_, record) => record.Appointments.length,
    },
    {
      title: "Total Commission Fee",
      dataIndex: "totalEarnings",
      render: (_, record) => {
        const totalEarnings = record.Appointments.reduce((acc, appointment) => {
          return record.Appointments.length * record.doctor_commission;
        }, 0);
        return `₹${totalEarnings.toFixed(2)}`;
      },
    },
  ];
  useEffect(() => {
    const totalAppointmentsCount = outDoctorOrgData.reduce(
      (acc, doctor) => acc + doctor.Appointments.length,
      0
    );

    const CommissionFee = outDoctorOrgData.reduce((acc, doctor) => {
      const doctorCommission = doctor.doctor_commission;
      return acc + doctorCommission;
    }, 0);
    const totalCommission = outDoctorOrgData.reduce((acc, doctor) => {
      const doctorCommission =
        doctor.Appointments.length * doctor.doctor_commission;
      return acc + doctorCommission;
    }, 0);
    setTotalAppointments(totalAppointmentsCount);
    setTotalCommissionFee(totalCommission);
    setCommissionFee(CommissionFee);
    dispatch(getOrgDetailsByIdInProgress(org_id));
  }, []);
  console.log("orgData", orgData);
  return (
    <Card className="mx-20">
      <Row>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Row className="print-button-invoice mx-20">
            <Col>
              <ButtonComponent
                type="success"
                icon={<DownloadOutlined />}
                onClick={() => downloadPDF()}
                className={"print-button"}
              ></ButtonComponent>
              <ButtonComponent
                type="danger"
                icon={<ArrowLeftOutlined />}
                onClick={goBackHandler}
              ></ButtonComponent>
            </Col>
          </Row>

          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Row ref={printRef} className="mt-30 px-10">
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <Row>
                  <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <img src={logo} width={150} alt="image" />
                  </Col>{" "}
                  <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Row className="px-10">
                      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <Typography className="doctor-name">
                          {orgData?.organization_name}
                        </Typography>
                      </Col>
                      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <Typography className="doctor-details py-5">
                          <EnvironmentFilled />
                          {orgData?.address1}, {orgData?.address2}
                        </Typography>{" "}
                        <Typography className="doctor-details py-5">
                          {orgData?.city}, {orgData?.state}
                        </Typography>
                      </Col>{" "}
                      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <Typography className="doctor-details py-5">
                          <PhoneFilled />
                          {orgData?.contact}.
                        </Typography>{" "}
                      </Col>
                    </Row>
                  </Col>{" "}
                  <Col
                    xl={24}
                    lg={24}
                    md={24}
                    sm={24}
                    xs={24}
                    className="mt-5 mb-5"
                  >
                    <Divider
                      style={{
                        borderColor: "black",
                      }}
                    />
                  </Col>
                </Row>{" "}
              </Col>
              <Col xl={24} lg={24} md={24} sm={24} xs={24} className="ml-5">
                <Row className="mt-10">
                  <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Row>
                      <Col
                        xl={5}
                        lg={5}
                        md={5}
                        sm={10}
                        xs={18}
                        className="py-5"
                      >
                        <Typography className="list-header1 ml-10">
                          No of out doctors
                        </Typography>
                      </Col>{" "}
                      <Col
                        xl={1}
                        lg={1}
                        md={1}
                        sm={0.5}
                        xs={0.5}
                        className="my-10"
                      >
                        <Typography className="list-header1">:</Typography>
                      </Col>
                      <Col
                        xl={16}
                        lg={10}
                        md={10}
                        sm={10}
                        xs={20}
                        className="my-10"
                      >
                        <Typography className="list-item">
                          {outDoctorOrgData?.length}
                        </Typography>
                      </Col>{" "}
                      <Col
                        xl={5}
                        lg={5}
                        md={5}
                        sm={10}
                        xs={18}
                        className="py-5"
                      >
                        <Typography className="list-header1 ml-10">
                          No .of appointments
                        </Typography>
                      </Col>{" "}
                      <Col
                        xl={1}
                        lg={1}
                        md={1}
                        sm={0.5}
                        xs={0.5}
                        className="py-5"
                      >
                        <Typography className="list-header1">:</Typography>
                      </Col>
                      <Col
                        xl={16}
                        lg={10}
                        md={10}
                        sm={10}
                        xs={20}
                        className="my-10"
                      >
                        <Typography className="list-item">
                          {totalAppointments}
                        </Typography>
                      </Col>{" "}
                      <Col
                        xl={5}
                        lg={5}
                        md={5}
                        sm={10}
                        xs={18}
                        className="py-5"
                      >
                        <Typography className="list-header1 ml-10">
                          Commission Fee
                        </Typography>
                      </Col>
                      <Col
                        xl={1}
                        lg={1}
                        md={1}
                        sm={0.5}
                        xs={0.5}
                        className="my-10"
                      >
                        <Typography className="list-header1">:</Typography>
                      </Col>
                      <Col
                        xl={16}
                        lg={10}
                        md={10}
                        sm={10}
                        xs={20}
                        className="my-10"
                      >
                        <Typography className="list-item">
                          {CommissionFee}
                        </Typography>
                      </Col>{" "}
                      <Col
                        xl={5}
                        lg={5}
                        md={5}
                        sm={10}
                        xs={18}
                        className="py-5"
                      >
                        <Typography className="list-header1 ml-10">
                          Total Commission
                        </Typography>
                      </Col>
                      <Col
                        xl={1}
                        lg={1}
                        md={1}
                        sm={0.5}
                        xs={0.5}
                        className="my-10"
                      >
                        <Typography className="list-header1">:</Typography>
                      </Col>
                      <Col
                        xl={16}
                        lg={10}
                        md={10}
                        sm={10}
                        xs={20}
                        className="my-10"
                      >
                        <Typography className="list-item">
                          {doctorData?.totalcommission}
                        </Typography>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>{" "}
              <Col
                xl={24}
                lg={24}
                md={24}
                sm={24}
                xs={24}
                className="mt-20 px-20"
              >
                {" "}
                <TableComponent
                  columns={columns}
                  dataSource={outDoctorOrgData}
                  //   pagination={{
                  //     total: doctorTotalRecords,
                  //     onChange: (page) => handlePage(page),
                  //   }}
                  //   doctorRowselectors={handleDoctorSelectChange}
                  //   itemList={doctorsLists}
                  field={"firstName"}
                  documentTitle={"Doctor"}
                  disableTableHeaderButtons={true}
                />
              </Col>
            </Row>
          </Col>
        </Col>
      </Row>
    </Card>
  );
};

export default OutDoctorReports;
