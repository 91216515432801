// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.buttons {
  float: right;
}
.text {
  font-weight: 500;
}
.space {
  margin-bottom: 0.8rem;
  float: right;
}
.btn-edit {
  margin: auto;
  margin-bottom: -14px;
}
.btn-pos {
  width: 75%;
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 426px) and (min-width: 320px) {
  .bot {
    align-items: center;
  }
  .space {
    width: 100%;
  }
  .btn-pos {
    width: 100%;
    display: flex;
    margin-right: 30px;
    justify-content: center;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/modules/reference.less"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;AACA;EACE,gBAAgB;AAClB;AACA;EACE,qBAAqB;EACrB,YAAY;AACd;AACA;EACE,YAAY;EACZ,oBAAoB;AACtB;AACA;EACE,UAAU;EACV,aAAa;EACb,uBAAuB;AACzB;AACA;EACE;IACE,mBAAmB;EACrB;EACA;IACE,WAAW;EACb;EACA;IACE,WAAW;IACX,aAAa;IACb,kBAAkB;IAClB,uBAAuB;EACzB;AACF","sourcesContent":[".buttons {\n  float: right;\n}\n.text {\n  font-weight: 500;\n}\n.space {\n  margin-bottom: 0.8rem;\n  float: right;\n}\n.btn-edit {\n  margin: auto;\n  margin-bottom: -14px;\n}\n.btn-pos {\n  width: 75%;\n  display: flex;\n  justify-content: center;\n}\n@media only screen and (max-width: 426px) and (min-width: 320px) {\n  .bot {\n    align-items: center;\n  }\n  .space {\n    width: 100%;\n  }\n  .btn-pos {\n    width: 100%;\n    display: flex;\n    margin-right: 30px;\n    justify-content: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
