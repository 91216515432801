import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Checkbox,
  Form,
  Input,
  Col,
  Row,
  DatePicker,
  Upload,
} from "antd";
import { Link, useHistory } from "react-router-dom";
import "../../src/styles/modules/auth.less";
import { useDispatch, useSelector } from "react-redux";

import { message } from "antd/lib/index";
import CircularProgress from "../components/CircularProgress";
import GoogleOutlined from "@ant-design/icons/lib/icons/GoogleOutlined";
import FacebookOutlined from "@ant-design/icons/lib/icons/FacebookOutlined";
import GithubOutlined from "@ant-design/icons/lib/icons/GithubOutlined";
import TwitterOutlined from "@ant-design/icons/lib/icons/TwitterOutlined";
import { hideMessage, showAuthLoader } from "../appRedux/doctor/actions/Auth";
import FormDatePicker from "../components/DatePicker/FormDatePicker";
import moment from "moment";
import { requestOrgInProgress } from "../appRedux/doctor/actions/organisation";
import { UploadOutlined } from "@ant-design/icons";
import CountrySelector from "../screens/Doctor/component/CountrySelector";
import StateSelector from "../screens/Doctor/component/StateSelector";
import CitySelector from "../screens/Doctor/component/CitySelector";
import SkeletonImage from "antd/lib/skeleton/Image";
import OrganizationSelector from "../screens/Doctor/component/OrganizationSelector";
import SpecializationSelector from "../screens/Doctor/component/SpecializationSelector";
import DesignationSelector from "../screens/Doctor/component/DesignationSelector";
import QualificationSelector from "../screens/Doctor/component/QualificationSelector";
import IntlMessages from "../util/IntlMessages";
import { RegisterUserInProgress } from "../appRedux/doctor/actions/AuthAction";
import HeartIcon from "../common/images/doctor-heartbeat.jpg";
const FormItem = Form.Item;

const SignUp = (props) => {
  const [country, setCountry] = useState("");
  const selectedCountryID = useRef("");
  const [state, setState] = useState("");
  const selectedStateID = useRef("");
  const [city, setCity] = useState("");
  const selectedCityID = useRef("");
  const [image, setImage] = useState("");
  // const [org, setOrg] = useState("");
  // const [designation, setDesignation] = useState("");
  // const [spec, setOrg] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  const { loader, alertMessage, showMessage, authUser } = useSelector(
    ({ auth }) => auth
  );
  const dataSuccess = useSelector((state) => state?.auth?.registerSuccess);
  const onChangeCountry = (value) => {
    setCity([]);
    console.log("city", city);
    setCountry(value);
  };
  const onChangeCity = (value) => {
    console.log("vvvv", value);
    setCity(value);
  };
  const onChangeState = (value) => {
    setState(value);
  };
  useEffect(() => {
    selectedCountryID.current = country;
  }, [country]);
  useEffect(() => {
    selectedStateID.current = state;
  }, [state]);
  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 100);
    }
    if (dataSuccess === true) {
      history.push("/otpRegistration");
    }
  });

  const onFinishFailed = (errorInfo) => {};

  const onFinish = (values) => {
    dispatch(showAuthLoader());
    console.log("register", values);
    const dateofBirth = values.dob.toString();
    const dobdata = moment(dateofBirth).format("YYYY/MM/DD");
    const stringData = dobdata?.toString();
    let formData = new URLSearchParams();
    formData.append("firstName", values.firstName);
    formData.append("lastName", values.lastName);
    formData.append("email", values.email);
    formData.append("password", values.password);
    formData.append("dob", stringData);
    formData.append("contact", values.contact);
    formData.append("address1", values.address1);
    formData.append("address2", values.address2);
    formData.append("street", values.street);
    formData.append("city", values?.city?.value);
    formData.append("state", values.state?.value);
    formData.append("country_code", values.country);
    formData.append("role_id", "2");
    formData.append("status", "5");
    formData.append("organization_id", values.organization_id);
    formData.append("qualification", values.qualification);
    formData.append("designation_id", values.designation_id);
    formData.append("specialization_id", values.specialization_id);
    formData.append("license_no", values.license_no);
    formData.append("profile_image", image);

    // dispatch(userSignUp(formData));
    dispatch(RegisterUserInProgress(formData));
  };
  function encodeImageFileAsURL(e) {
    var file = e.target.files[0];
    var reader = new FileReader();
    reader.onloadend = function () {
      setImage(reader.result);
    };
    reader.readAsDataURL(file);
  }
  useEffect(() => {
    console.log("country changed");
    console.log("city list", city);
  }, [country]);
  return (
    <div>
      <div>
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo sign-up-logo">
              <img alt="example" src="/assets/images/logo.png" />
            </div>
            <div className="gx-app-logo-content-bg">
              <img src={HeartIcon} alt="Neature" />
            </div>
            <div className="gx-app-logo-wid">
              <h1>
                <IntlMessages id="app.userAuth.signUp" />
              </h1>
              <p>
                <IntlMessages id="app.userAuth.bySigning" />
              </p>
              <p>
                <IntlMessages id="app.userAuth.getAccount" />
              </p>
            </div>
          </div>

          <div className="gx-app-login-content">
            <Form
              initialValues={{ remember: true }}
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="gx-signin-form gx-form-row0 signup-form"
            >
              <Row>
                <Col span={12}>
                  {" "}
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Please input your First Name!",
                      },
                    ]}
                    name="firstName"
                  >
                    <Input placeholder="First Name" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  {" "}
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Please input your Last Name!",
                      },
                    ]}
                    name="lastName"
                  >
                    <Input placeholder="Last Name" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  {" "}
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        type: "email",
                        message: "The input is not valid E-mail!",
                      },
                    ]}
                  >
                    <Input placeholder="Email" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  {" "}
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Password!",
                      },
                    ]}
                  >
                    <Input type="text" placeholder="Password" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Form.Item
                    rules={[
                      { required: true, message: "Please input your contact!" },
                    ]}
                    name="contact"
                  >
                    <Input placeholder="contact" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="dob"
                    rules={[
                      {
                        type: "object",
                        required: true,
                        message: "Please select your date of birth!",
                      },
                    ]}
                  >
                    <DatePicker
                      className="calender-responsive"
                      placeholder="Date of Birth"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Please input your address1!",
                      },
                    ]}
                    name="address1"
                  >
                    <Input placeholder="Address1" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  {" "}
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Please input your address2!",
                      },
                    ]}
                    name="address2"
                  >
                    <Input placeholder="Address 2" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  {" "}
                  <FormItem
                    rules={[
                      { required: true, message: "Please input your street!" },
                    ]}
                    name="street"
                  >
                    <Input placeholder="Street" />
                  </FormItem>
                </Col>
                <Col span={12}>
                  {" "}
                  <FormItem name="country_code">
                    <CountrySelector
                      name={"country"}
                      onChange={onChangeCountry}
                      country={country}
                      setCity={setCity}
                      rules={[
                        {
                          required: true,
                          message: "Please Select Your Country",
                        },
                      ]}
                    />
                  </FormItem>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  {" "}
                  <FormItem name="state">
                    <StateSelector
                      name={"state"}
                      onChange={onChangeState}
                      country={state}
                      setCity={setCity}
                      countryId={country?.id}
                      rules={[
                        {
                          required: true,
                          message: "Please Select Your state",
                        },
                      ]}
                    />
                  </FormItem>
                </Col>
                <Col span={12}>
                  {" "}
                  <FormItem name="city">
                    <CitySelector
                      name={"city"}
                      onChange={onChangeCity}
                      country={city}
                      StateId={state?.id}
                      rules={[
                        {
                          required: true,
                          message: "Please Select Your state",
                        },
                      ]}
                    />
                  </FormItem>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  {" "}
                  <FormItem name="organization_id">
                    <OrganizationSelector
                      margin="mb-30"
                      name={"organization_id"}
                      placeholder={"Select Your Organization"}
                      editMode={true}
                      rules={[
                        {
                          required: true,
                          message: "Please enter your Organization",
                        },
                      ]}
                    />
                  </FormItem>
                </Col>
                <Col span={12}>
                  {" "}
                  <FormItem name="specialization_id">
                    <SpecializationSelector
                      margin="mb-30"
                      name={"specialization_id"}
                      placeholder={"Select Your Specialization"}
                      editMode={true}
                      rules={[
                        {
                          required: true,
                          message: "Please enter your Specialization",
                        },
                      ]}
                    />
                  </FormItem>
                </Col>
              </Row>

              <Row>
                <Col span={12}>
                  {" "}
                  <FormItem name="designation_id">
                    <DesignationSelector
                      margin="mb-30"
                      name={"designation_id"}
                      placeholder={"Select Your Designation"}
                      editMode={true}
                      rules={[
                        {
                          required: true,
                          message: "Please enter your Designation",
                        },
                      ]}
                    />
                  </FormItem>
                </Col>
                <Col span={12}>
                  {" "}
                  <FormItem name="Qualification">
                    <QualificationSelector
                      margin="mb-30"
                      name={"qualification"}
                      placeholder={"Select Your Qualification"}
                      editMode={true}
                      rules={[
                        {
                          required: true,
                          message: "Please enter your Qualification",
                        },
                      ]}
                    />
                  </FormItem>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  {" "}
                  <FormItem
                    rules={[
                      {
                        required: true,
                        message: "Please input your License Number!",
                      },
                    ]}
                    name="license_no"
                  >
                    <Input placeholder="License Number" />
                  </FormItem>
                </Col>{" "}
                <Col span={12}>
                  {" "}
                  <FormItem
                    rules={[
                      {
                        required: true,
                        message: "Please upload your image!",
                      },
                    ]}
                    name="image"
                    valuePropName="fileList"
                  >
                    <Input
                      type={"file"}
                      name="picture"
                      onChange={(e) => encodeImageFileAsURL(e)}
                    />
                  </FormItem>
                </Col>
              </Row>
              {/* <FormItem valuePropName="checked">
                <Checkbox>Remember me</Checkbox>
              </FormItem> */}
              <FormItem className="form-buttons">
                <Button
                  type="primary"
                  className="gx-mb-0 form-button"
                  htmlType="submit"
                >
                  <IntlMessages id="app.userAuth.signUp" />
                </Button>
                <span>
                  <IntlMessages id="app.userAuth.or" />
                </span>
                <Link to="/signin" className="form-link">
                  <IntlMessages id="app.userAuth.signIn" />
                </Link>
              </FormItem>
              {/* <div className="gx-flex-row gx-justify-content-between">
                <span>or connect with</span>
                <ul className="gx-social-link">
                  <li>
                    <GoogleOutlined
                      onClick={() => {
                        dispatch(showAuthLoader());
                        dispatch(userGoogleSignIn());
                      }}
                    />
                  </li>
                  <li>
                    <FacebookOutlined
                      onClick={() => {
                        dispatch(showAuthLoader());
                        dispatch(userFacebookSignIn());
                      }}
                    />
                  </li>
                  <li>
                    <GithubOutlined
                      onClick={() => {
                        dispatch(showAuthLoader());
                        dispatch(userGithubSignIn());
                      }}
                    />
                  </li>
                  <li>
                    <TwitterOutlined
                      onClick={() => {
                        dispatch(showAuthLoader());
                        dispatch(userTwitterSignIn());
                      }}
                    />
                  </li>
                </ul>
              </div> */}
            </Form>
          </div>
          {loader && (
            <div className="gx-loader-view">
              <CircularProgress />
            </div>
          )}
          {showMessage && message.error(alertMessage)}
        </div>
      </div>
    </div>
  );
};

export default SignUp;
