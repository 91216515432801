import React, { useEffect, useState } from "react";

import { Row, Col, Spin, Typography } from "antd";
import {
  EyeOutlined,
  LoadingOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import { useDispatch } from "react-redux";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import TableComponent from "../../../components/Table/TableComponent";

import { patientListInProgress } from "../../../appRedux/doctor/actions/patient";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { getAppointmentByDoctorPatientInProgress } from "../../../appRedux/Patient/Action/Action";
import moment from "moment";

const DoctorHistory = () => {
  const location = useLocation();
  const doctorId = location.state?.id;
  const history = useHistory();

  const patientId = localStorage.getItem("patient_id");
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );
  const columns = [
    {
      title: "FirstName",
      dataIndex: "firstName",
    },

    {
      title: "LastName",
      dataIndex: "lastName",
    },
    {
      title: "DOB",
      dataIndex: "dob",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Address1",
      dataIndex: "address1",
    },

    { title: "OrganizationName", dataIndex: "organization_name" },
    {
      title: "Options",
      dataIndex: "option",
      render: (_, record) => {
        return (
          <Row className="table-button">
            <Col className="button-edit">
              <Link
                to={{
                  pathname: "/patient/view",
                  state: { patientData: record },
                }}
              >
                <ButtonComponent
                  size="small"
                  type="primary"
                  icon={<EyeOutlined />}
                  // onClick={()=>editPatient(record.id)}
                ></ButtonComponent>
              </Link>
            </Col>
          </Row>
        );
      },
    },
  ];
  const dispatch = useDispatch();
  const singlePatientData = useSelector(
    (state) => state.patient.getSingleHealthRecord
  );
  const doctorData = useSelector(
    (state) => state?.patientAppointment?.appoitmnetsByDoctorPatient
  );
  const goBackHandler = () => {
    history.goBack();
  };
  const doctorList = doctorData?.data;

  const [Loading, setLoading] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState("");
  const totalRecords = singlePatientData?.data?.totalRecords;
  const handlePage = (page) => {
    dispatch(patientListInProgress(page));
  };
  const handleSelectChange = (value) => {
    if (value === "all") {
      setFilteredUsers(doctorList);
    } else {
      const newData = doctorList.slice(0, value);
      setFilteredUsers(newData);
    }
  };
  const CurrentDate = moment().format("YYYY-MM-DD");
  const CurrentTime = moment().format("hh:mmA");
  const day = moment().format("A");

  useEffect(() => {
    const formData = new FormData();
    formData.append("patient_id", localStorage.getItem("patient_id"));
    formData.append("doctor_id", doctorId);
    formData.append("current_time", CurrentTime);
    formData.append("current_date", CurrentDate);

    dispatch(
      getAppointmentByDoctorPatientInProgress({
        patient_id: localStorage.getItem("patient_id"),
        doctor_id: doctorId,
        current_time: CurrentTime,
        current_date: CurrentDate,
        isPrev: 0,
        pagination: true,
        pageNo: 1,
      })
    );
  }, [patientId]);

  useEffect(() => {
    if (singlePatientData?.inProgress) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [singlePatientData?.inProgress]);
  return (
    <Spin indicator={antIcon} spinning={Loading}>
      <Row>
        <Col xl={24} lg={24} md={24} xs={24} sm={24}>
          <Row justify={"space-between"} className="margin-row">
            <Col>
              <Typography className="view-header">View History</Typography>
            </Col>
            <Col>
              <ButtonComponent
                type="danger"
                icon={<ArrowLeftOutlined />}
                onClick={goBackHandler}
                size="small"
              />
            </Col>
          </Row>
        </Col>
        <Col xl={24} lg={24} md={24} xs={24} sm={24}>
          <TableComponent
            columns={columns}
            dataSource={filteredUsers ? filteredUsers : doctorList}
            pagination={{
              total: totalRecords,
              onChange: (page) => handlePage(page),
            }}
            itemList={doctorList}
            filteredUsers={filteredUsers}
            setFilteredUsers={setFilteredUsers}
            field={"firstName"}
            rowselector={handleSelectChange}
          />
        </Col>
      </Row>
    </Spin>
  );
};

export default DoctorHistory;
