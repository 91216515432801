import React from "react";

import { Card } from "antd";

import SuppliersHeader from "./SuppliersHeader";
import SuppliersTable from "./SuppliersTable";

const SuppliersScreen = () => {
  return (
    <Card>
      <SuppliersHeader />
      <SuppliersTable />
    </Card>
  );
};

export default SuppliersScreen;
