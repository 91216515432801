import React, { useEffect, useState } from "react";
import { Row, Col, Spin, Card, Form } from "antd";
import { LoadingOutlined, PlusCircleOutlined } from "@ant-design/icons";

import { Link } from "react-router-dom";
import "../../styles/modules/superAdmin.less";

import { useDispatch, useSelector } from "react-redux";
import { getOrganisationListInProgress } from "../../appRedux/super-admin/SuperAdminAction";
import { getVitalsByTypeInprogress } from "../../appRedux/Patient/Action/Action";
import IntlMessages from "../../util/IntlMessages";
import { APP_ROUTES } from "../../constants/Routes";
import ButtonComponent from "../../components/Button/ButtonComponent";
import TableComponent from "../../components/Table/TableComponent";
import FormSelectBox from "../../components/SelectBox/SelectBox";
import moment from "moment";

const VitalsList = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [Loading, setLoading] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState("");
  const [vitalType, setVitalType] = useState();
  const vitalsListByTYpe = useSelector(
    (state) => state?.patientAppointment?.getVitalsByType
  );

  const currentList = vitalsListByTYpe?.data?.Vitals?.Vitals;

  const bpcolumn = [
    {
      title: "S.No",
      dataIndex: "sno",
      render: (text, record, index) => index + 1,
    },

    {
      title: "BP",
      dataIndex: "bp",
      key: "bp",
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      render: (text) => moment(text, "HH:mm").format("hh:mm A"),
    },
    {
      title: "Date",
      dataIndex: "select_date",
      key: "select_date",
      render: (text) => moment(text).format("DD-MM-YYYY"),
    },
  ];
  const tempcolumn = [
    {
      title: "S.No",
      dataIndex: "sno",
      render: (text, record, index) => index + 1,
    },

    {
      title: "Temperature",
      dataIndex: "temperature",
      key: "temperature",
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      render: (text) => moment(text, "HH:mm").format("hh:mm A"),
    },
    {
      title: "Date",
      dataIndex: "select_date",
      key: "select_date",
      render: (text) => moment(text).format("DD-MM-YYYY"),
    },
  ];
  const exercisecolumn = [
    {
      title: "S.No",
      dataIndex: "sno",
      render: (text, record, index) => index + 1,
    },

    {
      title: "Exercise",
      dataIndex: "exercise",
      key: "exercise",
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      render: (text) => moment(text, "HH:mm").format("hh:mm A"),
    },
    {
      title: "Date",
      dataIndex: "select_date",
      key: "select_date",
      render: (text) => moment(text).format("DD-MM-YYYY"),
    },
  ];
  const pulsecolumn = [
    {
      title: "S.No",
      dataIndex: "sno",
      render: (text, record, index) => index + 1,
    },

    {
      title: "Pulse",
      dataIndex: "pulse",
      key: "pulse",
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      render: (text) => moment(text, "HH:mm").format("hh:mm A"),
    },
    {
      title: "Date",
      dataIndex: "select_date",
      key: "select_date",
      render: (text) => moment(text).format("DD-MM-YYYY"),
    },
  ];
  const spo2column = [
    {
      title: "S.No",
      dataIndex: "sno",
      render: (text, record, index) => index + 1,
    },

    {
      title: "SPO2",
      dataIndex: "spo2",
      key: "spo2",
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      render: (text) => moment(text, "HH:mm").format("hh:mm A"),
    },
    {
      title: "Date",
      dataIndex: "select_date",
      key: "select_date",
      render: (text) => moment(text).format("DD-MM-YYYY"),
    },
  ];
  const weightColumn = [
    {
      title: "S.No",
      dataIndex: "sno",
      render: (text, record, index) => index + 1,
    },

    {
      title: "Weight",
      dataIndex: "weight",
      key: "weight",
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      render: (text) => moment(text, "HH:mm").format("hh:mm A"),
    },
    {
      title: "Date",
      dataIndex: "select_date",
      key: "select_date",
      render: (text) => moment(text).format("DD-MM-YYYY"),
    },
  ];
  const vitalTypeList = [
    {
      label: "BP",
      value: "bp",
      key: "bp",
    },
    {
      label: "Temperature",
      value: "temperature",
      key: "temperature",
    },
    {
      label: "Pulse",
      value: "pulse",
      key: "pulse",
    },
    {
      label: "Weight",
      value: "weight",
      key: "weight",
    },
    {
      label: "Exercise",
      value: "exercise",
      key: "exercise",
    },
    {
      label: "SPO2",
      value: "spo2",
      key: "spo2",
    },
  ];
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );

  const changeVitalType = (value) => {
    setVitalType(value);
    dispatch(getVitalsByTypeInprogress(value));
  };
  const handleSelectChange = (value) => {
    if (value === "all") {
      setFilteredUsers(currentList);
    } else {
      const newData = currentList.slice(0, value);
      setFilteredUsers(newData);
    }
  };

  console.log("vitalsListByTYpe", vitalsListByTYpe);
  useEffect(() => {
    form.setFieldValue("vital_type", "bp");
    setVitalType("bp");
    dispatch(getVitalsByTypeInprogress("bp"));
  }, []);
  useEffect(() => {
    if (vitalsListByTYpe?.inProgress) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [vitalsListByTYpe]);
  return (
    <Card>
      <Row className="p-10 Add-button">
        <Col>
          <p className="header-tag">{<IntlMessages id={"Addnew.vitals"} />}</p>
        </Col>
        <Col>
          <Link to={APP_ROUTES.ADD_VITAL}>
            <ButtonComponent
              className="buttons"
              icon={<PlusCircleOutlined />}
              type="primary"
              label={<IntlMessages id={"Addnew.vitals"} />}
            />
          </Link>
        </Col>
      </Row>
      <Row>
        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
          {" "}
          <Form
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            initialValues={{
              remember: true,
            }}
            colon={"true"}
            autoComplete="off"
            form={form}
          >
            {" "}
            <Form.Item
              labelCol={{
                span: 24,
              }}
              wrapperCol={{
                span: 24,
              }}
              label={<IntlMessages id={"Addnew.admin.label.vitaType"} />}
              name={"vital_type"}
              rules={[
                {
                  required: true,
                  message: (
                    <IntlMessages id={"Addnew.admin.validator.vitalType"} />
                  ),
                },
              ]}
            >
              <FormSelectBox
                placeholder="Select Vital Type"
                value={vitalType}
                options={vitalTypeList}
                onChange={changeVitalType}
              />
            </Form.Item>
          </Form>
        </Col>
      </Row>
      <Spin indicator={antIcon} spinning={Loading}>
        <TableComponent
          columns={
            vitalType === "bp"
              ? bpcolumn
              : vitalType === "temperature"
              ? tempcolumn
              : vitalType === "spo2"
              ? spo2column
              : vitalType === "pulse"
              ? pulsecolumn
              : vitalType === "weight"
              ? weightColumn
              : exercisecolumn
          }
          dataSource={filteredUsers ? filteredUsers : currentList}
          rowselector={handleSelectChange}
          itemList={currentList}
          filteredUsers={filteredUsers}
          setFilteredUsers={setFilteredUsers}
          field={"name"}
          documentTitle={`Vitals - ${
            vitalType === "bp"
              ? "BP"
              : vitalType === "temperature"
              ? "Temperature"
              : vitalType === "spo2"
              ? "SPO2"
              : vitalType === "pulse"
              ? "Pulse"
              : vitalType === "weight"
              ? "Weight"
              : "Exercise"
          }`}
          pagination={false}
        />
      </Spin>
    </Card>
  );
};

export default VitalsList;
