import React from "react";

import { Card } from "antd";
import DesignationHeader from "./components/DesignationHeader";
import DesignationTable from "./components/DesignationTable";

const DesignationScreen = () => {
  return (
    <Card>
      <DesignationHeader />
      <DesignationTable />
    </Card>
  );
};

export default DesignationScreen;
