import {  TRACKER_ADD_IN_ERROR, TRACKER_ADD_IN_PROGRESS, TRACKER_ADD_IN_SUCCESS, TRACKER_APPOINTMENT_LIST_IN_ERROR, TRACKER_APPOINTMENT_LIST_IN_PROGRESS, TRACKER_APPOINTMENT_LIST_IN_SUCCESS, TRACKER_LIST_BY_ORGANIZATION_IN_ERROR, TRACKER_LIST_BY_ORGANIZATION_IN_PROGRESS, TRACKER_LIST_BY_ORGANIZATION_IN_SUCCESS, TRACKER_UPDATE_BY_REPORT_ERROR, TRACKER_UPDATE_BY_REPORT_PROGRESS, TRACKER_UPDATE_BY_REPORT_SUCCESS, TRACKER_UPDATE_BY_STATUS_ERROR, TRACKER_UPDATE_BY_STATUS_PROGRESS, TRACKER_UPDATE_BY_STATUS_SUCCESS } from "../../../constants/ActionTypes";
  export const getTrackerListByAppointmentProgress = (payload) => {
    return {
      type: TRACKER_APPOINTMENT_LIST_IN_PROGRESS,
      payload: payload,
    };
  };
  export const getTrackerListByAppointmentSuccess = (payload) => {
    return {
      type: TRACKER_APPOINTMENT_LIST_IN_SUCCESS,
      payload: payload,
    };
  };
  export const getTrackerListByAppointmentError = (payload) => {
    return {
      type: TRACKER_APPOINTMENT_LIST_IN_ERROR,
      payload:payload
    };
  }
  export const addTrackerProgress = (payload) => {
      return {
        type: TRACKER_ADD_IN_PROGRESS,
        payload: payload,
      };
    };
  export const addTrackerSuccess = (payload) => {
      return {
        type: TRACKER_ADD_IN_SUCCESS,
        payload: payload,
      };
    };
  export const addTrackerError = (payload) => {
      return {
        type: TRACKER_ADD_IN_ERROR,
        payload:payload
      };
    }

  export const getTrackerListByOrganizationsProgress = (payload) => {
      return {
        type: TRACKER_LIST_BY_ORGANIZATION_IN_PROGRESS,
        payload: payload,
      };
    };
 export const getTrackerListByOrganizationsSuccess = (payload) => {
      return {
        type: TRACKER_LIST_BY_ORGANIZATION_IN_SUCCESS,
        payload: payload,
      };
    };
  export const getTrackerListByOrganizationsError = (payload) => {
   return {
        type: TRACKER_LIST_BY_ORGANIZATION_IN_ERROR,
        payload:payload
     };  
      }
export const updateTrackerReportProgress = (payload) => {
 return {
type: TRACKER_UPDATE_BY_REPORT_PROGRESS,
payload: payload
};
};
export const updateTrackerReportSuccess = (payload) => {
        return {
          type: TRACKER_UPDATE_BY_REPORT_SUCCESS,
          payload: payload,
        };
      };
export const updateTrackerReportError = (payload) => {
     return {
          type: TRACKER_UPDATE_BY_REPORT_ERROR,
          payload:payload
       };  
        }

export const updateTrackerStatusProgress = (payload) => {
return {
 type: TRACKER_UPDATE_BY_STATUS_PROGRESS,
 payload: payload
};
};
 export const updateTrackerStatusSuccess = (payload) => {
 return {
 type: TRACKER_UPDATE_BY_STATUS_SUCCESS,
 payload: payload,
};
};
 export const updateTrackerStatusError = (payload) => {
return {
 type: TRACKER_UPDATE_BY_STATUS_ERROR,
 payload:payload
};
}