import { Card, Col, Form, Row, Spin, Typography, Upload } from "antd";
import React, { useEffect, useState } from "react";
import IntlMessages from "../../util/IntlMessages";
import {
  ArrowLeftOutlined,
  LoadingOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import SelectorsAppointment from "../Appoinment/Selectors";
import ButtonComponent from "../../components/Button/ButtonComponent";
import FormInput from "../../components/Input/FormInput";
import { useLocation, useHistory } from "react-router-dom";
import { updateTrackerReportProgress } from "../../appRedux/doctor/actions/Trackers";
import { APP_ROUTES } from "../../constants/Routes";

function AddScanLabReport() {
  const locations = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const details = locations?.state;
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [base64, setBase64] = useState("");
  const { updateTRackerReport } = useSelector((state) => state?.tracker);

  const onFinish = (datas) => {
    const handleSuccess = () => {
      history.push(APP_ROUTES.SCAN);
    };
    const payload = {
      fileName: details?.type_name + datas?.fileName,
      files: base64,
      tracker_id: details?.id,
      remarks: datas?.remark,
      handleSuccess: handleSuccess,
    };
    dispatch(updateTrackerReportProgress(payload));
  };
  const goBackHandler = () => {
    history.goBack();
  };
  useEffect(() => {
    form.setFieldValue("patient_id", details?.patient_id);
  }, [form, details]);
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );
  const normFile = (e) => {
    let selectedFile = e.fileList;

    if (selectedFile?.length > 0) {
      let fileToLoad = selectedFile[0].originFileObj;
      let fileReader = new FileReader();
      fileReader.onload = function (fileLoadedEvent) {
        let base64EncodedFile = fileLoadedEvent.target.result;
        const base64WithoutPrefix = base64EncodedFile.slice(28);
        const uri = JSON.stringify(base64WithoutPrefix);
        setBase64(uri);
      };

      fileReader.readAsDataURL(fileToLoad);
    }
    if (Array.isArray(e)) {
      return e;
    }
    if (
      e &&
      e.fileList &&
      e.fileList.length > 0 &&
      e.fileList[0].name.endsWith(".pdf")
    ) {
      return e.fileList;
    }
    return [];
  };

  const validateFile = (rule, value) => {
    if (!value || value.length === 0) {
      return Promise.reject("Please upload a PDF file.");
    }

    const file = value[0].originFileObj;

    return Promise.resolve();
  };

  return (
    <>
      <Spin indicator={antIcon} spinning={updateTRackerReport?.inProgress}>
        <Card>
          <Form
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            initialValues={{
              remember: true,
            }}
            //   colon={"true"}
            onFinish={onFinish}
            autoComplete="off"
            form={form}
          >
            <Row>
              {" "}
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                className="my-20"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Typography className="header-tag">
                  {<IntlMessages id={"sidebar.scan"} />}
                </Typography>
                <ButtonComponent
                  type="danger"
                  icon={<ArrowLeftOutlined />}
                  onClick={goBackHandler}
                ></ButtonComponent>
              </Col>{" "}
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <SelectorsAppointment
                  margin="mb-10"
                  label={"Patient"}
                  name={"patient_id"}
                  placeholder={"Select your patient"}
                  editMode={true}
                  rules={[
                    {
                      required: true,
                      message: "Please enter your patient",
                    },
                  ]}
                  labelCol={24}
                  wrapperCol={24}
                />
              </Col>
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <FormInput
                  labelCol={"24"}
                  wrapperCol={"24"}
                  type="text"
                  placeholder="File Name"
                  name="fileName"
                  label={
                    <IntlMessages id={"File Name (TYPE OF SCAN OR TEST)"} />
                  }
                  rules={[
                    {
                      required: true,
                      message: <IntlMessages id={"Enter the File Name"} />,
                    },
                  ]}
                />
              </Col>
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <FormInput
                  labelCol={"24"}
                  wrapperCol={"24"}
                  type="text"
                  placeholder="remark"
                  name="remark"
                  label={<IntlMessages id={"remark"} />}
                  rules={[
                    {
                      required: true,
                      message: <IntlMessages id={"Enter the remark"} />,
                    },
                  ]}
                />
              </Col>
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <Form.Item
                  name="File"
                  label={<IntlMessages id={"File"} />}
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                  rules={[
                    {
                      required: true,
                      validator: validateFile,
                      message: (
                        <IntlMessages
                          id={"Addnew.healthRecord.validator.pdf"}
                        />
                      ),
                    },
                  ]}
                >
                  <Upload name="file" maxCount={1} fileList={fileList}>
                    <ButtonComponent
                      icon={<UploadOutlined />}
                      label={<IntlMessages id={"file"} />}
                    />
                  </Upload>
                </Form.Item>
              </Col>
              <Col
                xl={12}
                lg={12}
                md={12}
                sm={24}
                xs={24}
                style={{ display: "flex", alignItems: "center" }}
              >
                <ButtonComponent
                  type="primary"
                  htmlType="Submit"
                  label="Submit"
                  className={"mb-0"}
                />
              </Col>
            </Row>
          </Form>
        </Card>
      </Spin>
    </>
  );
}

export default AddScanLabReport;
