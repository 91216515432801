import { message } from "antd";
import { call, put, takeLatest } from "redux-saga/effects";
import Message from "../../../components/Message/Message";
import {
  TRACKER_ADD_IN_PROGRESS,
  TRACKER_APPOINTMENT_LIST_IN_PROGRESS,
  TRACKER_LIST_BY_ORGANIZATION_IN_PROGRESS,
  TRACKER_UPDATE_BY_REPORT_PROGRESS,
  TRACKER_UPDATE_BY_STATUS_PROGRESS,
} from "../../../constants/ActionTypes";
import { getErrorMessageFromAPI } from "../../../util/utilz";
import { addTracker, TrackerGetByAppointment, TrackerGetByOrginations, trackerStatusUpdate, TrackerUpdateReport } from "./effects/TrackerEffect";
import { addTrackerError, addTrackerSuccess, getTrackerListByAppointmentError, getTrackerListByAppointmentSuccess, getTrackerListByOrganizationsError, getTrackerListByOrganizationsSuccess, updateTrackerReportError, updateTrackerReportSuccess, updateTrackerStatusError, updateTrackerStatusSuccess } from "../actions/Trackers";

function* TrackerListByAppointmentWatcher({ payload }) {
  try {
    let { data } = yield call(TrackerGetByAppointment, payload);

    yield put(
        getTrackerListByAppointmentSuccess({
        data: data,
        message: data["message"],
        authSuccess: true,
      })
    );
  } catch (e) {
    yield put(
        getTrackerListByAppointmentError({
        authError: true,
        message: e["message"],
      })
    );
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
  }
}
function* AddTrackerWatcher({ payload }) {
  try {
    let { data } = yield call(addTracker, payload);

    yield put(
      addTrackerSuccess({
        data: data,
        message: data["message"],
        authSuccess: true,
      })
    );
  } catch (e) {
    yield put(
      addTrackerError({
        authError: true,
        message: e["message"],
      })
    );
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
  }
}
function* TrackerListByOrginationsWatcher({ payload }) {
  try {
    let { data } = yield call(TrackerGetByOrginations, payload);

    yield put(
      getTrackerListByOrganizationsSuccess({
        data: data,
        message: data["message"],
       
      })
    );
  } catch (e) {
    yield put(
      getTrackerListByOrganizationsError({
        error: true,
        message: e["message"],
      })
    );
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
  }
}
function* TrackerUpdatebyReportWatcher({ payload }) {
  try {
    let { data } = yield call(TrackerUpdateReport, payload);

    yield put(
      updateTrackerReportSuccess({
        data: data,
        message: data["message"],
        
      })
    );
    if (payload.handleSuccess) {
      payload.handleSuccess();
    }
  } catch (e) {
    yield put(
      updateTrackerReportError({
        error: true,
        message: e["message"],
      })
    );
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
  }
}
function* TrackerUpdatebyStatusWatcher({ payload }) {
  try {
    let { data } = yield call(trackerStatusUpdate, payload);

    yield put(
      updateTrackerStatusSuccess({
        data: data,
        message: data["message"],
        
      })
    );
    if (payload.handleSuccess) {
      payload.handleSuccess();
    }
  } catch (e) {
    yield put(
      updateTrackerStatusError({
        error: true,
        message: e["message"],
      })
    );
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
  }
}
export default function* TrackerSaga() {
  yield takeLatest(TRACKER_APPOINTMENT_LIST_IN_PROGRESS, TrackerListByAppointmentWatcher);
  yield takeLatest(TRACKER_ADD_IN_PROGRESS, AddTrackerWatcher);
  yield takeLatest( TRACKER_LIST_BY_ORGANIZATION_IN_PROGRESS, TrackerListByOrginationsWatcher);
  yield takeLatest( TRACKER_UPDATE_BY_REPORT_PROGRESS, TrackerUpdatebyReportWatcher)
  yield takeLatest( TRACKER_UPDATE_BY_STATUS_PROGRESS , TrackerUpdatebyStatusWatcher)

}
