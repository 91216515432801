import { createClient, createMicrophoneAndCameraTracks } from "agora-rtc-react";

const appId = "8800cfcd1dba48138b7c020ab26b7530";
const token =
  "007eJxTYFjqFZL/4rOtE6vUnNVX7a8alitlvTeROT+tzqJnWpOnr7oCg4WFgUFyWnKKYUpSoomFobFFknmygZFBYpKRWZK5qbFB3hHVZAE+BoYdvIVMjAyMDCxADOIzgUlmMMkCJVNSc/MZGQwAv7Acpw==";

export const config = { mode: "rtc", codec: "vp8", appId, token };
export const useClient = createClient({
  mode: "rtc",
  codec: "vp8",
  appId,
  token,
});

export const useMicrophoneAndCameraTracks = createMicrophoneAndCameraTracks();
