import React, { useEffect, useState } from "react";
import { getFamilyMemberListEffect } from "../../appRedux/doctor/sagas/effects/FamilyMemberEffect";
import SelectBoxAppointment from "../Appoinment/SelectBox";

const SelectFamilyMember = ({
  placeholder = "Select Family member",
  label = "family member",
  rules = [],
  name,
  editMode,
  margin = "mb-0",
  onChange,
  patient_id,
}) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getStatusList();
  }, [patient_id]);
  const getStatusList = async () => {
    try {
      setLoading(true);
      const { data } = await getFamilyMemberListEffect(patient_id);
      const doctorList = data?.data;
      let options = doctorList.map((row) => ({
        label: `${row.firstName} - ${row.relationshipName}`,
        value: row.id,
      }));
      setOptions(options);
      setLoading(false);
    } catch (error) {
      setOptions([]);
      setLoading(false);
    }
  };
  return (
    <SelectBoxAppointment
      loading={loading}
      options={options}
      placeholder={placeholder}
      label={label}
      margin={margin}
      rules={rules}
      name={name}
      editMode={editMode}
      onChange={onChange}
    />
  );
};

export default SelectFamilyMember;
