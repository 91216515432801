import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Spin,
  Card,
  Typography,
  Tooltip,
  Form,
  DatePicker,
  Alert,
} from "antd";
import {
  LoadingOutlined,
  EditFilled,
  ArrowLeftOutlined,
  FileTextOutlined,
} from "@ant-design/icons";

import { Link } from "react-router-dom";
import "../../../styles/modules/superAdmin.less";
import IntlMessages from "../../../util/IntlMessages";
import TableComponent from "../../../components/Table/TableComponent";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getReportsInProgress } from "../../../appRedux/super-admin/SuperAdminAction";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const { RangePicker } = DatePicker;

const HospitalReportList = () => {
  const dispatch = useDispatch();
  const [orgId, setId] = useState("");
  const [datesSelected, setDatesSelected] = useState(false);
  const [selectedDates, setSelectedDates] = useState(null); // State to store selected dates
  const [form] = Form.useForm();
  const history = useHistory();
  const [Loading, setLoading] = useState(false);
  const location = useLocation();
  const hospitalData = location.state?.hospitalData;
  const previousDates = location.state?.selectedDates; // Retrieve previously selected dates if available
  const orgListData = useSelector((state) => state?.superAdmin?.getReports);

  const totalRecords = orgListData?.data?.totalRecords;
  const hospitalList = orgListData?.data?.data;
  useEffect(() => {
    if (previousDates && previousDates.length === 2) {
      setDatesSelected(true);
      setSelectedDates(previousDates);
      const payload = {
        fromDate: previousDates[0],
        toDate: previousDates[1],
        orgId: hospitalData?.id ? hospitalData?.id : location.state?.orgId,
      };
      dispatch(getReportsInProgress(payload));
    }
  }, [previousDates, hospitalData, dispatch]);

  const onChange = (dates, dateStrings) => {
    if (dateStrings[0] && dateStrings[1]) {
      setDatesSelected(true);
      setSelectedDates(dateStrings); // Store selected dates
      const payload = {
        fromDate: dateStrings[0],
        toDate: dateStrings[1],
        orgId: hospitalData?.id ? hospitalData?.id : location.state?.orgId,
      };
      dispatch(getReportsInProgress(payload));
    } else {
      setDatesSelected(false);
      setSelectedDates(null); // Clear dates if reset
    }
  };
  console.log("orgListData", orgListData);
  const columns = [
    {
      title: "S.No",
      dataIndex: "sno",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Consultation type",
      dataIndex: "consultation_type",
      key: "consultation_type",
      render: (text) =>
        text === "1" ? "In Person Consultation" : "Tele Consultation",
    },
    {
      title: "Time",
      dataIndex: "start_time",
      key: "start_time",
    },
    {
      title: "Action",
      dataIndex: "Action",
      render: (_, record) => {
        return (
          <Row className="table-button">
            <Col className="button-edit">
              <Tooltip placement="top" title={"Report"}>
                <Link
                  to={{
                    pathname: `/organization/report`,
                    state: {
                      hospitalData: record,
                      selectedDates,
                      orgId: hospitalData?.id,
                      orgName: location.state?.orgName,
                    },
                  }}
                >
                  <ButtonComponent
                    className="secondary-color"
                    icon={<FileTextOutlined />}
                  />
                </Link>
              </Tooltip>
            </Col>
          </Row>
        );
      },
    },
  ];

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );

  const handlePage = (page) => {
    const payload = {
      fromDate: selectedDates[0],
      toDate: selectedDates[1],
      orgId: page,
    };
    dispatch(getReportsInProgress(payload));
  };

  const goBackHandler = () => {
    history.push("/organization");
  };
  console.log("hospitalData", hospitalData);

  useEffect(() => {
    if (orgListData?.inProgress) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [orgListData]);
  return (
    <Card>
      <Row className="p-10 Add-button">
        <Col>
          <p className="header-tag">
            {<IntlMessages id={"Addnew.appoinments 0f"} />}
            {location.state?.orgName}
          </p>
        </Col>
        <ButtonComponent
          type="danger"
          icon={<ArrowLeftOutlined />}
          onClick={goBackHandler}
        ></ButtonComponent>
      </Row>

      <Row>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <RangePicker
            format="YYYY-MM-DD"
            onChange={onChange}
            placeholder={["From Date", "To Date"]}
            value={
              selectedDates
                ? [moment(selectedDates[0]), moment(selectedDates[1])]
                : null
            } // Pre-set dates if available
          />
        </Col>{" "}
      </Row>

      <Spin indicator={antIcon} spinning={Loading}>
        {datesSelected && hospitalList && hospitalList.length > 0 ? (
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            {" "}
            <TableComponent
              columns={columns}
              dataSource={hospitalList}
              documentTitle={"Organization List"}
              pagination={{
                total: totalRecords,
                onChange: (page) => handlePage(page),
              }}
            />
          </Col>
        ) : (
          <Col
            xl={20}
            lg={20}
            md={20}
            sm={24}
            xs={24}
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Alert
              message="No data available"
              description="Please select a date range.."
              type="info"
              showIcon
            />
          </Col>
        )}
      </Spin>
    </Card>
  );
};

export default HospitalReportList;
