import React, { useEffect, useState } from "react";

import { Row, Col, Spin, Typography } from "antd";
import {
  WomanOutlined,
  ManOutlined,
  EditOutlined,
  EyeOutlined,
  DeleteOutlined,
  LoadingOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import { useDispatch } from "react-redux";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import TableComponent from "../../../components/Table/TableComponent";

import { patientListInProgress } from "../../../appRedux/doctor/actions/patient";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { getSinglePatientHealthRecordsEffect } from "../../../appRedux/Patient/Sagas/Effect/patientAppointmentEffect";
import { getSinglePatientHeathRecordsInProgress } from "../../../appRedux/Patient/Action/Action";

const ViewHealthRecord = () => {
  const location = useLocation();
  const patientId = location.state;
  const history = useHistory();
  console.log("patientId", patientId);
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );
  const columns = [
    {
      title: "First Name",
      dataIndex: "firstName",
    },

    {
      title: "Last Name",
      dataIndex: "lastName",
    },
    {
      title: "DOB",
      dataIndex: "dob",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Address1",
      dataIndex: "address1",
    },

    {
      title: "City",
      dataIndex: "city",
    },
    {
      title: "State",
      dataIndex: "state",
    },
  ];
  const goBackHandler = () => {
    history.goBack();
  };
  const dispatch = useDispatch();
  const singlePatientData = useSelector(
    (state) => state.patient.getSingleHealthRecord
  );
  console.log("singlePatientData", singlePatientData);
  const patientList = singlePatientData?.data?.data;
  const [Loading, setLoading] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState("");
  const totalRecords = singlePatientData?.data?.totalRecords;
  const handlePage = (page) => {
    dispatch(patientListInProgress(page));
  };
  const handleSelectChange = (value) => {
    if (value === "all") {
      setFilteredUsers(patientList);
    } else {
      const newData = patientList.slice(0, value);
      setFilteredUsers(newData);
    }
  };
  useEffect(() => {
    dispatch(getSinglePatientHeathRecordsInProgress(patientId));
  }, [patientId]);

  useEffect(() => {
    if (singlePatientData?.inProgress) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [singlePatientData?.inProgress]);
  return (
    <Spin indicator={antIcon} spinning={Loading}>
      <Row>
        <Col xl={24} lg={24} md={24} xs={24} sm={24}>
          <Row justify={"space-between"} className="margin-row">
            <Col>
              <Typography className="view-header">
                Patient Health Record
              </Typography>
            </Col>
            <Col>
              <ButtonComponent
                type="danger"
                icon={<ArrowLeftOutlined />}
                onClick={goBackHandler}
                size="small"
              />
            </Col>
          </Row>
        </Col>
        <Col xl={24} lg={24} md={24} xs={24} sm={24}>
          <TableComponent
            columns={columns}
            dataSource={filteredUsers ? filteredUsers : patientList}
            pagination={{
              total: totalRecords,
              onChange: (page) => handlePage(page),
            }}
            itemList={patientList}
            filteredUsers={filteredUsers}
            setFilteredUsers={setFilteredUsers}
            field={"firstName"}
            rowselector={handleSelectChange}
          />
        </Col>
      </Row>
    </Spin>
  );
};

export default ViewHealthRecord;
