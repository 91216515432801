import {
  ADD_HOLIDAYS_LIST_ERROR,
  ADD_HOLIDAYS_LIST_PROGRESS,
  ADD_HOLIDAYS_LIST_SUCCESS,
  DEFAULT_HOLIDAY,
  DELETE_HOLIDAYS_LIST_ERROR,
  DELETE_HOLIDAYS_LIST_PROGRESS,
  DELETE_HOLIDAYS_LIST_SUCCESS,
  GET_HOLIDAYS_LIST_ERROR,
  GET_HOLIDAYS_LIST_PROGRESS,
  GET_HOLIDAYS_LIST_SUCCESS,
  GET_HOLIDAY_UPDATE_LIST_ERROR,
  GET_HOLIDAY_UPDATE_LIST_PROGRESS,
  GET_HOLIDAY_UPDATE_LIST_SUCCESS,
  SET_HOLIDAY_LIST_FAILURE,
  SET_HOLIDAY_LIST_SUCCESS,
  UPDATE_HOLIDAYS_LIST_ERROR,
  UPDATE_HOLIDAYS_LIST_PROGRESS,
  UPDATE_HOLIDAYS_LIST_SUCCESS,
} from "../../../constants/ActionTypes";

export const getHolidaysListInProgress = (payload) => ({
  type: GET_HOLIDAYS_LIST_PROGRESS,
  payload: payload,
});

export const getHolidaysListInSuccess = (payload) => ({
  type: GET_HOLIDAYS_LIST_SUCCESS,
  payload: payload,
});

export const getHolidaysListInError = (payload) => ({
  type: GET_HOLIDAYS_LIST_ERROR,
  payload: payload,
});

export const deleteHolidaysListInProgress = (payload) => ({
  type: DELETE_HOLIDAYS_LIST_PROGRESS,
  payload: payload,
});

export const deleteHolidaysListInSuccess = (payload) => ({
  type: DELETE_HOLIDAYS_LIST_SUCCESS,
  payload: payload,
});

export const deleteHolidaysListInError = (payload) => ({
  type: DELETE_HOLIDAYS_LIST_ERROR,
  payload: payload,
});

export const addHolidaysListInProgress = (payload) => ({
  type: ADD_HOLIDAYS_LIST_PROGRESS,
  payload: payload,
});

export const addHolidaysListInSuccess = (payload) => ({
  type: ADD_HOLIDAYS_LIST_SUCCESS,
  payload: payload,
});

export const addHolidaysListInError = (payload) => ({
  type: ADD_HOLIDAYS_LIST_ERROR,
  payload: payload,
});

export const updateHolidaysListInProgress = (payload) => ({
  type: UPDATE_HOLIDAYS_LIST_PROGRESS,
  payload: payload,
});

export const updateHolidaysListInSuccess = (payload) => ({
  type: UPDATE_HOLIDAYS_LIST_SUCCESS,
  payload: payload,
});

export const updateHolidaysListInError = () => ({
  type: UPDATE_HOLIDAYS_LIST_ERROR,
});

export const getHolidayUpdateListInProgress = (payload) => {
  return {
    type: GET_HOLIDAY_UPDATE_LIST_PROGRESS,
    payload: payload,
  };
};
export const getHolidayUpdateListSuccess = (payload) => {
  return {
    type: GET_HOLIDAY_UPDATE_LIST_SUCCESS,
    payload: payload,
  };
};

export const getHolidayUpdateListError = () => {
  return {
    type: GET_HOLIDAY_UPDATE_LIST_ERROR,
  };
};

// SETHOLIDAY
export const setHolidaysListInFailure = () => ({
  type: SET_HOLIDAY_LIST_FAILURE,
});
export const defaultHoliday = () => ({
  type: DEFAULT_HOLIDAY,
});
