import {
  CheckCircleOutlined,
  DeleteOutlined,
  EyeOutlined,
  FileAddOutlined,
  FolderViewOutlined,
  ForkOutlined,
  LoadingOutlined,
  PlusCircleOutlined,
  ScheduleOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import React, { Fragment, useState } from "react";
import ButtonComponent from "../../components/Button/ButtonComponent";
import {
  Row,
  Col,
  Button,
  Spin,
  message,
  Popconfirm,
  Tooltip,
  Card,
} from "antd";
import PageHeader from "../../components/PageHeader/PageHeader";
import { APP_ROUTES } from "../../constants/Routes";
import IntlMessages from "../../util/IntlMessages";
import TableComponent from "../../components/Table/TableComponent";
import { Tabs } from "antd";
import { useEffect } from "react";
import {
  getAppointmentsListInProgress,
  getAppointmentsUpcommingListInProgress,
  getDoctorAppointmentListInProgress,
  getDoctorUpcomingAppointmentListInProgress,
  getUpcomingAppointmentInProgress,
  reScheduleAppointmentByIdInProgress,
} from "../../appRedux/doctor/actions/Appointment";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  addPrescription,
  submitPrescription,
} from "../../appRedux/doctor/actions/Prescription";
import moment from "moment";
import {
  getPatientAppoinmentListInProgress,
  getUpcomingPatientAppoinmentListInProgress,
  patientDeleteAppointmentListInProgress,
} from "../../appRedux/Patient/Action/Action";
import { useClient } from "./components/VideoAppoinment/Settings";
import { access, formatDate } from "../../util/utilz";
import { getTrackerListByAppointmentProgress } from "../../appRedux/doctor/actions/Trackers";
import { trackerStatus } from "../Doctor/data";
import ReferModel from "./ReferModel";
import CompletedModel from "./CompletedModel";
import ChatBots from "../ChatBot/ChatBots";

const { TabPane } = Tabs;

const AllAppoinments = () => {
  const client = useClient();
  const [loading, setLoading] = useState(false);
  const [reScheduleModel, setRescheduleModel] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const dispatch = useDispatch();
  const [tabStatus, setTabStatus] = useState(2);
  const currentDate = moment(new Date()).format("YYYY-MM-DD");
  const [currentTime, setCurrentTime] = useState(moment().format("hh:mm A"));
  const patient = localStorage.getItem("patient_id") !== "null" ? true : false;
  const doctorID = localStorage.getItem("doctor_id");
  const doctor_id = doctorID !== "null" ? true : false;
  const [currentData, setCurrentData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCompletedModalOpen, setIsCompletedModalOpen] = useState(false);
  const admin = localStorage.getItem("role_id") === "1" ? true : false;

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment().format("hh:mm A"));
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  useEffect(async () => {
    client.leave();
    client.removeAllListeners();
  }, []);

  const { trackerByAppointment } = useSelector((state) => state?.tracker);

  const {
    upcomingAppointmentlist,
    appointmentlist,
    doctorupcomingappointmentlist,
    doctorappointmentlist,
    reSchedule,
    appointmentStatus,
  } = useSelector((state) => state.appointment);
  const { addTracker } = useSelector((state) => state?.tracker);
  const { getAppointmentList } = useSelector(
    (state) => state.patientAppointment
  );
  const { getUpcomingAppointmentList, deleteAppointmentList } = useSelector(
    (state) => state.patientAppointment
  );
  const upcomingPatientList = getUpcomingAppointmentList?.data;

  const appointmentLists = doctor_id
    ? doctorappointmentlist?.data?.data
    : patient
    ? getAppointmentList?.data?.data
    : appointmentlist?.data?.data;
  const totalRecords = doctor_id
    ? doctorappointmentlist?.data?.totalRecords
    : patient
    ? getAppointmentList?.data?.totalRecords
    : appointmentlist?.data?.totalRecords;

  const upcomingAppointmentLists = patient
    ? upcomingPatientList?.data
    : doctor_id
    ? doctorupcomingappointmentlist?.data?.data
    : upcomingAppointmentlist?.data?.data;
  const totalRecords2 = patient
    ? upcomingPatientList?.totalRecords
    : doctor_id
    ? doctorupcomingappointmentlist?.data?.totalRecords
    : upcomingAppointmentlist?.data?.totalRecords;

  // const moment = appointmentslist?.appointmentlist?.data?.data?.start_time

  const handleDelete = (id) => {
    dispatch(patientDeleteAppointmentListInProgress(id));
  };
  const handleReSchedule = (id) => {
    dispatch(reScheduleAppointmentByIdInProgress({ id: id.id }));
    setOpenModel(true);
  };
  useEffect(() => {
    if (reSchedule?.data?.appointmentInfo && openModel) {
      setRescheduleModel(true);
    }
  }, [reSchedule?.data?.appointmentInfo]);
  const referModel = (data) => {
    setCurrentData(data);
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleCompletedAppointment = () => {
    setIsCompletedModalOpen(false);
  };
  const handleCompleted = (data) => {
    setIsCompletedModalOpen(true);
    setCurrentData(data);
  };
  const columns = [
    {
      title: "Patient Name",
      dataIndex: "patient_name",
      sortDirections: ["ascend", "descend"],
      key: "patient_name",
    },
    {
      title: "Doctor Name",
      dataIndex: "doctor_name",
      sortDirections: ["ascend", "descend"],
      key: "doctor_name",
    },
    // {
    //   title: "Day",
    //   dataIndex: "week_day",
    // },
    {
      title: "Date & Time",
      dataIndex: "start_time",
      render: (start_time, data) =>
        moment(start_time, ["h:mm A"]).format("hh:mm A") +
        " - " +
        moment(data?.end_time, ["h:mm A"]).format("hh:mm A") +
        ",  " +
        formatDate(data?.date) +
        ", " +
        data?.week_day,
    },

    // {
    //   title: "Date",
    //   dataIndex: "date",
    //   render: (date) => formatDate(date),
    // },
    {
      title: "Reason",
      dataIndex: "reason",
      // render: (reason_name, record) =>
      //   reason_name === "Others" ? record.other_reason : reason_name,
    },
    {
      title: "Symptom",
      dataIndex: "symptom",
      // render: (symptom_name, record) =>
      //   symptom_name === "Others" ? record.other_symptom : symptom_name,
    },
    {
      title: "Status",
      dataIndex: "appointment_status_name",
    },
    {
      title: "Action",
      dataIndex: "Action",
      render: (_, record) => {
        const confirm = () => {
          handleDelete(record.id);
          message.success("Deleted successfully");
        };
        const cancel = () => {
          message.success("cancelled");
        };
        const currentDate = moment();
        const startTime = moment(record.date + "T" + record.start_time);
        const endTime = moment(record.date + "T" + record.end_time);
        const isCurrentDate = currentDate.isSame(moment(record.date), "day");
        const isWithinTimeRange = currentDate.isBetween(startTime, endTime);
        return (
          <Row className="table-button">
            <Col className="button-edit mx-5">
              <Tooltip placement="top" title={"View Appointment"}>
                <Link
                  to={{
                    pathname: APP_ROUTES.APPOINMENTVIEW,
                    state: { record: record },
                  }}
                >
                  <ButtonComponent
                    size="small"
                    type="primary"
                    icon={<EyeOutlined />}
                  />
                </Link>
              </Tooltip>
            </Col>

            {((isCurrentDate && isWithinTimeRange) ||
              Number(tabStatus) === 3) && (
              <>
                {[1, 3].includes(Number(record?.tracker)) &&
                  [1, 2, 3].includes(Number(record?.appointment_status)) &&
                  doctor_id && (
                    <Col className="button-edit mx-5">
                      <Tooltip placement="top" title={"Refer to"}>
                        <Link>
                          <ButtonComponent
                            size="small"
                            type="primary"
                            icon={<ForkOutlined />}
                            onClick={() => referModel(record)}
                          />
                        </Link>
                      </Tooltip>
                    </Col>
                  )}
              </>
            )}
            {/* {[1, 2, 3].includes(Number(record?.appointment_status)) &&
              doctor_id && (
                <Col className="button-edit mx-5">
                  <Tooltip placement="top" title={"Completed Appointment"}>
                    <Link>
                      <ButtonComponent
                        size="small"
                        type="primary"
                        icon={<CheckCircleOutlined />}
                        onClick={() => {
                          handleCompleted(record);
                        }}
                        disabled={[2, 4].includes(Number(record?.tracker))}
                      />
                    </Link>
                  </Tooltip>
                </Col>
              )} */}

            {isCurrentDate &&
            isWithinTimeRange &&
            Number(record.consultation_type) === 2 ? (
              <Col className="button-edit mx-5">
                <Tooltip placement="top" title={"Video Call"}>
                  <Link
                    to={{
                      pathname: APP_ROUTES.VIDEOCALL,
                      state: record,
                      type: "edit",
                    }}
                  >
                    <ButtonComponent
                      size="small"
                      type="primary"
                      icon={<VideoCameraOutlined />}
                    />
                  </Link>
                </Tooltip>
              </Col>
            ) : null}

            {Number(tabStatus) === 2 && patient && (
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={12}
                xl={8}
                className="button-edit mx-5"
              >
                {" "}
                <Popconfirm
                  title="Cancel the Appointment"
                  description="Are you sure to cancel this Appointment?"
                  onConfirm={confirm}
                  onCancel={cancel}
                  okText="Yes"
                  cancelText="No"
                >
                  <Tooltip placement="top" title={"Cancel Appointment"}>
                    {access("appointments", "delete") && (
                      <Button
                        size="small"
                        type="danger"
                        icon={<DeleteOutlined />}
                      ></Button>
                    )}
                  </Tooltip>
                </Popconfirm>
              </Col>
            )}

            {record?.isPrescriptionAdded === "Yes" && (
              <Col className="button-edit mx-5">
                <Tooltip placement="top" title={"View-Prescription"}>
                  <Link
                    to={{
                      pathname: "/prescription/data",
                      state: { record: record },
                    }}
                  >
                    <ButtonComponent
                      size="small"
                      type="primary"
                      icon={<FolderViewOutlined />}
                    />
                  </Link>
                </Tooltip>
              </Col>
            )}
            {doctor_id && (
              <>
                {record?.isPrescriptionAdded === "No" &&
                Number(tabStatus) === 3 ? (
                  <>
                    {[1, 2, 3].includes(Number(record?.appointment_status)) && (
                      <Col className="button-edit mx-5">
                        <Tooltip placement="top" title={"Add-Prescription"}>
                          <Link
                            to={{
                              pathname: `${APP_ROUTES.ADDPRESCRIPTION}`,
                              state: record,
                            }}
                          >
                            <ButtonComponent
                              size="small"
                              type="primary"
                              icon={<FileAddOutlined />}
                              disabled={[2, 4].includes(
                                Number(record?.tracker)
                              )}
                            />
                          </Link>
                        </Tooltip>
                      </Col>
                    )}
                  </>
                ) : (
                  <>
                    {Number(record.isPrev) !== 1 &&
                      [1].includes(Number(record?.appointment_status)) && (
                        <>
                          {access("appointments", "edit") && (
                            <>
                              <Col className="button-edit mx-5">
                                <Tooltip placement="top" title={"Re-Scheduled"}>
                                  <Popconfirm
                                    title="Re-Scheduled the Appointment"
                                    description="Are you sure to Re-Scheduled this Appointment?"
                                    onConfirm={() => {
                                      handleReSchedule(record);
                                    }}
                                    onCancel={cancel}
                                    okText="Yes"
                                    cancelText="No"
                                  >
                                    <Button
                                      icon={<ScheduleOutlined />}
                                      size="small"
                                      type="primary"
                                      // onClick={}
                                    ></Button>
                                  </Popconfirm>
                                </Tooltip>
                              </Col>
                            </>
                          )}
                        </>
                      )}
                  </>
                )}
              </>
            )}
          </Row>
        );
      },
    },
  ];

  useEffect(() => {
    // handlePage(1);
    handleUpcomingPage(1);
    previousHandlePage(1);
    handleTracker(1);
  }, [
    deleteAppointmentList?.success,
    reSchedule?.success,
    appointmentStatus?.success,
    addTracker?.success,
  ]);

  useEffect(() => {
    dispatch(submitPrescription());
    dispatch(addPrescription());
  }, [deleteAppointmentList?.success, reSchedule?.success]);

  const previousHandlePage = (page) => {
    if (patient) {
      dispatch(
        getPatientAppoinmentListInProgress({
          page,
          currentDate,
          currentTime,
        })
      );
    } else if (doctor_id) {
      dispatch(
        getDoctorAppointmentListInProgress({ page, currentDate, currentTime })
      );
    } else {
      dispatch(getAppointmentsListInProgress({ page, isPrev: 1 }));
    }
  };
  const handleUpcomingPage = (page) => {
    if (patient) {
      dispatch(
        getUpcomingPatientAppoinmentListInProgress({
          page,
          currentDate,
          currentTime,
        })
      );
    } else if (doctor_id) {
      dispatch(
        getDoctorUpcomingAppointmentListInProgress({
          page,
          currentDate,
          currentTime,
        })
      );
    } else {
      dispatch(getUpcomingAppointmentInProgress({ page, isPrev: 0 }));
    }
  };
  const handleTracker = (page) => {
    if (doctor_id) {
      dispatch(
        getTrackerListByAppointmentProgress({
          page,
          references_doctor_id: doctorID,
        })
      );
    }
  };
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );

  const column = [
    {
      title: "Patient Name",
      dataIndex: "patient_name",
      sortDirections: ["ascend", "descend"],
      key: "patient_name",
      render: (_, data) =>
        `${data?.Patient?.firstName} - ${data?.Patient?.patient_serial_no}`,
    },
    {
      title: "Refer By Doctor",
      dataIndex: "doctor_name",
      sortDirections: ["ascend", "descend"],
      key: "doctor_name",
      render: (_, data) => {
        return `${data?.Doctor?.firstName} - ${data?.Doctor?.doctor_serial_no}`;
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      render: (_, data) => formatDate(data?.Appointment?.date),
    },
    {
      title: "Reason",
      dataIndex: "reason",
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (val) =>
        trackerStatus?.find((data) => Number(data.value) === Number(val)).label,
    },
    {
      title: "Action",
      dataIndex: "Action",
      render: (_, record) => {
        return (
          <Row>
            <Col xs={4} className="button-edit mx-5">
              <Tooltip placement="top" title={"View Appointment"}>
                <Link
                  to={{
                    pathname: APP_ROUTES.APPOINMENTVIEW,
                    state: { record: record },
                  }}
                >
                  <Button
                    icon={<EyeOutlined />}
                    size="small"
                    type="primary"
                  ></Button>
                </Link>
              </Tooltip>
            </Col>
            {record.status !== 3 && (
              <Col xs={4} className="button-edit mx-5">
                <Tooltip placement="top" title={"Add-Prescription"}>
                  <Link
                    to={{
                      pathname: `${APP_ROUTES.ADDPRESCRIPTION}`,
                      state: { record, tracker: true },
                    }}
                  >
                    <ButtonComponent
                      size="small"
                      type="primary"
                      icon={<FileAddOutlined />}
                    />
                  </Link>
                </Tooltip>
              </Col>
            )}
            {record?.prescription_id !== null && (
              <Col xs={4} className="button-edit mx-5">
                <Tooltip placement="top" title={"View-Prescription"}>
                  <Link
                    to={{
                      pathname: "/prescription/data",
                      state: { record: record },
                    }}
                  >
                    <ButtonComponent
                      size="small"
                      type="primary"
                      icon={<FolderViewOutlined />}
                    />
                  </Link>
                </Tooltip>
              </Col>
            )}
          </Row>
        );
      },
    },
  ];

  return (
    <Fragment>
      <Card>
        <Spin
          indicator={antIcon}
          spinning={
            deleteAppointmentList.inProgress ||
            reSchedule.progressing ||
            appointmentlist?.progressing ||
            doctorappointmentlist?.progressing ||
            upcomingAppointmentlist?.progressing
          }
        >
          <PageHeader
            header={<IntlMessages id={"Addnew.appoinment"} />}
            link={APP_ROUTES.APPOINMENTANSWER}
            buttonLabel={<IntlMessages id={"Addnew.patient.addappoinment"} />}
            icon={<PlusCircleOutlined />}
            module={"appointments"}
            accessData="true"
          />

          <Tabs
            tabPosition={"top"}
            onChange={(e) => {
              setTabStatus(e);
            }}
          >
            {doctor_id && (
              <TabPane tab="Referred" key="1">
                <Spin
                  size="large"
                  spinning={loading}
                  tip="Please wait a moment"
                  style={{ margin: "20px" }}
                >
                  <TableComponent
                    columns={column}
                    dataSource={trackerByAppointment?.data?.data || []}
                    pagination={{
                      total: totalRecords2,
                      onChange: (page) => handleTracker(page),
                    }}
                    itemList={trackerByAppointment?.data?.data}
                    type="Appointment"
                    documentTitle={"Appointment"}
                  />
                </Spin>
              </TabPane>
            )}

            <TabPane tab="Upcoming" key="2">
              <TableComponent
                columns={columns}
                dataSource={upcomingAppointmentLists}
                pagination={{
                  total: totalRecords2,
                  onChange: (page) => handleUpcomingPage(page),
                }}
                itemList={upcomingAppointmentLists}
                type="Appointment"
                documentTitle={"Appointment"}
              />
            </TabPane>
            <TabPane tab="Previous" key="3">
              <TableComponent
                columns={columns}
                dataSource={appointmentLists}
                pagination={{
                  total: totalRecords,
                  onChange: (page) => previousHandlePage(page),
                }}
                itemList={appointmentLists}
                type="Appointment"
                documentTitle={"Appointment"}
              />
            </TabPane>
          </Tabs>
          <ReferModel
            currentData={currentData}
            isVisible={isModalOpen}
            onCancel={handleCancel}
            onSubmit={handleCancel}
          />
          <CompletedModel
            currentData={currentData}
            isVisible={isCompletedModalOpen}
            onCancel={handleCompletedAppointment}
            onSubmit={handleCompletedAppointment}
          />
          <ChatBots />
        </Spin>
      </Card>
    </Fragment>
  );
};

export default AllAppoinments;
