import React from "react";

import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

import AddPurchaseReturn from "./AddPurchaseReturn";

const EditPurchaseReturn = () => {
  const location = useLocation();
  const purchaseRetunData = location.state?.supplierData;

  return (
    <AddPurchaseReturn
      purchaseRetunData={purchaseRetunData}
      editPurchaseReturn="true"
    />
  );
};

export default EditPurchaseReturn;
