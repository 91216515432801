import React from "react";
import { Form, Input } from "antd";
const FormInput = ({
  placeholder,
  value,
  className,
  type,
  name,
  onChange,
  rules = [],
  label = "",
  labelCol,
  wrapperCol,
  onKeyDown,
  readOnly,
  min,
  rows,
  disabled,
  password = "false",
  defaultValue,
  max,
  hidden,
  suffix,
  size = "default",
  step,
}) => {
  return (
    <Form.Item
      name={name}
      rules={rules}
      label={label}
      labelCol={{
        span: labelCol,
      }}
      wrapperCol={{
        span: wrapperCol,
      }}
    >
      {password === "true" ? (
        <Input.Password
          placeholder={placeholder}
          value={value}
          name={name}
          onChange={onChange}
          className={className}
          onKeyDown={onKeyDown}
          readOnly={readOnly}
          disabled={disabled}
          min={min}
          rows={rows}
        />
      ) : (
        <Input
          type={type}
          placeholder={placeholder}
          value={value}
          name={name}
          onChange={onChange}
          className={className}
          onKeyDown={onKeyDown}
          readOnly={readOnly}
          disabled={disabled}
          min={min}
          max={max}
          rows={rows}
          defaultValue={defaultValue}
          hidden={hidden}
          suffix={suffix}
          size={size}
          step={step}
        />
      )}
    </Form.Item>
  );
};
export default FormInput;
