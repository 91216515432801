import React from "react";
import PurchaseRequestTable from "./PurchaseRequestTable";
import { Card } from "antd";
import PurchaseRequestHeader from "./PurchaseRequestHeader";

const PurchaseRequestScreen = () => {
  return (
    <Card>
      <PurchaseRequestHeader />
      <PurchaseRequestTable />
    </Card>
  );
};

export default PurchaseRequestScreen;
