/* eslint-disable no-sequences */
import { Col, Divider, Row, Spin, Table, Typography } from "antd";
import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  ArrowLeftOutlined,
  BranchesOutlined,
  DownloadOutlined,
  EnvironmentFilled,
  LoadingOutlined,
  PhoneFilled,
  PrinterFilled,
} from "@ant-design/icons";
import moment from "moment";
import { drawDOM, exportPDF } from "@progress/kendo-drawing";
import { saveAs } from "@progress/kendo-file-saver";
import { useLocation } from "react-router-dom";
import { APP_ROUTES } from "../../constants/Routes";
import { appoinmentType } from "../Doctor/data";
import ButtonComponent from "../../components/Button/ButtonComponent";
import { getPatientAppointmentByIdInProgress } from "../../appRedux/Patient/Action/Action";
import { formatDate } from "../../util/utilz";

function ViewAppointment(props) {
  const locations = useLocation();
  const dispatch = useDispatch();
  const pdfContentRef = React.useRef(null);
  const [loading, setLoading] = useState(false);
  const [printingDone, setPrintingDone] = useState(false);
  const [PDFDone, setPDFDone] = useState(false);
  const details = props.location.state || locations?.state;
  const history = useHistory();
  useEffect(() => {
    dispatch(getPatientAppointmentByIdInProgress(details?.id));
  }, [details]);
  const { getAppointmentById } = useSelector(
    (state) => state.patientAppointment
  );
  const apidata = getAppointmentById.data?.data;
  const trackers =
    apidata?.Trackers?.map((data) =>
      data?.RefSubPackageCategory
        ? {
            price: data?.RefSubPackageCategory.price,
            package_name: data?.RefSubPackageCategory.package_name,
            subpackagescategory_id: data?.RefSubPackageCategory.id,
            type: data?.RefSubPackageCategory?.type,
            id: data?.RefSubPackageCategory.id,
          }
        : null
    ).filter((item) => item !== null) || []; // Ensure it's an array

  const updatedTrackers = [
    {
      price: apidata?.SubPackageCategory?.price,
      package_name: apidata?.SubPackageCategory?.package_name,
      subpackagescategory_id: apidata?.SubPackageCategory?.id,
      type: 3,
      id: apidata?.SubPackageCategory?.id,
    },
    ...trackers,
  ];

  const columns = [
    {
      title: "Packages Name",
      dataIndex: "package_name",
    },
    {
      title: "Type",
      dataIndex: "type",
      render: (val) =>
        appoinmentType?.find((data) => Number(data.value) === Number(val))
          .label,
    },
    {
      title: "Price",
      dataIndex: "price",
    },
  ];
  const handlePrint = () => {
    setPrintingDone(true);
  };

  useEffect(() => {
    if (printingDone) {
      window.print();
    }
    setPrintingDone(false);
  }, [printingDone]);
  const goBackHandler = () => {
    history.goBack();
  };
  useEffect(() => {
    const pdf = async () => {
      const content = pdfContentRef.current;

      const options = {
        paperSize: "A4",
        scale: 1,
        margin: { left: "1cm", right: "1cm", top: "1cm" }, // Adjust margins as needed
        landscape: true,
        // Set a fixed DPI for screen resolution, e.g., 96 DPI
        pdf: {
          autoTable: { useCss: true },
          avoidLinks: true,
          format: "A4",
          scale: 1,
        },
      };

      const group = await drawDOM(content, options);
      exportPDF(group).then((data) => {
        saveAs(data, "download.pdf");
      });
    };
    if (PDFDone) {
      pdf();
    }
    setPDFDone(false);
  }, [PDFDone]);

  const handleDownloadPDF = () => {
    setPDFDone(true);
  };
  const handleReadyPrescription = () => {
    history.push({
      pathname: `${APP_ROUTES.ADDBILLINGS}`,
      state: {
        updatedTrackers,
        doctorID: apidata.doctor_id,
        patientID: apidata.patient_id,
      },
    });
  };
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );
  return (
    <>
      <div>
        <Spin spinning={loading} indicator={antIcon}>
          {!printingDone && (
            <Row justify="end" className="view-grid">
              <ButtonComponent
                type="primary"
                icon={<DownloadOutlined />}
                onClick={handleDownloadPDF}
              ></ButtonComponent>
              <ButtonComponent
                type="success"
                icon={<PrinterFilled />}
                className={"print-button"}
                onClick={handlePrint}
              ></ButtonComponent>

              <ButtonComponent
                type="danger"
                icon={<ArrowLeftOutlined />}
                onClick={goBackHandler}
              ></ButtonComponent>
            </Row>
          )}
          <div ref={pdfContentRef}>
            <Row>
              <Col xl={24} lg={24} md={24} xs={24} sm={24}>
                <Row
                  style={{
                    marginLeft: 0,
                    marginRight: 0,
                    marginBottom: 20,
                  }}
                  justify="start"
                  gutter={[0, 10]}
                >
                  <Col xs={24} align="left">
                    <Typography align="center" className="view-name">
                      {apidata?.organizations_name}
                    </Typography>
                  </Col>
                  <Col xs={24}>
                    <Typography align="center" justify="center">
                      {!PDFDone ? <EnvironmentFilled /> : null}{" "}
                      {apidata?.organization_address},
                      {apidata?.organization_state},
                      {apidata?.organization_country}
                    </Typography>
                  </Col>
                  <Col xs={24}>
                    <Typography align="center">
                      {!PDFDone ? <PhoneFilled /> : null}

                      {apidata?.organization_contact}
                    </Typography>
                  </Col>
                </Row>
              </Col>
              <Col xl={12} lg={12} md={12} xs={24} sm={12}>
                <Row
                  style={{
                    marginLeft: 0,
                    marginRight: 0,
                    marginBottom: 20,
                  }}
                  justify="start"
                  gutter={[0, 10]}
                >
                  <Col xs={24} align="left">
                    <Typography className="view-list-details text-warp py-5">
                      Doctor : {apidata?.doctor_name} [
                      {apidata?.specialization_name}]
                    </Typography>
                    <Typography className="text-warp  py-5">
                      Patient : {apidata?.patient_name} [
                      {apidata?.patient_serial_no}]
                    </Typography>
                    <Typography className="text-warp  py-5">
                      Patient Mobile : {apidata?.Trackers[0]?.Patient?.contact}
                    </Typography>
                  </Col>
                </Row>
              </Col>
              <Col xl={12} lg={12} md={12} xs={24} sm={12}>
                <Row
                  style={{
                    marginLeft: 0,
                    marginRight: 0,
                    marginBottom: 20,
                  }}
                  justify="end"
                  gutter={[0, 10]}
                >
                  <Col xs={24} align="right">
                    <Typography className="text-warp  py-5">
                      Date : {formatDate(apidata?.date)}
                    </Typography>
                  </Col>
                  <Col xs={24} align="right">
                    <Typography className="text-warp  py-5">
                      Status : {apidata?.appointment_status_name}
                    </Typography>
                  </Col>
                </Row>
              </Col>
              <Col xs={24}>
                <Divider />
              </Col>
            </Row>
            <Table
              columns={columns}
              dataSource={updatedTrackers || []}
              pagination={false}
            />
          </div>
          {!printingDone && (
            <Row
              justify="end"
              className="view-grid"
              style={{ marginTop: "10px" }}
            >
              <ButtonComponent
                type="success"
                icon={<BranchesOutlined />}
                className={"print-button"}
                onClick={handleReadyPrescription}
                label={"Ready Billing"}
              />
            </Row>
          )}
        </Spin>
      </div>
    </>
  );
}

export default ViewAppointment;
