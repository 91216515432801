import React from "react";
import { Col, Row, Divider } from "antd";
import IntlMessages from "../../util/IntlMessages";

const DoctorAppointmentHeader = () => {
  return (
    <div>
      <Row className="p-10 Add-button">
        <Col>
          <p className="header-tag">
            {<IntlMessages id={"Appointment.doctorbyappointment"} />}
          </p>
        </Col>
      </Row>

      <Divider />
    </div>
  );
};

export default DoctorAppointmentHeader;
