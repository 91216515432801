import { PlusCircleOutlined } from "@ant-design/icons";
import { Col, Divider, Row } from "antd";
import React from "react";
import { Link } from "react-router-dom";

import { APP_ROUTES } from "../../../constants/Routes";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import IntlMessages from "../../../util/IntlMessages";
import Auxiliary from "../../../util/Auxiliary";

const RefDoctorHeader = () => {
  return (
    <Auxiliary>
      <Row className="bot">
        <Col span={12}>
          <h1 className="text">
            {<IntlMessages id={"Addnew.referencedoctor"} />}
          </h1>
        </Col>
        <Col span={12}>
          <Link to={APP_ROUTES.REFERENCEDOCTORADDNEW}>
            <ButtonComponent
              className="buttons"
              icon={<PlusCircleOutlined />}
              type="primary"
              label={
                <IntlMessages id={"Addnew.referencedoctor.label.addnew"} />
              }
            />
          </Link>
        </Col>
      </Row>
      <Divider />
    </Auxiliary>
  );
};

export default RefDoctorHeader;
