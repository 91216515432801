import { call, put, takeLatest } from "@redux-saga/core/effects";

import {
  ADD_BILLING_IN_PROGRESS,
  ADD_PAYMENT_IN_PROGRESS,
  DELETE_BILLING_IN_PROGRESS,
  GET_BILLING_BY_INVOICE_LIST_IN_PROGRESS,
  GET_BILLING_BY_PATIENT_LIST_IN_PROGRESS,
  GET_BILLING_LIST_IN_PROGRESS,
  GET_DUE_BY_PATIENT_IN_PROGRESS,
  UNDO_BILL_IN_PROGRESS,
  UPDATE_BILLING_IN_PROGRESS,
} from "../../../constants/ActionTypes";
import { getErrorMessageFromAPI } from "../../../util/utilz";
import { message } from "antd";
import {
  addPaymentInInSuccess,
  addPaymentInError,
  getBillingListInSuccess,
  getBillingListInError,
  addBillingInSuccess,
  addBillingInError,
  updateBillingInSuccess,
  updateBillingInError,
  deleteBillingSuccess,
  deleteBillingError,
  getBillingByInvoiceListInSuccess,
  getBillingByInvoiceListInError,
  getBillingByPatientListInSuccess,
  getBillingByPatientListInError,
  getDueByPatientInSuccess,
  getDueByPatientInError,
  undoBillInSuccess,
  undoBillInError,
} from "../actions/addPayment";
import {
  AddBillingPaymentsEffect,
  addBillingEffect,
  deleteBillingEffect,
  getBillingsByInvoiceEffect,
  getBillingsByPatientEffect,
  getBillingsListEffect,
  getDueByPatientEffect,
  undoBillEffect,
  updateBillingEffect,
} from "./effects/billingPaymentEffects";
import Message from "../../../components/Message/Message";

function* addPayments({ payload }) {
  try {
    let { data } = yield call(AddBillingPaymentsEffect, payload);

    yield put(
      addPaymentInInSuccess({
        data: data,
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
    if (payload.onSubmit) {
      payload.onSubmit();
    }
  } catch (e) {
    yield put(
      addPaymentInError({
        error: true,
        message: e["message"],
      })
    );
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
  }
}
function* getBillingPackageList({ payload }) {
  try {
    const { data } = yield call(getBillingsListEffect, payload);
    yield put(
      getBillingListInSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
  } catch (e) {
    yield put(
      getBillingListInError({
        message: getErrorMessageFromAPI(e),
        error: true,
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}

function* addBilling({ payload }) {
  try {
    let { data } = yield call(addBillingEffect, payload);

    yield put(
      addBillingInSuccess({
        data: data,
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield put(
      addBillingInError({
        error: true,
        message: e["message"],
      })
    );
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
  }
}

function* updateBilling({ payload }) {
  try {
    let { data } = yield call(updateBillingEffect, payload);

    yield put(
      updateBillingInSuccess({
        data: data,
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield put(
      updateBillingInError({
        error: true,
        message: e["message"],
      })
    );
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
  }
}

function* deleteBilling({ payload }) {
  try {
    let { data } = yield call(deleteBillingEffect, payload);

    yield put(
      deleteBillingSuccess({
        data: data,
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield put(
      deleteBillingError({
        error: true,
        message: e["message"],
      })
    );
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
  }
}

function* getBillingByInvoice({ payload }) {
  try {
    const { data } = yield call(getBillingsByInvoiceEffect, payload);
    yield put(
      getBillingByInvoiceListInSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
  } catch (e) {
    yield put(
      getBillingByInvoiceListInError({
        message: getErrorMessageFromAPI(e),
        error: true,
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}

function* getBillingByPatient({ payload }) {
  try {
    const { data } = yield call(getBillingsByPatientEffect, payload);
    yield put(
      getBillingByPatientListInSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
  } catch (e) {
    yield put(
      getBillingByPatientListInError({
        message: getErrorMessageFromAPI(e),
        error: true,
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}

function* getDueByPatient({ payload }) {
  try {
    const { data } = yield call(getDueByPatientEffect, payload);
    yield put(
      getDueByPatientInSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
  } catch (e) {
    yield put(
      getDueByPatientInError({
        message: getErrorMessageFromAPI(e),
        error: true,
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}

function* undoBill({ payload }) {
  try {
    const { data } = yield call(undoBillEffect, payload);
    yield put(
      undoBillInSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
  } catch (e) {
    yield put(
      undoBillInError({
        message: getErrorMessageFromAPI(e),
        error: true,
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}
export function* BillingPaymentSaga() {
  yield takeLatest(ADD_PAYMENT_IN_PROGRESS, addPayments);
  yield takeLatest(GET_BILLING_LIST_IN_PROGRESS, getBillingPackageList);
  yield takeLatest(ADD_BILLING_IN_PROGRESS, addBilling);
  yield takeLatest(UPDATE_BILLING_IN_PROGRESS, updateBilling);
  yield takeLatest(DELETE_BILLING_IN_PROGRESS, deleteBilling);
  yield takeLatest(
    GET_BILLING_BY_INVOICE_LIST_IN_PROGRESS,
    getBillingByInvoice
  );
  yield takeLatest(
    GET_BILLING_BY_PATIENT_LIST_IN_PROGRESS,
    getBillingByPatient
  );
  yield takeLatest(GET_DUE_BY_PATIENT_IN_PROGRESS, getDueByPatient);
  yield takeLatest(UNDO_BILL_IN_PROGRESS, undoBill);
}
