import { GET_DASHBOARD_IN_ERROR, GET_DASHBOARD_IN_PROGRESS, GET_DASHBOARD_IN_SUCCESS } from "../../../../constants/ActionTypes";
  
  const defaultStatus = {
    progressing: false,
    success: false,
    error: false,
    data: [],
  };
  const initialState = {
    adminDashboardList: { ...defaultStatus },

  };
  const adminDashboardReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
      //dashboardList
      case GET_DASHBOARD_IN_PROGRESS:
        return {
          ...state,
          adminDashboardList: { ...defaultStatus, ...payload, progressing: true },
        };
      case GET_DASHBOARD_IN_SUCCESS:
        return {
          ...state,
          adminDashboardList: { ...defaultStatus, ...payload, success: true },
        };
      case GET_DASHBOARD_IN_ERROR:
        return {
          ...state,
          adminDashboardList: {
            ...defaultStatus,
            ...payload,
            error: true,
          },
        };


    
      default:
        return state;
    }
  };
  
  export default adminDashboardReducer;
  