import { AlibabaOutlined, AliwangwangOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import React from "react";

function AppointmentQuestionsAnswerModel({ qusetion }) {
  return (
    <div>
      {qusetion &&
        qusetion.map((val, i) => (
          <div className="mb-10">
            <Typography className="f-20 fw-400">
              <AliwangwangOutlined /> {i + 1}. {val.question}
            </Typography>
            <Typography color="red" className="ml-20 my-5 f-18 star">
              <AlibabaOutlined />: {val.answer} &nbsp;
            </Typography>
          </div>
        ))}{" "}
    </div>
  );
}

export default AppointmentQuestionsAnswerModel;
