/* eslint-disable no-sequences */
import { Col, Divider, Row, Spin, Tag, Timeline, Typography } from "antd";
import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  ArrowLeftOutlined,
  BranchesOutlined,
  DownloadOutlined,
  EnvironmentFilled,
  LoadingOutlined,
  PhoneFilled,
  PrinterFilled,
} from "@ant-design/icons";
import moment from "moment";
import { drawDOM, exportPDF } from "@progress/kendo-drawing";
import { saveAs } from "@progress/kendo-file-saver";
import { useLocation } from "react-router-dom";
import { APP_ROUTES } from "../../constants/Routes";
import { appoinmentType } from "../Doctor/data";
import ButtonComponent from "../../components/Button/ButtonComponent";
import { getPatientAppointmentByIdInProgress } from "../../appRedux/Patient/Action/Action";
import { formatDate, formatTime } from "../../util/utilz";
import ReferralTimeLine from "./ReferralTimeLine";

function ViewAppointments(props) {
  const locations = useLocation();
  const dispatch = useDispatch();
  const pdfContentRef = React.useRef(null);
  const [printingDone, setPrintingDone] = useState(false);
  const [PDFDone, setPDFDone] = useState(false);
  const details = props.location.state?.record || locations?.state?.record;
  const history = useHistory();
  console.log("getAppointmentById", details?.appointment_id);
  useEffect(() => {
    dispatch(
      getPatientAppointmentByIdInProgress(
        details?.appointment_id || details?.id
      )
    );
  }, [details, dispatch]);
  const { getAppointmentById } = useSelector(
    (state) => state.patientAppointment
  );

  const patientAppList = getAppointmentById.data?.data;
  const apidata = patientAppList;

  const handlePrint = () => {
    setPrintingDone(true);
  };

  useEffect(() => {
    if (printingDone) {
      window.print();
    }
    setPrintingDone(false);
  }, [printingDone]);
  const goBackHandler = () => {
    history.goBack();
  };
  useEffect(() => {
    const pdf = async () => {
      const content = pdfContentRef.current;

      const options = {
        paperSize: "A4",
        scale: 1,
        margin: { left: "1cm", right: "1cm", top: "1cm" }, // Adjust margins as needed
        landscape: true,
        // Set a fixed DPI for screen resolution, e.g., 96 DPI
        pdf: {
          autoTable: { useCss: true },
          avoidLinks: true,
          format: "A4",
          scale: 1,
        },
      };

      const group = await drawDOM(content, options);
      exportPDF(group).then((data) => {
        saveAs(data, "download.pdf");
      });
    };
    if (PDFDone) {
      pdf();
    }
    setPDFDone(false);
  }, [PDFDone]);

  const handleDownloadPDF = () => {
    setPDFDone(true);
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );
  return (
    <>
      <div>
        <Spin spinning={getAppointmentById?.inProgress} indicator={antIcon}>
          {!printingDone && (
            <Row justify="end" className="view-grid">
              <ButtonComponent
                type="primary"
                icon={<DownloadOutlined />}
                onClick={handleDownloadPDF}
              ></ButtonComponent>
              <ButtonComponent
                type="success"
                icon={<PrinterFilled />}
                className={"print-button"}
                onClick={handlePrint}
              ></ButtonComponent>

              <ButtonComponent
                type="danger"
                icon={<ArrowLeftOutlined />}
                onClick={goBackHandler}
              ></ButtonComponent>
            </Row>
          )}
          <div ref={pdfContentRef}>
            <Row>
              <Col xl={24} lg={24} md={24} xs={24} sm={24}>
                <Row
                  style={{
                    marginLeft: 0,
                    marginRight: 0,
                    marginBottom: 20,
                  }}
                  justify="start"
                  gutter={[0, 10]}
                >
                  <Col xs={24} align="left">
                    <Typography align="center" className="view-name">
                      View Appointment
                    </Typography>
                  </Col>
                </Row>
              </Col>
              <Col xl={19} lg={18} md={17} xs={24} sm={12}>
                <Row
                  style={{
                    marginLeft: 0,
                    marginRight: 0,
                    marginBottom: 20,
                  }}
                  justify="start"
                  gutter={[0, 10]}
                >
                  <Col xs={24} align="left">
                    <Typography className="view-list-details text-warp py-5">
                      Doctor : {apidata?.doctor_name} [
                      {apidata?.specialization_name}]
                    </Typography>
                    <Typography className="text-warp  py-5">
                      Patient : {apidata?.patient_name} [
                      {apidata?.patient_serial_no}]
                    </Typography>
                    <Typography className="text-warp  py-5">
                      Patient Mobile : {apidata?.Trackers[0]?.Patient?.contact}
                    </Typography>
                    <Typography className="text-warp  py-5">
                      Patient Blood : {apidata?.patient_blood_group}
                    </Typography>
                    <Typography className="text-warp  py-5">
                      Patient Gender :{" "}
                      {apidata?.patient_gender === "M"
                        ? "Male"
                        : apidata?.patient_gender === "F"
                        ? "FeMale"
                        : "Others"}
                    </Typography>
                  </Col>
                </Row>
              </Col>
              <Col xl={5} lg={6} md={7} xs={24} sm={12}>
                <Row
                  style={{
                    marginLeft: 0,
                    marginRight: 0,
                    marginBottom: 20,
                  }}
                  justify="end"
                  gutter={[0, 10]}
                >
                  <Col xs={24} align="left">
                    <Typography className="text-warp  py-5">
                      Date : {formatDate(apidata?.date)}
                    </Typography>
                  </Col>
                  <Col xs={24} align="left">
                    <Typography className="text-warp  py-5">
                      Time :{" "}
                      {formatTime(apidata?.start_time) +
                        " - " +
                        formatTime(apidata?.end_time)}
                    </Typography>
                  </Col>

                  <Col xs={24} align="left">
                    <Typography className="text-warp  py-5">
                      Symptom : {apidata?.symptom}
                    </Typography>
                  </Col>
                  <Col xs={24} align="left">
                    <Typography className="text-warp  py-5">
                      Reason : {apidata?.reason}
                    </Typography>
                  </Col>
                  <Col xs={24} align="left">
                    <Typography className="text-warp ">
                      Status :{" "}
                      <Tag color="success">
                        {apidata?.appointment_status_name}
                      </Tag>{" "}
                    </Typography>
                  </Col>
                </Row>
              </Col>
              <Col xs={24}>
                <Divider />
              </Col>
              {apidata?.Trackers.map((data) => (
                <Col xs={8} className="my-10">
                  <ReferralTimeLine
                    data={data}
                    date={formatDate(apidata?.date)}
                  />
                </Col>
              ))}
            </Row>
          </div>
        </Spin>
      </div>
    </>
  );
}

export default ViewAppointments;
