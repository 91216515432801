import { Card, Col, Divider, Row } from "antd";
import React from "react";

import "../../../styles/modules/reference.less";
import Auxiliary from "../../../util/Auxiliary";
import IntlMessages from "../../../util/IntlMessages";
import RefDoctorForm from "./RefDoctorForm";

const RefDoctorEdit = () => {
  return (
    <Auxiliary>
      <Card>
        <Row>
          <Col span={24}>
            <h1 className="text">
              {
                <IntlMessages
                  id={"Addnew.referencedoctor.label.editreferencedoctor"}
                />
              }
            </h1>
          </Col>
          <Divider />
        </Row>
        <RefDoctorForm />
      </Card>
    </Auxiliary>
  );
};

export default RefDoctorEdit;
