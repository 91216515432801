import React from "react";
import { Form, InputNumber } from "antd";
const NumberData = ({
  placeholder,
  value,
  className,
  name,
  onChange,
  rules = [],
  label = "",
  labelCol,
  wrapperCol,
  min,
  max,
  style,
}) => {
  return (
    <Form.Item
      name={name}
      label={label}
      rules={rules}
      labelCol={labelCol}
      wrapperCol={wrapperCol}
    >
      <InputNumber
        className={className}
        placeholder={placeholder}
        value={value}
        min={min}
        max={max}
        onChange={onChange}
        style={style}
      />
    </Form.Item>
  );
};
export default NumberData;
