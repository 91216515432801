import {
  UserOutlined,
  CalendarOutlined,
  ForkOutlined,
} from "@ant-design/icons";
import { Col, Row } from "antd";
import React, { useState } from "react";
import { productData } from "../../data";
import CalendarItem from "./CalendarItem";

const Timeline = () => {
  return (
    <div>
      <div className="gx-main-content">
        <Row>
          {productData.map((product, index) => (
            <Col key={index} span={24}>
              <CalendarItem key={index} product={product} />
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};

export default Timeline;
