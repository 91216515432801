import React from "react";
import { Select } from "antd";
const FormSelectBox = ({
  placeholder,
  value,
  name,
  onChange,
  options,
  className,
  mode,
  showSearch,
  defaultValue,
  disabled,
  filterOption,
  handleFilter,
  onSelect,
}) => {
  return (
    <Select
      name={name}
      value={value}
      onChange={onChange}
      onSelect={onSelect}
      mode={mode}
      className={className}
      showSearch={showSearch}
      placeholder={placeholder}
      disabled={disabled}
      defaultValue={defaultValue}
      optionFilterProp="children" // Specify which prop of option will be used for filter
      filterOption={
        handleFilter
          ? false
          : (input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      onSearch={handleFilter} // Trigger handleFilter on search
      // Custom filter function
    >
      {/* <option value="">{placeholder}</option> */}
      {options.map((option, index) => (
        <option value={option.value} key={index}>
          {option.label}
        </option>
      ))}
    </Select>
  );
};
export default FormSelectBox;
