import {
  LOGIN_USER_ERROR,
  LOGIN_USER_IN_PROGRESS,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_IN_PROGRESS,
  LOGOUT_USER_SUCCESS,
  REGISTER_USER_IN_PROGRESS,
  REGISTER_USER_SUCCESS,
} from "../../../constants/ActionTypes";

export const UserLoginInProgress = (user) => {
  return {
    type: LOGIN_USER_IN_PROGRESS,
    payload: user,
  };
};
export const UserLoginSuccess = (user) => {
  return {
    type: LOGIN_USER_SUCCESS,
    payload: user,
  };
};
export const UserLoginError = (user) => {
  return {
    type: LOGIN_USER_ERROR,
    payload: user,
  };
};
export const RegisterUserInProgress = (user) => {
  return {
    type: REGISTER_USER_IN_PROGRESS,
    payload: user,
  };
};
export const RegisterUserSuccess = (user) => {
  return {
    type: REGISTER_USER_SUCCESS,
    payload: user,
  };
};
export const RegisterUserError = (user) => {
  return {
    type: REGISTER_USER_SUCCESS,
    payload: user,
  };
};
export const UserLogoutInProgress = () => {
  return {
    type: LOGOUT_USER_IN_PROGRESS,
  };
};
export const UserLogoutSuccess = (payload) => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: payload,
  };
};
