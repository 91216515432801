import React, { useEffect, useState } from "react";

import {
  Card,
  Col,
  Form,
  InputNumber,
  Row,
  Select,
  Slider,
  Space,
  Spin,
  Typography,
} from "antd";
import { Progress } from "antd";
import {
  CreditCardOutlined,
  LoadingOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";

import { useDispatch, useSelector } from "react-redux";
import IntlMessages from "../../util/IntlMessages";
import ButtonComponent from "../../components/Button/ButtonComponent";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ReactSpeedometer from "react-d3-speedometer";
const { Text } = Typography;
const { Option } = Select;
const conicColors = {
  "0%": "#87d068",
  "50%": "#ffe58f",
  "100%": "#ffccc7",
};
const BMIScreen = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );
  const [loading, setLoading] = useState(false);
  const [weightValue, setWeightValue] = useState(1);
  const [heightValue, setHeightValue] = useState(0);
  const [bmi, setBmi] = useState(0);
  const [heightUnit, setHeightUnit] = useState("cm");
  const changeHeightUnit = (value) => {
    setHeightUnit(value);
  };
  const onChangeHeight = (value) => {
    if (isNaN(value)) {
      return;
    }
    setHeightValue(value);
    form.setFieldValue("height", value);
  };
  const onChangeWeight = (newValue) => {
    form.setFieldValue("weight", newValue);
    setWeightValue(newValue);
  };
  const goBackHandler = () => {
    history.goBack();
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinish = (data) => {
    const { weight, height } = data;
    let heightInMeters = height;
    const unit = heightUnit;
    console.log("unittt", unit);
    if (unit === "cm") {
      heightInMeters = height / 100;
    } else if (unit === "feet") {
      const feet = Math.floor(height);
      const inches = (height - feet) * 10;
      heightInMeters = feet * 0.3048 + inches * 0.0254;
    }

    const bmiValue = weight / (heightInMeters * heightInMeters);
    setBmi(bmiValue.toFixed(2));

    console.log("datas", data);
    console.log("bmi", bmiValue.toFixed(2));
  };
  const marks = {
    0: "0",
    50: "50",
    100: {
      style: {
        color: "#f50",
      },
      label: <strong>100</strong>,
    },
    150: "150",
    200: "200",
  };
  const marksHeight = {
    0: "0.01",
    100: "100.0",
    150.0: {
      style: {
        color: "#f50",
      },
      label: <strong>150.0</strong>,
    },
    180.0: "180.0",
    200.0: "200.0",
  };
  const marksFeet = {
    0: "0.01",
    2.0: "2.0",
    3.0: "3.0",
    5.0: {
      style: {
        color: "#f50",
      },
      label: <strong>5.0</strong>,
    },
    6.0: "6.0",
    8.0: "8.0",
    10.0: "10.0",
  };
  const getBMICategory = (bmi) => {
    if (bmi >= 30) {
      return "Over Obese";
    } else if (bmi >= 25) {
      return "Obese";
    } else if (bmi >= 18.5) {
      return "Normal";
    } else {
      return "Underweight";
    }
  };
  return (
    <Card>
      <Spin indicator={antIcon} spinning={loading}>
        <Row className="p-10 Add-button">
          <Col>
            <Typography className="header-tag">
              {<IntlMessages id={"Addnew.BMI"} />}
            </Typography>
            {/* <Text disabled className="mt-30">
            Readings can be updated for last 7 days
          </Text> */}
          </Col>
          <Col>
            <ButtonComponent
              className="buttons"
              icon={<PlusCircleOutlined />}
              type="primary"
              label={<IntlMessages id={"back"} />}
              onClick={goBackHandler}
            />
          </Col>
        </Row>
        <Form
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          initialValues={{
            remember: true,
          }}
          colon={"true"}
          onFinish={onFinish}
          autoComplete="off"
          form={form}
          onFinishFailed={onFinishFailed}
        >
          <Row>
            {" "}
            <Col xl={24} style={{ display: "flex", justifyContent: "center" }}>
              <ReactSpeedometer
                width={400}
                needleHeightRatio={0.7}
                value={
                  bmi < 50
                    ? bmi
                    : bmi >= 30 && bmi <= 50
                    ? 35
                    : bmi >= 51 && bmi <= 100
                    ? 40
                    : bmi >= 101 && bmi <= 150
                    ? 50
                    : 58
                }
                valueFormat={"d"}
                textColor={"black"}
                maxValue={60}
                customSegmentStops={[0, 18, 25, 30, 60]}
                segmentColors={["#9399ff", "#14ffec", "#00bbf0", "pink"]}
                currentValueText={
                  bmi >= 1 && bmi <= 18.5
                    ? `under Weight: ${bmi}`
                    : bmi >= 18.5 && bmi <= 24.9
                    ? `Normal: ${bmi}`
                    : bmi >= 25 && bmi <= 29.9
                    ? `Over Weight: ${bmi}`
                    : bmi > 30
                    ? `obese: ${bmi}`
                    : bmi === 0
                    ? "BMI"
                    : ""
                }
                customSegmentLabels={[
                  {
                    text: ">18.5",
                    position: "OUTSIDE",
                    color: "#283747",
                    fontSize: "10px",
                  },
                  {
                    text: "18.5-24.9",
                    position: "OUTSIDE",
                    color: "#283747",
                    fontSize: "10px",
                  },
                  {
                    text: "25-29.9",
                    position: "OUTSIDE",
                    color: "#283747",
                    fontSize: "10px",
                  },
                  {
                    text: ">30",
                    position: "OUTSIDE",
                    color: "#283747",
                    fontSize: "10px",
                  },
                ]}
                ringWidth={47}
                needleTransitionDuration={3333}
                needleTransition="easeElastic"
                needleColor={"#a7ff83"}
                // textColor={"#d8dee9"}
              />
            </Col>
            <Col xl={23} lg={23} className="ml-20">
              <Row gutter={[16, 16]}>
                <Col xl={12}>
                  <Row>
                    <Col xl={24}>
                      <Form.Item
                        name="weight"
                        label={
                          <IntlMessages id={"Addnew.health.label.weight"} />
                        }
                        labelCol={{ span: "24" }}
                        wrapperCol={{ span: "24" }}
                        rules={[
                          {
                            required: true,
                            message: (
                              <IntlMessages
                                id={"Addnew.health.validator.weight"}
                              />
                            ),
                          },
                        ]}
                      >
                        <InputNumber
                          type="number"
                          size="large"
                          min={1}
                          max={200}
                          defaultValue={3}
                          addonAfter={
                            <Text style={{ color: "#038FDE" }} strong>
                              KG
                            </Text>
                          }
                          // min={1}
                          // max={200}
                          value={weightValue}
                          onChange={onChangeWeight}
                          style={{
                            width: "70%",
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xl={20}>
                      <Slider
                        min={1}
                        max={200}
                        marks={marks}
                        onChange={onChangeWeight}
                        value={
                          typeof weightValue === "number" ? weightValue : 0
                        }
                      />
                    </Col>
                  </Row>
                </Col>
                <Col xl={12}>
                  <Row>
                    {" "}
                    <Col xl={24}>
                      <Form.Item
                        name="height"
                        label={
                          <IntlMessages id={"Addnew.health.label.height"} />
                        }
                        labelCol={{ span: "24" }}
                        wrapperCol={{ span: "24" }}
                        rules={[
                          {
                            required: true,
                            message: (
                              <IntlMessages
                                id={"Addnew.health.validator.height"}
                              />
                            ),
                          },
                        ]}
                      >
                        <InputNumber
                          type="number"
                          size="large"
                          min={1}
                          max={200}
                          defaultValue={3}
                          addonAfter={
                            <Select
                              value={heightUnit}
                              onChange={changeHeightUnit}
                              defaultValue={"cm"}
                            >
                              <Option value="cm">
                                <Text style={{ color: "#038FDE" }} strong>
                                  cm
                                </Text>
                              </Option>
                              <Option value="feet">
                                <Text style={{ color: "#038FDE" }} strong>
                                  feet
                                </Text>
                              </Option>
                            </Select>
                          }
                          value={heightValue}
                          onChange={onChangeHeight}
                          style={{
                            width: "70%",
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xl={20}>
                      {heightUnit === "cm" ? (
                        <Slider
                          min={1}
                          max={200}
                          marks={marksHeight}
                          onChange={onChangeHeight}
                          value={
                            typeof heightValue === "number" ? heightValue : 0
                          }
                          step={0.1}
                        />
                      ) : (
                        <Slider
                          min={1}
                          max={10}
                          marks={marksFeet}
                          onChange={onChangeHeight}
                          value={
                            typeof heightValue === "number" ? heightValue : 0
                          }
                          step={0.1}
                        />
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="mt-20">
            <Col
              xl={23}
              align="right"
              className="button-alignment-pharmacy cancel-btn mr-10 mt-20"
            >
              <Link
                to={{
                  pathname: "/health-management",
                }}
              >
                <ButtonComponent
                  type="danger"
                  label="Cancel"
                  className="btn-cancel secondary-color"
                />
              </Link>
              <ButtonComponent
                icon={<CreditCardOutlined />}
                label={"Check BMI"}
                htmlType="submit"
                type="primary"
              />
            </Col>
          </Row>
        </Form>
      </Spin>
    </Card>
  );
};

export default BMIScreen;
