import {
  REQUEST_SCHEDULE_IN_PROGRESS,
  ADD_SCHEDULE_FAILURE,
  ADD_SCHEDULE_IN_PROGRESS,
  DELETE_SCHEDULE_FAILURE,
  DELETE_SCHEDULE_IN_PROGRESS,
  DELETE_SCHEDULE_SUCCESS,
  GETDATA_SCHEDULE_FAILURE,
  GETDATA_SCHEDULE_IN_PROGRESS,
  GETDATA_SCHEDULE_SUCCESS,
  REQUEST_SCHEDULE_FAILURE,
  REQUEST_SCHEDULE_SUCCESS,
  UPDATE_SCHEDULE_FAILURE,
  UPDATE_SCHEDULE_IN_PROGRESS,
  UPDATE_SCHEDULE_SUCCESS,
  WEEKDAY_SCHEDULE_FAILURE,
  WEEKDAY_SCHEDULE_SUCCESS,
  WEEKDAY_SCHEDULE_IN_PROGRESS,
  ADD_SCHEDULE_SUCCESS,
} from "../../../constants/ActionTypes";

const defaultStatus = {
  inProgress: false,
  success: false,
  error: false,
  data: null,
};
const initialState = {
  schedule: { ...defaultStatus },
  addSchedule: { ...defaultStatus },
};

const ScheduleReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case REQUEST_SCHEDULE_IN_PROGRESS:
      return {
        ...state,
        schedule: { ...defaultStatus, ...payload, inProgress: true },
        addSchedule: { ...defaultStatus, ...payload, success: false },
        updateSchedule: { ...defaultStatus, ...payload, success: false },
      };
    case REQUEST_SCHEDULE_SUCCESS:
      return {
        ...state,
        schedule: { ...defaultStatus, ...payload, success: true },
      };
    case REQUEST_SCHEDULE_FAILURE:
      return {
        ...state,
        schedule: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case ADD_SCHEDULE_IN_PROGRESS:
      return {
        ...state,
        addSchedule: { ...defaultStatus, ...payload, inProgress: true },
      };
    case ADD_SCHEDULE_SUCCESS:
      return {
        ...state,
        addSchedule: { ...defaultStatus, ...payload, success: true },
      };
    case ADD_SCHEDULE_FAILURE:
      return {
        ...state,
        addSchedule: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case DELETE_SCHEDULE_IN_PROGRESS:
      return {
        ...state,
        deleteSchedule: { ...defaultStatus, ...payload, inProgress: true },
      };
    case DELETE_SCHEDULE_SUCCESS:
      return {
        ...state,
        deleteSchedule: { ...defaultStatus, ...payload, success: true },
      };
    case DELETE_SCHEDULE_FAILURE:
      return {
        ...state,
        deleteSchedule: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case UPDATE_SCHEDULE_IN_PROGRESS:
      return {
        ...state,
        updateSchedule: { ...defaultStatus, ...payload, inProgress: true },
      };
    case UPDATE_SCHEDULE_SUCCESS:
      return {
        ...state,
        updateSchedule: { ...defaultStatus, ...payload, success: true },
      };
    case UPDATE_SCHEDULE_FAILURE:
      return {
        ...state,
        updateSchedule: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case GETDATA_SCHEDULE_IN_PROGRESS:
      return {
        ...state,
        getDataSchedule: { ...defaultStatus, ...payload, inProgress: true },
      };
    case GETDATA_SCHEDULE_SUCCESS:
      return {
        ...state,
        getDataSchedule: { ...defaultStatus, ...payload, success: true },
      };
    case GETDATA_SCHEDULE_FAILURE:
      return {
        ...state,
        getDataSchedule: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };

    case WEEKDAY_SCHEDULE_IN_PROGRESS:
      return {
        ...state,
        weekDaySchedule: { ...defaultStatus, inProgress: true },
      };
    case WEEKDAY_SCHEDULE_SUCCESS:
      return {
        ...state,
        weekDaySchedule: { ...defaultStatus, ...payload, success: true },
      };
    case WEEKDAY_SCHEDULE_FAILURE:
      return {
        ...state,
        weekDaySchedule: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    default:
      return state;
  }
};

export default ScheduleReducer;
