import {
  ADD_BLOCK_ERROR,
  ADD_BLOCK_IN_PROGRESS,
  ADD_BLOCK_SUCCESS,
  ADD_ROOM_ERROR,
  ADD_ROOM_IN_PROGRESS,
  ADD_ROOM_SUCCESS,
  ADD_WARD_ERROR,
  ADD_WARD_IN_PROGRESS,
  ADD_WARD_LIST_ERROR,
  ADD_WARD_SUCCESS,
  ALL_DOCTOR_LIST_ERROR,
  ALL_DOCTOR_LIST_IN_PROGRESS,
  ALL_DOCTOR_LIST_SUCCESS,
  DEFAULT_BLOCK,
  DEFAULT_ROOM,
  DEFAULT_ROOM_BOOKING,
  DEFAULT_SHIFT_ROOM,
  DEFAULT_WARD,
  DELETE_BLOCK_ERROR,
  DELETE_BLOCK_IN_PROGRESS,
  DELETE_BLOCK_SUCCESS,
  DELETE_ROOM_ERROR,
  DELETE_ROOM_IN_PROGRESS,
  DELETE_ROOM_SUCCESS,
  DELETE_WARD_ERROR,
  DELETE_WARD_IN_PROGRESS,
  DELETE_WARD_SUCCESS,
  EDIT_BLOCK_ERROR,
  EDIT_BLOCK_IN_PROGRESS,
  EDIT_BLOCK_SUCCESS,
  EDIT_ROOM_ERROR,
  EDIT_ROOM_IN_PROGRESS,
  EDIT_ROOM_SUCCESS,
  EDIT_WARD_ERROR,
  EDIT_WARD_IN_PROGRESS,
  EDIT_WARD_SUCCESS,
  GET_AVAILABLE_ROOM_LIST_IN_FAILURE,
  GET_AVAILABLE_ROOM_LIST_IN_PROGRESS,
  GET_AVAILABLE_ROOM_LIST_IN_SUCCESS,
  GET_BLOCK_LIST_ERROR,
  GET_BLOCK_LIST_IN_PROGRESS,
  GET_BLOCK_LIST_SUCCESS,
  GET_BOOKED_ROOM_LIST_IN_FAILURE,
  GET_BOOKED_ROOM_LIST_IN_PROGRESS,
  GET_BOOKED_ROOM_LIST_IN_SUCCESS,
  GET_ROOM_LIST_ERROR,
  GET_ROOM_LIST_IN_PROGRESS,
  GET_ROOM_LIST_SUCCESS,
  GET_TOTAL_ROOM_LIST_IN_FAILURE,
  GET_TOTAL_ROOM_LIST_IN_PROGRESS,
  GET_TOTAL_ROOM_LIST_IN_SUCCESS,
  GET_WARD_LIST_BY_BLOCK_ERROR,
  GET_WARD_LIST_BY_BLOCK_IN_PROGRESS,
  GET_WARD_LIST_BY_BLOCK_SUCCESS,
  GET_WARD_LIST_ERROR,
  GET_WARD_LIST_IN_PROGRESS,
  GET_WARD_LIST_SUCCESS,
  ROOM_BOOKING_IN_FAILURE,
  ROOM_BOOKING_IN_PROGRESS,
  ROOM_BOOKING_IN_SUCCESS,
  ROOM_DISCHARGE_IN_FAILURE,
  ROOM_DISCHARGE_IN_PROGRESS,
  ROOM_DISCHARGE_IN_SUCCESS,
  ROOM_SHIFT_IN_FAILURE,
  ROOM_SHIFT_IN_PROGRESS,
  ROOM_SHIFT_IN_SUCCESS,
  WARD_DASHBOARD_COUNT_IN_FAILURE,
  WARD_DASHBOARD_COUNT_IN_PROGRESS,
  WARD_DASHBOARD_COUNT_IN_SUCCESS,
} from "../../constants/ActionTypes";

const defaultStatus = {
  inProgress: false,
  success: false,
  error: false,
  data: null,
};
const initialState = {
  blockList: { ...defaultStatus },
  addBlock: { ...defaultStatus },
  editBlock: { ...defaultStatus },
  deleteBlock: { ...defaultStatus },
  wardList: { ...defaultStatus },
  addWard: { ...defaultStatus },
  editWard: { ...defaultStatus },
  deleteWard: { ...defaultStatus },
  wardListByBlock: { ...defaultStatus },
  roomList: { ...defaultStatus },
  addRoom: { ...defaultStatus },
  editRoom: { ...defaultStatus },
  deleteRoom: { ...defaultStatus },
  allDoctorList: { ...defaultStatus },
  availableRoomsList: { ...defaultStatus },
  roomBook: { ...defaultStatus },
  wardCount: { ...defaultStatus },
  bookedRoomsList: { ...defaultStatus },
  totalRoomsList: { ...defaultStatus },
  roomShift: { ...defaultStatus },
  roomDischarge: { ...defaultStatus },
};
const WardReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_BLOCK_LIST_IN_PROGRESS:
      console.log("reducrr");
      return {
        ...state,
        blockList: { ...defaultStatus, ...payload, inProgress: true },
      };
    case GET_BLOCK_LIST_SUCCESS:
      return {
        ...state,
        blockList: { ...defaultStatus, ...payload, success: true },
      };
    case GET_BLOCK_LIST_ERROR:
      return {
        ...state,
        blockList: { ...defaultStatus, ...payload, error: true },
      };
    case ADD_BLOCK_IN_PROGRESS:
      console.log("reducrr");
      return {
        ...state,
        addBlock: { ...defaultStatus, ...payload, inProgress: true },
      };
    case ADD_BLOCK_SUCCESS:
      return {
        ...state,
        addBlock: { ...defaultStatus, ...payload, success: true },
      };
    case ADD_BLOCK_ERROR:
      return {
        ...state,
        addBlock: { ...defaultStatus, ...payload, error: true },
      };
    case EDIT_BLOCK_IN_PROGRESS:
      return {
        ...state,
        editBlock: { ...defaultStatus, ...payload, inProgress: true },
      };
    case EDIT_BLOCK_SUCCESS:
      return {
        ...state,
        editBlock: { ...defaultStatus, ...payload, success: true },
      };
    case EDIT_BLOCK_ERROR:
      return {
        ...state,
        editBlock: { ...defaultStatus, ...payload, error: true },
      };
    case DEFAULT_BLOCK:
      return {
        ...state,
        addBlock: { ...defaultStatus, ...payload, success: false },
        editBlock: { ...defaultStatus, ...payload, success: false },
      };
    case DELETE_BLOCK_IN_PROGRESS:
      return {
        ...state,
        deleteBlock: { ...defaultStatus, ...payload, inProgress: true },
      };
    case DELETE_BLOCK_SUCCESS:
      return {
        ...state,
        deleteBlock: { ...defaultStatus, ...payload, success: true },
      };
    case DELETE_BLOCK_ERROR:
      return {
        ...state,
        deleteBlock: { ...defaultStatus, ...payload, error: true },
      };

    case GET_WARD_LIST_IN_PROGRESS:
      console.log("reducrr");
      return {
        ...state,
        wardList: { ...defaultStatus, ...payload, inProgress: true },
      };
    case GET_WARD_LIST_SUCCESS:
      return {
        ...state,
        wardList: { ...defaultStatus, ...payload, success: true },
      };
    case GET_WARD_LIST_ERROR:
      return {
        ...state,
        blockList: { ...defaultStatus, ...payload, error: true },
      };
    case ADD_WARD_IN_PROGRESS:
      console.log("reducrr");
      return {
        ...state,
        addWard: { ...defaultStatus, ...payload, inProgress: true },
      };
    case ADD_WARD_SUCCESS:
      return {
        ...state,
        addWard: { ...defaultStatus, ...payload, success: true },
      };
    case ADD_WARD_ERROR:
      return {
        ...state,
        addWard: { ...defaultStatus, ...payload, error: true },
      };
    case EDIT_WARD_IN_PROGRESS:
      return {
        ...state,
        editWard: { ...defaultStatus, ...payload, inProgress: true },
      };
    case EDIT_WARD_SUCCESS:
      return {
        ...state,
        editWard: { ...defaultStatus, ...payload, success: true },
      };
    case EDIT_WARD_ERROR:
      return {
        ...state,
        editWard: { ...defaultStatus, ...payload, error: true },
      };
    case DEFAULT_WARD:
      return {
        ...state,
        addWard: { ...defaultStatus, ...payload, inProgress: true },
        editWard: { ...defaultStatus, ...payload, inProgress: true },
      };
    case DELETE_WARD_IN_PROGRESS:
      return {
        ...state,
        deleteWard: { ...defaultStatus, ...payload, inProgress: true },
      };
    case DELETE_WARD_SUCCESS:
      return {
        ...state,
        deleteWard: { ...defaultStatus, ...payload, success: true },
      };
    case DELETE_WARD_ERROR:
      return {
        ...state,
        deleteWard: { ...defaultStatus, ...payload, error: true },
      };
    case GET_WARD_LIST_BY_BLOCK_IN_PROGRESS:
      return {
        ...state,
        wardListByBlock: { ...defaultStatus, ...payload, inProgress: true },
      };
    case GET_WARD_LIST_BY_BLOCK_SUCCESS:
      return {
        ...state,
        wardListByBlock: { ...defaultStatus, ...payload, success: true },
      };
    case GET_WARD_LIST_BY_BLOCK_ERROR:
      return {
        ...state,
        wardListByBlock: { ...defaultStatus, ...payload, error: true },
      };

    case GET_ROOM_LIST_IN_PROGRESS:
      console.log("reducrr");
      return {
        ...state,
        roomList: { ...defaultStatus, ...payload, inProgress: true },
      };
    case GET_ROOM_LIST_SUCCESS:
      return {
        ...state,
        roomList: { ...defaultStatus, ...payload, success: true },
      };
    case GET_ROOM_LIST_ERROR:
      return {
        ...state,
        roomList: { ...defaultStatus, ...payload, error: true },
      };
    case ADD_ROOM_IN_PROGRESS:
      console.log("reducrr");
      return {
        ...state,
        addRoom: { ...defaultStatus, ...payload, inProgress: true },
      };
    case ADD_ROOM_SUCCESS:
      return {
        ...state,
        addRoom: { ...defaultStatus, ...payload, success: true },
      };
    case ADD_ROOM_ERROR:
      return {
        ...state,
        addRoom: { ...defaultStatus, ...payload, error: true },
      };
    case EDIT_ROOM_IN_PROGRESS:
      return {
        ...state,
        editRoom: { ...defaultStatus, ...payload, inProgress: true },
      };
    case EDIT_ROOM_SUCCESS:
      return {
        ...state,
        editRoom: { ...defaultStatus, ...payload, success: true },
      };
    case EDIT_ROOM_ERROR:
      return {
        ...state,
        editRoom: { ...defaultStatus, ...payload, error: true },
      };
    case DEFAULT_ROOM:
      return {
        ...state,
        addRoom: { ...defaultStatus, ...payload, inProgress: true },
        editRoom: { ...defaultStatus, ...payload, inProgress: true },
      };
    case DELETE_ROOM_IN_PROGRESS:
      return {
        ...state,
        deleteRoom: { ...defaultStatus, ...payload, inProgress: true },
      };
    case DELETE_ROOM_SUCCESS:
      return {
        ...state,
        deleteRoom: { ...defaultStatus, ...payload, success: true },
      };
    case DELETE_ROOM_ERROR:
      return {
        ...state,
        deleteRoom: { ...defaultStatus, ...payload, error: true },
      };
    case ALL_DOCTOR_LIST_IN_PROGRESS:
      return {
        ...state,
        allDoctorList: { ...defaultStatus, ...payload, inProgress: true },
      };
    case ALL_DOCTOR_LIST_SUCCESS:
      return {
        ...state,
        allDoctorList: { ...defaultStatus, ...payload, success: true },
      };
    case ALL_DOCTOR_LIST_ERROR:
      return {
        ...state,
        allDoctorList: { ...defaultStatus, ...payload, error: true },
      };
    case GET_AVAILABLE_ROOM_LIST_IN_PROGRESS:
      return {
        ...state,
        availableRoomsList: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case GET_AVAILABLE_ROOM_LIST_IN_SUCCESS:
      console.log("reducer", payload);
      return {
        ...state,
        availableRoomsList: { ...defaultStatus, ...payload, success: true },
      };
    case GET_AVAILABLE_ROOM_LIST_IN_FAILURE:
      return {
        ...state,
        availableRoomsList: { ...defaultStatus, ...payload, error: true },
      };
    case DEFAULT_ROOM_BOOKING:
      return {
        ...state,
        roomBook: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case ROOM_BOOKING_IN_PROGRESS:
      return {
        ...state,
        roomBook: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case ROOM_BOOKING_IN_SUCCESS:
      return {
        ...state,
        roomBook: { ...defaultStatus, ...payload, success: true },
      };
    case ROOM_BOOKING_IN_FAILURE:
      return {
        ...state,
        roomBook: { ...defaultStatus, ...payload, error: true },
      };
    case WARD_DASHBOARD_COUNT_IN_PROGRESS:
      return {
        ...state,
        wardCount: { ...defaultStatus, ...payload, inProgress: true },
      };
    case WARD_DASHBOARD_COUNT_IN_SUCCESS:
      return {
        ...state,
        wardCount: { ...defaultStatus, ...payload, success: true },
      };
    case WARD_DASHBOARD_COUNT_IN_FAILURE:
      return {
        ...state,
        wardCount: { ...defaultStatus, ...payload, error: true },
      };
    case GET_TOTAL_ROOM_LIST_IN_PROGRESS:
      return {
        ...state,
        totalRoomsList: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case GET_TOTAL_ROOM_LIST_IN_SUCCESS:
      return {
        ...state,
        totalRoomsList: { ...defaultStatus, ...payload, success: true },
      };
    case GET_TOTAL_ROOM_LIST_IN_FAILURE:
      return {
        ...state,
        totalRoomsList: { ...defaultStatus, ...payload, error: true },
      };
    case GET_BOOKED_ROOM_LIST_IN_PROGRESS:
      return {
        ...state,
        bookedRoomsList: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case GET_BOOKED_ROOM_LIST_IN_SUCCESS:
      return {
        ...state,
        bookedRoomsList: { ...defaultStatus, ...payload, success: true },
      };
    case GET_BOOKED_ROOM_LIST_IN_FAILURE:
      return {
        ...state,
        bookedRoomsList: { ...defaultStatus, ...payload, error: true },
      };
    case DEFAULT_SHIFT_ROOM:
      return {
        ...state,
        roomShift: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case ROOM_SHIFT_IN_PROGRESS:
      return {
        ...state,
        roomShift: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case ROOM_SHIFT_IN_SUCCESS:
      return {
        ...state,
        roomShift: { ...defaultStatus, ...payload, success: true },
      };
    case ROOM_SHIFT_IN_FAILURE:
      return {
        ...state,
        roomShift: { ...defaultStatus, ...payload, error: true },
      };
    case ROOM_DISCHARGE_IN_PROGRESS:
      return {
        ...state,
        roomDischarge: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case ROOM_DISCHARGE_IN_SUCCESS:
      return {
        ...state,
        roomDischarge: { ...defaultStatus, ...payload, success: true },
      };
    case ROOM_DISCHARGE_IN_FAILURE:
      return {
        ...state,
        roomDischarge: { ...defaultStatus, ...payload, error: true },
      };
    default:
      return state;
  }
};
export default WardReducer;
