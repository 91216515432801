import React, { useEffect } from "react";
import "../../styles/modules/admin.less";
import { Card, Col, Progress, Row, Spin } from "antd";
import IconWithTextCard from "../../components/card/IconWithTextCard";
import doctor from "../../assets/images/DocMotez/doctorslists.png";
import patient from "../../assets/images/DocMotez/patient_logo.png";
import billing from "../../assets/images/DocMotez/myappointments.png";
import todayBilling from "../../assets/images/DocMotez/schedule.png";
import {
  MedicineBoxOutlined,
  UnorderedListOutlined,
  IdcardOutlined,
  BookOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import TicketList from "../../components/card/TicketList";
import BillingList from "../Admin/BillingLIst";
import { useDispatch, useSelector } from "react-redux";
import { getDoctorDashBoardProgress } from "../../appRedux/doctor/actions/DashBoard";
import DashboardCard from "../../components/card/DashboardCard";
import DemoArea from "./DemoArea";

const DoctorDashboardScreen = () => {
  const dispatch = useDispatch();
  const getToken = localStorage.getItem("user_id");
  const { doctorDashBoard } = useSelector((state) => state?.dashBoard);

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );
  useEffect(() => {
    if (getToken) {
      dispatch(getDoctorDashBoardProgress());
    }
  }, [getToken]);

  return (
    <Card>
      {" "}
      <Spin indicator={antIcon} spinning={doctorDashBoard?.progressing}>
        <Row gutter={[16, 16]}>
          <Col xl={6} lg={6} md={6} sm={12} xs={24}>
            <DashboardCard
              image={patient}
              color="#47B3FF" // Purple for the icon and progress bar
              title={"Over All Patients"}
              value={
                doctorDashBoard?.data?.count?.patientCount.toString() || "0"
              }
            />
          </Col>
          <Col xl={6} lg={6} md={6} sm={12} xs={24}>
            <DashboardCard
              image={doctor}
              color="#47B3FF" // Purple for the icon and progress bar
              title={"Over All Doctors"}
              value={
                doctorDashBoard?.data?.count?.doctorCount.toString() || "0"
              }
            />
          </Col>
          <Col xl={6} lg={6} md={6} sm={12} xs={24}>
            <DashboardCard
              image={billing}
              color="#47B3FF" // Purple for the icon and progress bar
              title={"Over All Billing"}
              value={
                doctorDashBoard?.data?.count?.billingcount.toString() || "0"
              }
            />
          </Col>
          <Col xl={6} lg={6} md={6} sm={12} xs={24}>
            <DashboardCard
              image={todayBilling}
              color="#47B3FF" // Purple for the icon and progress bar
              title={"Today Billing"}
              value={
                doctorDashBoard?.data?.count?.todayBillingCount?.toString() ||
                "0"
              }
            />
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: "20px", marginBottom: "20px" }}>
          <Col span={16}>
            <Card
              title="Graph Representation"
              style={{ height: "100%" }}
              headStyle={{ textAlign: "center", backgroundColor: "#E0F7FA" }}
            >
              <DemoArea />
            </Card>
          </Col>
          <Col span={8}>
            <Card
              title="Patient Summary"
              style={{ height: "100%" }}
              headStyle={{
                textAlign: "center",
                backgroundColor: "#F3E5F5",
                boxShadow:
                  "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px",
              }}
            >
              <div style={{ textAlign: "center", padding: "20px" }}>
                <Row align="middle" justify="center" gutter={[16, 16]}>
                  <Col>
                    <Progress
                      type="circle"
                      percent={65}
                      strokeColor={{
                        "0%": "#1D3557",
                        "100%": "#F72585",
                      }}
                      strokeWidth={12}
                      trailColor="#F0F0F0"
                      width={100}
                    />
                  </Col>
                  <Col>
                    <div style={{ textAlign: "left" }}>
                      <div>
                        <strong>Total Patient</strong>: 1000
                      </div>
                      <div style={{ marginTop: "10px" }}>
                        <span style={{ color: "#F72585" }}>●</span> Woman: 350
                      </div>
                      <div>
                        <span style={{ color: "#1D3557" }}>●</span> Man: 650
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xl={8} lg={12} md={12} sm={12} xs={24}>
            <TicketList
              recentAppointments={
                doctorDashBoard?.data?.count?.recentAppointments || []
              }
            />
          </Col>
          <Col xl={8} lg={12} md={12} sm={12} xs={24}>
            <BillingList
              recentAppointments={
                doctorDashBoard?.data?.count?.recentBillings || []
              }
            />
          </Col>
          <Col xl={8} lg={12} md={12} sm={12} xs={24}>
            <TicketList
              recentAppointments={
                doctorDashBoard?.data?.count?.recentAppointments || []
              }
            />
            {/* <DoctorList
              recentAppointments={adminDashboardDetails?.recentAppointments}
            /> */}
          </Col>
        </Row>
      </Spin>
    </Card>
  );
};

export default DoctorDashboardScreen;
