import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import AddStockAdjustment from "./AddStockAdjustment";

const EditStockAdjustment = () => {
  const location = useLocation();
  const stockAdjustmentData = location.state?.stockAdjustment;
  const [stockAdjustmentArray, setStockAdjustmentArray] = useState([]);
  useEffect(() => {
    if (stockAdjustmentData) {
      setStockAdjustmentArray([stockAdjustmentData]);
    }
  }, [stockAdjustmentData]);
  return (
    <AddStockAdjustment
      stockAdjustmentData={stockAdjustmentArray}
      editStockAdjustment={true}
    />
  );
};

export default EditStockAdjustment;
