import { Col, Divider, Row } from "antd";
import React, { useEffect, useRef, useState } from "react";
import IntlMessages from "../../../util/IntlMessages";

const ConsultationHeader = () => {
  return (
    <div>
      <Row className="p-10 Add-button">
        <Col>
          <p className="header-tag">
            {<IntlMessages id={"consultation.header"} />}
          </p>
        </Col>
      </Row>

      <Divider />
    </div>
  );
};

export default ConsultationHeader;
