import { Card, Progress } from "antd";
import React from "react";

const DashboardCard = ({ icon, color, title, value, image }) => {
  // Set a fixed progress percentage as an example (can be dynamically set)
  const progressPercentage = Math.min(
    parseInt(value.replace(/[^\d]/g, "")) % 100,
    100
  );

  return (
    <Card className="card">
      <div className="card-header">
        {image ? (
          <div>
            <img src={image} style={{ width: 50, height: 50 }} alt={""} />
          </div>
        ) : (
          <div className="icon-s" style={{ color: color }}>
            {icon}
          </div>
        )}

        <div className="content">
          <h4>{title}</h4>
          <h3>{value}</h3>
        </div>
      </div>
      <div className="progress-container">
        <Progress
          percent={progressPercentage}
          strokeColor={color} // The color for the progress bar matches the icon color
          strokeWidth={6}
          showInfo={false} // Hide the percentage text
          status="active"
          steps={12}
          //   size="small"
        />
      </div>
    </Card>
  );
};

export default DashboardCard;
