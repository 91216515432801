import React, { useState } from "react";

const SearchBox = ({ styleName, itemList, setFilteredUsers, field }) => {
  const [searchValue, setSearchValue] = useState("");

  const handleSearch = (e) => {
    const searchData = e.target.value.toLowerCase();
    setSearchValue(searchData);

    if (searchData === "") {
      setFilteredUsers(itemList); // Reset to original list if search is cleared
    } else {
      const filteredResults =
        itemList &&
        itemList.filter((entry) =>
          entry[field].toLowerCase().includes(searchData)
        );

      setFilteredUsers(filteredResults); // Update with filtered results
    }
  };

  return (
    <div className={`gx-search-bar ${styleName}`}>
      <div className="gx-form-group">
        <input
          className="ant-input"
          type="search"
          placeholder="Search"
          value={searchValue}
          onChange={handleSearch} // Updated to use handleSearch directly
        />
        <span className="gx-search-icon gx-pointer">
          <i className="icon icon-search" />
        </span>
      </div>
    </div>
  );
};

export default SearchBox;
