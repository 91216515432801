import React from "react";
import { Col, Row, Divider } from "antd";

import { PlusCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import IntlMessages from "../../../util/IntlMessages";
import { APP_ROUTES } from "../../../constants/Routes";
import ButtonComponent from "../../../components/Button/ButtonComponent";

const PackageHeader = () => {
  return (
    <div>
      <Row className="p-10 Add-button">
        <Col>
          <p className="header-tag">{<IntlMessages id={"Addnew.package"} />}</p>
        </Col>{" "}
        <Col>
          <Link to={APP_ROUTES.ADDPACKAGE}>
            <ButtonComponent
              className="buttons"
              icon={<PlusCircleOutlined />}
              type="primary"
              label={<IntlMessages id={"Addnew.newPackage"} />}
            />
          </Link>
        </Col>
      </Row>

      <Divider />
    </div>
  );
};

export default PackageHeader;
