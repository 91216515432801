import {
  REQUEST_ORGANISATION_IN_PROGRESS,
  ADD_ORGANISATION_FAILURE,
  ADD_ORGANISATION_IN_PROGRESS,
  DELETE_ORGANISATION_FAILURE,
  DELETE_ORGANISATION_IN_PROGRESS,
  DELETE_ORGANISATION_SUCCESS,
  REQUEST_ORGANISATION_FAILURE,
  REQUEST_ORGANISATION_SUCCESS,
  UPDATE_ORGANISATION_FAILURE,
  UPDATE_ORGANISATION_IN_PROGRESS,
  UPDATE_ORGANISATION_SUCCESS,
  ADD_ORGANISATION_SUCCESS,
  GET_ORGANISATION_NAME_IN_PROGRESS,
  GET_ORGANISATION_NAME__SUCCESS,
  GET_ORGANISATION_NAME__FAILURE,
  UPDATE_PAYMENT_MODE_IN_PROGRESS,
  UPDATE_PAYMENT_MODE_SUCCESS,
  UPDATE_PAYMENT_MODE_FAILURE,
} from "../../../constants/ActionTypes";

const defaultStatus = {
  inProgress: false,
  success: false,
  error: false,
  data: null,
};
const initialState = {
  org: { ...defaultStatus },
  addorg: { ...defaultStatus },
  getOrgName: { ...defaultStatus },
  updatePaymentMode:{...defaultStatus}
};

const OrganisationReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case REQUEST_ORGANISATION_IN_PROGRESS:
      return {
        ...state,
        org: { ...defaultStatus, inProgress: true, ...payload },
        updateorg: { ...defaultStatus },
        addorg: { ...defaultStatus },
      };
    case REQUEST_ORGANISATION_SUCCESS:
      return {
        ...state,
        org: { ...defaultStatus, ...payload, success: true },
      };
    case REQUEST_ORGANISATION_FAILURE:
      return {
        ...state,
        org: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case ADD_ORGANISATION_IN_PROGRESS:
      return {
        ...state,
        addorg: { ...defaultStatus, ...payload, inProgress: true },
      };
    case ADD_ORGANISATION_SUCCESS:
      return {
        ...state,
        addorg: { ...defaultStatus, ...payload, success: true },
      };
    case ADD_ORGANISATION_FAILURE:
      return {
        ...state,
        addorg: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case DELETE_ORGANISATION_IN_PROGRESS:
      return {
        ...state,
        deleteorg: { ...defaultStatus, ...payload, inProgress: true },
      };
    case DELETE_ORGANISATION_SUCCESS:
      return {
        ...state,
        deleteorg: { ...defaultStatus, ...payload, success: true },
      };
    case DELETE_ORGANISATION_FAILURE:
      return {
        ...state,
        deleteorg: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case UPDATE_ORGANISATION_IN_PROGRESS:
      return {
        ...state,
        updateorg: { ...defaultStatus, ...payload, inProgress: true },
      };
    case UPDATE_ORGANISATION_SUCCESS:
      return {
        ...state,
        updateorg: { ...defaultStatus, ...payload, success: true },
      };
    case UPDATE_ORGANISATION_FAILURE:
      return {
        ...state,
        updateorg: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case GET_ORGANISATION_NAME_IN_PROGRESS:
      return {
        ...state,
        getOrgName: { ...defaultStatus, ...payload, inProgress: true },
      };
    case GET_ORGANISATION_NAME__SUCCESS:
      return {
        ...state,
        getOrgName: { ...defaultStatus, ...payload, success: true },
      };
    case GET_ORGANISATION_NAME__FAILURE:
      return {
        ...state,
        getOrgName: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
      case UPDATE_PAYMENT_MODE_IN_PROGRESS:
        return {
          ...state,
          updatePaymentMode: { ...defaultStatus, inProgress: true, ...payload },
        
        };
      case UPDATE_PAYMENT_MODE_SUCCESS:
        return {
          ...state,
          updatePaymentMode: { ...defaultStatus, ...payload, success: true },
        };
      case UPDATE_PAYMENT_MODE_FAILURE:
        return {
          ...state,
          updatePaymentMode: {
            ...defaultStatus,
            ...payload,
            error: true,
          },
        };
     
    default:
      return state;
  }
};

export default OrganisationReducer;
