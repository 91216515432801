import { Descriptions, Divider } from "antd";
import React, { Fragment } from "react";

const Description = ({ label, data, title }) => {
  return (
    <Fragment>
      <Descriptions title={title}>
        <Descriptions.Item label={label}>{data}</Descriptions.Item>
      </Descriptions>
      <Divider />
    </Fragment>
  );
};
export default Description;
