import Form from "antd/lib/form/Form";
import React, { useState } from "react";
import FormSelectBox from "../../../components/SelectBox/SelectBox";
import IntlMessages from "../../../util/IntlMessages";

import {
  appoinment_type,
  Doctor_Name,
  Patient_Name,
} from "../../Appoinment/data";

const AppoinmentType = () => {
  const [appoinmentType, setAppoinmentType] = useState("");
  const [patient, setPatient] = useState("");
  const [doctor, setDoctor] = useState("");
  const changeAppoinment = (value) => {
    setPatient(value);
  };
  const changePatient = (value) => {
    setAppoinmentType(value);
  };
  const changeDoctor = (value) => {
    setDoctor(value);
  };

  return (
    <div className="appoinment-type-container">
      <Form.Item
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          span: 16,
        }}
        label={<IntlMessages id={"Addnew.appoinment.label.appoinmenttype"} />}
        name={"appoinmenttype"}
        rules={[
          {
            required: false,
            message: (
              <IntlMessages id={"Addnew.appoinment.validator.appoinmentype"} />
            ),
          },
        ]}
      >
        <FormSelectBox
          placeholder="Appoinment Type"
          value={appoinmentType}
          options={appoinment_type}
          onChange={changeAppoinment}
        />
      </Form.Item>
      <Form.Item
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          span: 16,
        }}
        label={<IntlMessages id={"Addnew.appoinment.label.patient"} />}
        name={"patient"}
        rules={[
          {
            required: false,
            message: (
              <IntlMessages id={"Addnew.appoinment.validator.patient"} />
            ),
          },
        ]}
      >
        <FormSelectBox
          placeholder="Search Patient Name/ID"
          value={patient}
          options={Patient_Name}
          onChange={changePatient}
          showSearch="true"
          className="appoinment-type-select"
        />
      </Form.Item>
      <Form.Item
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          span: 16,
        }}
        label={<IntlMessages id={"Addnew.appoinment.label.doctor"} />}
        name={"doctor"}
        rules={[
          {
            required: false,
            message: <IntlMessages id={"Addnew.appoinment.validator.doctor"} />,
          },
        ]}
      >
        <FormSelectBox
          placeholder="Search Doctor Name/ID"
          value={doctor}
          options={Doctor_Name}
          onChange={changeDoctor}
          showSearch="true"
        />
      </Form.Item>
    </div>
  );
};

export default AppoinmentType;
