import axios from "axios";
import {
  axiosInstance,
  getTokenfromLocalStorage,
} from "../../../../util/utilz";

export const getSubPackagesListEffect = ( type) => {
  const token = localStorage.getItem("user_id");
  const params = {
    pagination_required: false,
    ...(type?.organization_id &&{ organization_id : type?.organization_id}),
    ...(Number(type) && { type: type }),
  }
 
  return axiosInstance.request({
    url: `/subPackageCategory/getAllPackage`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    params:params
  });
};
export const AddBillingPackagesEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: "/package/insert",
    method: "POST",
    data: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const getMainPackagesListEffect = (payload) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/package/packageGetAll?pagination_required=true&organization_id=${payload?.organization_id}&pageNo=${payload?.pageNo}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const updateBillingPackagesEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: "/package/update",
    method: "PUT",
    data: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const deleteBillingPackagesEffect = (payload) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/package/delete/${payload?.id}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const AddSubpackagesEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: "/subPackageCategory/insert",
    method: "POST",
    data: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const updateSubpackagesEffect = (formData) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/subPackageCategory/update/${formData.id}`,
    method: "PUT",
    data: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const deleteSubpackagesEffect = (payload) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: `/subPackageCategory/delete/${payload?.id}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
