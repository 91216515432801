import React from "react";

import { Card } from "antd";
import PurchaseReturnHeader from "./PurchaseReturnHeader";
import PurchaseReturnTable from "./PurchaseReturnTable";

const PurchaseReturnScreen = () => {
  return (
    <Card>
      <PurchaseReturnHeader />
      <PurchaseReturnTable />
    </Card>
  );
};

export default PurchaseReturnScreen;
