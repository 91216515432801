import React from "react";

import { Card } from "antd";

import QualificationHeader from "./Components/QualificationHeader";
import QualificationTable from "./Components/QualificationTable";

const QualificationScreen = () => {
  return (
    <Card>
      <QualificationHeader />
      <QualificationTable />
    </Card>
  );
};

export default QualificationScreen;
