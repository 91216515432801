import React from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import AddMedicine from "./AddMedicine";

const EditMedicineList = () => {
  const location = useLocation();
  const medicineListData = location.state?.medicineList;

  return (
    <AddMedicine medicineListData={medicineListData} editMedicine="true" />
  );
};

export default EditMedicineList;
