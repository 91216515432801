import React from "react";
import { Card } from "antd";
import MedicineListHeader from "./MedicineListHeader";
import MedicineListTable from "./MedicineListTable";

const MedicineListScreen = () => {
  return (
    <Card>
      <MedicineListHeader />
      <MedicineListTable />
    </Card>
  );
};

export default MedicineListScreen;
