import React from "react";

import { Card } from "antd";
import TabletHeader from "./components/TabletHeader";
import TabletTable from "./components/TabletTable";

const TabletScreen = () => {
  return (
    <Card>
      <TabletHeader />
      <TabletTable />
    </Card>
  );
};

export default TabletScreen;
