import {
  GET_TABLET_LIST_IN_PROGRESS,
  GET_TABLET_ERROR,
  GET_TABLET_IN_PROGRESS,
  GET_TABLET_LIST_ERROR,
  GET_TABLET_LIST_SUCCESS,
  GET_TABLET_SUCCESS,
  DELETE_TABLET_ERROR,
  DELETE_TABLET_IN_PROGRESS,
  DELETE_TABLET_SUCCESS,
  UPDATE_TABLET_ERROR,
  UPDATE_TABLET_IN_PROGRESS,
  UPDATE_TABLET_SUCCESS,
  NEW_TABLET_IN_PROGRESS,
  NEW_TABLET_SUCCESS,
  NEW_TABLET_ERROR,
  TABLET_BY_ORG_IN_PROGRESS,
  TABLET_BY_ORG_SUCCESS,
  TABLET_BY_ORG_ERROR,
} from "../../../constants/ActionTypes";

export const getTabletsListInProgress = (payload) => ({
  type: GET_TABLET_LIST_IN_PROGRESS,
  payload: payload,
});

export const getTabletsListSuccess = (payload) => ({
  type: GET_TABLET_LIST_SUCCESS,
  payload: payload,
});

export const getTabletsListError = (payload) => ({
  type: GET_TABLET_LIST_ERROR,
  payload: payload,
});

export const updateTabletsInProgress = (payload) => ({
  type: UPDATE_TABLET_IN_PROGRESS,
  payload: payload,
});

export const updateTabletsSuccess = (payload) => ({
  type: UPDATE_TABLET_SUCCESS,
  payload: payload,
});

export const updateTabletsError = (payload) => ({
  type: UPDATE_TABLET_ERROR,
  payload: payload,
});

export const getTabletInProgress = (payload) => ({
  type: GET_TABLET_IN_PROGRESS,
  payload: payload,
});

export const getTabletSuccess = (payload) => ({
  type: GET_TABLET_SUCCESS,
  payload: payload,
});

export const getTabletError = (payload) => ({
  type: GET_TABLET_ERROR,
  payload: payload,
});

export const deleteTabletInProgress = (payload) => ({
  type: DELETE_TABLET_IN_PROGRESS,
  payload: payload,
});


export const deleteTabletError = (payload) => ({
  type: DELETE_TABLET_ERROR,
  payload: payload,
});

export const newTabletInProgress = (payload) => ({
  type: NEW_TABLET_IN_PROGRESS,
  payload: payload,
});

export const newTabletSuccess = (payload) => ({
  type: NEW_TABLET_SUCCESS,
  payload: payload,
});

export const newTabletError = (payload) => ({
  type: NEW_TABLET_ERROR,
  payload: payload,
});
export const TabletsByOrgInProgress = (payload) => ({
  type: TABLET_BY_ORG_IN_PROGRESS,
  payload: payload,
});

export const TabletByOrgSuccess = (payload) => ({
  type: TABLET_BY_ORG_SUCCESS,
  payload: payload,
});

export const TabletByOrgError = (payload) => ({
  type: TABLET_BY_ORG_ERROR,
  payload: payload,
});
