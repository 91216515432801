import moment from "moment";
export const appoinment_type = [
  { label: "Walk-in", value: "Walk-in" },
  { label: "Online", value: "Online" },
  { label: "Booking through call", value: "Booking through call" },
  { label: "In-Patient", value: "In-Patient" },
  { label: "New-Patient", value: "New-Patient" },
];
export const data = [
  {
    ID: "1",
    Patient: "Aruna",
    Doctor: "Dr. Niveditha V C",
    DateTime: "08/04/2022",
    Remarks: "admin@gmail.com",
    Status: "pendingconfirmed",
    Type: "walk-in",
  },
  {
    ID: "2",
    Patient: "Aruna",
    Doctor: "Dr. Niveditha V C",
    DateTime: "08/05/2022",
    Remarks: "admin@gmail.com",
    Status: "pending",
    Type: "walk-in",
  },
  {
    ID: "3",
    Patient: "Aruna",
    Doctor: "Dr. Niveditha V C",
    DateTime: "08/05/2022",
    Remarks: "admin@gmail.com",
    Status: "treated",
    Type: "walk-in",
  },
  {
    ID: "4",
    Patient: "Aruna",
    Doctor: "Dr. Niveditha V C",
    DateTime: "08/25/2022",
    Remarks: "admin@gmail.com",
    Status: "treated",
    Type: "walk-in",
  },
  {
    ID: "5",
    Patient: "Aruna",
    Doctor: "Dr. Niveditha V C",
    DateTime: "08/22/2022",
    Remarks: "admin@gmail.com",
    Status: "cancelled",
    Type: "walk-in",
  },
  {
    ID: "6",
    Patient: "Aruna",
    Doctor: "Dr. Niveditha V C",
    DateTime: "08/20/2022",
    Remarks: "admin@gmail.com",
    Status: "requested",
    Type: "walk-in",
  },
  {
    ID: "6",
    Patient: "Aruna",
    Doctor: "Dr. Niveditha V C",
    DateTime: "08/20/2022",
    Remarks: "admin@gmail.com",
    Status: "confirmed",
    Type: "walk-in",
  },
];

export const TodayAppoinment = data.filter(
  (person) => person.DateTime === moment().format("L")
);
export const UpcomingAppoinment = data.filter(
  (person) => person.DateTime > moment().format("L")
);

export const appoinment_status = [
  { label: "pending confirmation", value: "pending confirmation" },
  { label: "confirmed", value: "confirmed" },
  { label: "treated", value: "treated" },
  { label: "cancelled", value: "cancelled" },
];

export const TimeSlots = [
  { start_time: "10.00.00", end_time:"11.00.00",status: 1 },
  {  start_time: "12.00.00", end_time:"13.00.00", status: 1 },
  { start_time: "14.00.00", end_time:"15.00.00", status: 1 },
  { start_time: "16.00.00", end_time:"17.00.00",  status: 1 },
  {  start_time: "18.00.00", end_time:"19.00.00",status: 1 },
];
export const events = [
  {
    title: "All Day Event very long title",
    allDay: true,
    start: new Date(2022, 7, 9),
    end: new Date(2022, 7, 9),
    patientname: "Sudha",
    Patientid: "hive456",
    number: "90987654433",
    gender: "female",
    dob: "22/9/1994",
    email: "sudha@gmail.com",
    address: " no 15,main road,Near Bus stand,Rasipuram",
  },
  {
    title: "All Day Event ",
    allDay: true,
    start: new Date(2022, 7, 11),
    end: new Date(2022, 7, 11),
    patientname: "Ram",
    Patientid: "hive4098",
    number: "90987654433",
    gender: "male",
    dob: "22/9/1999",
    address: " no 8,main road,Near Bus stand,chennai",
    email: "ram@gmail.com",
  },
  {
    title: "All Day Event very long title",
    allDay: true,
    start: new Date(2022, 7, 31),
    end: new Date(2022, 7, 31),
  },
  {
    title: "All Day Event very long title",
    allDay: true,
    start: new Date(2022, 8, 3),
    end: new Date(2022, 8, 3),
    patientname: "chay",
    Patientid: "hive456",
    number: "90987654433",
    gender: "male",
    dob: "22/9/1994",
    address: " no 15,main road,Near Bus stand,Bangalore",
    email: "chay@gmail.com",
  },
];
export const CardData = [
  {
    date: "22-8-2022",
    time: "10 30AM-11AM",
    doctorname: "Dr.Nivethika",
  },
  {
    date: "22-8-2022",
    time: "10 30AM-11AM",
    doctorname: "Dr.Nivethika",
  },
  {
    date: "22-8-2022",
    time: "10 30AM-11AM",
    doctorname: "Dr.Nivethika",
  },
];

export const productData = [
  {
    date: "22-8-2022",
    time: "10 30AM-11AM",
    doctorname: "Dr.Nivethika",
  },
  {
    date: "24-8-2022",
    time: "1PM-2PM",
    doctorname: "Dr.Nivethika",
  },
];
export const patient_symptoms = [
  { label: "Fever", value: "Fever" },
  { label: "Skin Allergy", value: "Skin Allergy" },
  { label: "Diabetes ", value: "Diabetes" },
];
export const Doctor_Name = [
  { label: "Seetha", value: "Seetha" },
  { label: "Ravi", value: "Ravi" },
  { label: "Kowshi", value: "Kowshi" },
];
export const Patient_Name = [
  { label: "Raja", value: "Raja" },
  { label: "Surya", value: "Surya" },
  { label: "Abi", value: "Abi" },
];
export const categoriesType = [
  { value: 1, label: "Scan and X-ray" },
  { value: 2, label: "Lab - Test Sample" },
  { value: 3, label: "Specialists" },
 
];
export const categoriesPlaceHolder = {
  1 : "Select the Scan and X-ray" ,
  2 : "Select the Lab - Test Sample" ,
  3 : "Select the Specialists" ,
};
export const viewType = {
  1 : "Scan" ,
  2 : "Lab" ,
  3 : "Doctor" ,
};