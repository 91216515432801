import React, { useEffect, useState } from "react";

import { Card, Col, Row, Spin } from "antd";

import { Link } from "react-router-dom/cjs/react-router-dom";
import { APP_ROUTES } from "../../constants/Routes";

import {
  IdcardOutlined,
  LoadingOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import doctor from "../../assets/images/DocMotez/doctorslists.png";
import patient from "../../assets/images/DocMotez/patient_logo.png";
import billing from "../../assets/images/DocMotez/myappointments.png";
import todayBilling from "../../assets/images/DocMotez/schedule.png";
import ColorCard from "../../components/card/ColorCard";
import { useDispatch, useSelector } from "react-redux";
import IconWithTextCard from "../../components/card/IconWithTextCard";
import {
  defaulInsulin,
  defaultBloodGlucose,
  defaultInsulin,
  defaultVital,
} from "../../appRedux/Patient/Action/Action";

const HealthManagementScreen = () => {
  const dispatch = useDispatch();
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );
  const [loading, setLoading] = useState(false);
  const addVitalData = useSelector(
    (state) => state?.patientAppointment?.addVitals
  );
  const addBloodGlucoseData = useSelector(
    (state) => state?.patientAppointment?.addBloodGlucose
  );
  const addInsulinData = useSelector(
    (state) => state?.patientAppointment?.addBloodGlucose
  );
  useEffect(() => {
    if (addVitalData?.success) {
      dispatch(defaultVital());
    } else if (addBloodGlucoseData?.success) {
      dispatch(defaultBloodGlucose());
    } else {
      dispatch(defaultInsulin());
    }
  }, [addVitalData, addBloodGlucoseData, addInsulinData]);
  return (
    <Card>
      <Spin indicator={antIcon} spinning={loading}>
        <Row
          gutter={[16, 16]}
          style={{ display: "flex", justifyContent: "space-around" }}
        >
          <Col xl={6} lg={6} md={6} sm={12} xs={24}>
            <Link to={APP_ROUTES?.VITAL}>
              <IconWithTextCard
                icon={<IdcardOutlined />}
                subTitle={"Vitals"}
                cardColor={"card-blue"}
                image={patient}
                size={85}
              />
            </Link>
          </Col>
          <Col xl={6} lg={6} md={6} sm={12} xs={24}>
            <Link to={APP_ROUTES?.BLOOD_GLUCOSE}>
              <IconWithTextCard
                icon={<IdcardOutlined />}
                subTitle={"Blood Glucose"}
                cardColor={"card-orange"}
                image={doctor}
                size={85}
              />
            </Link>
          </Col>
          <Col xl={6} lg={6} md={6} sm={12} xs={24}>
            <Link to={APP_ROUTES?.BMI}>
              <IconWithTextCard
                icon={<IdcardOutlined />}
                subTitle={"BMI"}
                cardColor={"card-green"}
                image={todayBilling}
                size={85}
              />
            </Link>
          </Col>{" "}
          <Col xl={6} lg={6} md={6} sm={12} xs={24}>
            <Link to={APP_ROUTES.INSULIN}>
              <IconWithTextCard
                icon={<IdcardOutlined />}
                subTitle={"INSULIN"}
                cardColor={"card-violet"}
                image={billing}
                size={85}
              />
            </Link>
          </Col>
        </Row>
      </Spin>
    </Card>
  );
};

export default HealthManagementScreen;
