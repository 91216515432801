import React from "react";
import { Card } from "antd";
import MedicineCategoryHeader from "./MedicineCategoryHeader";
import MedicineCategoryTable from "./MedicineCategoryTable";

const MedicineCategoryScreen = () => {
  return (
    <Card>
      <MedicineCategoryHeader />
      <MedicineCategoryTable />
    </Card>
  );
};

export default MedicineCategoryScreen;
