import React from "react";
import { Card } from "antd";


import RolesHeader from "./RolesHeader";
import Auxiliary from "../../../util/Auxiliary";
import RolesTable from "./RolesTable";



const RolesScreen = () => {
  return (
    <Auxiliary>
      <Card>
        <RolesHeader/>
        <RolesTable/>
      </Card>
    </Auxiliary>
  );
};
export default RolesScreen;
