import { call, put, takeLatest } from "@redux-saga/core/effects";
import { getFamilyMemberListEffect } from "./effects/FamilyMemberEffect";
import {
  getFamilyMemberListInError,
  getFamilyMemberListInSuccess,
} from "../actions/FamilyMember";
import { FAMILY_MEMBER_LIST_IN_PROGRESS } from "../../../constants/ActionTypes";
import { getErrorMessageFromAPI } from "../../../util/utilz";
import { message } from "antd";

function* getFamilyMemberList({ payload }) {
  try {
    const { data } = yield call(getFamilyMemberListEffect, payload);
  
    yield put(
      getFamilyMemberListInSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
  } catch (e) {
    yield put(
      getFamilyMemberListInError({
        message: getErrorMessageFromAPI(e),
        error: true,
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}
export function* FamilyMemberSaga() {
  yield takeLatest(FAMILY_MEMBER_LIST_IN_PROGRESS, getFamilyMemberList);
}
