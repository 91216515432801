import {
  ADD_CONSULTATION_ERROR,
  ADD_CONSULTATION_IN_PROGRESS,
  ADD_CONSULTATION_SUCCESS,
  GET_CONSULTATION_ERROR,
  GET_CONSULTATION_IN_PROGRESS,
  GET_CONSULTATION_SUCCESS,
  UPDATE_CONSULTATION_ERROR,
  UPDATE_CONSULTATION_IN_PROGRESS,
  UPDATE_CONSULTATION_SUCCESS,
} from "../../../constants/ActionTypes";

export const AddConsultationInProgress = (user) => {
  return {
    type: ADD_CONSULTATION_IN_PROGRESS,
    payload: user,
  };
};
export const AddConsultationSuccess = (user) => {
  return {
    type: ADD_CONSULTATION_SUCCESS,
    payload: user,
  };
};
export const AddConsultationError = (user) => {
  return {
    type: ADD_CONSULTATION_ERROR,
    payload: user,
  };
};
export const UpdateConsultationInProgress = (user) => {
  return {
    type: UPDATE_CONSULTATION_IN_PROGRESS,
    payload: user,
  };
};
export const UpdateConsultationSuccess = (user) => {
  return {
    type: UPDATE_CONSULTATION_SUCCESS,
    payload: user,
  };
};
export const UpdateConsultationError = (user) => {
  return {
    type: UPDATE_CONSULTATION_ERROR,
    payload: user,
  };
};

export const GetConsultationInProgress = (user) => {
  return {
    type: GET_CONSULTATION_IN_PROGRESS,
    payload: user,
  };
};
export const GetConsultationSuccess = (user) => {
  return {
    type: GET_CONSULTATION_SUCCESS,
    payload: user,
  };
};
export const GetConsultationError = (user) => {
  return {
    type: GET_CONSULTATION_ERROR,
    payload: user,
  };
};
