import { Row, Col, Form, Card, Modal, Spin } from "antd";
import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";

import moment from "moment";
import IntlMessages from "../../../util/IntlMessages";
import FormInput from "../../../components/Input/FormInput";
import { disablePastDate } from "../../../util/utilz";
import FormSelectBox from "../../../components/SelectBox/SelectBox";

import ButtonComponent from "../../../components/Button/ButtonComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  addSubscriptionDetailsInProgress,
  getOrgDetailsByIdInProgress,
  getOrganisationListInProgress,
  getPlanListInProgress,
  getsubscriptionListInProgress,
} from "../../../appRedux/super-admin/SuperAdminAction";
import PaymentForm from "./PaymentForm";
import { LoadingOutlined } from "@ant-design/icons";

const AddPaymentForm = () => {
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const history = useHistory();
  const [gstAmount, setGstAmount] = useState();
  const [backButton, setBackButton] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [selectedHospital, setSelectedHospital] = useState([]);
  const [orgValue, setOrgValue] = useState();
  const [subscriptionName, setSubscriptionName] = useState();

  const [paymentModal, setPaymentModal] = useState(false);
  const [orgSubscriptionData, setOrgSubscriptionData] = useState([]);
  const [subscriptionValue, setSubscriptionValue] = useState("Yes");
  const addPaymentData = useSelector(
    (state) => state.superAdmin?.addubscriptionPayment
  );
  const addSubscriptionDetails = useSelector(
    (state) => state.superAdmin?.addSubscriptionDetails
  );
  const planListDetails = useSelector((state) => state?.superAdmin?.planList);
  const subscriptionistData = useSelector(
    (state) => state.superAdmin?.subscriptionList
  );
  const subscriptionDetailsData = useSelector(
    (state) => state.superAdmin?.addSubscriptionDetails
  );
  const orgListData = useSelector(
    (state) => state?.superAdmin?.orgList?.data?.data
  );

  const orgDetails = useSelector(
    (state) => state?.superAdmin?.getOrgDetailsById?.data?.data
  );
  const orgDetailsLoading = useSelector(
    (state) => state?.superAdmin?.getOrgDetailsById
  );

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const onFinish = (datas) => {
    const payload = {
      subscription_id: subscriptionValue,
      organization_id: orgValue,
      start_date: datas?.date,
      pricing: datas?.pricing,
      periods: datas?.period,
      payment_status: "1",
    };
    dispatch(addSubscriptionDetailsInProgress(payload));
    setSelectedHospital(orgSubscriptionData[0]);
  };
  const onClickDueButton = () => {
    setPaymentModal(true);
  };
  const changeOrganization = (value) => {
    setOrgValue(value);

    dispatch(getOrgDetailsByIdInProgress(value));
  };
  const changeSubscription = (value) => {
    setSubscriptionValue(value);

    const selectedSubscription = subscriptionistData?.data?.data.find(
      (item) => item.id === value
    );
    setSelectedHospital(selectedSubscription);
    if (selectedSubscription) {
      setLoading(false);
      form.setFieldsValue({
        pricing: selectedSubscription?.toatl,
        period: selectedSubscription?.periods,
      });
      setGstAmount(selectedSubscription?.gst_amount);
      setSubscriptionName(selectedSubscription?.name);
    } else {
      setLoading(true);
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      hospital_name: selectedHospital?.Organization?.name,
      date: moment().format("YYYY-MM-DD"),
    });
  }, [selectedHospital]);
  useEffect(() => {
    const payload = { pagination: false, pageSize: "10", pageNo: 1 };
    dispatch(getsubscriptionListInProgress(payload));
  }, []);

  useEffect(() => {
    const payload = { pagination: false, pageSize: "10", pageNo: "1" };

    dispatch(getOrganisationListInProgress(payload));

    dispatch(
      getPlanListInProgress({
        pagination: "false",
        pageNo: "1",
        pageSize: "10",
      })
    );
  }, []);
  const goBackHandler = () => {
    history.goBack();
  };
  useEffect(() => {
    if (orgDetails?.organization_serial_no) {
      setLoading(false);
      const orgSubscriptionData = planListDetails?.data?.data?.filter(
        (room) => room?.Organization?.id === orgDetails?.id
      );

      form.setFieldsValue({
        hospital_name:
          orgSubscriptionData && orgSubscriptionData[0]?.Organization?.name,
        date: moment().format("YYYY-MM-DD"),
      });
      setOrgSubscriptionData(orgSubscriptionData);
      setSelectedHospital(orgSubscriptionData[0]);

      if (orgSubscriptionData?.Subscription !== null) {
        form.setFieldsValue({
          pricing: orgSubscriptionData[0]?.Subscription?.toatl,
          period: orgSubscriptionData[0]?.periods,
          subscription: orgSubscriptionData[0]?.subscription_id,
          pendingAmount: orgSubscriptionData[0]?.pendingAmount,
        });

        if (orgSubscriptionData[0]?.Subscription?.plans === 1) {
          setBackButton(true);
        } else {
          setBackButton(false);
        }
      } else {
        form.setFields([
          { name: "pricing", value: undefined },
          { name: "period", value: undefined },
          { name: "subscription", value: undefined },
          { name: "pendingAmount", value: undefined },
        ]);
      }
    }
  }, [orgDetails]);

  const handleOkPayment = () => {
    setPaymentModal(false);
  };

  const handleCancelPayment = () => {
    setPaymentModal(false);
  };
  useEffect(() => {
    if (addSubscriptionDetails?.success) {
      setPaymentModal(true);
      //   history.push("/payment");
    }
  }, [addSubscriptionDetails]);
  useEffect(() => {
    if (addPaymentData?.success) {
      setPaymentModal(false);
      history.push("/payment");
    }
  }, [addPaymentData]);
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );
  useEffect(() => {
    if (orgDetailsLoading?.inProgress) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [orgDetailsLoading]);

  return (
    <Card>
      {" "}
      <Spin indicator={antIcon} spinning={Loading}>
        <Form
          initialValues={{
            remember: true,
          }}
          colon={"true"}
          onFinish={onFinish}
          autoComplete="off"
          form={form}
          onFinishFailed={onFinishFailed}
        >
          <Row>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                labelCol={{
                  span: 24,
                }}
                wrapperCol={{
                  span: 24,
                }}
                label={<IntlMessages id={"Addnew.admin.label.organization"} />}
                name={"organization"}
                rules={[
                  {
                    required: true,
                    message: (
                      <IntlMessages
                        id={"Addnew.admin.validator.subscription"}
                      />
                    ),
                  },
                ]}
              >
                <FormSelectBox
                  showSearch={true}
                  placeholder="Select Organization"
                  value={orgValue}
                  options={
                    orgListData?.map((pack, i) => ({
                      label: pack.name,
                      value: pack.id,
                      key: pack.id,
                    })) || []
                  }
                  onChange={changeOrganization}
                />
              </Form.Item>

              {/* <FormInput
              labelCol={"24"}
              wrapperCol={"24"}
              name={"hospital_name"}
              label={
                <IntlMessages id={"Addnew.superAdmin.label.hospital_name"} />
              }
              rules={[
                {
                  required: true,
                  message: (
                    <IntlMessages id={"superAdmin.validator.hospital_name"} />
                  ),
                },
              ]}
            /> */}
            </Col>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <FormInput
                type="date"
                placeholder="date"
                name={"date"}
                min={disablePastDate()}
                labelCol={"24"}
                wrapperCol={"24"}
                label={<IntlMessages id={"Addnew.superAdmin.label.date"} />}
                rules={[
                  {
                    required: true,
                    message: <IntlMessages id={"Date is required"} />,
                  },
                ]}
              />
            </Col>{" "}
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                labelCol={{
                  span: 24,
                }}
                wrapperCol={{
                  span: 24,
                }}
                label={<IntlMessages id={"Addnew.admin.label.subscription"} />}
                name={"subscription"}
                rules={[
                  {
                    required: true,
                    message: (
                      <IntlMessages
                        id={"Addnew.admin.validator.subscription"}
                      />
                    ),
                  },
                ]}
              >
                <FormSelectBox
                  placeholder="Select Subscription"
                  value={subscriptionValue}
                  options={
                    subscriptionistData?.data?.data?.map((pack, i) => ({
                      label: pack.name,
                      value: pack.id,
                      key: pack.id,
                    })) || []
                  }
                  onChange={changeSubscription}
                  disabled={orgSubscriptionData?.length === 0 ? false : true}
                />
              </Form.Item>
            </Col>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <FormInput
                labelCol={"24"}
                wrapperCol={"24"}
                name={"period"}
                readOnly={true}
                label={<IntlMessages id={"Addnew.superAdmin.label.periods"} />}
                rules={[
                  {
                    required: true,
                    message: (
                      <IntlMessages id={"superAdmin.validator.periods"} />
                    ),
                  },
                ]}
              />
            </Col>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <FormInput
                labelCol={"24"}
                wrapperCol={"24"}
                name={"pricing"}
                readOnly={true}
                label={<IntlMessages id={"Addnew.superAdmin.label.amount"} />}
                rules={[
                  {
                    required: true,
                    message: (
                      <IntlMessages id={"superAdmin.validator.paidAmount"} />
                    ),
                  },
                ]}
              />
            </Col>
            {orgSubscriptionData[0]?.paymentCompleted === "No" ? (
              <>
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                  <FormInput
                    labelCol={"24"}
                    wrapperCol={"24"}
                    name={"pendingAmount"}
                    readOnly={true}
                    label={
                      <IntlMessages
                        id={"Addnew.superAdmin.label.pendingAmount"}
                      />
                    }
                  />
                </Col>{" "}
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <ButtonComponent
                    label={"Proceed to pay pending amount"}
                    type="primary"
                    onClick={() => onClickDueButton()}
                  />
                </Col>
              </>
            ) : backButton ? (
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <ButtonComponent
                  label={"Back"}
                  type="primary"
                  onClick={goBackHandler}
                />
              </Col>
            ) : (
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <ButtonComponent
                  label={"Proceed to pay"}
                  htmlType="submit"
                  type="primary"
                />
              </Col>
            )}
            <Col xs={24} sm={24} md={24} lg={24} xl={24}></Col>
          </Row>
        </Form>
      </Spin>
      <Modal
        title={
          selectedHospital?.paymentCompleted === "No"
            ? `Payment Details- Due Amount ${selectedHospital?.pendingAmount}`
            : "Payment Details"
        }
        open={paymentModal}
        onOk={handleOkPayment}
        onCancel={handleCancelPayment}
        width={600}
        footer={null}
        destroyOnClose={handleCancelPayment}
      >
        <PaymentForm
          selectedHospital={selectedHospital}
          setPaymentModal={setPaymentModal}
          paymentModal={paymentModal}
          orgDetails={orgDetails}
        />
      </Modal>
    </Card>
  );
};

export default AddPaymentForm;
