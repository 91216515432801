import { PlusCircleOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Modal, Row } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import { APP_ROUTES } from "../../../constants/Routes";
import IntlMessages from "../../../util/IntlMessages";
import "../../../styles/modules/doctor.less";

import Auxiliary from "../../../util/Auxiliary";
import DoctorCSVfile from "../DoctorCsvFile";
import { access } from "../../../util/utilz";
const DoctorHeader = () => {
  const [open, setOpen] = useState(false);

  const showModal = () => {
    setOpen(true);
  };
  const handleOk = () => {
    setOpen(false);
  };
  const handleCancel = () => {
    setOpen(false);
  };
  const roleId = localStorage.getItem("role_id");

  return (
    <Auxiliary>
      <Row className="p-10 Add-button">
        <Col>
          <p className="header-tag">{<IntlMessages id={"Addnew.doctor"} />}</p>
        </Col>
        <Col>
          {access("doctors", "add") && roleId === "1" && (
            <>
              <Link to={APP_ROUTES.DOCTORADDNEW}>
                <ButtonComponent
                  icon={<PlusCircleOutlined />}
                  type="primary"
                  label="Add New"
                />
              </Link>
              <Button
                type="primary"
                onClick={showModal}
                style={{ marginLeft: "10px" }}
              >
                Import CSV
              </Button>
            </>
          )}

          <Modal
            open={open}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={
              [
                // <Button key="back" onClick={handleCancel}>
                //   Cancel
                // </Button>,
                // <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
                //   Submit
                // </Button>
              ]
            }
          >
            <DoctorCSVfile onCancel={handleCancel} />
          </Modal>
        </Col>
      </Row>
      <Divider />
    </Auxiliary>
  );
};

export default DoctorHeader;
