import { Card } from "antd";
import React from "react";
import Auxiliary from "../../util/Auxiliary";

import RefDoctorHeader from "./component/RefDoctorHeader";
import RefDoctorTable from "./component/RefDoctorTable";

const ReferenceDoctorScreen = () => {
  return (
    <Auxiliary>
      <Card>
        <RefDoctorHeader />
        <RefDoctorTable />
      </Card>
    </Auxiliary>
  );
};

export default ReferenceDoctorScreen;
