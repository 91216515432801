import {
  GET_SUB_PACKAGE_IN_PROGRESS,
  GET_SUB_PACKAGE_LIST_SUCCESS,
  GET_SUB_PACKAGE_LIST_ERROR,
  ADD_PACKAGE_IN_PROGRESS,
  ADD_PACKAGE_IN_SUCCESS,
  ADD_PACKAGE_IN_ERROR,
  GET_MAIN_PACKAGE_LIST_IN_PROGRESS,
  GET_MAIN_PACKAG_LIST_SUCCESS,
  GET_MAIN_PACKAG_LIST_ERROR,
  UPDATE_PACKAGE_ERROR,
  UPDATE_PACKAGE_IN_PROGRESS,
  UPDATE_PACKAGE_SUCCESS,
  DELETE_PACKAGE_IN_PROGRESS,
  DELETE_PACKAGE_SUCCESS,
  DELETE_PACKAGE_ERROR,
  ADD_SUBPACKAGE_IN_PROGRESS,
  ADD_SUBPACKAGE_IN_SUCCESS,
  ADD_SUBPACKAGE_IN_ERROR,
  UPDATE_SUBPACKAGE_IN_PROGRESS,
  UPDATE_SUBPACKAGE_SUCCESS,
  UPDATE_SUBPACKAGE_ERROR,
  DELETE_SUBPACKAGE_IN_PROGRESS,
  DELETE_SUBPACKAGE_SUCCESS,
  DELETE_SUBPACKAGE_ERROR,
} from "../../../constants/ActionTypes";

const defaultStatus = {
  progressing: false,
  success: false,
  error: false,
  data: [],
};
const initialState = {
  subPackagesList: { ...defaultStatus },
  addPackages: { ...defaultStatus },
  mainPackagesList: { ...defaultStatus },
  updatePackages: { ...defaultStatus },
  deletePackage: { ...defaultStatus },
  addSubPackages: { ...defaultStatus },
  updateSubPackages: { ...defaultStatus },
  deleteSubPackages: { ...defaultStatus },
};
const billingPackageReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    //GET_SUB_PACKAGE
    case GET_SUB_PACKAGE_IN_PROGRESS:
      return {
        ...state,
        subPackagesList: { ...defaultStatus, ...payload, inProgress: true },
      };
    case GET_SUB_PACKAGE_LIST_SUCCESS:
      return {
        ...state,
        subPackagesList: { ...defaultStatus, ...payload, success: true },
      };
    case GET_SUB_PACKAGE_LIST_ERROR:
      return {
        ...state,
        subPackagesList: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    //GET_MAIN_PACKAGE
    case GET_MAIN_PACKAGE_LIST_IN_PROGRESS:
      return {
        ...state,
        mainPackagesList: { ...defaultStatus, ...payload, inProgress: true },
      };
    case GET_MAIN_PACKAG_LIST_SUCCESS:
      return {
        ...state,
        mainPackagesList: { ...defaultStatus, ...payload, success: true },
      };
    case GET_MAIN_PACKAG_LIST_ERROR:
      return {
        ...state,
        mainPackagesList: { ...defaultStatus, ...payload, error: true },
      };

    //ADD_PACKAGE
    case ADD_PACKAGE_IN_PROGRESS:
      return {
        ...state,
        addPackages: { ...defaultStatus, ...payload, inProgress: true },
      };
    case ADD_PACKAGE_IN_SUCCESS:
      return {
        ...state,
        addPackages: { ...defaultStatus, ...payload, success: true },
      };
    case ADD_PACKAGE_IN_ERROR:
      return {
        ...state,
        addPackages: { ...defaultStatus, ...payload, error: true },
      };
    //UPDATE_PACKAGE
    case UPDATE_PACKAGE_IN_PROGRESS:
      return {
        ...state,
        updatePackages: { ...defaultStatus, ...payload, inProgress: true },
      };
    case UPDATE_PACKAGE_SUCCESS:
      return {
        ...state,
        updatePackages: { ...defaultStatus, ...payload, success: true },
      };
    case UPDATE_PACKAGE_ERROR:
      return {
        ...state,
        updatePackages: { ...defaultStatus, ...payload, error: true },
      };

    //DELETE_PACKAGE
    case DELETE_PACKAGE_IN_PROGRESS:
      return {
        ...state,
        deletePackage: { ...defaultStatus, ...payload, inProgress: true },
      };
    case DELETE_PACKAGE_SUCCESS:
      return {
        ...state,
        deletePackage: { ...defaultStatus, ...payload, success: true },
      };
    case DELETE_PACKAGE_ERROR:
      return {
        ...state,
        deletePackage: { ...defaultStatus, ...payload, error: true },
      };
    //ADD_SUB_PACKAGES
    case ADD_SUBPACKAGE_IN_PROGRESS:
      return {
        ...state,
        addSubPackages: { ...defaultStatus, ...payload, inProgress: true },
      };
    case ADD_SUBPACKAGE_IN_SUCCESS:
      return {
        ...state,
        addSubPackages: { ...defaultStatus, ...payload, success: true },
      };
    case ADD_SUBPACKAGE_IN_ERROR:
      return {
        ...state,
        addSubPackages: { ...defaultStatus, ...payload, error: true },
      };
    //UPDATE_SUB_PACKAGES
    case UPDATE_SUBPACKAGE_IN_PROGRESS:
      return {
        ...state,
        updateSubPackages: { ...defaultStatus, ...payload, inProgress: true },
      };
    case UPDATE_SUBPACKAGE_SUCCESS:
      return {
        ...state,
        updateSubPackages: { ...defaultStatus, ...payload, success: true },
      };
    case UPDATE_SUBPACKAGE_ERROR:
      return {
        ...state,
        updateSubPackages: { ...defaultStatus, ...payload, error: true },
      };
    //DELETE_SUB_PACKAGES
    case DELETE_SUBPACKAGE_IN_PROGRESS:
      return {
        ...state,
        deleteSubPackages: { ...defaultStatus, ...payload, inProgress: true },
      };
    case DELETE_SUBPACKAGE_SUCCESS:
      return {
        ...state,
        deleteSubPackages: { ...defaultStatus, ...payload, success: true },
      };
    case DELETE_SUBPACKAGE_ERROR:
      return {
        ...state,
        deleteSubPackages: { ...defaultStatus, ...payload, error: true },
      };

    default:
      return state;
  }
};

export default billingPackageReducer;
