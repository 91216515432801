import React from "react";
import { Col, Row, Divider } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { APP_ROUTES } from "../../../../constants/Routes";
import "../../../../styles/modules/patient.less";
import IntlMessages from "../../../../util/IntlMessages";
import ButtonComponent from "../../../../components/Button/ButtonComponent";

const PurchaseRequestHeader = () => {
  return (
    <div>
      <Row className="p-10 Add-button">
        <Col>
          <p className="header-tag">
            {<IntlMessages id={"label.purchasereuest"} />}
          </p>
        </Col>
        <Col>
          <Link to={APP_ROUTES.ADDPURCHASEREQUEST}>
            <ButtonComponent
              type="primary"
              label={<IntlMessages id={"Addnew.patient.addnew"} />}
              icon={<PlusCircleOutlined />}
            />
          </Link>
        </Col>
      </Row>

      <Divider />
    </div>
  );
};

export default PurchaseRequestHeader;
