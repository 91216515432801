import { call, put, takeLatest } from "redux-saga/effects";

import {
  DOCTOR_LIST_IN_PROGRESS,
  GET_DOCTOR_IN_PROGRESS,
} from "../../../constants/ActionTypes";

import {
  doctorListError,
  doctorListSuccess,
  getDoctorError,
  getDoctorInProgress,
  getDoctorSuccess,
} from "../actions/doctor";
import { DoctorListEffect, GetDoctorEffect } from "./effects/DoctorEffect";

function* DoctorListWatcher({ payload }) {
  try {
    let { data } = yield call(DoctorListEffect, payload);

    yield put(
      doctorListSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
  } catch (e) {
    yield put(
      doctorListError({
        error: true,
        message: e["message"],
      })
    );
  }
}
function* GetDoctorWatcher({ payload }) {
  try {
    let { data } = yield call(GetDoctorEffect, payload);

    yield put(
      getDoctorSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
  } catch (e) {
    yield put(
      getDoctorError({
        error: true,
        message: e["message"],
      })
    );
  }
}
export default function* DoctorListSagas() {
  yield takeLatest(DOCTOR_LIST_IN_PROGRESS, DoctorListWatcher);
  yield takeLatest(GET_DOCTOR_IN_PROGRESS, GetDoctorWatcher);
}
