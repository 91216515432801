import React, { useEffect } from "react";
import "../../styles/modules/admin.less";
import { Card, Col, Progress, Row, Spin } from "antd";
import IconWithTextCard from "../../components/card/IconWithTextCard";
import doctor from "../../assets/images/DocMotez/doctorslists.png";
import patient from "../../assets/images/DocMotez/patient_logo.png";
import billing from "../../assets/images/DocMotez/myappointments.png";
import todayBilling from "../../assets/images/DocMotez/schedule.png";
import {
  MedicineBoxOutlined,
  UnorderedListOutlined,
  IdcardOutlined,
  BookOutlined,
  LoadingOutlined,
  ReconciliationOutlined,
} from "@ant-design/icons";
import TicketList from "../../components/card/TicketList";
import BillingList from "./BillingLIst";
import { useDispatch, useSelector } from "react-redux";
import { getDashboradListInProgress } from "../../appRedux/admin/DashBorad/action/dashboardAction";
import DashboardCard from "../../components/card/DashboardCard";
import DemoArea from "../Dashboard/DemoArea";
import DoctorList from "./DoctorList";
const AdminDashboardScreen = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDashboradListInProgress());
  }, [dispatch]);
  const { adminDashboard } = useSelector((state) => state);

  const adminDashboardDetails = adminDashboard?.adminDashboardList?.data?.count;
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );
  return (
    <Spin
      indicator={antIcon}
      spinning={adminDashboard?.adminDashboardList?.progressing}
    >
      {/* <Card> */}
      <Row gutter={[16, 16]}>
        <Col xl={6} lg={12} md={12} sm={12} xs={24}>
          <DashboardCard
            icon={<IdcardOutlined />}
            color="#44C27F" // Purple for the icon and progress bar
            title="Over All Patients"
            value={adminDashboardDetails?.patientCount.toString() || "0"}
          />
          {/* <IconWithTextCard
              icon={<IdcardOutlined />}
              title={"Over All Patients"}
              subTitle={adminDashboardDetails?.patientCount}
              cardColor={"card-blue"}
              image={patient}
              size={85}
              height={100}
            /> */}
        </Col>
        <Col xl={6} lg={12} md={12} sm={12} xs={24}>
          <DashboardCard
            icon={<MedicineBoxOutlined />}
            color="#47B3FF" // Purple for the icon and progress bar
            title="Over All Doctors"
            value={adminDashboardDetails?.doctorCount.toString() || "0"}
          />
          {/* <IconWithTextCard
              icon={}
              title={"Over All Doctors"}
              subTitle={adminDashboardDetails?.doctorCount}
              cardColor={"card-orange"}
              className={"admin-dashboard-cards"}
              image={doctor}
              size={75}
              height={100}
            /> */}
        </Col>
        <Col xl={6} lg={12} md={12} sm={12} xs={24}>
          <DashboardCard
            icon={<ReconciliationOutlined />}
            color="#FF8744"
            title="Over All Billing"
            value={adminDashboardDetails?.billingCount.toString() || "0"}
          />
          {/* <IconWithTextCard
              icon={<UnorderedListOutlined />}
              title={"Over All Billing"}
              subTitle={adminDashboardDetails?.billingCount}
              cardColor={"card-green"}
              image={billing}
              size={200}
              height={100}
            /> */}
        </Col>
        <Col xl={6} lg={12} md={12} sm={12} xs={24}>
          <DashboardCard
            icon={<BookOutlined />}
            color="#A066FF"
            title={"Today Billing"}
            value={adminDashboardDetails?.todayBillingCount.toString() || "0"}
          />
          {/* <IconWithTextCard
              icon={<BookOutlined />}
              title={"Today Billing"}
              subTitle={adminDashboardDetails?.todayBillingCount}
              cardColor={"card-violet"}
              image={todayBilling}
              size={80}
              height={100}
            /> */}
        </Col>
      </Row>
      <Row gutter={16} style={{ marginTop: "20px", marginBottom: "20px" }}>
        <Col span={16}>
          <Card
            title="Graph Representation"
            style={{ height: "100%" }}
            headStyle={{ textAlign: "center", backgroundColor: "#E0F7FA" }}
          >
            <DemoArea />
          </Card>
        </Col>
        <Col span={8}>
          <Card
            title="Patient Summary"
            style={{ height: "100%" }}
            headStyle={{
              textAlign: "center",
              backgroundColor: "#F3E5F5",
              boxShadow:
                "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px",
            }}
          >
            <div style={{ textAlign: "center", padding: "20px" }}>
              <Row align="middle" justify="center" gutter={[16, 16]}>
                <Col>
                  <Progress
                    type="circle"
                    percent={65}
                    strokeColor={{
                      "0%": "#1D3557",
                      "100%": "#F72585",
                    }}
                    strokeWidth={12}
                    trailColor="#F0F0F0"
                    width={100}
                  />
                </Col>
                <Col>
                  <div style={{ textAlign: "left" }}>
                    <div>
                      <strong>Total Patient</strong>: 1000
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <span style={{ color: "#F72585" }}>●</span> Woman: 350
                    </div>
                    <div>
                      <span style={{ color: "#1D3557" }}>●</span> Man: 650
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Card>
        </Col>
      </Row>
      <Row gutter={[16, 16]} className="px-0 mt-20">
        <Col xl={8} lg={12} md={12} sm={12} xs={24}>
          <TicketList
            recentAppointments={adminDashboardDetails?.recentAppointments}
          />
        </Col>
        <Col xl={8} lg={12} md={12} sm={12} xs={24}>
          <BillingList
            recentAppointments={adminDashboardDetails?.recentBillings}
          />
        </Col>
        <Col xl={8} lg={12} md={12} sm={12} xs={24}>
          <DoctorList
            recentAppointments={adminDashboardDetails?.recentAppointments}
          />
        </Col>
      </Row>
      {/* </Card> */}
    </Spin>
  );
};

export default AdminDashboardScreen;
