import React from "react";
import { Card } from "antd";
import PatientHealthRecordHeader from "./PatientHealthRecordHeader";
import PatientHealthRecordList from "./PatientHealthRecordList";

const PatientHealthRecordScreen = () => {
  return (
    <Card>
      <PatientHealthRecordHeader />
      <PatientHealthRecordList />
    </Card>
  );
};

export default PatientHealthRecordScreen;
