import {
  ADD_TEAM_MEMBER_IN_ERROR,
  ADD_TEAM_MEMBER_IN_PROGRESS,
  ADD_TEAM_MEMBER_IN_SUCCESS,
  DELETE_TEAM_MEMBER_IN_ERROR,
  DELETE_TEAM_MEMBER_IN_PROGRESS,
  DELETE_TEAM_MEMBER_IN_SUCCESS,
  GET_TEAM_MEMBER_IN_ERROR,
  GET_TEAM_MEMBER_IN_PROGRESS,
  GET_TEAM_MEMBER_IN_SUCCESS,
  UPDATE_TEAM_MEMBER_IN_ERROR,
  UPDATE_TEAM_MEMBER_IN_PROGRESS,
  UPDATE_TEAM_MEMBER_IN_SUCCESS,
} from "../../../../constants/ActionTypes";

// Get All TeamMember List
export const getTeamMemberListInProgress = (payload) => ({
  type: GET_TEAM_MEMBER_IN_PROGRESS,
  payload: payload,
});

export const getTeamMemberListInSuccess = (payload) => ({
  type: GET_TEAM_MEMBER_IN_SUCCESS,
  payload: payload,
});

export const getTeamMemberListInError = (payload) => ({
  type: GET_TEAM_MEMBER_IN_ERROR,
  payload: payload,
});
// Add Team Member

export const addTeamMemberInProgress = (payload) => ({
  type: ADD_TEAM_MEMBER_IN_PROGRESS,
  payload: payload,
});

export const addTeamMemberSuccess = (payload) => ({
  type: ADD_TEAM_MEMBER_IN_SUCCESS,
  payload: payload,
});

export const addTeamMemberInError = (payload) => ({
  type: ADD_TEAM_MEMBER_IN_ERROR,
  payload: payload,
});

// update Team Member

export const updateTeamMemberInProgress = (payload) => ({
  type: UPDATE_TEAM_MEMBER_IN_PROGRESS,
  payload: payload,
});

export const updateTeamMemberSuccess = (payload) => ({
  type: UPDATE_TEAM_MEMBER_IN_SUCCESS,
  payload: payload,
});

export const updateTeamMemberInError = (payload) => ({
  type: UPDATE_TEAM_MEMBER_IN_ERROR,
  payload: payload,
});
//delete teamMember
export const deleteTeamMemberInProgress = (payload) => ({
  type: DELETE_TEAM_MEMBER_IN_PROGRESS,
  payload: payload,
});

export const deleteTeamMemberSuccess = (payload) => ({
  type: DELETE_TEAM_MEMBER_IN_SUCCESS,
  payload: payload,
});

export const deleteTeamMemberError = (payload) => ({
  type: DELETE_TEAM_MEMBER_IN_ERROR,
  payload: payload,
});
