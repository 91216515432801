import React, { useState } from "react";
import { Col, Row, Divider } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { APP_ROUTES } from "../../../../constants/Routes";
import "../../../../styles/modules/patient.less";
import IntlMessages from "../../../../util/IntlMessages";
import ButtonComponent from "../../../../components/Button/ButtonComponent";
import { access } from "../../../../util/utilz";

const PurchaseHeader = () => {
  return (
    <div>
      <Row className="p-10 Add-button">
        <Col>
          <p className="header-tag">{<IntlMessages id={"label.purchase"} />}</p>
        </Col>
        <Col>
          <Link to={APP_ROUTES.BULKPURCHASE}>
            <ButtonComponent
              className="buttons mx-10 secondary-color"
              icon={<PlusCircleOutlined />}
              type="primary"
              label={<IntlMessages id={"Addnew.bulkPurchase"} />}
            />
          </Link>

          {access("purchase", "add") && (
            <Link
              to={{
                pathname: APP_ROUTES.ADDPURCHASE,
                state: "Add",
              }}
            >
              <ButtonComponent
                type="primary"
                label={<IntlMessages id={"Addnew.patient.addnew"} />}
                icon={<PlusCircleOutlined />}
              />
            </Link>
          )}
        </Col>
      </Row>

      <Divider />
    </div>
  );
};

export default PurchaseHeader;
