import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Divider,
  Modal,
  Typography,
  Button,
  Table,
  Form,
  Spin,
} from "antd";
import {
  DeleteFilled,
  EditFilled,
  LoadingOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import IntlMessages from "../../../util/IntlMessages";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import { APP_ROUTES } from "../../../constants/Routes";
import "../../../styles/modules/pharmacy.less";
import FormInput from "../../../components/Input/FormInput";
import { useDispatch, useSelector } from "react-redux";
import {
  addDepartmentInProgress,
  deleteDepartmentInProgress,
  editDepartmentInProgress,
  getDepartmentListInProgress,
} from "../../../appRedux/Pharmacy/PharmacyAction";
import { access } from "../../../util/utilz";
const ItemTransferHeader = () => {
  const [departmentModal, setDepartmentModal] = useState(false);
  const [listLoading, setListLoading] = useState(false);
  const [departmentId, setDepartmentId] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [department, setDepartment] = useState("Add Departments");
  const [deleteId, setDeleteId] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [addDepartmentModal, setAddDepartmentModal] = useState(false);
  const departmentListData = useSelector(
    (state) => state?.pharmacy?.departmentList
  );
  const addDepartmentData = useSelector(
    (state) => state?.pharmacy?.addDepartment
  );
  const editDepartmentData = useSelector(
    (state) => state?.pharmacy?.editDepartment
  );
  const deleteDepartmentData = useSelector(
    (state) => state?.pharmacy?.deleteDepartment
  );
  const departmentList = departmentListData?.data?.data || [];

  const handleDepartmentModalClose = () => {
    setDepartmentModal(false);
  };
  const handleAddDepartmentModalClose = () => {
    setAddDepartmentModal(false);
    form.resetFields();
  };
  const showModal = (id) => {
    setIsModalOpen(true);
    setDeleteId(id);
  };
  const handleEditDepartment = (record) => {
    setAddDepartmentModal(true);
    form.setFieldValue("name", record?.name);
    setDepartment("Edit Department");
    setDepartmentId(record?.id);
  };
  const columns = [
    {
      title: "S.No",
      dataIndex: "sNo",
      render: (_, __, index) => index + 1,
    },

    {
      title: "Department",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => {
        return (
          <Row className="table-button">
            {access("department", "edit") && (
              <Col className="button-edit">
                <ButtonComponent
                  className="secondary-color"
                  icon={<EditFilled />}
                  size="small"
                  label="Edit"
                  onClick={() => handleEditDepartment(record)}
                ></ButtonComponent>
              </Col>
            )}
            {access("department", "delete") && (
              <Col className="button-edit">
                <ButtonComponent
                  type="danger"
                  icon={<DeleteFilled />}
                  size="small"
                  label={"Delete"}
                  onClick={() => showModal(record.id)}
                ></ButtonComponent>
              </Col>
            )}
          </Row>
        );
      },
    },
  ];

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );
  const onFinish = (datas) => {
    if (department === "Edit Department") {
      dispatch(editDepartmentInProgress({ datas, id: departmentId }));
    } else {
      dispatch(addDepartmentInProgress(datas));
    }

    setAddDepartmentModal(false);
    form.resetFields();
  };
  const handleOk = () => {
    setIsModalOpen(false);
    dispatch(deleteDepartmentInProgress(deleteId));
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    if (departmentModal) dispatch(getDepartmentListInProgress());
  }, [departmentModal]);
  useEffect(() => {
    if (departmentListData?.inProgress) {
      setListLoading(true);
    } else {
      setListLoading(false);
    }
  }, [departmentListData]);
  useEffect(() => {
    if (
      addDepartmentData?.inProgress ||
      editDepartmentData?.inProgress ||
      deleteDepartmentData?.inProgress
    ) {
      setListLoading(true);
    } else {
      setListLoading(false);
    }
  }, [addDepartmentData, editDepartmentData, deleteDepartmentData]);
  useEffect(() => {
    if (
      addDepartmentData?.success ||
      editDepartmentData?.success ||
      deleteDepartmentData?.success
    ) {
      dispatch(getDepartmentListInProgress());
    }
  }, [addDepartmentData, editDepartmentData, deleteDepartmentData]);
  return (
    <div>
      <Row className="p-10 Add-button">
        <Col>
          <p className="header-tag">
            {<IntlMessages id={"label.itemTransfer"} />}
          </p>
        </Col>
        <Col>
          {access("department", "noaccess") === false && (
            <ButtonComponent
              type="primary"
              label={<IntlMessages id={"Addnew.pharmacy.department"} />}
              onClick={() => setDepartmentModal(true)}
            />
          )}
          {access("itemTransfer", "add") && (
            <Link to={APP_ROUTES.ADDITEMTRANSFER}>
              <ButtonComponent
                className="secondary-color"
                label={<IntlMessages id={"Addnew.patient.addnew"} />}
                icon={<PlusCircleOutlined />}
              />
            </Link>
          )}
        </Col>
      </Row>
      <Modal
        title={
          <Row justify={"space-between"}>
            <Col>
              <Typography className="cancel-sales">Departments</Typography>
            </Col>
            <Col>
              {access("department", "add") && (
                <ButtonComponent
                  label={"New Department"}
                  type="primary"
                  onClick={() => (
                    // eslint-disable-next-line no-sequences
                    setAddDepartmentModal(true),
                    setDepartment("Add Departments")
                  )}
                />
              )}
            </Col>
          </Row>
        }
        open={departmentModal}
        width={600}
        closable={false}
        footer={[
          <Button
            key="back"
            className="secondary-color"
            onClick={handleDepartmentModalClose}
          >
            Close
          </Button>,
        ]}
      >
        <Spin indicator={antIcon} spinning={listLoading}>
          {access("department", "view") && (
            <Table
              columns={columns}
              dataSource={departmentList}
              pagination={false}
              className="responsive-table"
            />
          )}
        </Spin>
      </Modal>
      <Modal
        title={<Typography className="cancel-sales"> {department}</Typography>}
        open={addDepartmentModal}
        width={600}
        closable={false}
        footer={[]}
      >
        <Spin indicator={antIcon} spinning={addLoading}>
          <Form
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
            initialValues={{
              remember: true,
            }}
            colon={"true"}
            onFinish={onFinish}
            autoComplete="off"
            form={form}
          >
            <Row className="register-row">
              <Col xl={24} lg={24} md={12} sm={24} xs={24}>
                <FormInput
                  labelCol={"24"}
                  wrapperCol={"24"}
                  type="text"
                  name={"name"}
                  label={
                    <IntlMessages id={"Addnew.pharmacy.label.departmentName"} />
                  }
                  rules={[
                    {
                      required: true,
                      message: (
                        <IntlMessages
                          id={"Addnew.pharmacy.validator.departmentName"}
                        />
                      ),
                    },
                  ]}
                />
              </Col>
            </Row>
            <Row justify={"end"}>
              <Button key="back" type="primary" htmlType="submit">
                Save
              </Button>
              ,
              <Button
                key="back"
                className="secondary-color"
                onClick={handleAddDepartmentModalClose}
              >
                Close
              </Button>
              ,
            </Row>
          </Form>
        </Spin>
      </Modal>
      <Modal
        title="Delete Data"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Are you sure, you want to delete the Data</p>
      </Modal>
      <Divider />
    </div>
  );
};

export default ItemTransferHeader;
