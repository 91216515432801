import React from "react";
import { Card } from "antd";

import DoctorHeader from "./component/DoctorHeader";
import DoctorTable from "./component/DoctorTable";
import Auxiliary from "../../util/Auxiliary";

const DoctorScreen = () => {
  return (
    <Auxiliary>
      <Card>
        <DoctorHeader />
        <DoctorTable />
      </Card>
    </Auxiliary>
  );
};
export default DoctorScreen;
