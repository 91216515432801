import {
  ADD_PAYMENT_IN_PROGRESS,
  ADD_PAYMENT_IN_SUCCESS,
  ADD_PAYMENT_IN_ERROR,
  GET_BILLING_LIST_IN_PROGRESS,
  GET_BILLING_LIST_SUCCESS,
  GET_BILLING_LIST_ERROR,
  ADD_BILLING_IN_PROGRESS,
  ADD_BILLING_SUCCESS,
  ADD_BILLING_ERROR,
  UPDATE_BILLING_IN_PROGRESS,
  UPDATE_BILLING_SUCCESS,
  UPDATE_BILLING_ERROR,
  DELETE_BILLING_IN_PROGRESS,
  DELETE_BILLING_SUCCESS,
  DELETE_BILLING_ERROR,
  GET_BILLING_BY_INVOICE_LIST_IN_PROGRESS,
  GET_BILLING_BY_INVOICE_LIST_SUCCESS,
  GET_BILLING_BY_INVOICE_LIST_ERROR,
  GET_BILLING_BY_PATIENT_LIST_IN_PROGRESS,
  GET_BILLING_BY_PATIENT_LIST_SUCCESS,
  GET_BILLING_BY_PATIENT_LIST_ERROR,
  GET_DUE_BY_PATIENT_IN_PROGRESS,
  GET_DUE_BY_PATIENT_SUCCESS,
  GET_DUE_BY_PATIENT_ERROR,
  UNDO_BILL_IN_PROGRESS,
  UNDO_BILL_SUCCESS,
  UNDO_BILL_ERROR,
} from "../../../constants/ActionTypes";

const defaultStatus = {
  inProgress: false,
  success: false,
  error: false,
  data: [],
};
const initialState = {
  addPayments: { ...defaultStatus },
  biilingList: { ...defaultStatus },
  addBilling: { ...defaultStatus },
  updateBilling: { ...defaultStatus },
  deleteBilling: { ...defaultStatus },
  invoiceList: { ...defaultStatus },
  patientList: { ...defaultStatus },
  due: { ...defaultStatus },
  undo: { ...defaultStatus },
};
const billingPaymentReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    //ADD_PAYMENTS
    case ADD_PAYMENT_IN_PROGRESS:
      return {
        ...state,
        addPayments: { ...defaultStatus, ...payload, inProgress: true },
      };
    case ADD_PAYMENT_IN_SUCCESS:
      return {
        ...state,
        addPayments: { ...defaultStatus, ...payload, success: true },
      };
    case ADD_PAYMENT_IN_ERROR:
      return {
        ...state,
        addPayments: { ...defaultStatus, ...payload, error: true },
      };
    //GET_PAYMENTS
    case GET_BILLING_LIST_IN_PROGRESS:
      return {
        ...state,
        biilingList: { ...defaultStatus, ...payload, inProgress: true },
      };
    case GET_BILLING_LIST_SUCCESS:
      return {
        ...state,
        biilingList: { ...defaultStatus, ...payload, success: true },
      };
    case GET_BILLING_LIST_ERROR:
      return {
        ...state,
        biilingList: { ...defaultStatus, ...payload, error: true },
      };
    //ADD billing PAYMENTS
    case ADD_BILLING_IN_PROGRESS:
      return {
        ...state,
        addBilling: { ...defaultStatus, ...payload, inProgress: true },
      };
    case ADD_BILLING_SUCCESS:
      return {
        ...state,
        addBilling: { ...defaultStatus, ...payload, success: true },
      };
    case ADD_BILLING_ERROR:
      return {
        ...state,
        addBilling: { ...defaultStatus, ...payload, error: true },
      };
    //update billing PAYMENTS
    case UPDATE_BILLING_IN_PROGRESS:
      return {
        ...state,
        updateBilling: { ...defaultStatus, ...payload, inProgress: true },
      };
    case UPDATE_BILLING_SUCCESS:
      return {
        ...state,
        updateBilling: { ...defaultStatus, ...payload, success: true },
      };
    case UPDATE_BILLING_ERROR:
      return {
        ...state,
        updateBilling: { ...defaultStatus, ...payload, error: true },
      };

    //Delete billing PAYMENTS
    case DELETE_BILLING_IN_PROGRESS:
      return {
        ...state,
        deleteBilling: { ...defaultStatus, ...payload, inProgress: true },
      };
    case DELETE_BILLING_SUCCESS:
      return {
        ...state,
        deleteBilling: { ...defaultStatus, ...payload, success: true },
      };
    case DELETE_BILLING_ERROR:
      return {
        ...state,
        deleteBilling: { ...defaultStatus, ...payload, error: true },
      };

    //GET_PAYMENTS by invoice
    case GET_BILLING_BY_INVOICE_LIST_IN_PROGRESS:
      return {
        ...state,
        invoiceList: { ...defaultStatus, ...payload, inProgress: true },
      };
    case GET_BILLING_BY_INVOICE_LIST_SUCCESS:
      return {
        ...state,
        invoiceList: { ...defaultStatus, ...payload, success: true },
      };
    case GET_BILLING_BY_INVOICE_LIST_ERROR:
      return {
        ...state,
        invoiceList: { ...defaultStatus, ...payload, error: true },
      };
    //GET_PAYMENTS by patient
    case GET_BILLING_BY_PATIENT_LIST_IN_PROGRESS:
      return {
        ...state,
        patientList: { ...defaultStatus, ...payload, inProgress: true },
      };
    case GET_BILLING_BY_PATIENT_LIST_SUCCESS:
      return {
        ...state,
        patientList: { ...defaultStatus, ...payload, success: true },
      };
    case GET_BILLING_BY_PATIENT_LIST_ERROR:
      return {
        ...state,
        patientList: { ...defaultStatus, ...payload, error: true },
      };

    //GET due by patient
    case GET_DUE_BY_PATIENT_IN_PROGRESS:
      return {
        ...state,
        due: { ...defaultStatus, ...payload, inProgress: true },
      };
    case GET_DUE_BY_PATIENT_SUCCESS:
      return {
        ...state,
        due: { ...defaultStatus, ...payload, success: true },
      };
    case GET_DUE_BY_PATIENT_ERROR:
      return {
        ...state,
        due: { ...defaultStatus, ...payload, error: true },
      };

    // undo bill
    case UNDO_BILL_IN_PROGRESS:
      return {
        ...state,
        undo: { ...defaultStatus, ...payload, inProgress: true },
      };
    case UNDO_BILL_SUCCESS:
      return {
        ...state,
        undo: { ...defaultStatus, ...payload, success: true },
      };
    case UNDO_BILL_ERROR:
      return {
        ...state,
        undo: { ...defaultStatus, ...payload, error: true },
      };
    default:
      return state;
  }
};

export default billingPaymentReducer;
