import React from "react";
import Widget from "../Widget";
const ColorCard = (props) => {
  const { cardColor, count, title } = props;
  return (
    <Widget styleName={`gx-text-white gx-card-1367-p ${cardColor}`}>
      <h1 className="gx-font-weight-semi-bold gx-text-white">{count}</h1>
      <h2 className="gx-text-uppercase gx-fs-xl gx-mb-0 gx-text-white">
        {title}
      </h2>
    </Widget>
  );
};

export default ColorCard;
