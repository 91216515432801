import { Card, Col, Form, Row, Spin, Typography , Switch  } from 'antd';
import React, { useEffect } from 'react'
import IntlMessages from '../../util/IntlMessages';
import {  LoadingOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getOrgDetailsByIdInProgress } from '../../appRedux/super-admin/SuperAdminAction';
import { updatePaymentModeInProgress } from '../../appRedux/doctor/actions/organisation';


function Settings() {
   const org_id = localStorage.getItem("org_id");
   const dispatch = useDispatch();
    const [form] = Form.useForm();
    const orgDetails = useSelector(
      (state) => state?.superAdmin?.getOrgDetailsById?.data?.data
    )?.payment_mode
    ;
    const { updatePaymentMode} = useSelector(
      (state) => state?.org

    );

    const onFinish = (datas) => {
  const payload = {
    formData:   {
      "payment_mode": datas === true ?  1 :2
  },
  id:org_id
  }
 

  dispatch(updatePaymentModeInProgress(payload))
     
    };
   
    useEffect(()=>{dispatch(getOrgDetailsByIdInProgress(org_id  ))},[dispatch, org_id, updatePaymentMode.success])
    useEffect(() => {
      form.setFieldValue("payment_mode", orgDetails);
    }, [form, orgDetails]);
    const antIcon = (
      <LoadingOutlined
        style={{
          fontSize: 72,
        }}
        spin
      />
    );
  
   
  
   
    return (
      <>
        <Spin indicator={antIcon} spinning={updatePaymentMode?.inProgress}>
          <Card>
            <Form
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              initialValues={{
                remember: true,
              }}
            //   colon={"true"}
              onFinish={onFinish}
              autoComplete="off"
              form={form}
            >
              <Row>
                {" "}
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className="my-20">
                  <Typography className="header-tag">
                      <IntlMessages id={"sidebar.SETTINGS"} />
                  </Typography>
                </Col>{" "}
              
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                  <Form.Item
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    label={<IntlMessages id={"Addnew.admin.label.Payment_Mode"} />}
                    name={"payment_mode"}
                  >
                    <Switch
                      checkedChildren="ONLine"
                      unCheckedChildren="OFFLINE"
                      onChange={onFinish}
                      checked={orgDetails === 1 ? true : false}
                    />
                  </Form.Item>
                </Col>
           
             
              </Row>
            
            </Form>
         
          </Card>
        </Spin>
      </>
    );
  };
  

export default Settings