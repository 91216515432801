import React from "react";

import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

import AddPurchase from "./AddPurchase";

const EditPurchase = () => {
  const location = useLocation();
  const supplierData = location.state?.supplierData;

  return <AddPurchase editPurchaseData={supplierData} editPurchases="true" />;
};

export default EditPurchase;
