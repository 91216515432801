import React, { Fragment } from 'react'
import { Table } from 'antd'

const FormComponent = ({columns,dataSource,pagination,bordered,size,showHeader}) => {
  return (
    <Fragment>
        <Table columns={columns} dataSource={dataSource} pagination={pagination} bordered={bordered}
            size={size} scroll={{ x: true }} showHeader={showHeader}/>
    </Fragment>
  )
}

export default FormComponent