import React from "react";
import { Col, Row, Divider } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import IntlMessages from "../../../util/IntlMessages";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import { APP_ROUTES } from "../../../constants/Routes";
import { access } from "../../../util/utilz";

const MedicineListHeader = () => {
  return (
    <div>
      <Row className="p-10 Add-button">
        <Col>
          <p className="header-tag">{<IntlMessages id={"label.products"} />}</p>
        </Col>
        {access("medicineList", "add") && (
          <Col>
            <Link to={APP_ROUTES.ADDMEDICINE}>
              <ButtonComponent
                type="primary"
                label={<IntlMessages id={"Addnew.product"} />}
                icon={<PlusCircleOutlined />}
                className={"mr-5 "}
              />
            </Link>
            <Link to={APP_ROUTES.BULKMEDICINE}>
              <ButtonComponent
                type="primary"
                label={<IntlMessages id={"Addnew.bulkMedicine"} />}
                icon={<PlusCircleOutlined />}
                className={"secondary-color"}
              />
            </Link>
          </Col>
        )}
      </Row>

      <Divider />
    </div>
  );
};

export default MedicineListHeader;
