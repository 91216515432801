import {
  ADD_ROLES_IN_ERROR,
  ADD_ROLES_IN_PROGRESS,
  ADD_ROLES_IN_SUCCESS,
  DELETE_ROLESlIST_IN_ERROR,
  DELETE_ROLESlIST_IN_PROGRESS,
  DELETE_ROLESlIST_IN_SUCCESS,
  GET_ROLESlIST_IN_ERROR,
  GET_ROLESlIST_IN_PROGRESS,
  GET_ROLESlIST_IN_SUCCESS,
  UPDATE_ROLESlIST_IN_ERROR,
  UPDATE_ROLESlIST_IN_PROGRESS,
  UPDATE_ROLESlIST_IN_SUCCESS,
} from "../../../../constants/ActionTypes";

// ADD ROLES
export const addRolesInProgress = (payload) => ({
  type: ADD_ROLES_IN_PROGRESS,
  payload: payload,
});

export const addRolesSuccess = (payload) => ({
  type: ADD_ROLES_IN_SUCCESS,
  payload: payload,
});

export const addRolesError = (payload) => ({
  type: ADD_ROLES_IN_ERROR,
  payload: payload,
});
// GET ROLES

export const getRolesListInProgress = (payload) => ({
  type: GET_ROLESlIST_IN_PROGRESS,
  payload: payload,
});

export const getRolesListInSuccess = (payload) => ({
  type: GET_ROLESlIST_IN_SUCCESS,
  payload: payload,
});

export const getRolesListInError = (payload) => ({
  type: GET_ROLESlIST_IN_ERROR,
  payload: payload,
});
// Delete DONER
export const deleteROLESInProgress = (payload) => ({
  type: DELETE_ROLESlIST_IN_PROGRESS,
  payload: payload,
});

export const deleteROLESSuccess = (payload) => ({
  type: DELETE_ROLESlIST_IN_SUCCESS,
  payload: payload,
});

export const deleteROLESError = (payload) => ({
  type: DELETE_ROLESlIST_IN_ERROR,
  payload: payload,
});
// UPDATE ROLES
export const updateRolesInProgress = (payload) => ({
  type: UPDATE_ROLESlIST_IN_PROGRESS,
  payload: payload,
});

export const updateRolesSuccess = (payload) => ({
  type: UPDATE_ROLESlIST_IN_SUCCESS,
  payload: payload,
});

export const updateRolesError = (payload) => ({
  type: UPDATE_ROLESlIST_IN_ERROR,
  payload: payload,
});
