import React, { useEffect, useState } from "react";
import { Col, Form, Input, Radio, Row, Tag } from "antd";
import moment from "moment";
import TimeSlotDates from "../../Appoinment/components/AllAppoinments/TimeSlotDates";
const TimeSlot = ({ form }) => {
  const [dates, setDates] = useState([]);

  useEffect(() => {
    let data = [];
    const currentDate = moment();
    for (let i = 0; i <= 5; i++) {
      data[i] = moment(currentDate).add(i, "days");
    }
    setDates(data);
  }, []);

  const handleSlot = (val) => {
    form.setFieldsValue({ slot: val });
  };
  const slotValue = Form.useWatch("slot", form);
  return (
    <Radio.Group style={{ display: "grid" }}>
      <Row className="slot-row">
        {dates.map((date, index) => (
          <Col>
            <Tag color="#3F51B5" className="days-tag">
              {moment(date).format("ddd,MMM-DD")}
            </Tag>
            <TimeSlotDates
              date={moment(date).format("DD-MM-YYYY")}
              handleSlot={handleSlot}
              index={index}
              key={index}
              selectedValue={slotValue}
            />
          </Col>
        ))}
      </Row>
      <Form.Item name={"slot"} className={"d-none"}>
        <Input />
      </Form.Item>
    </Radio.Group>
  );
};

export default TimeSlot;
