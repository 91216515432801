import React from "react";
import { Card } from "antd";
import SalesHeader from "./SalesHeader";
import SalesTable from "./SalesTable";

const SalesScreen = () => {
  return (
    <Card>
      <SalesHeader />
      <SalesTable />
    </Card>
  );
};

export default SalesScreen;
