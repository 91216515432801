import {
  PATIENT_LIST_IN_FAILURE,
  PATIENT_LIST_IN_PROGRESS,
  PATIENT_LIST_IN_SUCCESS,
} from "../../../constants/ActionTypes";

const defaultStatus = {
  inProgress: false,
  success: false,
  error: false,
  data: null,
};
const initialState = {
  patientList: { ...defaultStatus },
};

const PatientListeducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case PATIENT_LIST_IN_PROGRESS:
      return {
        ...state,
        patientList: { ...defaultStatus, inProgress: true, ...payload },
      };
    case PATIENT_LIST_IN_SUCCESS:
      return {
        ...state,
        patientList: { ...defaultStatus, ...payload, success: true },
      };
    case PATIENT_LIST_IN_FAILURE:
      return {
        ...state,
        patientList: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };

    default:
      return state;
  }
};

export default PatientListeducer;
