import React, { useEffect, useState } from "react";
import { Row, Col, Spin, Card, Typography, Table } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import IntlMessages from "../../../util/IntlMessages";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import { useDispatch, useSelector } from "react-redux";
import { patientListInProgress } from "../../../appRedux/doctor/actions/patient";
import TableComponent from "../../../components/Table/TableComponent";

const PatientList = ({ selectedPatientId, onPatientSelect }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const patientData = useSelector((state) => state.patient.getList);
  const patientList = patientData?.data?.data;
  const handlePatientSelect = (record) => {
    if (onPatientSelect) {
      onPatientSelect(record.id);
    }
  };

  const columns = [
    {
      title: "S.No",
      dataIndex: "sno",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Patient Serial Number",
      dataIndex: "patient_serial_no",
      key: "patient_serial_no",
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
    },

    {
      title: "Action",
      dataIndex: "Action",
      render: (_, record) => (
        <Row className="table-button">
          <Col className="button-edit">
            {selectedPatientId === record.id ? (
              <ButtonComponent
                className={"secondary-color"}
                label={"Selected Patient"}
                onClick={() => handlePatientSelect(record)}
              />
            ) : (
              <ButtonComponent
                type="primary"
                label={"Select Patient"}
                onClick={() => handlePatientSelect(record)}
              />
            )}
          </Col>
        </Row>
      ),
    },
  ];

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );

  useEffect(() => {
    if (patientData?.inProgress) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [patientData]);

  useEffect(() => {
    dispatch(patientListInProgress(1));
  }, [dispatch]);

  const [filteredUsers, setFilteredUsers] = useState("");
  const totalRecords = patientData?.data?.totalRecords;
  const handleSelectChange = (value) => {
    if (value === "all") {
      setFilteredUsers(patientList);
    } else {
      const newData = patientList.slice(0, value);
      setFilteredUsers(newData);
    }
  };
  const handlePage = (page) => {
    const payload = {
      pageNo: page,
    };
    dispatch(patientListInProgress(payload));
  };
  return (
    <Card>
      <Row className="p-10 Add-button">
        <Col>
          <Typography className="header-tag">
            {<IntlMessages id={"Addnew.patientList"} />}
          </Typography>
        </Col>
      </Row>
      <Spin indicator={antIcon} spinning={loading}>
        <TableComponent
          columns={columns}
          dataSource={filteredUsers ? filteredUsers : patientList}
          rowselector={handleSelectChange}
          itemList={patientList}
          filteredUsers={filteredUsers}
          setFilteredUsers={setFilteredUsers}
          pagination={{
            total: totalRecords,
            onChange: (page) => handlePage(page),
          }}
          field={"firstName"}
          documentTitle="Patient List"
          disableTableHeaderButtons={true}
        />
      </Spin>
    </Card>
  );
};

export default PatientList;
