import React from "react";

import { Card } from "antd";
import UsersHeader from "./Components/UsersHeader";
import UsersTable from "./Components/UsersTable";

const UsersScreen = () => {
  return (
    <Card>
      <UsersHeader />
      <UsersTable />
    </Card>
  );
};

export default UsersScreen;
