import {
  ADD_PRESCRIPTION_IN_ERROR,
  ADD_PRESCRIPTION_IN_PROGRESS,
  ADD_PRESCRIPTION_IN_SUCCESS,
  CANCEL_PRESCRIPTION_IN_ERROR,
  CANCEL_PRESCRIPTION_IN_PROGRESS,
  CANCEL_PRESCRIPTION_IN_SUCCESS,
  PRESCRIPTION_BY_PATIENT_IN_ERROR,
  PRESCRIPTION_BY_PATIENT_IN_PROGRESS,
  PRESCRIPTION_BY_PATIENT_IN_SUCCESS,
  PRESCRIPTION_DATA_IN_ERROR,
  PRESCRIPTION_DATA_IN_PROGRESS,
  PRESCRIPTION_DATA_IN_SUCCESS,
  PRESCRIPTION_GET_ID_IN_ERROR,
  PRESCRIPTION_GET_ID_IN_PROGRESS,
  PRESCRIPTION_GET_ID_IN_SUCCESS,
  PRESCRIPTION_LIST_IN_ERROR,
  PRESCRIPTION_LIST_IN_PROGRESS,
  PRESCRIPTION_LIST_IN_SUCCESS,
  SAVE_PRESCRIPTION_IN_ERROR,
  SAVE_PRESCRIPTION_IN_PROGRESS,
  SAVE_PRESCRIPTION_IN_SUCCESS,
  SEEN_PRESCRIPTION_IN_ERROR,
  SEEN_PRESCRIPTION_IN_PROGRESS,
  SEEN_PRESCRIPTION_IN_SUCCESS,
  SET_PRESCRIPTION_ADD,
  SET_PRESCRIPTION_SUBMIT,
  SUBMIT_PRESCRIPTION_IN_ERROR,
  SUBMIT_PRESCRIPTION_IN_PROGRESS,
  SUBMIT_PRESCRIPTION_IN_SUCCESS,
} from "../../../constants/ActionTypes";

export const addPrescriptionInProgress = (payload) => {
  return {
    type: ADD_PRESCRIPTION_IN_PROGRESS,
    payload: payload,
  };
};
export const addPrescriptionInSuccess = (payload) => ({
  type: ADD_PRESCRIPTION_IN_SUCCESS,
  payload: payload,
});

export const addPrescriptionInError = (payload) => ({
  type: ADD_PRESCRIPTION_IN_ERROR,
  payload: payload,
});
export const prescriptionListInProgress = (payload) => ({
  type: PRESCRIPTION_LIST_IN_PROGRESS,
  payload: payload,
});

export const prescriptionListInSuccess = (payload) => ({
  type: PRESCRIPTION_LIST_IN_SUCCESS,
  payload: payload,
});

export const prescriptionListInError = (payload) => ({
  type: PRESCRIPTION_LIST_IN_ERROR,
  payload: payload,
});

export const prescriptionDataInProgress = (payload) => ({
  type: PRESCRIPTION_DATA_IN_PROGRESS,
  payload: payload,
});

export const PrescriptionDataInSuccess = (payload) => ({
  type: PRESCRIPTION_DATA_IN_SUCCESS,
  payload: payload,
});

export const prescriptionDataInError = (payload) => ({
  type: PRESCRIPTION_DATA_IN_ERROR,
  payload: payload,
});
export const prescriptionByPatientInProgress = (payload) => ({
  type: PRESCRIPTION_BY_PATIENT_IN_PROGRESS,
  payload: payload,
});

export const prescriptionByPatientInSuccess = (payload) => ({
  type: PRESCRIPTION_BY_PATIENT_IN_SUCCESS,
  payload: payload,
});

export const prescriptionByPatientInError = (payload) => ({
  type: PRESCRIPTION_BY_PATIENT_IN_ERROR,
  payload: payload,
});
export const savePrescriptionInProgress = (payload) => ({
  type: SAVE_PRESCRIPTION_IN_PROGRESS,
  payload: payload,
});

export const savePrescriptionInSuccess = (payload) => ({
  type: SAVE_PRESCRIPTION_IN_SUCCESS,
  payload: payload,
});

export const savePrescriptionInError = (payload) => ({
  type: SAVE_PRESCRIPTION_IN_ERROR,
  payload: payload,
});

export const cancelPrescriptionInProgress = (payload) => ({
  type: CANCEL_PRESCRIPTION_IN_PROGRESS,
  payload: payload,
});

export const cancelPrescriptionInSuccess = (payload) => ({
  type: CANCEL_PRESCRIPTION_IN_SUCCESS,
  payload: payload,
});

export const cancelPrescriptionInError = (payload) => ({
  type: CANCEL_PRESCRIPTION_IN_ERROR,
  payload: payload,
});

export const submitPrescriptionInProgress = (payload) => ({
  type: SUBMIT_PRESCRIPTION_IN_PROGRESS,
  payload: payload,
});

export const submitPrescriptionInSuccess = (payload) => ({
  type: SUBMIT_PRESCRIPTION_IN_SUCCESS,
  payload: payload,
});

export const submitPrescriptionInError = (payload) => ({
  type: SUBMIT_PRESCRIPTION_IN_ERROR,
  payload: payload,
});

export const submitPrescription = (payload) => ({
  type: SET_PRESCRIPTION_SUBMIT,
  payload: payload,
});
export const addPrescription = (payload) => ({
  type: SET_PRESCRIPTION_ADD,
  payload: payload,
});

export const seenPrescriptionInProgress = (payload) => ({
  type: SEEN_PRESCRIPTION_IN_PROGRESS,
  payload: payload,
});

export const seenPrescriptionInSuccess = (payload) => ({
  type: SEEN_PRESCRIPTION_IN_SUCCESS,
  payload: payload,
});

export const seenPrescriptionInError = (payload) => ({
  type: SEEN_PRESCRIPTION_IN_ERROR,
  payload: payload,
});

export const PrescriptionGetIdInProgress = (payload) => ({
  type:  PRESCRIPTION_GET_ID_IN_PROGRESS,
  payload: payload,
});

export const PrescriptionGetIdInSuccess = (payload) => ({
  type: PRESCRIPTION_GET_ID_IN_SUCCESS,
  payload: payload,
});

export const PrescriptionGetIdInError = (payload) => ({
  type: PRESCRIPTION_GET_ID_IN_ERROR,
  payload: payload,
});