import { Button, Col, Form, Modal, Row, Typography } from "antd";
import React from "react";
import FormInput from "../../../components/Input/FormInput";

const CancleModal = ({ isModalOpen, handleConformCancle, handleSubmit }) => {
  const [form] = Form.useForm();
  return (
    <Modal
      title={
        <Typography className="cancle-payment">Cancel Payment </Typography>
      }
      open={isModalOpen}
      width={"75%"}
      closable={false}
      footer={[
        <Button
          type="danger"
          onClick={handleConformCancle}
          className="close-button"
        >
          Close
        </Button>,
      ]}
    >
      <Form form={form} onFinish={handleSubmit}>
        <FormInput
          type="text"
          name={"reason"}
          label={"Reason"}
          rules={[
            {
              required: true,
              message: "Enter the reason",
            },
          ]}
        />
        <Row>
          <Col xs={24} className="ant-col-button">
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default CancleModal;
