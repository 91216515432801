import { axiosInstance } from "../../../../util/utilz";

const TrackerGetByAppointment = (payload) => {
  const token = localStorage.getItem("user_id");
  const params = {
    references_doctor_id:payload?.references_doctor_id,
    appointment_id:payload?.appointment_id,
    pageNo: payload?.page,
    pageSize: 10,
    pagination_required: true,
  }
  return axiosInstance.request({
    url: "/tracker/doctor",
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    params: params,
  });
};

const getDoctorsBySpecialist = (specialization_id) => {
  const tokens = localStorage.getItem("user_id") ;
  const organization_id = localStorage.getItem("org_id");
  return axiosInstance.request({
    url: `/doctors/orgspe`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${tokens}`,
      "Content-Type": "application/json",
    },
    params: {
      organization_id:organization_id,
      specialization_id:specialization_id,
      pagination_required:false,
    }
  });
};
const addTracker = (payload) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: "/tracker/add",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
   data:payload
  });
};


const TrackerGetByOrginations = (payload) => {
  const token = localStorage.getItem("user_id");
  const params = {
    organization_id:payload?.organization_id,
    pageNo: payload?.page,
    pageSize: 10,
    pagination_required: true,
  }
  return axiosInstance.request({
    url: "/tracker/getbyorganization",
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    params: params,
  });
};


const TrackerUpdateReport = (payload) => {
  const token = localStorage.getItem("user_id");
  return axiosInstance.request({
    url: "/tracker/report",
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  data:payload
  });
};

const trackerStatusUpdate = (data) => {
  console.log("data2", data)
 const tokens = localStorage.getItem("user_id") 
  return axiosInstance.request({
    url: `/tracker/status/${data?.id}`,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${tokens}`,
      "Content-Type": "application/json",
    },
    data:data?.data
  });
};
export {
  TrackerGetByAppointment,
  getDoctorsBySpecialist,
  addTracker,
  TrackerGetByOrginations,
  TrackerUpdateReport,
  trackerStatusUpdate
};
