import React, { Fragment } from "react";
import { Avatar } from "antd";
import { UserAddOutlined } from "@ant-design/icons";
import { CalendarOutlined, ForkOutlined } from "@ant-design/icons";
const CalendarItem = ({ product, grid }) => {
  const { date, time, doctorname } = product;

  return (
    <Fragment>
      <p>Appoinment</p>

      <div
        className={`gx-product-item  ${
          grid ? "gx-product-vertical" : "gx-product-horizontal  "
        }`}
      >
        <div className="gx-product-image avatarimage  ">
          <div className="gx-grid-thumb-equal">
            <span className="gx-link gx-grid-thumb-cover">
              <Avatar
                size={{
                  xl: 45,
                }}
                style={{
                  backgroundColor: "#8dd7d6",
                }}
                icon={<ForkOutlined />}
              />
            </span>
          </div>
        </div>

        <div className="gx-product-body">
          <div className="ant-row-flex">
            <CalendarOutlined style={{ fontSize: "20px" }} />
            <p className="gx-d-inline-block gx-ml-2 cardfont">{date}</p>
          </div>
          <div className="ant-row-flex gx-mb-1">
            <UserAddOutlined style={{ fontSize: "20px" }} />
            <p className="gx-d-inline-block gx-ml-2 cardfont">{doctorname}</p>
          </div>
          <p>
            {" "}
            <p className="gx-d-inline-block ">{time}</p>
          </p>
        </div>
      </div>
    </Fragment>
  );
};

export default CalendarItem;
