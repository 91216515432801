import {
  ADD_CONSULTATION_ERROR,
  ADD_CONSULTATION_IN_PROGRESS,
  ADD_CONSULTATION_SUCCESS,
  GET_CONSULTATION_ERROR,
  GET_CONSULTATION_IN_PROGRESS,
  GET_CONSULTATION_SUCCESS,
  UPDATE_CONSULTATION_ERROR,
  UPDATE_CONSULTATION_IN_PROGRESS,
  UPDATE_CONSULTATION_SUCCESS,
} from "../../../constants/ActionTypes";

const defaultStatus = {
  inProgress: false,
  success: false,
  error: false,
  data: null,
};
const initialState = {
  addconsultation: { ...defaultStatus },
  updateconsultation: { ...defaultStatus },
  getconsultation: { ...defaultStatus },
};

const ConsultationReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ADD_CONSULTATION_IN_PROGRESS:
      return {
        ...state,
        addconsultation: { ...defaultStatus, ...payload, inProgress: true },
      };
    case ADD_CONSULTATION_SUCCESS:
      return {
        ...state,
        addconsultation: { ...defaultStatus, ...payload, success: true },
      };
    case ADD_CONSULTATION_ERROR:
      return {
        ...state,
        addconsultation: { ...defaultStatus, ...payload, error: true },
      };
    case UPDATE_CONSULTATION_IN_PROGRESS:
      return {
        ...state,
        updateconsultation: { ...defaultStatus, ...payload, inProgress: true },
      };
    case UPDATE_CONSULTATION_SUCCESS:
      return {
        ...state,
        updateconsultation: { ...defaultStatus, ...payload, success: true },
      };
    case UPDATE_CONSULTATION_ERROR:
      return {
        ...state,
        updateconsultation: { ...defaultStatus, ...payload, error: true },
      };
    case GET_CONSULTATION_IN_PROGRESS:
      return {
        ...state,
        getconsultation: { ...defaultStatus, ...payload, inProgress: true },
      };
    case GET_CONSULTATION_SUCCESS:
      return {
        ...state,
        getconsultation: { ...defaultStatus, ...payload, success: true },
      };
    case GET_CONSULTATION_ERROR:
      return {
        ...state,
        getconsultation: { ...defaultStatus, ...payload, error: true },
      };
    default:
      return state;
  }
};

export default ConsultationReducer;
