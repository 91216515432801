import React from "react";
import { Col, Row, Divider } from "antd";

import { PlusCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import IntlMessages from "../../../util/IntlMessages";
import { APP_ROUTES } from "../../../constants/Routes";
import ButtonComponent from "../../../components/Button/ButtonComponent";

const SubscriptionHeader = () => {
  return (
    <div>
      <Row className="p-10 Add-button">
        <Col>
          <p className="header-tag">
            {<IntlMessages id={"Addnew.subscription"} />}
          </p>
        </Col>
        <Col>
          <Link to={APP_ROUTES.ADD_SUBSCRIPTION}>
            <ButtonComponent
              className="buttons"
              icon={<PlusCircleOutlined />}
              type="primary"
              label={<IntlMessages id={"Addnew.newSubscription"} />}
            />
          </Link>
        </Col>
      </Row>

      <Divider />
    </div>
  );
};

export default SubscriptionHeader;
