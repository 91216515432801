import React, { useEffect, useState } from "react";
import NewBillingPayment from "./NewBillingPayment";
import { Form } from "antd";
import Auxiliary from "../../../util/Auxiliary";
import BillingFromHeader from "./BillingFromHeader";
import { useLocation, useHistory } from "react-router-dom";
import { updateBillingInProgress } from "../../../appRedux/doctor/actions/addPayment";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { APP_ROUTES } from "../../../constants/Routes";

const EditForm = () => {
  const [form] = Form.useForm();
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [navigate, setNavigate] = useState(false);
  const { DATE, organization_id, id, patient_id } = location?.state;
  const { billingPaymentreducer } = useSelector((state) => state);
  const onFinish = (data) => {
    let paid_amount = 0;
    data?.BillingPayments?.forEach((dat) => {
      if (dat.paid_amount) {
        paid_amount += Number(dat.paid_amount);
      }
    });
    const modifiedData = [
      {
        ...data,
        id: id,
        BillingPayments: data.BillingPayments.map((payment) => ({
          ...payment,

          Date: payment?.Date ? payment?.Date : DATE,
        })),
        grand_total: data.sub_total - data.discount,
        organization_id: organization_id,
        DATE: DATE,
        paid_amount: paid_amount,
      },
    ];
    dispatch(updateBillingInProgress({ package_details: modifiedData }));
    setNavigate(true);
  };
  useEffect(() => {
    if (billingPaymentreducer?.updateBilling?.success && navigate) {
      history.push(APP_ROUTES.BILLINGS);
      setNavigate(false);
    }
  }, [billingPaymentreducer?.updateBilling?.success && navigate]);
  useEffect(() => {
    form.setFieldsValue(location.state);
    form.setFieldValue(
      "billingPackage",
      location.state?.PatientBillingSubPackages.map(
        (val) => val?.sub_package_id
      )
    );
  }, [location.state]);
  return (
    <Auxiliary>
      <BillingFromHeader title="Edit Payment" patientId={patient_id} />
      <NewBillingPayment form={form} onFinish={onFinish} editData={"true"} />
    </Auxiliary>
  );
};

export default EditForm;
