import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import Settings from "./Settings";
import Auth from "./Auth";
import Notes from "./Notes";
import Contact from "./Contact";
import Common from "./Common";
import OrganisationReducer from "./Organisation";
import DesignationReducer from "./Designation";
import SpecailizationReducer from "./Specialization";
import patientAppointmentReducer from "../../Patient/Reducer/Reducer";
import RegisterReducer from "./RegisterReducer";
import doctorReducer from "../../admin/Doctor/reducers/Doctor";
import patientReducer from "../../admin/patient/reducer/reducer";
import appointmentReducer from "./Appointment";
import qualificationReducer from "./Qualification";
import profileReducer from "./Profile";
import holidayReducer from "./Holiday";
import ScheduleReducer from "./Schedule";
import userReducer from "./User";
import prescriptionReducer from "./prescriptionReducer";
import PatientListeducer from "./PatientListeducer";
import DoctorListReducer from "./DoctorListReducer";
import AuthReducer from "./Auth";
import AuthReducer1 from "./AuthReducer1.js";
import ConsultationReducer from "./ConsultationReducer";
import Tablets from "./Tablets";
import familyMemebrReducer from "./FamilyMemberReducer.js";
import billingPackageReducer from "./BillingPackagesReducers.js";
import billingPaymentreducer from "./billingPayment.js";
// import pharmacyReducer from "../../pharmacy/pharmacyReducer.js";
import rolesReducer from "../../admin/roles/reducers/rolesReducer.js";
import teamMemberReducer from "../../admin/teamMembers/reducers/teamMemberReducer.js";

import adminDashboardReducer from "../../admin/DashBorad/reducer/dashboradreducers.js";
import PharmacyReducer from "../../Pharmacy/PharmacyReducer.js";
import dashBoardReducer from "./DashBoard.js";
import appointmentQuestionsReducer from "./AppointmentQuestionsReducers.js";
import SuperAdminReducer from "../../super-admin/SuperAdminReducer.js";
import WardReducer from "../../Ward/WardReducer.js";
import TrackerReducer from "./Tracker.js";


const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    auth: Auth,
    notes: Notes,
    contact: Contact,
    common: Common,
    org: OrganisationReducer,
    designation: DesignationReducer,
    specialization: SpecailizationReducer,
    Users: userReducer,
    patient: patientReducer,
    patientList: PatientListeducer,
    doctorList: DoctorListReducer,
    patientAppointment: patientAppointmentReducer,

    register: RegisterReducer,
    doctor: doctorReducer,
    appointment: appointmentReducer,
    qualification: qualificationReducer,
    profile: profileReducer,
    holiday: holidayReducer,
    schedule: ScheduleReducer,
    prescription: prescriptionReducer,
    authData: AuthReducer1,
    consultation: ConsultationReducer,
    tablets: Tablets,
    familyMember: familyMemebrReducer,
    billingPackages: billingPackageReducer,
    billingPaymentreducer: billingPaymentreducer,
    // pharmacy: pharmacyReducer,
    accessRoles: rolesReducer,
    teamMember: teamMemberReducer,
    pharmacy: PharmacyReducer,
    dashBoard: dashBoardReducer,
    appointmentQuestions: appointmentQuestionsReducer,
    superAdmin: SuperAdminReducer,
    ward: WardReducer,
    adminDashboard:adminDashboardReducer,
    tracker:TrackerReducer,
  });

export default rootReducer;
