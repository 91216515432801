import {
  ADD_BLOCK_ERROR,
  ADD_BLOCK_IN_PROGRESS,
  ADD_BLOCK_SUCCESS,
  ADD_ROOM_ERROR,
  ADD_ROOM_IN_PROGRESS,
  ADD_ROOM_SUCCESS,
  ADD_WARD_ERROR,
  ADD_WARD_IN_PROGRESS,
  ADD_WARD_SUCCESS,
  ALL_DOCTOR_LIST_ERROR,
  ALL_DOCTOR_LIST_IN_PROGRESS,
  ALL_DOCTOR_LIST_SUCCESS,
  DEFAULT_BLOCK,
  DEFAULT_ROOM,
  DEFAULT_ROOM_BOOKING,
  DEFAULT_SHIFT_ROOM,
  DEFAULT_WARD,
  DELETE_BLOCK_ERROR,
  DELETE_BLOCK_IN_PROGRESS,
  DELETE_BLOCK_SUCCESS,
  DELETE_ROOM_ERROR,
  DELETE_ROOM_IN_PROGRESS,
  DELETE_ROOM_SUCCESS,
  DELETE_WARD_ERROR,
  DELETE_WARD_IN_PROGRESS,
  DELETE_WARD_SUCCESS,
  EDIT_BLOCK_ERROR,
  EDIT_BLOCK_IN_PROGRESS,
  EDIT_BLOCK_SUCCESS,
  EDIT_ROOM_ERROR,
  EDIT_ROOM_IN_PROGRESS,
  EDIT_ROOM_SUCCESS,
  EDIT_WARD_ERROR,
  EDIT_WARD_IN_PROGRESS,
  EDIT_WARD_SUCCESS,
  GET_AVAILABLE_ROOM_LIST_IN_FAILURE,
  GET_AVAILABLE_ROOM_LIST_IN_PROGRESS,
  GET_AVAILABLE_ROOM_LIST_IN_SUCCESS,
  GET_BLOCK_LIST_ERROR,
  GET_BLOCK_LIST_IN_PROGRESS,
  GET_BLOCK_LIST_SUCCESS,
  GET_BOOKED_ROOM_LIST_IN_FAILURE,
  GET_BOOKED_ROOM_LIST_IN_PROGRESS,
  GET_BOOKED_ROOM_LIST_IN_SUCCESS,
  GET_ROOM_LIST_ERROR,
  GET_ROOM_LIST_IN_PROGRESS,
  GET_ROOM_LIST_SUCCESS,
  GET_TOTAL_ROOM_LIST_IN_FAILURE,
  GET_TOTAL_ROOM_LIST_IN_PROGRESS,
  GET_TOTAL_ROOM_LIST_IN_SUCCESS,
  GET_WARD_LIST_BY_BLOCK_ERROR,
  GET_WARD_LIST_BY_BLOCK_IN_PROGRESS,
  GET_WARD_LIST_BY_BLOCK_SUCCESS,
  GET_WARD_LIST_ERROR,
  GET_WARD_LIST_IN_PROGRESS,
  GET_WARD_LIST_SUCCESS,
  ROOM_BOOKING_IN_FAILURE,
  ROOM_BOOKING_IN_PROGRESS,
  ROOM_BOOKING_IN_SUCCESS,
  ROOM_DISCHARGE_IN_FAILURE,
  ROOM_DISCHARGE_IN_PROGRESS,
  ROOM_DISCHARGE_IN_SUCCESS,
  ROOM_SHIFT_IN_FAILURE,
  ROOM_SHIFT_IN_PROGRESS,
  ROOM_SHIFT_IN_SUCCESS,
  WARD_DASHBOARD_COUNT_IN_FAILURE,
  WARD_DASHBOARD_COUNT_IN_PROGRESS,
  WARD_DASHBOARD_COUNT_IN_SUCCESS,
} from "../../constants/ActionTypes";

export const getBlockListInProgress = (payload) => {
  console.log("action");
  return {
    type: GET_BLOCK_LIST_IN_PROGRESS,
    payload: payload,
  };
};
export const getBlockListSuccess = (payload) => {
  return {
    type: GET_BLOCK_LIST_SUCCESS,
    payload: payload,
  };
};
export const getBlockListError = (payload) => {
  return {
    type: GET_BLOCK_LIST_ERROR,
    payload: payload,
  };
};
export const addBlockInProgress = (payload) => {
  console.log("action", payload);
  return {
    type: ADD_BLOCK_IN_PROGRESS,
    payload: payload,
  };
};
export const addBlockSuccess = (payload) => {
  return {
    type: ADD_BLOCK_SUCCESS,
    payload: payload,
  };
};
export const addBlockError = (payload) => {
  return {
    type: ADD_BLOCK_ERROR,
    payload: payload,
  };
};
export const editBlockInProgress = (payload) => {
  return {
    type: EDIT_BLOCK_IN_PROGRESS,
    payload: payload,
  };
};
export const editBlockSuccess = (payload) => {
  return {
    type: EDIT_BLOCK_SUCCESS,
    payload: payload,
  };
};
export const editBlockError = (payload) => {
  return {
    type: EDIT_BLOCK_ERROR,
    payload: payload,
  };
};
export const deleteBlockInProgress = (payload) => {
  return {
    type: DELETE_BLOCK_IN_PROGRESS,
    payload: payload,
  };
};
export const deleteBlockSuccess = (payload) => {
  return {
    type: DELETE_BLOCK_SUCCESS,
    payload: payload,
  };
};
export const deleteBlockError = (payload) => {
  return {
    type: DELETE_BLOCK_ERROR,
    payload: payload,
  };
};

//ward
export const getWardListInProgress = (payload) => {
  return {
    type: GET_WARD_LIST_IN_PROGRESS,
    payload: payload,
  };
};
export const getWardListSuccess = (payload) => {
  return {
    type: GET_WARD_LIST_SUCCESS,
    payload: payload,
  };
};
export const getWardListError = (payload) => {
  return {
    type: GET_WARD_LIST_ERROR,
    payload: payload,
  };
};
export const addWardInProgress = (payload) => {
  return {
    type: ADD_WARD_IN_PROGRESS,
    payload: payload,
  };
};
export const addWardSuccess = (payload) => {
  return {
    type: ADD_WARD_SUCCESS,
    payload: payload,
  };
};
export const addWardError = (payload) => {
  return {
    type: ADD_WARD_ERROR,
    payload: payload,
  };
};
export const editWardInProgress = (payload) => {
  console.log("edit-ward-ation", payload);
  return {
    type: EDIT_WARD_IN_PROGRESS,
    payload: payload,
  };
};
export const editWardSuccess = (payload) => {
  return {
    type: EDIT_WARD_SUCCESS,
    payload: payload,
  };
};
export const editWardError = (payload) => {
  return {
    type: EDIT_WARD_ERROR,
    payload: payload,
  };
};
export const deleteWardInProgress = (payload) => {
  return {
    type: DELETE_WARD_IN_PROGRESS,
    payload: payload,
  };
};
export const deleteWardSuccess = (payload) => {
  return {
    type: DELETE_WARD_SUCCESS,
    payload: payload,
  };
};
export const deleteWardError = (payload) => {
  return {
    type: DELETE_WARD_ERROR,
    payload: payload,
  };
};
export const defaultWard = (payload) => {
  return {
    type: DEFAULT_WARD,
    payload: payload,
  };
};
export const getWardListByBlockInProgress = (payload) => {
  console.log("action");
  return {
    type: GET_WARD_LIST_BY_BLOCK_IN_PROGRESS,
    payload: payload,
  };
};
export const getWardListByBlockSuccess = (payload) => {
  return {
    type: GET_WARD_LIST_BY_BLOCK_SUCCESS,
    payload: payload,
  };
};
export const getWardListByBlockError = (payload) => {
  return {
    type: GET_WARD_LIST_BY_BLOCK_ERROR,
    payload: payload,
  };
};

export const roomListsInProgress = (payload) => {
  return {
    type: GET_ROOM_LIST_IN_PROGRESS,
    payload: payload,
  };
};
export const roomListSuccess = (payload) => {
  return {
    type: GET_ROOM_LIST_SUCCESS,
    payload: payload,
  };
};
export const roomListsError = (payload) => {
  return {
    type: GET_ROOM_LIST_ERROR,
    payload: payload,
  };
};

export const addRoomInProgress = (payload) => {
  return {
    type: ADD_ROOM_IN_PROGRESS,
    payload: payload,
  };
};
export const addRoomSuccess = (payload) => {
  return {
    type: ADD_ROOM_SUCCESS,
    payload: payload,
  };
};
export const addRoomError = (payload) => {
  return {
    type: ADD_ROOM_ERROR,
    payload: payload,
  };
};
export const editRoomInProgress = (payload) => {
  return {
    type: EDIT_ROOM_IN_PROGRESS,
    payload: payload,
  };
};
export const editRoomSuccess = (payload) => {
  return {
    type: EDIT_ROOM_SUCCESS,
    payload: payload,
  };
};
export const editRoomError = (payload) => {
  return {
    type: EDIT_ROOM_ERROR,
    payload: payload,
  };
};

export const deleteRoomInProgress = (payload) => {
  return {
    type: DELETE_ROOM_IN_PROGRESS,
    payload: payload,
  };
};
export const deleteRoomSuccess = (payload) => {
  return {
    type: DELETE_ROOM_SUCCESS,
    payload: payload,
  };
};
export const deleteRoomError = (payload) => {
  return {
    type: DELETE_ROOM_ERROR,
    payload: payload,
  };
};
export const defaultRoom = (payload) => {
  return {
    type: DEFAULT_ROOM,
    payload: payload,
  };
};
export const defaultBlock = (payload) => {
  return {
    type: DEFAULT_BLOCK,
    payload: payload,
  };
};
export const allDoctorListInProgress = (payload) => {
  return {
    type: ALL_DOCTOR_LIST_IN_PROGRESS,
    payload: payload,
  };
};
export const allDoctorListSuccess = (payload) => {
  return {
    type: ALL_DOCTOR_LIST_SUCCESS,
    payload: payload,
  };
};
export const allDoctorListError = (payload) => {
  return {
    type: ALL_DOCTOR_LIST_ERROR,
    payload: payload,
  };
};
export const getAvailableRoomsListInProgress = (payload) => {
  return {
    type: GET_AVAILABLE_ROOM_LIST_IN_PROGRESS,
    payload: payload,
  };
};
export const getAvailableRoomsListInSuccess = (payload) => {
  console.log("success", payload);
  return {
    type: GET_AVAILABLE_ROOM_LIST_IN_SUCCESS,
    payload: payload,
  };
};
export const getAvailableRoomsListInFailure = (payload) => {
  return {
    type: GET_AVAILABLE_ROOM_LIST_IN_FAILURE,
    payload: payload,
  };
};
export const roomBookInProgress = (payload) => {
  return {
    type: ROOM_BOOKING_IN_PROGRESS,
    payload: payload,
  };
};
export const roomBookInSuccess = (payload) => {
  return {
    type: ROOM_BOOKING_IN_SUCCESS,
    payload: payload,
  };
};
export const roomBookInFailure = (payload) => {
  return {
    type: ROOM_BOOKING_IN_FAILURE,
    payload: payload,
  };
};
export const wardDashboardCountListInProgress = (payload) => {
  return {
    type: WARD_DASHBOARD_COUNT_IN_PROGRESS,
    payload: payload,
  };
};
export const wardDashboardCountListInSuccess = (payload) => {
  return {
    type: WARD_DASHBOARD_COUNT_IN_SUCCESS,
    payload: payload,
  };
};
export const wardDashboardCountListInFailure = (payload) => {
  return {
    type: WARD_DASHBOARD_COUNT_IN_FAILURE,
    payload: payload,
  };
};
export const getTotalRoomsListInProgress = (payload) => {
  return {
    type: GET_TOTAL_ROOM_LIST_IN_PROGRESS,
    payload: payload,
  };
};
export const getTotalRoomsListInSuccess = (payload) => {
  return {
    type: GET_TOTAL_ROOM_LIST_IN_SUCCESS,
    payload: payload,
  };
};
export const getTotalRoomsListInFailure = (payload) => {
  return {
    type: GET_TOTAL_ROOM_LIST_IN_FAILURE,
    payload: payload,
  };
};
export const getBookedRoomsListInProgress = (payload) => {
  return {
    type: GET_BOOKED_ROOM_LIST_IN_PROGRESS,
    payload: payload,
  };
};
export const getBookedRoomsListInSuccess = (payload) => {
  return {
    type: GET_BOOKED_ROOM_LIST_IN_SUCCESS,
    payload: payload,
  };
};
export const getBookedRoomsListInFailure = (payload) => {
  return {
    type: GET_BOOKED_ROOM_LIST_IN_FAILURE,
    payload: payload,
  };
};
export const roomShiftInProgress = (payload) => {
  return {
    type: ROOM_SHIFT_IN_PROGRESS,
    payload: payload,
  };
};
export const roomShiftInSuccess = (payload) => {
  return {
    type: ROOM_SHIFT_IN_SUCCESS,
    payload: payload,
  };
};
export const roomShiftInFailure = (payload) => {
  return {
    type: ROOM_SHIFT_IN_FAILURE,
    payload: payload,
  };
};
export const roomDischargeInProgress = (payload) => {
  return {
    type: ROOM_DISCHARGE_IN_PROGRESS,
    payload: payload,
  };
};
export const roomDischargeInSuccess = (payload) => {
  return {
    type: ROOM_DISCHARGE_IN_SUCCESS,
    payload: payload,
  };
};
export const roomDischargeInFailure = (payload) => {
  return {
    type: ROOM_DISCHARGE_IN_FAILURE,
    payload: payload,
  };
};
export const defaultShiftRoom = (payload) => {
  return {
    type: DEFAULT_SHIFT_ROOM,
    payload: payload,
  };
};
export const defaultRoomBooking = (payload) => {
  return {
    type: DEFAULT_ROOM_BOOKING,
    payload: payload,
  };
};
