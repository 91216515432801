import {
  DELETE_TABLET_ERROR,
  DELETE_TABLET_IN_PROGRESS,
  DELETE_TABLET_SUCCESS,
  GET_TABLET_ERROR,
  GET_TABLET_IN_PROGRESS,
  GET_TABLET_LIST_ERROR,
  GET_TABLET_LIST_IN_PROGRESS,
  GET_TABLET_LIST_SUCCESS,
  GET_TABLET_SUCCESS,
  NEW_TABLET_ERROR,
  NEW_TABLET_IN_PROGRESS,
  NEW_TABLET_SUCCESS,
  TABLET_BY_ORG_ERROR,
  TABLET_BY_ORG_IN_PROGRESS,
  TABLET_BY_ORG_SUCCESS,
  UPDATE_TABLET_ERROR,
  UPDATE_TABLET_IN_PROGRESS,
  UPDATE_TABLET_SUCCESS,
} from "../../../constants/ActionTypes";

const defaultStatus = {
  inProgress: false,
  success: false,
  error: false,
  data: null,
};
const initialState = {
  tabletList: { ...defaultStatus },
  getTablet: { ...defaultStatus },
  newTablet: { ...defaultStatus },
  updateTablet: { ...defaultStatus },
  deleteTablet: { ...defaultStatus },
  TabletOrg: { ...defaultStatus },
};

const Tablets = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_TABLET_LIST_IN_PROGRESS:
      return {
        ...state,
        tabletList: { ...defaultStatus, inProgress: true, ...payload },
        updateTablet: { ...defaultStatus },
      };
    case GET_TABLET_LIST_SUCCESS:
      return {
        ...state,
        tabletList: { ...defaultStatus, ...payload, success: true },
      };
    case GET_TABLET_LIST_ERROR:
      return {
        ...state,
        tabletList: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case NEW_TABLET_IN_PROGRESS:
      return {
        ...state,
        newTablet: { ...defaultStatus, ...payload, inProgress: true },
      };
    case NEW_TABLET_SUCCESS:
      return {
        ...state,
        newTablet: { ...defaultStatus, ...payload, success: true },
      };
    case NEW_TABLET_ERROR:
      return {
        ...state,
        newTablet: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };

    case UPDATE_TABLET_IN_PROGRESS:
      return {
        ...state,
        updateTablet: { ...defaultStatus, ...payload, inProgress: true },
      };
    case UPDATE_TABLET_SUCCESS:
      return {
        ...state,
        updateTablet: { ...defaultStatus, ...payload, success: true },
      };
    case UPDATE_TABLET_ERROR:
      return {
        ...state,
        updateTablet: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };

    case GET_TABLET_IN_PROGRESS:
      return {
        ...state,
        getTablet: { ...defaultStatus, ...payload, inProgress: true },
      };
    case GET_TABLET_SUCCESS:
      return {
        ...state,
        getTablet: { ...defaultStatus, ...payload, success: true },
      };
    case GET_TABLET_ERROR:
      return {
        ...state,
        getTablet: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };

    case DELETE_TABLET_IN_PROGRESS:
      return {
        ...state,
        deleteTablet: { ...defaultStatus, ...payload, inProgress: true },
      };
    case DELETE_TABLET_SUCCESS:
      return {
        ...state,
        deleteTablet: { ...defaultStatus, ...payload, success: true },
      };
    case DELETE_TABLET_ERROR:
      return {
        ...state,
        deleteTablet: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case TABLET_BY_ORG_IN_PROGRESS:
      return {
        ...state,
        TabletOrg: { ...defaultStatus, ...payload, inProgress: true },
      };
    case TABLET_BY_ORG_SUCCESS:
      return {
        ...state,
        TabletOrg: { ...defaultStatus, ...payload, success: true },
      };
    case TABLET_BY_ORG_ERROR:
      return {
        ...state,
        TabletOrg: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    default:
      return state;
  }
};

export default Tablets;
