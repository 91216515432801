import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Spin, Modal, Typography, Tooltip, Form, Button } from "antd";
import {
  DeleteFilled,
  LoadingOutlined,
  EditFilled,
  EyeFilled,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import {
  deleteQuestionAnswerInProgress,
  getQuestionAnswerListInProgress,
} from "../../../appRedux/doctor/actions/appointmentQuestions";
import { access } from "../../../util/utilz";
import {
  addReasonInprogress,
  addSymptomInprogress,
  deleteSymptomInprogress,
  getReasonListInprogress,
  getSymptomListInprogress,
  updateSymptomInprogress,
} from "../../../appRedux/admin/Doctor/actions/Doctors";
import TableComponent from "../../../components/Table/TableComponent";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import { answerType } from "../../AppointmentQuestions/data";
import IntlMessages from "../../../util/IntlMessages";
import FormInput from "../../../components/Input/FormInput";
import { APP_ROUTES } from "../../../constants/Routes";

const SymptomTable = () => {
  const [symptomId, setSymptomId] = useState("");
  const [symptomType, setSymptomType] = useState("Add Symptom");
  const [viewSymptom, setViewSymptom] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [form] = Form.useForm();
  const symptomTypeRef = useRef(symptomType);
  const [filteredUsers, setFilteredUsers] = useState("");
  const dispatch = useDispatch();
  const { symptomList, addSymptom, updateSymptom, deleteSymptom } = useSelector(
    (state) => state?.doctor
  );
  const [addSymptomModal, setAddSymptomModal] = useState(false);
  const symptomListData = symptomList?.data;

  const totalRecords = symptomList?.totalRecords;
  console.log("symptomListData", symptomListData);
  const handleDeleteModal = (id) => {
    setDeleteModalOpen(true);
    setSymptomId(id);
  };
  const handleDeleteModalOk = () => {
    setDeleteModalOpen(false);
    dispatch(deleteSymptomInprogress({ id: symptomId }));
  };
  const handleDeleteModalCancel = () => {
    setDeleteModalOpen(false);
  };
  const handleEditSymptom = (record) => {
    setAddSymptomModal(true);
    form.setFieldValue("symptom", record?.name);
    setSymptomId(record?.id);
    setSymptomType("Edit Symptom");
    setViewSymptom(false);
  };
  const handleViewSymptom = (record) => {
    form.setFieldValue("symptom", record?.name);
    setSymptomId(record?.id);
    setSymptomType("View Symptom");
    setViewSymptom(true);
  };
  const columns = [
    {
      title: "S.No",
      dataIndex: "sno",
      render: (text, record, index) => index + 1,
    },

    {
      title: " Name",
      dataIndex: "name",
      sortDirections: ["ascend", "descend"],
      key: "name",
      sorter: (a, b) => {
        if (a && b && a.name && b.name) {
          return a.name.localeCompare(b.name);
        }
        return 0;
      },
    },

    {
      title: "Action",
      dataIndex: "Action",
      render: (_, record) => {
        return (
          <Row className="table-button">
            <Col className="button-edit">
              <Tooltip placement="top" title={"View"}>
                <ButtonComponent
                  size="small"
                  type="primary"
                  icon={<EyeFilled />}
                  onClick={() => handleViewSymptom(record)}
                ></ButtonComponent>
              </Tooltip>
            </Col>

            <Col className="button-edit">
              <Tooltip placement="top" title={"Edit"}>
                <ButtonComponent
                  size="small"
                  className="secondary-color"
                  icon={<EditFilled />}
                  onClick={() => handleEditSymptom(record)}
                ></ButtonComponent>
              </Tooltip>
            </Col>

            <Col className="button-edit">
              {" "}
              <Tooltip placement="top" title={"Delete"}>
                <Typography>
                  {" "}
                  <ButtonComponent
                    size="small"
                    type="danger"
                    icon={<DeleteFilled />}
                    onClick={() => handleDeleteModal(record.id)}
                  ></ButtonComponent>
                </Typography>
              </Tooltip>
            </Col>
          </Row>
        );
      },
    },
  ];

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );
  const handleSymptomModalClose = () => {
    setAddSymptomModal(false);
    form.resetFields();
  };
  const handleViewSymptomModalClose = () => {
    setViewSymptom(false);
  };
  const handlePage = (page) => {
    const payload = {
      pagination: true,
      page: page,
      pageSize: 10,
    };
    dispatch(getQuestionAnswerListInProgress(payload));
  };

  const handleSelectChange = (value) => {
    if (value === "all") {
      setFilteredUsers(symptomListData);
    } else {
      const newData = symptomListData.slice(0, value);
      setFilteredUsers(newData);
    }
  };

  useEffect(() => {
    const payload = {
      pagination: true,
      page: 1,
      pageSize: 10,
    };
    dispatch(getSymptomListInprogress(payload));
  }, []);
  useEffect(() => {
    const payload = {
      pagination: true,
      page: 1,
      pageSize: 10,
    };
    if (
      addSymptom?.success ||
      updateSymptom?.success ||
      deleteSymptom?.success
    ) {
      dispatch(getSymptomListInprogress(payload));
    }
  }, [addSymptom, updateSymptom, deleteSymptom]);
  const onFinish = (datas) => {
    const payload = {
      name: datas?.symptom,
    };
    if (symptomType === "Edit Symptom") {
      dispatch(updateSymptomInprogress({ data: payload, id: symptomId }));
    } else {
      dispatch(addSymptomInprogress(payload));
    }
  };
  useEffect(() => {
    if (addSymptom?.success || updateSymptom?.success) {
      setAddSymptomModal(false);
      form.resetFields();
    }
  }, [addSymptom, updateSymptom]);
  useEffect(() => {
    symptomTypeRef.current = symptomType; // Update ref whenever state changes
  }, [symptomType]);
  console.log("symptomType", symptomType);
  return (
    <Row className="p-10 Add-button">
      <Col>
        <p className="header-tag">
          {<IntlMessages id={"Label.symptom.heading"} />}
        </p>
      </Col>
      <Col>
        <ButtonComponent
          type="primary"
          label={<IntlMessages id={"Addnew.symptoms"} />}
          onClick={() => setAddSymptomModal(true)}
        />
      </Col>
      <Col xs={24} sm={24}>
        <Spin indicator={antIcon} spinning={symptomList?.progressing}>
          <TableComponent
            columns={columns}
            dataSource={filteredUsers ? filteredUsers : symptomListData}
            rowselector={handleSelectChange}
            itemList={symptomListData}
            filteredUsers={filteredUsers}
            setFilteredUsers={setFilteredUsers}
            pagination={{
              total: totalRecords,
              onChange: (page) => handlePage(page),
            }}
            field={"name"}
            type={"symptomList"}
            documentTitle="Symptoms List"
          />
          <Modal
            title="Delete Data"
            open={deleteModalOpen}
            onOk={handleDeleteModalOk}
            onCancel={handleDeleteModalCancel}
            cancelButtonProps={{ className: "secondary-color" }}
          >
            <p>Are you sure, you want to delete the Data</p>
          </Modal>
          <Modal
            title={
              <Typography className="cancel-sales"> {symptomType}</Typography>
            }
            open={addSymptomModal}
            width={600}
            closable={false}
            footer={[]}
          >
            <Spin indicator={antIcon} spinning={addSymptom?.progressing}>
              <Form
                labelCol={{
                  span: 24,
                }}
                wrapperCol={{
                  span: 24,
                }}
                initialValues={{
                  remember: true,
                }}
                colon={"true"}
                onFinish={onFinish}
                autoComplete="off"
                form={form}
              >
                <Row className="register-row">
                  <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <FormInput
                      labelCol={"24"}
                      wrapperCol={"24"}
                      type="text"
                      name={"symptom"}
                      readOnly={symptomType === "View Symptom" ? true : false}
                      label={<IntlMessages id={"Addnew.label.symptom"} />}
                      rules={[
                        {
                          required: true,
                          message: <IntlMessages id={"Enter the symptom "} />,
                        },
                      ]}
                    />
                  </Col>
                </Row>
                <Row justify={"end"}>
                  <Button key="back" type="primary" htmlType="submit">
                    Save
                  </Button>

                  <Button
                    key="back"
                    className="secondary-color"
                    onClick={handleSymptomModalClose}
                  >
                    Close
                  </Button>
                </Row>
              </Form>
            </Spin>
          </Modal>
          <Modal
            title="Delete Data"
            open={deleteModalOpen}
            onOk={handleDeleteModalOk}
            onCancel={handleDeleteModalCancel}
          >
            <Spin indicator={antIcon} spinning={deleteSymptom?.progressing}>
              <p>Are you sure, you want to delete the Data</p>
            </Spin>
          </Modal>{" "}
          <Modal
            title={
              <Typography className="cancel-sales"> {symptomType}</Typography>
            }
            open={viewSymptom}
            width={600}
            closable={false}
            footer={[]}
          >
            <Spin indicator={antIcon} spinning={addSymptom?.progressing}>
              <Form
                labelCol={{
                  span: 24,
                }}
                wrapperCol={{
                  span: 24,
                }}
                initialValues={{
                  remember: true,
                }}
                colon={"true"}
                onFinish={onFinish}
                autoComplete="off"
                form={form}
              >
                <Row className="register-row">
                  <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <FormInput
                      labelCol={"24"}
                      wrapperCol={"24"}
                      type="text"
                      name={"symptom"}
                      readOnly={true}
                      label={<IntlMessages id={"Addnew.label.symptom"} />}
                      rules={[
                        {
                          required: true,
                          message: <IntlMessages id={"Enter the symptom "} />,
                        },
                      ]}
                    />
                  </Col>
                </Row>
                <Row justify={"end"}>
                  <Button
                    key="back"
                    className="secondary-color"
                    onClick={handleViewSymptomModalClose}
                  >
                    Close
                  </Button>
                </Row>
              </Form>
            </Spin>
          </Modal>
        </Spin>
      </Col>
    </Row>
  );
};

export default SymptomTable;
