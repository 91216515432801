import React, { useEffect, useState } from "react";
import SelectBoxAppointment from "./SelectBox";
import { getDoctorsBySpecialist } from "../../appRedux/doctor/sagas/effects/TrackerEffect";
const SpecialistSelects = ({
  placeholder = "Select patient",
  label = "patient",
  rules = [],
  name,
  editMode,
  margin = "mb-0",
  onChange,
  getApiData,
  className,
  disabled,
  specialization_id,
  setLoadingModel,
}) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getStatusList();
  }, [specialization_id]);
  const getStatusList = async () => {
    try {
      setLoading(true);
      setLoadingModel(true);
      const { data } = await getDoctorsBySpecialist(specialization_id);
      const patientList = data?.data;
      let options = patientList.map((row) => ({
        label: `${row.firstName} - ${row.Doctor_serial_no}`,
        value: row.id,
      }));
      setOptions(options);
      setLoading(false);
      setLoadingModel(false);
    } catch (error) {
      setOptions([]);
      setLoading(false);
      setLoadingModel(false);
    }
  };
  return (
    <SelectBoxAppointment
      loading={loading}
      options={options}
      placeholder={placeholder}
      label={label}
      margin={margin}
      rules={rules}
      name={name}
      editMode={editMode}
      onChange={onChange}
      className={className}
      disabled={disabled}
      labelCol={24}
      wrapperCol={24}
    />
  );
};

export default SpecialistSelects;
