import {
  ADD_BLOOD_GLUCOSE_ERROR,
  ADD_BLOOD_GLUCOSE_INPROGRESS,
  ADD_BLOOD_GLUCOSE_SUCCESS,
  ADD_BMI_ERROR,
  ADD_BMI_INPROGRESS,
  ADD_BMI_SUCCESS,
  ADD_INSULIN_ERROR,
  ADD_INSULIN_INPROGRESS,
  ADD_INSULIN_SUCCESS,
  ADD_PATIENT_HEALTH_RECORDS_ERROR,
  ADD_PATIENT_HEALTH_RECORDS_IN_PROGRESS,
  ADD_PATIENT_HEALTH_RECORDS_SUCCESS,
  ADD_VITALS_ERROR,
  ADD_VITALS_INPROGRESS,
  ADD_VITALS_SUCCESS,
  DEFAULT_BLOOD_GLUCOSE,
  DEFAULT_BMI,
  DEFAULT_INSULIN,
  DEFAULT_VITAL,
  DELETE_PATIENT_HEALTH_RECORDS_ERROR,
  DELETE_PATIENT_HEALTH_RECORDS_IN_PROGRESS,
  DELETE_PATIENT_HEALTH_RECORDS_SUCCESS,
  DOCTOR_CONSULTATION_IN_FAILURE,
  DOCTOR_CONSULTATION_IN_PROGRESS,
  DOCTOR_CONSULTATION_IN_SUCCESS,
  EDIT_PATIENT_HEALTH_RECORDS_ERROR,
  EDIT_PATIENT_HEALTH_RECORDS_IN_PROGRESS,
  EDIT_PATIENT_HEALTH_RECORDS_SUCCESS,
  GET_APPOINTMENT_BY_DOCTOR_PATIENT_ERROR,
  GET_APPOINTMENT_BY_DOCTOR_PATIENT_IN_PROGRESS,
  GET_APPOINTMENT_BY_DOCTOR_PATIENT_SUCCESS,
  GET_BLOOD_GLUCOSE_LIST_ERROR,
  GET_BLOOD_GLUCOSE_LIST_INPROGRESS,
  GET_BLOOD_GLUCOSE_LIST_SUCCESS,
  GET_BMI_LIST_ERROR,
  GET_BMI_LIST_INPROGRESS,
  GET_BMI_LIST_SUCCESS,
  GET_INSULIN_LIST_ERROR,
  GET_INSULIN_LIST_INPROGRESS,
  GET_INSULIN_LIST_SUCCESS,
  GET_PATIENT_APPOINMENT_BY_LIST_IN_FAILURE,
  GET_PATIENT_APPOINMENT_BY_LIST_IN_PROGRESS,
  GET_PATIENT_APPOINMENT_BY_LIST_IN_SUCCESS,
  GET_PATIENT_APPOINTMENT_BY_ID_IN_FAILURE,
  GET_PATIENT_APPOINTMENT_BY_ID_IN_PROGRESS,
  GET_PATIENT_APPOINTMENT_BY_ID_IN_SUCCESS,
  GET_PATIENT_HEALTH_RECORDS_ERROR,
  GET_PATIENT_HEALTH_RECORDS_IN_PROGRESS,
  GET_PATIENT_HEALTH_RECORDS_SUCCESS,
  GET_SCHEDULE_IN_DEFAULT,
  GET_SCHEDULE_IN_FAILURE,
  GET_SCHEDULE_IN_PROGRESS,
  GET_SCHEDULE_IN_SUCCESS,
  GET_SINGLE_PATIENT_HEALTH_RECORDS_ERROR,
  GET_SINGLE_PATIENT_HEALTH_RECORDS_IN_PROGRESS,
  GET_SINGLE_PATIENT_HEALTH_RECORDS_SUCCESS,
  GET_UPCOMING_PATIENT_APPOINMENT_BY_LIST_IN_FAILURE,
  GET_UPCOMING_PATIENT_APPOINMENT_BY_LIST_IN_PROGRESS,
  GET_UPCOMING_PATIENT_APPOINMENT_BY_LIST_IN_SUCCESS,
  GET_VITALS_LIST_ERROR,
  GET_VITALS_LIST_INPROGRESS,
  GET_VITALS_LIST_SUCCESS,
  GET_VITALS_LIST_TYPE_ERROR,
  GET_VITALS_LIST_TYPE_INPROGRESS,
  GET_VITALS_LIST_TYPE_SUCCESS,
  PATIENT_ADD_APPOINTMENT_LIST_IN_FAILURE,
  PATIENT_ADD_APPOINTMENT_LIST_IN_PROGRESS,
  PATIENT_ADD_APPOINTMENT_LIST_IN_SUCCESS,
  PATIENT_DAHBOARD_IN_PROGRESS,
  PATIENT_DASHBOARD_ERROR,
  PATIENT_DASHBOARD_IN_PROGRESS,
  PATIENT_DASHBOARD_SUCCESS,
  PATIENT_DELETE_APPOINTMENT_LIST_IN_FAILURE,
  PATIENT_DELETE_APPOINTMENT_LIST_IN_PROGRESS,
  PATIENT_DELETE_APPOINTMENT_LIST_IN_SUCCESS,
  PATIENT_HEALTH_RECORDS_DEFAULT,
  PATIENT_UPCOMING_DELETE_APPOINTMENT_LIST_IN_PROGRESS,
  PATIENT_UPCOMING_DELETE_APPOINTMENT_LIST_IN_SUCCESS,
  PATIENT_UPCOMING__DELETE_APPOINTMENT_LIST_IN_FAILURE,
  PATIENT_UPDATE_APPOINTMENT_LIST_IN_FAILURE,
  PATIENT_UPDATE_APPOINTMENT_LIST_IN_PROGRESS,
  PATIENT_UPDATE_APPOINTMENT_LIST_IN_SUCCESS,
} from "../../../constants/ActionTypes";

const defaultStatus = {
  inProgress: false,
  success: false,
  error: false,
  data: null,
};
const initialState = {
  getAppointmentList: { ...defaultStatus },
  deleteAppointmentList: { ...defaultStatus },
  deleteUpcomingAppointmentList: { ...defaultStatus },
  addAppointmentList: { ...defaultStatus },
  updateAppointmentList: { ...defaultStatus },
  getAppointmentById: { ...defaultStatus },
  getUpcomingAppointmentList: { ...defaultStatus },
  getSchedule: { ...defaultStatus },
  consultationFee: { ...defaultStatus },
  getPatientHealthRecords: { ...defaultStatus },
  addPatientHealthRecords: { ...defaultStatus },
  editPatientHealthRecords: { ...defaultStatus },
  deletePatientHealthRecords: { ...defaultStatus },
  getSinglePatientHealthRecords: { ...defaultStatus },
  appoitmnetsByDoctorPatient: { ...defaultStatus },
  patientDashboard: { ...defaultStatus },
  vitalsList: { ...defaultStatus },
  addVitals: { ...defaultStatus },
  bmiList: { ...defaultStatus },
  addBmi: { ...defaultStatus },
  bloodGlucoseList: { ...defaultStatus },
  addBloodGlucose: { ...defaultStatus },
  insulinList: { ...defaultStatus },
  addInsulin: { ...defaultStatus },
  getVitalsByType: { ...defaultStatus },
};
const patientAppointmentReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
     // get patient appointment id
     case GET_PATIENT_APPOINTMENT_BY_ID_IN_PROGRESS:
      return {
        ...state,
        getAppointmentById: { ...defaultStatus, inProgress: true },
      };
    case GET_PATIENT_APPOINTMENT_BY_ID_IN_SUCCESS:
      return {
        ...state,
        getAppointmentById: { ...defaultStatus, ...payload, success: true },
      };
    case GET_PATIENT_APPOINTMENT_BY_ID_IN_FAILURE:
      return {
        ...state,
        getAppointmentById: { ...defaultStatus, ...payload, error: true },
      };
 
    // get patient appointment list
    case GET_PATIENT_APPOINMENT_BY_LIST_IN_PROGRESS:
      return {
        ...state,
        getAppointmentList: { ...defaultStatus, inProgress: true },
      };
    case GET_PATIENT_APPOINMENT_BY_LIST_IN_SUCCESS:
      return {
        ...state,
        getAppointmentList: { ...defaultStatus, ...payload, success: true },
      };
    case GET_PATIENT_APPOINMENT_BY_LIST_IN_FAILURE:
      return {
        ...state,
        getAppointmentList: { ...defaultStatus, ...payload, error: true },
      };
    // get upcoming patient appointment list
    case GET_UPCOMING_PATIENT_APPOINMENT_BY_LIST_IN_PROGRESS:
      return {
        ...state,
        getUpcomingAppointmentList: { ...defaultStatus, inProgress: true },
      };
    case GET_UPCOMING_PATIENT_APPOINMENT_BY_LIST_IN_SUCCESS:
      return {
        ...state,
        getUpcomingAppointmentList: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case GET_UPCOMING_PATIENT_APPOINMENT_BY_LIST_IN_FAILURE:
      return {
        ...state,
        getUpcomingAppointmentList: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    // delete patient appointment list
    case PATIENT_DELETE_APPOINTMENT_LIST_IN_PROGRESS:
      return {
        ...state,
        deleteAppointmentList: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case PATIENT_DELETE_APPOINTMENT_LIST_IN_SUCCESS:
      return {
        ...state,
        deleteAppointmentList: { ...defaultStatus, ...payload, success: true },
      };
    case PATIENT_DELETE_APPOINTMENT_LIST_IN_FAILURE:
      return {
        ...state,
        deleteAppointmentList: { ...defaultStatus, ...payload, error: true },
      };
    // delete upcoming patient appointment list
    case PATIENT_UPCOMING_DELETE_APPOINTMENT_LIST_IN_PROGRESS:
      return {
        ...state,
        deleteUpcomingAppointmentList: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case PATIENT_UPCOMING_DELETE_APPOINTMENT_LIST_IN_SUCCESS:
      return {
        ...state,
        deleteUpcomingAppointmentList: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case PATIENT_UPCOMING__DELETE_APPOINTMENT_LIST_IN_FAILURE:
      return {
        ...state,
        deleteUpcomingAppointmentList: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    // add patient appointment list

    case PATIENT_ADD_APPOINTMENT_LIST_IN_PROGRESS:
      return {
        ...state,
        addAppointmentList: { ...defaultStatus, ...payload, inProgress: true },
      };
    case PATIENT_ADD_APPOINTMENT_LIST_IN_SUCCESS:
      return {
        ...state,
        addAppointmentList: { ...defaultStatus, ...payload, success: true },
      };
    case PATIENT_ADD_APPOINTMENT_LIST_IN_FAILURE:
      return {
        ...state,
        addAppointmentList: { ...defaultStatus, ...payload, error: true },
      };

    //  update patient appointment list
    case PATIENT_UPDATE_APPOINTMENT_LIST_IN_PROGRESS:
      return {
        ...state,
        updateAppointmentList: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case PATIENT_UPDATE_APPOINTMENT_LIST_IN_SUCCESS:
      return {
        ...state,
        updateAppointmentList: { ...defaultStatus, ...payload, success: true },
      };
    case PATIENT_UPDATE_APPOINTMENT_LIST_IN_FAILURE:
      return {
        ...state,
        updateAppointmentList: { ...defaultStatus, ...payload, error: true },
      };

    // get schedule
    case GET_SCHEDULE_IN_DEFAULT:
      return {
        ...state,
        getSchedule: { ...defaultStatus },
      };
    case GET_SCHEDULE_IN_PROGRESS:
      return {
        ...state,
        getSchedule: { ...defaultStatus, ...payload, inProgress: true },
      };
    case GET_SCHEDULE_IN_SUCCESS:
      return {
        ...state,
        getSchedule: { ...defaultStatus, ...payload, success: true },
      };
    case GET_SCHEDULE_IN_FAILURE:
      return {
        ...state,
        getSchedule: { ...defaultStatus, ...payload, error: true },
      };
    // doctor consultation fee
    case DOCTOR_CONSULTATION_IN_PROGRESS:
      return {
        ...state,
        consultationFee: { ...defaultStatus, ...payload, inProgress: true },
      };
    case DOCTOR_CONSULTATION_IN_SUCCESS:
      return {
        ...state,
        consultationFee: { ...defaultStatus, ...payload, success: true },
      };
    case DOCTOR_CONSULTATION_IN_FAILURE:
      return {
        ...state,
        consultationFee: { ...defaultStatus, ...payload, error: true },
      };
    case GET_PATIENT_HEALTH_RECORDS_IN_PROGRESS:
      return {
        ...state,
        getPatientHealthRecords: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case GET_PATIENT_HEALTH_RECORDS_SUCCESS:
      return {
        ...state,
        getPatientHealthRecords: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case GET_PATIENT_HEALTH_RECORDS_ERROR:
      return {
        ...state,
        getPatientHealthRecords: { ...defaultStatus, ...payload, error: true },
      };
    case ADD_PATIENT_HEALTH_RECORDS_IN_PROGRESS:
      return {
        ...state,
        addPatientHealthRecords: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case ADD_PATIENT_HEALTH_RECORDS_SUCCESS:
      return {
        ...state,
        addPatientHealthRecords: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case ADD_PATIENT_HEALTH_RECORDS_ERROR:
      return {
        ...state,
        addPatientHealthRecords: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case PATIENT_HEALTH_RECORDS_DEFAULT:
      return {
        ...state,
        addPatientHealthRecords: {
          ...defaultStatus,
        },
        editPatientHealthRecords: {
          ...defaultStatus,
        },
      };
    case EDIT_PATIENT_HEALTH_RECORDS_IN_PROGRESS:
      return {
        ...state,
        editPatientHealthRecords: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case EDIT_PATIENT_HEALTH_RECORDS_SUCCESS:
      return {
        ...state,
        editPatientHealthRecords: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case EDIT_PATIENT_HEALTH_RECORDS_ERROR:
      return {
        ...state,
        editPatientHealthRecords: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case DELETE_PATIENT_HEALTH_RECORDS_IN_PROGRESS:
      return {
        ...state,
        deletePatientHealthRecords: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case DELETE_PATIENT_HEALTH_RECORDS_SUCCESS:
      return {
        ...state,
        deletePatientHealthRecords: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case DELETE_PATIENT_HEALTH_RECORDS_ERROR:
      return {
        ...state,
        deletePatientHealthRecords: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case GET_SINGLE_PATIENT_HEALTH_RECORDS_IN_PROGRESS:
      return {
        ...state,
        getSinglePatientHealthRecords: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case GET_SINGLE_PATIENT_HEALTH_RECORDS_SUCCESS:
      return {
        ...state,
        getSinglePatientHealthRecords: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case GET_SINGLE_PATIENT_HEALTH_RECORDS_ERROR:
      return {
        ...state,
        getSinglePatientHealthRecords: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case GET_APPOINTMENT_BY_DOCTOR_PATIENT_IN_PROGRESS:
      return {
        ...state,
        appoitmnetsByDoctorPatient: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case GET_APPOINTMENT_BY_DOCTOR_PATIENT_SUCCESS:
      return {
        ...state,
        appoitmnetsByDoctorPatient: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case GET_APPOINTMENT_BY_DOCTOR_PATIENT_ERROR:
      return {
        ...state,
        appoitmnetsByDoctorPatient: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };

    case PATIENT_DASHBOARD_IN_PROGRESS:
      return {
        ...state,
        patientDashboard: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case PATIENT_DASHBOARD_SUCCESS:
      return {
        ...state,
        patientDashboard: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case PATIENT_DASHBOARD_ERROR:
      return {
        ...state,
        patientDashboard: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case GET_VITALS_LIST_INPROGRESS:
      return {
        ...state,
        vitalsList: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case GET_VITALS_LIST_SUCCESS:
      return {
        ...state,
        vitalsList: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case GET_VITALS_LIST_ERROR:
      return {
        ...state,
        vitalsList: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case DEFAULT_VITAL:
      return {
        ...state,
        addVitals: {
          ...defaultStatus,
          ...payload,
          success: false,
        },
      };
    case ADD_VITALS_INPROGRESS:
      return {
        ...state,
        addVitals: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case ADD_VITALS_SUCCESS:
      return {
        ...state,
        addVitals: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case ADD_VITALS_ERROR:
      return {
        ...state,
        addVitals: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case GET_BMI_LIST_INPROGRESS:
      return {
        ...state,
        bmiList: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case GET_BMI_LIST_SUCCESS:
      return {
        ...state,
        bmiList: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case GET_BMI_LIST_ERROR:
      return {
        ...state,
        bmiList: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case DEFAULT_BMI:
      return {
        ...state,
        addBmi: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case ADD_BMI_INPROGRESS:
      return {
        ...state,
        addBmi: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case ADD_BMI_SUCCESS:
      return {
        ...state,
        addBmi: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case ADD_BMI_ERROR:
      return {
        ...state,
        addBmi: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case GET_BLOOD_GLUCOSE_LIST_INPROGRESS:
      return {
        ...state,
        bloodGlucoseList: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case GET_BLOOD_GLUCOSE_LIST_SUCCESS:
      return {
        ...state,
        bloodGlucoseList: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case GET_BLOOD_GLUCOSE_LIST_ERROR:
      return {
        ...state,
        bloodGlucoseList: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case DEFAULT_BLOOD_GLUCOSE:
      return {
        ...state,
        addBloodGlucose: {
          ...defaultStatus,
          ...payload,
          success: false,
        },
      };
    case ADD_BLOOD_GLUCOSE_INPROGRESS:
      return {
        ...state,
        addBloodGlucose: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case ADD_BLOOD_GLUCOSE_SUCCESS:
      return {
        ...state,
        addBloodGlucose: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case ADD_BLOOD_GLUCOSE_ERROR:
      return {
        ...state,
        addBloodGlucose: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case GET_INSULIN_LIST_INPROGRESS:
      return {
        ...state,
        insulinList: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case GET_INSULIN_LIST_SUCCESS:
      return {
        ...state,
        insulinList: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case GET_INSULIN_LIST_ERROR:
      return {
        ...state,
        insulinList: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case DEFAULT_INSULIN:
      return {
        ...state,
        addInsulin: {
          ...defaultStatus,
          ...payload,
          success: false,
        },
      };
    case ADD_INSULIN_INPROGRESS:
      return {
        ...state,
        addInsulin: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case ADD_INSULIN_SUCCESS:
      return {
        ...state,
        addInsulin: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case ADD_INSULIN_ERROR:
      return {
        ...state,
        addInsulin: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case GET_VITALS_LIST_TYPE_INPROGRESS:
      return {
        ...state,
        getVitalsByType: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case GET_VITALS_LIST_TYPE_SUCCESS:
      return {
        ...state,
        getVitalsByType: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case GET_VITALS_LIST_TYPE_ERROR:
      return {
        ...state,
        getVitalsByType: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    default:
      return state;
  }
};
export default patientAppointmentReducer;
