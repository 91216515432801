import { DELETE_USERS_LIST_ERROR, DELETE_USERS_LIST_PROGRESS, DELETE_USERS_LIST_SUCCESS, GET_USERS_LIST_ERROR, GET_USERS_LIST_PROGRESS, GET_USERS_LIST_SUCCESS, GET_USER_UPDATE_LIST_ERROR, GET_USER_UPDATE_LIST_PROGRESS, GET_USER_UPDATE_LIST_SUCCESS, USER_UPDATE_IN_ERROR, USER_UPDATE_IN_PROGRESS, USER_UPDATE_IN_SUCCESS } from "../../../constants/ActionTypes";

export const getUserListInProgress = () => ({
    type: GET_USERS_LIST_PROGRESS,
  });

  export const getUserListInSuccess = (payload) => ({
    type: GET_USERS_LIST_SUCCESS,
    payload :payload,
  });
  
  export const getUserListInError = (payload) =>
   ({
    type:GET_USERS_LIST_ERROR,
    payload :payload,
  });

  export const deleteUsersListInProgress = (payload) => ({
    type: DELETE_USERS_LIST_PROGRESS,
    payload:payload,
  });
  
  export const deleteUsersListInSuccess = (payload) => ({
    type: DELETE_USERS_LIST_SUCCESS,
    payload:payload,
  });
  
  export const deleteUsersListInError = (payload) => ({
    type: DELETE_USERS_LIST_ERROR,
    payload:payload,
  });


  export const getUserUpdateListProgress = (payload) => {
    return {
      type: GET_USER_UPDATE_LIST_PROGRESS,
      payload: payload,
    };
  };
  export const getUserUpdateListSuccess = (payload) => {
    return {
      type: GET_USER_UPDATE_LIST_SUCCESS,
      payload: payload,
    };
  };
  
  export const getUserUpdateListError = () => {
    return {
      type: GET_USER_UPDATE_LIST_ERROR,
    };
  };
