import {
  REQUEST_SCHEDULE_IN_PROGRESS,
  ADD_SCHEDULE_FAILURE,
  ADD_SCHEDULE_IN_PROGRESS,
  ADD_SCHEDULE_SUCCESS,
  DELETE_SCHEDULE_FAILURE,
  DELETE_SCHEDULE_IN_PROGRESS,
  DELETE_SCHEDULE_SUCCESS,
  GETDATA_SCHEDULE_FAILURE,
  GETDATA_SCHEDULE_IN_PROGRESS,
  GETDATA_SCHEDULE_SUCCESS,
  REQUEST_SCHEDULE_FAILURE,
  REQUEST_SCHEDULE_SUCCESS,
  UPDATE_SCHEDULE_FAILURE,
  UPDATE_SCHEDULE_IN_PROGRESS,
  WEEKDAY_SCHEDULE_FAILURE,
  WEEKDAY_SCHEDULE_SUCCESS,
  WEEKDAY_SCHEDULE_IN_PROGRESS,
  UPDATE_SCHEDULE_SUCCESS,
} from "../../../constants/ActionTypes";

export const requestScheduleInProgress = (payload) => {
  return {
    type: REQUEST_SCHEDULE_IN_PROGRESS,
    payload: payload,
  };
};
export const requestScheduleSuccess = (payload) => {
  return {
    type: REQUEST_SCHEDULE_SUCCESS,
    payload: payload,
  };
};
export const requestScheduleError = () => {
  return {
    type: REQUEST_SCHEDULE_FAILURE,
  };
};

export const addScheduleInProgress = (payload) => {
  return {
    type: ADD_SCHEDULE_IN_PROGRESS,
    payload: payload,
  };
};
export const addScheduleSuccess = (payload) => {
  return {
    type: ADD_SCHEDULE_SUCCESS,
    payload: payload,
  };
};
export const addScheduleError = () => {
  return {
    type: ADD_SCHEDULE_FAILURE,
  };
};
export const deleteScheduleInProgress = (payload) => {
  return {
    type: DELETE_SCHEDULE_IN_PROGRESS,
    payload: payload,
  };
};
export const deleteScheduleSuccess = (payload) => {
  return {
    type: DELETE_SCHEDULE_SUCCESS,
    payload: payload,
  };
};

export const deleteScheduleError = () => {
  return {
    type: DELETE_SCHEDULE_FAILURE,
  };
};

export const updateScheduleInProgress = (payload) => {
  return {
    type: UPDATE_SCHEDULE_IN_PROGRESS,
    payload: payload,
  };
};
export const updateScheduleSuccess = (payload) => {
  return {
    type: UPDATE_SCHEDULE_SUCCESS,
    payload: payload,
  };
};

export const updateScheduleError = () => {
  return {
    type: UPDATE_SCHEDULE_FAILURE,
  };
};
export const getDataScheduleInProgress = (payload) => {
  return {
    type: GETDATA_SCHEDULE_IN_PROGRESS,
    payload: payload,
  };
};
export const getDataScheduleSuccess = (payload) => {
  return {
    type: GETDATA_SCHEDULE_SUCCESS,
    payload: payload,
  };
};

export const getDataScheduleError = () => {
  return {
    type: GETDATA_SCHEDULE_FAILURE,
  };
};
export const weekDayScheduleInProgress = () => {
  return {
    type: WEEKDAY_SCHEDULE_IN_PROGRESS,
  };
};
export const weekDayScheduleSuccess = (payload) => {
  return {
    type: WEEKDAY_SCHEDULE_SUCCESS,
    payload: payload,
  };
};

export const weekDayScheduleError = () => {
  return {
    type: WEEKDAY_SCHEDULE_FAILURE,
  };
};
