import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Spin,
  Card,
  Typography,
  Modal,
  Tooltip,
  Form,
  DatePicker,
} from "antd";
import {
  LoadingOutlined,
  EditFilled,
  CreditCardOutlined,
  FieldTimeOutlined,
  FileTextOutlined,
} from "@ant-design/icons";

import { Link } from "react-router-dom";
import "../../../styles/modules/superAdmin.less";
import IntlMessages from "../../../util/IntlMessages";
import TableComponent from "../../../components/Table/TableComponent";
import HospitalHeader from "./HospitalHeader";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import { useDispatch, useSelector } from "react-redux";

import {
  addOrgTimeInProgress,
  defaultOrganization,
  getOrganisationListInProgress,
  subscriptionPaymentDefault,
} from "../../../appRedux/super-admin/SuperAdminAction";
import FormInput from "../../../components/Input/FormInput";
import moment from "moment";

const HospitalTable = () => {
  const dispatch = useDispatch();
  const [orgId, setId] = useState("");
  const [totalHours, setTotalHours] = useState();
  const [timeValue, setTimeValue] = useState(0);
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [Loading, setLoading] = useState(false);

  const orgListData = useSelector((state) => state?.superAdmin?.orgList);
  const deleteOrgData = useSelector((state) => state?.superAdmin?.deleteOrg);
  const addOrgTime = useSelector((state) => state?.superAdmin?.addOrgTime);
  const addSubscriptionDetails = useSelector(
    (state) => state.superAdmin?.addSubscriptionDetails
  );
  const totalRecords = orgListData?.data?.totalRecords;
  const hospitalList = orgListData?.data?.data;
  const showModal = (record) => {
    setIsModalOpen(true);
    setId(record?.id);

    form.setFieldsValue({ date: moment() });

    if (record?.total_hours === null) {
      setTotalHours(false);
    } else {
      setTotalHours(true);
      form.setFieldValue("old_time", record?.total_hours);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };
  const [hospitalListData, setHospitalListData] = useState(hospitalList);
  const addOrgData = useSelector((state) => state?.superAdmin?.addOrg);
  const editOrgData = useSelector((state) => state?.superAdmin?.editOrg);
  const addPaymentData = useSelector(
    (state) => state.superAdmin?.addubscriptionPayment
  );

  const columns = [
    {
      title: "S.No",
      dataIndex: "sno",
      render: (text, record, index) => index + 1,
    },

    {
      title: "Organization Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Address",
      dataIndex: "Address",
      render: (_, record) => {
        return (
          <>
            {record?.address ? record?.address : null}
            {record?.city ? "," + record?.city : null}
            {record?.state ? "," + record?.state : null}
            {record?.country ? "," + record?.country : null}.
          </>
        );
      },
    },

    {
      title: "Contact",
      dataIndex: "contact",
      key: "contact",
    },
    {
      title: "Package Name",
      dataIndex: ["Subscription", "name"],
      key: "Subscription",
    },
    {
      title: "Total Hours",
      dataIndex: "total_hours",
      key: "total_hours",
    },

    {
      title: "Action",
      dataIndex: "Action",
      render: (_, record) => {
        return (
          <Row className="table-button">
            <Col className="button-edit">
              {" "}
              <Tooltip placement="top" title={"Edit"}>
                {" "}
                <Link
                  to={{
                    pathname: `/organization/edit`,
                    state: { hospitalData: record },
                  }}
                >
                  <ButtonComponent
                    size="small"
                    className="secondary-color"
                    icon={<EditFilled />}
                  ></ButtonComponent>
                </Link>
              </Tooltip>
            </Col>
            {/* <Col className="button-edit">
              <Tooltip
                placement="top"
                title={!totalHours ? "Add Time" : "Edit Time"}
              >
                <Typography>
                  <ButtonComponent
                    size="small"
                    type="primary"
                    icon={<FieldTimeOutlined />}
                    // disabled={record.total_hours === null ? false : true}
                    onClick={() => showModal(record)}
                  ></ButtonComponent>
                </Typography>
              </Tooltip>
            </Col>{" "} */}
            <Col className="button-edit">
              <Tooltip placement="top" title={"Reports"}>
                <Link
                  to={{
                    pathname: `/organization/reportList`,
                    state: { hospitalData: record, orgName: record?.name },
                  }}
                >
                  <ButtonComponent
                    icon={<FileTextOutlined />}
                    type="primary"
                    size="small"
                  ></ButtonComponent>
                </Link>
              </Tooltip>
            </Col>
          </Row>
        );
      },
    },
  ];
  const handlePage = (page) => {
    const payload = { pagination: true, pageSize: "10", pageNo: page };

    dispatch(getOrganisationListInProgress(payload));
  };
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );

  const onFinish = (datas) => {
    const payload = {
      date: moment(datas?.date).format("YYYY-MM-DD"),
      total_hours: datas?.time,
    };

    dispatch(addOrgTimeInProgress({ payload, orgId: orgId }));
  };

  useEffect(() => {
    if (orgListData?.inProgress || deleteOrgData?.inProgress) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [orgListData, deleteOrgData]);

  useEffect(() => {
    if (addOrgData?.success || editOrgData?.success) {
      dispatch(defaultOrganization());
    } else if (addPaymentData?.success || addSubscriptionDetails?.success) {
      dispatch(subscriptionPaymentDefault());
    }
  }, [addOrgData, editOrgData, addPaymentData, addSubscriptionDetails]);
  useEffect(() => {
    form.setFieldsValue({ date: moment() });
    const payload = { pagination: true, pageSize: "10", pageNo: "1" };
    dispatch(getOrganisationListInProgress(payload));
  }, []);

  useEffect(() => {
    if (addOrgTime?.success) {
      setIsModalOpen(false);
      form.resetFields();
      const payload = { pagination: true, pageSize: "10", pageNo: "1" };
      dispatch(getOrganisationListInProgress(payload));
    }
  }, [addOrgTime]);
  const handleValuesChange = (changedValues, allValues) => {
    const { time: timeToAdd, old_time: oldTime } = allValues;
    if (timeToAdd !== undefined && oldTime !== undefined) {
      setTimeValue(Number(timeToAdd) + Number(oldTime));
      form.setFieldsValue({ total_time: Number(timeToAdd) + Number(oldTime) });
    }
  };
  return (
    <Card>
      <HospitalHeader />
      <Spin indicator={antIcon} spinning={Loading}>
        <TableComponent
          columns={columns}
          dataSource={hospitalList || []}
          documentTitle={"Organization List"}
          pagination={{
            total: totalRecords,
            onChange: (page) => handlePage(page),
          }}
        />
      </Spin>
      <Modal
        title={!totalHours ? "Add TIme" : "Update Time"}
        open={isModalOpen}
        footer={null}
        closable={false}
        pagination={{
          total: totalRecords,
          onChange: (page) => handlePage(page),
        }}
      >
        <Form
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValues={{
            remember: true,
          }}
          colon={"true"}
          onFinish={onFinish}
          autoComplete="off"
          form={form}
          onValuesChange={handleValuesChange}
        >
          <Spin indicator={antIcon} spinning={addOrgTime?.inProgress}>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <Form.Item
                name="date"
                label={<IntlMessages id={"Addnew.health.label.date"} />}
                rules={[
                  {
                    required: true,
                    message: (
                      <IntlMessages id={"Addnew.health.validator.date"} />
                    ),
                  },
                ]}
              >
                <DatePicker style={{ width: "100%" }} format="DD/MM/YYYY" />
              </Form.Item>
            </Col>
            {totalHours && (
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <FormInput
                  labelCol={"24"}
                  wrapperCol={"24"}
                  type="number"
                  name={"old_time"}
                  readOnly={true}
                  label={
                    <IntlMessages id={"Addnew.health.label.timeAlreadyAdded"} />
                  }
                />
              </Col>
            )}
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <FormInput
                labelCol={"24"}
                wrapperCol={"24"}
                type="number"
                name={"time"}
                label={<IntlMessages id={"Addnew.health.label.hours"} />}
                rules={[
                  {
                    required: true,
                    message: (
                      <IntlMessages id={"Addnew.health.validator.totalHours"} />
                    ),
                  },
                ]}
              />
            </Col>{" "}
            {totalHours && (
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <FormInput
                  labelCol={"24"}
                  wrapperCol={"24"}
                  type="number"
                  name={"total_time"}
                  readOnly={true}
                  label={<IntlMessages id={"Addnew.health.label.totaHours"} />}
                />
              </Col>
            )}
            <Row>
              <Col
                xl={23}
                align="right"
                className="button-alignment-pharmacy cancel-btn"
              >
                <ButtonComponent
                  type="danger"
                  label="Cancel"
                  className="btn-cancel secondary-color"
                  onClick={handleCancel}
                />

                <ButtonComponent
                  icon={<CreditCardOutlined />}
                  label={!totalHours ? "Add TIme" : "Update Time"}
                  htmlType="submit"
                  type="primary"
                />
              </Col>
            </Row>
          </Spin>
        </Form>
      </Modal>
    </Card>
  );
};

export default HospitalTable;
