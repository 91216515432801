import {
  GET_DESIGNATION_IN_PROGRESS,
  ADD_DESIGNATION_FAILURE,
  ADD_DESIGNATION_IN_PROGRESS,
  ADD_DESIGNATION_SUCCESS,
  DELETE_DESIGNATION_FAILURE,
  DELETE_DESIGNATION_IN_PROGRESS,
  DELETE_DESIGNATION_SUCCESS,
  GETDATA_DESIGNATION_FAILURE,
  GETDATA_DESIGNATION_IN_PROGRESS,
  GETDATA_DESIGNATION_SUCCESS,
  GET_DESIGNATION_FAILURE,
  GET_DESIGNATION_SUCCESS,
  UPDATE_DESIGNATION_FAILURE,
  UPDATE_DESIGNATION_IN_PROGRESS,
  UPDATE_DESIGNATION_SUCCESS,
} from "../../../constants/ActionTypes";

const defaultStatus = {
  inProgress: false,
  success: false,
  error: false,
  data: null,
};
const initialState = {
  designation: { ...defaultStatus },
  addDesignation: { ...defaultStatus },
  deleteDesignation: { ...defaultStatus },
  updateDesignation: { ...defaultStatus },
};

const DesignationReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_DESIGNATION_IN_PROGRESS:
      return {
        ...state,
        designation: { ...defaultStatus, inProgress: true, ...payload },
        updateDesignation: { ...defaultStatus },
        addDesignation: { ...defaultStatus },
      };
    case GET_DESIGNATION_SUCCESS:
      return {
        ...state,
        designation: { ...defaultStatus, ...payload, success: true },
      };
    case GET_DESIGNATION_FAILURE:
      return {
        ...state,
        designation: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case ADD_DESIGNATION_IN_PROGRESS:
      return {
        ...state,
        addDesignation: { ...defaultStatus, ...payload, inProgress: true },
      };
    case ADD_DESIGNATION_SUCCESS:
      return {
        ...state,
        addDesignation: { ...defaultStatus, ...payload, success: true },
      };
    case ADD_DESIGNATION_FAILURE:
      return {
        ...state,
        addDesignation: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case DELETE_DESIGNATION_IN_PROGRESS:
      return {
        ...state,
        deleteDesignation: { ...defaultStatus, ...payload, inProgress: true },
      };
    case DELETE_DESIGNATION_SUCCESS:
      return {
        ...state,
        deleteDesignation: { ...defaultStatus, ...payload, success: true },
      };
    case DELETE_DESIGNATION_FAILURE:
      return {
        ...state,
        deleteDesignation: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case GETDATA_DESIGNATION_IN_PROGRESS:
      return {
        ...state,
        getDataDesignation: { ...defaultStatus, ...payload, inProgress: true },
      };
    case GETDATA_DESIGNATION_SUCCESS:
      return {
        ...state,
        getDataDesignation: { ...defaultStatus, ...payload, success: true },
      };
    case GETDATA_DESIGNATION_FAILURE:
      return {
        ...state,
        getDataDesignation: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case UPDATE_DESIGNATION_IN_PROGRESS:
      return {
        ...state,
        updateDesignation: { ...defaultStatus, ...payload, inProgress: true },
      };
    case UPDATE_DESIGNATION_SUCCESS:
      return {
        ...state,
        updateDesignation: { ...defaultStatus, ...payload, success: true },
      };
    case UPDATE_DESIGNATION_FAILURE:
      return {
        ...state,
        updateDesignation: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    default:
      return state;
  }
};

export default DesignationReducer;
