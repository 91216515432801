export const options = [
  { label: "25", value: "25" },
  { label: "50", value: "50" },
  { label: "100", value: "100" },
  { label: "All", value: "All" }
]
export const datas = [
  {
  "S.No":'1',
    Doctor: 'DR. SUMITHRA',
    Description: '',
  },
  {
    "S.No":'2',
    Doctor: 'PRINCE JIJI',
    Description: '',
  },
  {
    "S.No":'3',
    Doctor: 'PRIYA PRABHU',
    Description: '',
  },
  {
    "S.No":'4',
    Doctor: 'PRACTO',
    Description: '',
  },
  {
    "S.No":'5',
    Doctor: 'Dr.Ayyam Perumal',
    Description: '',
  },
{
    "S.No":'6',
    Doctor: 'Dr.Shantha',
    Description: '',
  },
{
    "S.No":'7',
    Doctor: 'Dr.Nagashree',
    Description: '',
  },
{
    "S.No":'8',
    Doctor: 'Dr. Krishnamma',
    Description: '',
  },
{
    "S.No":'9',
    Doctor: 'Dr. Renuka Jayaprakash',
    Description: '',
  },
{
    "S.No":'10',
    Doctor: 'Relative',
    Description: '',
  },
];