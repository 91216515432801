import React from "react";
import { Card } from "antd";
import ItemTransferHeader from "./ItemTransferHeader";
import ItemTransferTable from "./ItemTransferTable";

const ItemTransferScreen = () => {
  return (
    <Card>
      <ItemTransferHeader />
      <ItemTransferTable />
    </Card>
  );
};

export default ItemTransferScreen;
