import { Card, Col, Divider, Row } from "antd";
import React from "react";
import Auxiliary from "../../../util/Auxiliary";

import IntlMessages from "../../../util/IntlMessages";
import DoctorEditForm from "./DoctorEditForm";

const DoctorEditScreen = () => {
  return (
    <Auxiliary>
      <Card>
        <Row className="p-10 Add-button">
          <Col span={24}>
            {/* <h1 className="text"> */}
            <p className="header-tag">{<IntlMessages id={"Addnew.doctor.label.editdoctor"} />}</p>  
            {/* </h1> */}
          </Col>
          <Divider />
        </Row>
        <DoctorEditForm />
      </Card>
    </Auxiliary>
  );
};

export default DoctorEditScreen;
