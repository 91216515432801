import { GET_DASHBOARD_IN_ERROR, GET_DASHBOARD_IN_PROGRESS, GET_DASHBOARD_IN_SUCCESS } from "../../../../constants/ActionTypes";
  
  // Get All TeamMember List
  export const getDashboradListInProgress = (payload) => ({
    type: GET_DASHBOARD_IN_PROGRESS,
    payload: payload,
  });
  
  export const getDashboradListInSuccess = (payload) => ({
    type: GET_DASHBOARD_IN_SUCCESS,
    payload: payload,
  });
  
  export const getDashboradListInError = (payload) => ({
    type: GET_DASHBOARD_IN_ERROR,
    payload: payload,
  });

  
 