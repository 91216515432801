import React from "react";

import { Card } from "antd";
import StockAdjustmentHeader from "./StockAdjustmentHeader";
import StockAdjustmentTable from "./stockAdjustmentTable";

const StockadjustmentScreen = () => {
  return (
    <Card>
      <StockAdjustmentHeader />
      <StockAdjustmentTable />
    </Card>
  );
};

export default StockadjustmentScreen;
