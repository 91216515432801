import {
  LOGIN_USER_ERROR,
  LOGIN_USER_IN_PROGRESS,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_IN_PROGRESS,
  LOGOUT_USER_SUCCESS,
  REGISTER_USER_ERROR,
  REGISTER_USER_IN_PROGRESS,
  REGISTER_USER_SUCCESS,
} from "../../../constants/ActionTypes";

const defaultStatus = {
  inProgress: false,
  success: false,
  error: false,
  data: [],
};
const initialState = {
  authData: { ...defaultStatus },
  registerData: { ...defaultStatus },
};

const AuthReducer1 = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOGIN_USER_IN_PROGRESS:
      return {
        ...state,
        authData: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        authData: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case LOGIN_USER_ERROR:
      return {
        ...state,
        authData: { ...defaultStatus, ...payload, error: true },
      };
    case REGISTER_USER_IN_PROGRESS:
      return {
        ...state,
        registerData: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case REGISTER_USER_SUCCESS:
      return {
        ...state,
        registerData: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case REGISTER_USER_ERROR:
      return {
        ...state,
        registerData: { ...defaultStatus, ...payload, error: true },
      };
    case LOGOUT_USER_IN_PROGRESS:
      return {
        ...state,
        authData: {
          ...defaultStatus,
        },
      };
    case LOGOUT_USER_SUCCESS:
      return {
        ...state,
        authData: {
          ...defaultStatus,
          success: false,
          error: true,
        },
      };
    default:
      return state;
  }
};

export default AuthReducer1;
