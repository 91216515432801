import {
  FORGET_PASSWORD_FAILURE,
  FORGET_PASSWORD_IN_PROGRESS,
  FORGET_PASSWORD_SUCCESS,
  GET_DESIGNATION_LIST_FAILURE,
  GET_DESIGNATION_LIST_IN_PROGRESS,
  GET_DESIGNATION_LIST_SUCCESS,
  GET_ORG_LIST_FAILURE,
  GET_ORG_LIST_IN_PROGRESS,
  GET_ORG_LIST_SUCCESS,
  GET_SPECIALIZATION_LIST_FAILURE,
  GET_SPECIALIZATION_LIST_IN_PROGRESS,
  GET_SPECIALIZATION_LIST_SUCCESS,
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  OTP_SEND_FAILURE,
  OTP_SEND_IN_PROGRESS,
  OTP_SEND_SUCCESS,
  REQUEST_VIDEO_CALL_FAILURE,
  REQUEST_VIDEO_CALL_IN_PROGRESS,
  REQUEST_VIDEO_CALL_SUCCESS,
  SET_FORGET_SUCCESS,
  SET_OTP,
  SET_REGISTER_SUCCESS,
  SET_VERIFICATION_OTP,
  SHOW_MESSAGE,
  SIGNIN_FACEBOOK_USER,
  SIGNIN_FACEBOOK_USER_SUCCESS,
  SIGNIN_GITHUB_USER,
  SIGNIN_GITHUB_USER_SUCCESS,
  SIGNIN_GOOGLE_USER,
  SIGNIN_GOOGLE_USER_SUCCESS,
  SIGNIN_TWITTER_USER,
  SIGNIN_TWITTER_USER_SUCCESS,
  SIGNIN_USER,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER,
  SIGNUP_USER_SUCCESS,
  VERIFY_OTP_FAILURE,
  VERIFY_OTP_IN_PROGRESS,
  VERIFY_OTP_SUCCESS,
} from "../../../constants/ActionTypes";

export const userSignUp = (user) => {
  return {
    type: SIGNUP_USER,
    payload: user,
  };
};
export const userSignIn = (user) => {
  return {
    type: SIGNIN_USER,
    payload: user,
  };
};
export const userSignOut = () => {
  return {
    type: SIGNOUT_USER,
  };
};
export const userSignUpSuccess = (authUser) => {
  return {
    type: SIGNUP_USER_SUCCESS,
    payload: authUser,
  };
};

export const userSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_USER_SUCCESS,
    payload: authUser,
  };
};

export const userSignOutSuccess = () => {
  return {
    type: SIGNOUT_USER_SUCCESS,
  };
};

export const showAuthMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message,
  };
};

export const userGoogleSignIn = () => {
  return {
    type: SIGNIN_GOOGLE_USER,
  };
};
export const userGoogleSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_GOOGLE_USER_SUCCESS,
    payload: authUser,
  };
};
export const userFacebookSignIn = () => {
  return {
    type: SIGNIN_FACEBOOK_USER,
  };
};
export const userFacebookSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_FACEBOOK_USER_SUCCESS,
    payload: authUser,
  };
};
export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url,
  };
};
export const userTwitterSignIn = () => {
  return {
    type: SIGNIN_TWITTER_USER,
  };
};
export const userTwitterSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_TWITTER_USER_SUCCESS,
    payload: authUser,
  };
};
export const userGithubSignIn = () => {
  return {
    type: SIGNIN_GITHUB_USER,
  };
};
export const userGithubSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_GITHUB_USER_SUCCESS,
    payload: authUser,
  };
};
export const showAuthLoader = () => {
  return {
    type: ON_SHOW_LOADER,
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};
export const hideAuthLoader = () => {
  return {
    type: ON_HIDE_LOADER,
  };
};

export function VideoCallInProgress(payload) {
  return {
    type: REQUEST_VIDEO_CALL_IN_PROGRESS,
    payload: payload,
  };
}

export function videoCallSuccess(payload) {
  return {
    type: REQUEST_VIDEO_CALL_SUCCESS,
    payload: payload,
  };
}
export function VideoCallError(payload) {
  return {
    type: REQUEST_VIDEO_CALL_FAILURE,
    payload: payload,
  };
}

export function OtpSendInProgress(payload) {
  return {
    type: OTP_SEND_IN_PROGRESS,
    payload: payload,
  };
}

export function OtpSendSuccess(payload) {
  return {
    type: OTP_SEND_SUCCESS,
    payload: payload,
  };
}
export function OtpSendError(payload) {
  return {
    type: OTP_SEND_FAILURE,
    payload: payload,
  };
}
export function VerifyOtpInProgress(payload) {
  return {
    type: VERIFY_OTP_IN_PROGRESS,
    payload: payload,
  };
}

export function VerifyOtpSuccess(payload) {
  return {
    type: VERIFY_OTP_SUCCESS,
    payload: payload,
  };
}
export function VerifyOtpError(payload) {
  return {
    type: VERIFY_OTP_FAILURE,
    payload: payload,
  };
}

export function ForgetPasswordInProgress(payload) {
  return {
    type: FORGET_PASSWORD_IN_PROGRESS,
    payload: payload,
  };
}

export function ForgetPasswordSuccess(payload) {
  return {
    type: FORGET_PASSWORD_SUCCESS,
    payload: payload,
  };
}
export function ForgetPasswordError(payload) {
  return {
    type: FORGET_PASSWORD_FAILURE,
    payload: payload,
  };
}
export function setOtp() {
  return {
    type: SET_OTP,
  };
}
export function setVerificationOtp() {
  return {
    type: SET_VERIFICATION_OTP,
  };
}
export function setForgetPassword() {
  return {
    type: SET_FORGET_SUCCESS,
  };
}
export function setRegisterSuccess() {
  return {
    type: SET_REGISTER_SUCCESS,
  };
}
export function getOrgListInProgress(payload) {
  return {
    type: GET_ORG_LIST_IN_PROGRESS,
    payload: payload,
  };
}

export function getOrgListSuccess(payload) {
  return {
    type: GET_ORG_LIST_SUCCESS,
    payload: payload,
  };
}
export function getOrgListError(payload) {
  return {
    type: GET_ORG_LIST_FAILURE,
    payload: payload,
  };
}

export function getSpecializationListInProgress(payload) {
  return {
    type: GET_SPECIALIZATION_LIST_IN_PROGRESS,
    payload: payload,
  };
}

export function getSpecializationListSuccess(payload) {
  return {
    type: GET_SPECIALIZATION_LIST_SUCCESS,
    payload: payload,
  };
}
export function getSpecializationListError(payload) {
  return {
    type: GET_SPECIALIZATION_LIST_FAILURE,
    payload: payload,
  };
}
export function getDesignationListInProgress(payload) {
  return {
    type: GET_DESIGNATION_LIST_IN_PROGRESS,
    payload: payload,
  };
}

export function getDesignationListSuccess(payload) {
  return {
    type: GET_DESIGNATION_LIST_SUCCESS,
    payload: payload,
  };
}
export function getDesignationListError(payload) {
  return {
    type: GET_DESIGNATION_LIST_FAILURE,
    payload: payload,
  };
}
