import { call, put, takeLatest } from "@redux-saga/core/effects";
import { GET_DOCTOR_DASHBOARD_PROGRESS } from "../../../constants/ActionTypes";
import {
  getDoctorDashBoardInError,
  getDoctorDashBoardInSuccess,
} from "../actions/DashBoard";
import { getDoctorDashBoardEffect } from "./effects/DashBoard";
import { getErrorMessageFromAPI } from "../../../util/utilz";
import { message } from "antd";

function* getDoctorDashBoard({ payload }) {
  try {
    const { data, status } = yield call(getDoctorDashBoardEffect, payload);

    if (status == "500") {
      message.loading("Please try again later!");
    }
    yield put(
      getDoctorDashBoardInSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
  } catch (e) {
    yield put(
      getDoctorDashBoardInError({
        message: getErrorMessageFromAPI(e),
        error: true,
      })
    );
    // message.error(getErrorMessageFromAPI(e));
  }
}

export function* DashBoardSaga() {
  yield takeLatest(GET_DOCTOR_DASHBOARD_PROGRESS, getDoctorDashBoard);
}
