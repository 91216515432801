import {
  ADD_QUALIFICATION_LIST_IN_ERROR,
  ADD_QUALIFICATION_LIST_IN_PROGRESS,
  ADD_QUALIFICATION_LIST_IN_SUCCESS,
  DELETE_QUALIFICATION_LIST_IN_ERROR,
  DELETE_QUALIFICATION_LIST_IN_PROGRESS,
  DELETE_QUALIFICATION_LIST_IN_SUCCESS,
  GET_QUALIFICATION_LIST_IN_ERROR,
  GET_QUALIFICATION_LIST_IN_PROGRESS,
  GET_QUALIFICATION_LIST_IN_SUCCESS,
  GET_QUALIFICATION_UPDATE_LIST_ERROR,
  GET_QUALIFICATION_UPDATE_LIST_PROGRESS,
  GET_QUALIFICATION_UPDATE_LIST_SUCCESS,
  UPDATE_QUALIFICATION_LIST_IN_ERROR,
  UPDATE_QUALIFICATION_LIST_IN_PROGRESS,
  UPDATE_QUALIFICATION_LIST_IN_SUCCESS,
} from "../../../constants/ActionTypes";

export const getQualificationListInProgress = (payload) => ({
  type: GET_QUALIFICATION_LIST_IN_PROGRESS,
  payload: payload,
});

export const getQualificationListInSuccess = (payload) => ({
  type: GET_QUALIFICATION_LIST_IN_SUCCESS,
  payload: payload,
});

export const getQualificationListInError = (payload) => ({
  type: GET_QUALIFICATION_LIST_IN_ERROR,
  payload: payload,
});

export const deleteQualificationListInProgress = (payload) => ({
  type: DELETE_QUALIFICATION_LIST_IN_PROGRESS,
  payload: payload,
});

export const deleteQualificationListInSuccess = (payload) => ({
  type: DELETE_QUALIFICATION_LIST_IN_SUCCESS,
  payload: payload,
});

export const deleteQualificationListInError = (payload) => ({
  type: DELETE_QUALIFICATION_LIST_IN_ERROR,
  payload: payload,
});

export const addQualificationListInProgress = (payload) => ({
  type: ADD_QUALIFICATION_LIST_IN_PROGRESS,
  payload: payload,
});

export const addQualificationListInSuccess = (payload) => ({
  type: ADD_QUALIFICATION_LIST_IN_SUCCESS,
  payload: payload,
});

export const addQualificationListInError = (payload) => ({
  type: ADD_QUALIFICATION_LIST_IN_ERROR,
  payload: payload,
});

export const updateQualificationListInProgress = (payload) => ({
  type: UPDATE_QUALIFICATION_LIST_IN_PROGRESS,
  payload: payload,
});

export const updateQualificationListInSuccess = (payload) => ({
  type: UPDATE_QUALIFICATION_LIST_IN_SUCCESS,
  payload: payload,
});

export const updateQualificationListInError = () => ({
  type: UPDATE_QUALIFICATION_LIST_IN_ERROR,
});

export const getQualificationUpdateListInProgress = (payload) => {
  return {
    type: GET_QUALIFICATION_UPDATE_LIST_PROGRESS,
    payload: payload,
  };
};
export const getQualificationUpdateListSuccess = (payload) => {
  return {
    type: GET_QUALIFICATION_UPDATE_LIST_SUCCESS,
    payload: payload,
  };
};

export const getQualificationUpdateListError = () => {
  return {
    type: GET_QUALIFICATION_UPDATE_LIST_ERROR,
  };
};
