import {
  ADD_DOCTOR_LIST_ERROR,
  ADD_DOCTOR_LIST_PROGRESS,
  ADD_DOCTOR_LIST_SUCCESS,
  ADD_REASON_ERROR,
  ADD_REASON_INPROGRESS,
  ADD_REASON_SUCCESS,
  ADD_SYMPTOM_ERROR,
  ADD_SYMPTOM_INPROGRESS,
  ADD_SYMPTOM_SUCCESS,
  DELETE_DOCTOR_LIST_ERROR,
  DELETE_DOCTOR_LIST_PROGRESS,
  DELETE_DOCTOR_LIST_SUCCESS,
  DELETE_REASON_ERROR,
  DELETE_REASON_INPROGRESS,
  DELETE_REASON_SUCCESS,
  DELETE_SYMPTOM_ERROR,
  DELETE_SYMPTOM_INPROGRESS,
  DELETE_SYMPTOM_SUCCESS,
  DOCTOR_CSV_UPLOAD_IN_FAILURE,
  DOCTOR_CSV_UPLOAD_IN_PROGRESS,
  DOCTOR_CSV_UPLOAD_IN_SUCCESS,
  GET_DOCTOR_LIST_ERROR,
  GET_DOCTOR_LIST_PROGRESS,
  GET_DOCTOR_LIST_SUCCESS,
  GET_DOCTOR_UPDATE_LIST_ERROR,
  GET_DOCTOR_UPDATE_LIST_PROGRESS,
  GET_DOCTOR_UPDATE_LIST_SUCCESS,
  GET_OUT_DOCTORLIST_ERROR,
  GET_OUT_DOCTORLIST_INPROGRESS,
  GET_OUT_DOCTORLIST_SUCCESS,
  GET_REASON_ERROR,
  GET_REASON_INPROGRESS,
  GET_REASON_SUCCESS,
  GET_SYMPTOM_ERROR,
  GET_SYMPTOM_INPROGRESS,
  GET_SYMPTOM_SUCCESS,
  UPDATE_DOCTOR_LIST_ERROR,
  UPDATE_DOCTOR_LIST_PROGRESS,
  UPDATE_DOCTOR_LIST_SUCCESS,
  UPDATE_REASON_ERROR,
  UPDATE_REASON_INPROGRESS,
  UPDATE_REASON_SUCCESS,
  UPDATE_SYMPTOM_ERROR,
  UPDATE_SYMPTOM_INPROGRESS,
  UPDATE_SYMPTOM_SUCCESS,
} from "../../../../constants/ActionTypes";

const defaultStatus = {
  progressing: false,
  success: false,
  error: false,
  data: [],
};
const initialState = {
  getlist: { ...defaultStatus },
  addlist: { ...defaultStatus },
  updatelist: { ...defaultStatus },
  deletelist: { ...defaultStatus },
  getupdatelist: { ...defaultStatus },
  getdesignationlist: { ...defaultStatus },
  getorganizationlist: { ...defaultStatus },
  getspecializationlist: { ...defaultStatus },
  csv: { ...defaultStatus },
  reasonList: { ...defaultStatus },
  symptomList: { ...defaultStatus },
  addReason: { ...defaultStatus },
  addSymptom: { ...defaultStatus },
  updateReason: { ...defaultStatus },
  deleteReason: { ...defaultStatus },
  updateSymptom: { ...defaultStatus },
  deleteSymptom: { ...defaultStatus },
  outDoctorList: { ...defaultStatus },
};
const doctorReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_DOCTOR_LIST_PROGRESS:
      return {
        ...state,
        getlist: {
          ...defaultStatus,
          progressing: true,
        },
      };
    case GET_DOCTOR_LIST_SUCCESS:
      return {
        ...state,
        getlist: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case GET_DOCTOR_LIST_ERROR:
      return {
        ...state,
        getlist: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case ADD_DOCTOR_LIST_PROGRESS:
      return {
        ...state,
        addlist: {
          ...defaultStatus,
          ...payload,
          progressing: true,
        },
      };
    case ADD_DOCTOR_LIST_SUCCESS:
      return {
        ...state,
        addlist: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case ADD_DOCTOR_LIST_ERROR:
      return {
        ...state,
        addlist: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case UPDATE_DOCTOR_LIST_PROGRESS:
      return {
        ...state,
        updatelist: {
          ...defaultStatus,
          ...payload,
          progressing: true,
        },
      };
    case UPDATE_DOCTOR_LIST_SUCCESS:
      return {
        ...state,
        updatelist: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case UPDATE_DOCTOR_LIST_ERROR:
      return {
        ...state,
        updatelist: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case DELETE_DOCTOR_LIST_PROGRESS:
      return {
        ...state,
        deletelist: {
          ...defaultStatus,
          progressing: true,
          ...payload,
        },
      };
    case DELETE_DOCTOR_LIST_SUCCESS:
      return {
        ...state,
        deletelist: {
          ...defaultStatus,
          success: true,
          ...payload,
        },
      };
    case DELETE_DOCTOR_LIST_ERROR:
      return {
        ...state,
        deletelist: {
          ...defaultStatus,
          error: true,
          ...payload,
        },
      };

    case GET_DOCTOR_UPDATE_LIST_PROGRESS:
      return {
        ...state,
        getupdatelist: { ...defaultStatus, ...payload, progressing: true },
      };
    case GET_DOCTOR_UPDATE_LIST_SUCCESS:
      return {
        ...state,
        getupdatelist: { ...defaultStatus, ...payload.data, success: true },
      };
    case GET_DOCTOR_UPDATE_LIST_ERROR:
      return {
        ...state,
        getupdatelist: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };

    case DOCTOR_CSV_UPLOAD_IN_PROGRESS:
      return {
        ...state,
        csv: { ...defaultStatus, ...payload, progressing: true },
      };
    case DOCTOR_CSV_UPLOAD_IN_SUCCESS:
      return {
        ...state,
        csv: { ...defaultStatus, ...payload.data, success: true },
      };
    case DOCTOR_CSV_UPLOAD_IN_FAILURE:
      return {
        ...state,
        csv: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case GET_REASON_INPROGRESS:
      return {
        ...state,
        reasonList: { ...defaultStatus, ...payload, progressing: true },
      };
    case GET_REASON_SUCCESS:
      return {
        ...state,
        reasonList: { ...defaultStatus, ...payload.data, success: true },
      };
    case GET_REASON_ERROR:
      return {
        ...state,
        reasonList: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case GET_SYMPTOM_INPROGRESS:
      return {
        ...state,
        symptomList: { ...defaultStatus, ...payload, progressing: true },
      };
    case GET_SYMPTOM_SUCCESS:
      return {
        ...state,
        symptomList: { ...defaultStatus, ...payload.data, success: true },
      };
    case GET_SYMPTOM_ERROR:
      return {
        ...state,
        symptomList: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case ADD_REASON_INPROGRESS:
      return {
        ...state,
        addReason: { ...defaultStatus, ...payload, progressing: true },
      };
    case ADD_REASON_SUCCESS:
      return {
        ...state,
        addReason: { ...defaultStatus, ...payload.data, success: true },
      };
    case ADD_REASON_ERROR:
      return {
        ...state,
        addReason: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case UPDATE_REASON_INPROGRESS:
      return {
        ...state,
        updateReason: { ...defaultStatus, ...payload, progressing: true },
      };
    case UPDATE_REASON_SUCCESS:
      return {
        ...state,
        updateReason: { ...defaultStatus, ...payload.data, success: true },
      };
    case UPDATE_REASON_ERROR:
      return {
        ...state,
        updateReason: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case DELETE_REASON_INPROGRESS:
      return {
        ...state,
        deleteReason: { ...defaultStatus, ...payload, progressing: true },
      };
    case DELETE_REASON_SUCCESS:
      return {
        ...state,
        deleteReason: { ...defaultStatus, ...payload.data, success: true },
      };
    case DELETE_REASON_ERROR:
      return {
        ...state,
        deleteReason: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case ADD_SYMPTOM_INPROGRESS:
      return {
        ...state,
        addSymptom: { ...defaultStatus, ...payload, progressing: true },
      };
    case ADD_SYMPTOM_SUCCESS:
      return {
        ...state,
        addSymptom: { ...defaultStatus, ...payload.data, success: true },
      };
    case ADD_SYMPTOM_ERROR:
      return {
        ...state,
        addSymptom: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case UPDATE_SYMPTOM_INPROGRESS:
      return {
        ...state,
        updateSymptom: { ...defaultStatus, ...payload, progressing: true },
      };
    case UPDATE_SYMPTOM_SUCCESS:
      return {
        ...state,
        updateSymptom: { ...defaultStatus, ...payload.data, success: true },
      };
    case UPDATE_SYMPTOM_ERROR:
      return {
        ...state,
        updateSymptom: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case DELETE_SYMPTOM_INPROGRESS:
      return {
        ...state,
        deleteSymptom: { ...defaultStatus, ...payload, progressing: true },
      };
    case DELETE_SYMPTOM_SUCCESS:
      return {
        ...state,
        deleteSymptom: { ...defaultStatus, ...payload.data, success: true },
      };
    case DELETE_SYMPTOM_ERROR:
      return {
        ...state,
        deleteSymptom: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case GET_OUT_DOCTORLIST_INPROGRESS:
      return {
        ...state,
        outDoctorList: { ...defaultStatus, ...payload, progressing: true },
      };
    case GET_OUT_DOCTORLIST_SUCCESS:
      return {
        ...state,
        outDoctorList: { ...defaultStatus, ...payload.data, success: true },
      };
    case GET_OUT_DOCTORLIST_ERROR:
      return {
        ...state,
        outDoctorList: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    default:
      return state;
  }
};
export default doctorReducer;
