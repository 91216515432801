import { Col, Radio, Row } from "antd";
import { useForm } from "antd/es/form/Form";
import moment from "moment";
import { start } from "nprogress";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getscheduleListEffect } from "../../../../appRedux/Patient/Sagas/Effect/patientAppointmentEffect";
import { TimeSlots } from "../../data";
import { upperCase } from "lodash";
const TimeSlotDates = ({
  handleSlot,
  date,
  selectedValue,
  selectValue,
  form,
}) => {
  // useEffect(()=>{
  //   getStatus();
  // },[]);
  //   const [schedule,setSchedule]=useState();

  //   console.log("fomr daaaa", form.getFieldsValue())
  //   const getStatus = async()=>{
  //     try{
  //       if(form.getFieldValue('date') && form.getFieldValue('doctor_id')){
  // // const date = localStorage.getItem("date");
  // // const doctor_id = localStorage.getItem("doctor-id")

  //       const {data} = await getscheduleListEffect('date','doctor_id');
  //       console.log("schedule slot time",data)

  //       // console.log("dataslot",data)
  //       // const scheduleList =  data?.data?.[0]
  //       console.log("schedule slot",data)

  //     //  console.log(typeof(scheduleList))
  //     setSchedule(data)
  //       }
  // //  localStorage.setItem("scheduleAppointment",JSON.stringify([scheduleList]))
  //     }
  //     catch(error){
  //     console.log(error)
  //     }

  //   }
  // const schedule =localStorage.getItem("scheduleAppointment")
  // console.log("schedule",schedule)
  // const start_time= localStorage.getItem("start-time");
  // const end_time= localStorage.getItem("end-time");
  // const isBooked= localStorage.getItem("bookedno");
  // console.log("isbooked",isBooked)
  // const [val, setVal] = useState(isBooked || "");
  const { getSchedule } = useSelector((state) => state.patientAppointment);

  // const scheduleData = useSelector((state)=>state);

  const [error, setError] = useState(null);
  return (
    <Row
      // style={{ flexDirection: "row" }}
      // justify={"center"}
      //  justify-content= {"space-evenly"}
      //   align={"middle"}
      className={"timeslot-container"}
    >
      {getSchedule.data?.data.map(
        ({ id, start_time, end_time, isBooked }, index) => {
          localStorage.setItem("schedule-id", id);
          const startTime = moment(start_time, ["h:mm A"]).format("hh:mm A");
          const endTime = moment(end_time, ["h:mm A"]).format("hh:mm A");
          const value = id;

          return (
            <Col style={{ marginLeft: "25px" }}>
              <Radio.Button
                disabled={upperCase(isBooked) === "YES"}
                className={`slot-button ${
                  selectedValue === value ? "selected" : "shake"
                } ${upperCase(isBooked) === "YES" ? "disabled" : ""}`}
                onClick={() => {
                  handleSlot(value);
                }}
              >
                {startTime}-{endTime}
              </Radio.Button>
              {error && <p style={{ color: "red" }}>{error}</p>}
            </Col>
          );
        }
      )}

      {/* })}  */}
    </Row>
  );
};
export default TimeSlotDates;
