import React, { useEffect, useState } from "react";

import { Row, Col, Spin, Modal, Tooltip, Typography } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from "@ant-design/icons";

import { useDispatch } from "react-redux";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import TableComponent from "../../../components/Table/TableComponent";
import {
  defaultBulkMedicine,
  defaultTablet,
  deleteTabletInProgress,
  getTabletsByOrgInProgress,
} from "../../../appRedux/Pharmacy/PharmacyAction";

import moment from "moment";
import { access } from "../../../util/utilz";

const MedicineListTable = () => {
  const [deleteId, setId] = useState("");
  const orgId = localStorage.getItem("org_id");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState("");
  const dispatch = useDispatch();
  const tabletsByOrgData = useSelector((state) => state?.pharmacy?.tabletOrg);
  const deleteTablets = useSelector((state) => state?.pharmacy?.deleteTablet);
  const tabletsByOrgDataList = tabletsByOrgData?.data?.data;

  const totalRecords = tabletsByOrgData?.data?.totalRecords;
  const showModal = (id) => {
    setIsModalOpen(true);
    setId(id);
  };
  const columns = [
    {
      title: "Id",
      dataIndex: "tablet_serial_no",
      // render: (text, record, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "medicine_name",
      sortDirections: ["ascend", "descend"],
      key: "medicine_name",
      sorter: (a, b) => {
        if (a && b && a.medicine_name && b.medicine_name) {
          return a.medicine_name.localeCompare(b.medicine_name);
        }
        return 0;
      },
    },

    {
      title: "Store Box",
      dataIndex: "box",
      sortDirections: ["ascend", "descend"],
      key: "box",

      sorter: (a, b) => {
        if (a && b && a.box && b.box) {
          return a.box.localeCompare(b.box);
        }
        return 0;
      },
    },
    {
      title: "Category",
      dataIndex: "category",
      sortDirections: ["ascend", "descend"],
      key: "category",
      sorter: (a, b) => {
        if (a && b && a.category && b.category) {
          return a.category.localeCompare(b.category);
        }
        return 0;
      },
    },
    {
      title: "Purchase Price",
      dataIndex: "price",
      sortDirections: ["ascend", "descend"],
      key: "price",
      sorter: (a, b) => {
        if (a && b && a.price && b.price) {
          return a.price.localeCompare(b.price);
        }
        return 0;
      },
    },
    {
      title: "Sale Price",
      dataIndex: "s_price",
      sortDirections: ["ascend", "descend"],
      key: "s_price",
      sorter: (a, b) => {
        if (a && b && a.s_price && b.s_price) {
          return a.s_price.localeCompare(b.s_price);
        }
        return 0;
      },
    },
    {
      title: "Generic Name",
      dataIndex: "generic",
      sortDirections: ["ascend", "descend"],
      key: "generic",
      sorter: (a, b) => {
        if (a && b && a.generic && b.generic) {
          return a.generic.localeCompare(b.generic);
        }
        return 0;
      },
    },
    {
      title: "Company",
      dataIndex: "company",
      sortDirections: ["ascend", "descend"],
      key: "company",
      sorter: (a, b) => {
        if (a && b && a.company && b.company) {
          return a.company.localeCompare(b.company);
        }
        return 0;
      },
    },
    {
      title: "Expiry Date",
      dataIndex: "e_date",
      sortDirections: ["ascend", "descend"],
      key: "e_date",
      render: (text, record) => {
        return moment(text).format("DD-MM-YYYY");
      },
      sorter: (a, b) => {
        if (a && b && a.e_date && b.e_date) {
          return a.e_date.localeCompare(b.e_date);
        }
        return 0;
      },
    },

    {
      title: "Action",
      dataIndex: "Action",
      render: (_, record) => {
        return (
          <Row className="table-button">
            {access("medicineList", "edit") && (
              <Col className="button-edit">
                {" "}
                <Tooltip placement="top" title={"Edit"}>
                  {" "}
                  <Link
                    to={{
                      pathname: `/medicine/edit`,
                      state: { medicineList: record },
                    }}
                  >
                    <ButtonComponent
                      size="small"
                      type="primary"
                      icon={<EditOutlined />}
                    ></ButtonComponent>
                  </Link>
                </Tooltip>
              </Col>
            )}
            {access("medicineList", "delete") && (
              <Col className="button-edit">
                {" "}
                <Tooltip placement="top" title={"Delete"}>
                  <Typography>
                    {" "}
                    <ButtonComponent
                      size="small"
                      type="danger"
                      icon={<DeleteOutlined />}
                      onClick={() => showModal(record.id)}
                    ></ButtonComponent>
                  </Typography>
                </Tooltip>
              </Col>
            )}
          </Row>
        );
      },
    },
  ];

  const handleOk = () => {
    setIsModalOpen(false);
    dispatch(deleteTabletInProgress(deleteId));
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );

  const handlePage = (page) => {
    const formData = {
      pageNo: page,
      orgId: orgId,
      pageSize: 10,
      pagination_required: true,
    };
    dispatch(getTabletsByOrgInProgress(formData));
  };

  const handleSelectChange = (value) => {
    if (value === "all") {
      setFilteredUsers(tabletsByOrgDataList);
    } else {
      const newData = tabletsByOrgDataList.slice(0, value);
      setFilteredUsers(newData);
    }
  };

  useEffect(() => {
    const pageNo = 1;
    const formData = {
      pageNo: pageNo,
      orgId: orgId,
      pageSize: 10,
      pagination_required: true,
    };
    dispatch(getTabletsByOrgInProgress(formData));
    dispatch(defaultTablet());
    dispatch(defaultBulkMedicine());
  }, []);

  useEffect(() => {
    if (tabletsByOrgData?.inProgress) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [tabletsByOrgData]);
  useEffect(() => {
    if (deleteTablets?.success) {
      const formData = {
        pageNo: 1,
        orgId: orgId,
        pageSize: 10,
        pagination_required: true,
      };
      dispatch(getTabletsByOrgInProgress(formData));
    }
  }, [deleteTablets]);
  return (
    <Spin indicator={antIcon} spinning={Loading}>
      <TableComponent
        columns={columns}
        dataSource={filteredUsers ? filteredUsers : tabletsByOrgDataList}
        pagination={{
          total: totalRecords,
          onChange: (page) => handlePage(page),
        }}
        rowselector={handleSelectChange}
        itemList={tabletsByOrgDataList}
        filteredUsers={filteredUsers}
        setFilteredUsers={setFilteredUsers}
        field={"medicine_name"}
        documentTitle={"Medicine List"}
      />
      <Modal
        title="Delete Data"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelButtonProps={{ className: "secondary-color" }}
        // pagination={{
        //   total: totalRecords,
        //   onChange: (page) => handlePage(page),
        // }}
      >
        <p>Are you sure, you want to delete the Data</p>
      </Modal>
    </Spin>
  );
};

export default MedicineListTable;
