import { call, put, takeLatest } from "redux-saga/effects";
import Message from "../../../components/Message/Message";
import {
  ADD_CONSULTATION_IN_PROGRESS,
  GET_CONSULTATION_IN_PROGRESS,
  UPDATE_CONSULTATION_IN_PROGRESS,
} from "../../../constants/ActionTypes";
import { getErrorMessageFromAPI } from "../../../util/utilz";
import {
  AddConsultationError,
  AddConsultationSuccess,
  GetConsultationError,
  GetConsultationSuccess,
  UpdateConsultationError,
  UpdateConsultationSuccess,
} from "../actions/consultation";
import {
  AddConsultationEffect,
  GetConsultationEffect,
  UpdateConsultationEffect,
} from "./effects/ConsultationEffect";

function* AddConsultationWatcher({ payload }) {
  try {
    let { data } = yield call(AddConsultationEffect, payload);

    yield put(
      AddConsultationSuccess({
        data: data,
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield put(
      AddConsultationError({
        error: true,
        message: e["message"],
      })
    );
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
  }
}
function* UpdateConsultationWatcher({ payload }) {
  try {
    let { data } = yield call(UpdateConsultationEffect, payload);

    yield put(
      UpdateConsultationSuccess({
        data: data,
        success: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield put(
      UpdateConsultationError({
        error: true,
        message: e["message"],
      })
    );
  }
}

function* GetConsultationWatcher({ payload }) {
  try {
    let data = yield call(GetConsultationEffect, payload);

    yield put(
      GetConsultationSuccess({
        data: data?.data,
        success: true,
      })
    );
  } catch (e) {
    // yield call(Message, {
    //   type: "error",
    //   content: getErrorMessageFromAPI(e),
    // });
    yield put(
      GetConsultationError({
        error: true,
        data: getErrorMessageFromAPI(e),
      })
    );
  }
}
export default function* ConsultationSaga() {
  yield takeLatest(ADD_CONSULTATION_IN_PROGRESS, AddConsultationWatcher);
  yield takeLatest(UPDATE_CONSULTATION_IN_PROGRESS, UpdateConsultationWatcher);
  yield takeLatest(GET_CONSULTATION_IN_PROGRESS, GetConsultationWatcher);
}
