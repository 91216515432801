import React, { useState } from "react";

import { Col, Divider, Row } from "antd";
import { Link } from "react-router-dom";
// import ButtonComponent from "../../../components/Button/ButtonComponent";

// import IntlMessages from "../../../util/IntlMessages";
import "../../../styles/modules/billingPackages.less";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import IntlMessages from "../../../util/IntlMessages";
import { PlusCircleOutlined } from "@ant-design/icons";
import Auxiliary from "../../../util/Auxiliary";
import { APP_ROUTES } from "../../../constants/Routes";


const RolesHeader = () => {

  return (
    <Auxiliary>
      <Row className="bot">
        <Col span={12}>
          <h1 className="text">{<IntlMessages id={"roles.roles"} />}</h1>
        </Col>
        <Col span={12} className="btn-main">
          <Link to={APP_ROUTES.ADDROLES}>
            <ButtonComponent
              className="buttons"
              icon={<PlusCircleOutlined />}
              type="primary"
              label={<IntlMessages id={"roles.add"} />}
              // onClick={""}
            />
          </Link>
        </Col>
       
      </Row>
      <Divider />
    </Auxiliary>
  );
};

export default RolesHeader;
