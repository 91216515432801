import React from "react";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import "./assets/vendors/style";
import configureStore, { history } from "./appRedux/store";
import App from "./containers/App/index";

const store = configureStore(/* provide initial state if any */);
const originalConsoleLog = console.log;
// console.log = function () {
//   if (
//     arguments.length > 0 &&
//     typeof arguments[0] === "string" &&
//     arguments[0].includes("Agora-SDK")
//   ) {
//     return;
//   }

//   originalConsoleLog.apply(console, arguments);
// };
const NextApp = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>
);

export default NextApp;
