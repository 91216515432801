import { Col, Form, Row } from "antd";
import React from "react";

import ButtonComponent from "../../../components/Button/ButtonComponent";
import FormInput from "../../../components/Input/FormInput";
import Auxiliary from "../../../util/Auxiliary";
import IntlMessages from "../../../util/IntlMessages";

const RefDoctorForm = () => {
  const onFinish = (data) => {
    console.log("DT", data);
  };
  return (
    <Auxiliary>
      <Form
        name="basic"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        autoComplete="off"
        onFinish={onFinish}
      >
        <Row>
          <Col xs={24} md={12}>
            <FormInput
              type="text"
              placeholder="Doctor"
              name="Doctor"
              label={
                <IntlMessages id={"Addnew.referencedoctor.label.doctor"} />
              }
              rules={[
                {
                  required: true,
                  message: (
                    <IntlMessages
                      id={"Addnew.referencedoctor.validator.doctor"}
                    />
                  ),
                },
              ]}
            />
          </Col>
          <Col xs={24} md={12}>
            <FormInput
              type="text"
              placeholder="Description"
              name="Description"
              label={
                <IntlMessages id={"Addnew.referencedoctor.label.description"} />
              }
              rules={[
                {
                  required: true,
                  message: (
                    <IntlMessages
                      id={"Addnew.referencedoctor.validator.description"}
                    />
                  ),
                },
              ]}
            />
          </Col>
        </Row>
        <Row className="space">
          <Col className="btn-pos">
            <ButtonComponent type="danger" htmlType="reset" label="Cancel" />
            <ButtonComponent type="primary" htmlType="Submit" label="Submit" />
          </Col>
        </Row>
      </Form>
    </Auxiliary>
  );
};

export default RefDoctorForm;
