export const answerType = [
  { label: "Radio Button", value: 1 },
  { label: "Text Box", value: 2 },
  { label: "Select Box", value: 3 },
  { label: "Text Area", value: 4 },
  { label: "Check Box", value: 5 },
];

export const diseasesType = [
  { label: "Fever", value: 1 },
  { label: "Cold", value: 2 },
  { label: "Head Atack", value: 3 },
];
export const field = [
  {
    // Category:<FormSelectBox placeholder="Select" options={list} className="sel" />,
    // Quantity:<FormInput className="input" type="number" />,
    // Price:<FormInput className="input len" type="number" />,
    // Total:<FormInput className="input len" type="number" />
  },
];
