import React from "react";

import { Card } from "antd";
import PrescriptionHeader from "./Components/PrescriptionHeader";
import PrescriptionTable from "./Components/PrescriptionTable";

const PrescriptionScreen = () => {
  return (
    <Card>
      <PrescriptionHeader />
      <PrescriptionTable />
    </Card>
  );
};

export default PrescriptionScreen;
