import { Alert, InputNumber } from "antd";
import Form from "antd/lib/form/Form";
import React from "react";
import FormInput from "../../../components/Input/FormInput";
import FormSelectBox from "../../../components/SelectBox/SelectBox";
import IntlMessages from "../../../util/IntlMessages";
import { Radio } from "antd";
import { appoinment_status, patient_symptoms } from "../../Appoinment/data";

const FinalInfo = ({ form }) => {
  const [appoinmentStatus, setappoinmentStatus] = React.useState("");

  const changeAppoinmentStatus = (value) => {
    setappoinmentStatus(value);
  };
  const [symptoms, setSymptoms] = React.useState("");
  const onChangeNumber = (value) => {
    console.log("changed", value);
  };
  const changeSymptoms = (value) => {
    setSymptoms(value);
  };

  return (
    <Form.Item className="final-info-container">
      <Form.Item
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        label={<IntlMessages id={"Addnew.appoinment.label.remarks"} />}
        name={"Reference"}
      >
        <FormInput type="text" placeholder="Remarks" className="remarks" />
      </Form.Item>
      <Form.Item
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        label={<IntlMessages id={"Addnew.appoinment.label.appoinmentstatus"} />}
        name={"appoinment status"}
        rules={[
          {
            required: true,
            message: <IntlMessages id={"Addnew.patient.validator.proof"} />,
          },
        ]}
      >
        <FormSelectBox
          placeholder="Appoinment status"
          value={appoinmentStatus}
          options={appoinment_status}
          onChange={changeAppoinmentStatus}
        />
      </Form.Item>{" "}
      <Form.Item
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        label={"Select your option"}
        name={"appoinment symptoms"}
        rules={[
          {
            required: true,
            message: <IntlMessages id={"Addnew.patient.validator.proof"} />,
          },
        ]}
      >
        <FormSelectBox
          placeholder="select here"
          value={symptoms}
          options={patient_symptoms}
          onChange={changeSymptoms}
        />
      </Form.Item>
      {symptoms === "Fever" ? (
        <div>
          {" "}
          <Alert message="Enter the symptoms of fever" type="info" />
          <Form.Item
            labelCol={{
              span: 12,
            }}
            wrapperCol={{
              span: 17,
            }}
            label="Does the  temperature remains constant"
          >
            <Radio.Group>
              <Radio value="yes"> yes </Radio>
              <Radio value="no"> no </Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            labelCol={{
              span: 12,
            }}
            wrapperCol={{
              span: 17,
            }}
            label="How many days do you have fever"
          >
            <InputNumber
              min={1}
              max={10}
              defaultValue={0}
              onChange={onChangeNumber}
            />
          </Form.Item>
          <Form.Item
            labelCol={{
              span: 12,
            }}
            wrapperCol={{
              span: 17,
            }}
            label="Do you have any digestion problem"
          >
            <Radio.Group>
              <Radio value="yes"> yes </Radio>
              <Radio value="no"> no </Radio>
            </Radio.Group>
          </Form.Item>
        </div>
      ) : symptoms === "Skin Allergy" ? (
        <div>
          {" "}
          <Alert message="Enter the symptoms of Skin Allergy" type="info" />
          <Form.Item
            labelCol={{
              span: 12,
            }}
            wrapperCol={{
              span: 17,
            }}
            label="Does the  temperature remains constant"
          >
            <Radio.Group>
              <Radio value="yes"> yes </Radio>
              <Radio value="no"> no </Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            labelCol={{
              span: 12,
            }}
            wrapperCol={{
              span: 17,
            }}
            label="How many days do you have the allergy"
          >
            <InputNumber
              min={1}
              max={10}
              defaultValue={0}
              onChange={onChangeNumber}
            />
          </Form.Item>
          <Form.Item
            labelCol={{
              span: 12,
            }}
            wrapperCol={{
              span: 17,
            }}
            label="Do you have any digestion problem"
          >
            <Radio.Group>
              <Radio value="yes"> yes </Radio>
              <Radio value="no"> no </Radio>
            </Radio.Group>
          </Form.Item>
        </div>
      ) : symptoms === "Diabetes" ? (
        <div>
          {" "}
          <Alert message="Enter the symptoms of Diabetes" type="info" />
          <Form.Item
            labelCol={{
              span: 12,
            }}
            wrapperCol={{
              span: 17,
            }}
            label="Do you gain weight"
          >
            <Radio.Group>
              <Radio value="yes"> yes </Radio>
              <Radio value="no"> no </Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            labelCol={{
              span: 12,
            }}
            wrapperCol={{
              span: 17,
            }}
            label="Do you have any digestion problem"
          >
            <Radio.Group>
              <Radio value="yes"> yes </Radio>
              <Radio value="no"> no </Radio>
            </Radio.Group>
          </Form.Item>
        </div>
      ) : (
        ""
      )}
    </Form.Item>
  );
};

export default FinalInfo;
