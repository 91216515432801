import {
  ADD_DOCTOR_LIST_ERROR,
  ADD_DOCTOR_LIST_PROGRESS,
  ADD_DOCTOR_LIST_SUCCESS,
  ADD_REASON_ERROR,
  ADD_REASON_INPROGRESS,
  ADD_REASON_SUCCESS,
  ADD_SYMPTOM_ERROR,
  ADD_SYMPTOM_INPROGRESS,
  ADD_SYMPTOM_SUCCESS,
  DELETE_DOCTOR_LIST_ERROR,
  DELETE_DOCTOR_LIST_PROGRESS,
  DELETE_DOCTOR_LIST_SUCCESS,
  DELETE_REASON_ERROR,
  DELETE_REASON_INPROGRESS,
  DELETE_REASON_SUCCESS,
  DELETE_SYMPTOM_ERROR,
  DELETE_SYMPTOM_INPROGRESS,
  DELETE_SYMPTOM_SUCCESS,
  DOCTOR_CSV_UPLOAD_IN_FAILURE,
  DOCTOR_CSV_UPLOAD_IN_PROGRESS,
  DOCTOR_CSV_UPLOAD_IN_SUCCESS,
  GET_DOCTOR_LIST_ERROR,
  GET_DOCTOR_LIST_PROGRESS,
  GET_DOCTOR_LIST_SUCCESS,
  GET_DOCTOR_UPDATE_LIST_ERROR,
  GET_DOCTOR_UPDATE_LIST_PROGRESS,
  GET_DOCTOR_UPDATE_LIST_SUCCESS,
  GET_OUT_DOCTORLIST_ERROR,
  GET_OUT_DOCTORLIST_INPROGRESS,
  GET_OUT_DOCTORLIST_SUCCESS,
  GET_REASON_ERROR,
  GET_REASON_INPROGRESS,
  GET_REASON_SUCCESS,
  GET_SYMPTOM_ERROR,
  GET_SYMPTOM_INPROGRESS,
  GET_SYMPTOM_SUCCESS,
  UPDATE_DOCTOR_LIST_ERROR,
  UPDATE_DOCTOR_LIST_PROGRESS,
  UPDATE_DOCTOR_LIST_SUCCESS,
  UPDATE_REASON_ERROR,
  UPDATE_REASON_INPROGRESS,
  UPDATE_REASON_SUCCESS,
  UPDATE_SYMPTOM_ERROR,
  UPDATE_SYMPTOM_INPROGRESS,
  UPDATE_SYMPTOM_SUCCESS,
} from "../../../../constants/ActionTypes";

export const getDoctorListInProgress = (payload) => ({
  type: GET_DOCTOR_LIST_PROGRESS,
  payload: payload,
});

export const getDoctorListInSuccess = (payload) => ({
  type: GET_DOCTOR_LIST_SUCCESS,
  payload: payload,
});

export const getDoctorListInError = (payload) => ({
  type: GET_DOCTOR_LIST_ERROR,
  payload: payload,
});

export const addDoctorListInProgress = (payload) => ({
  type: ADD_DOCTOR_LIST_PROGRESS,
  payload: payload,
});

export const addDoctorListInSuccess = (payload) => ({
  type: ADD_DOCTOR_LIST_SUCCESS,
  payload: payload,
});

export const addDoctorListInError = (payload) => ({
  type: ADD_DOCTOR_LIST_ERROR,
  payload: payload,
});

export const updateDoctorListInProgress = (payload) => ({
  type: UPDATE_DOCTOR_LIST_PROGRESS,
  payload: payload,
});

export const updateDoctorListInSuccess = (payload) => ({
  type: UPDATE_DOCTOR_LIST_SUCCESS,
  payload: payload,
});

export const updateDoctorListInError = () => ({
  type: UPDATE_DOCTOR_LIST_ERROR,
});

export const deleteDoctorListInProgress = (payload) => ({
  type: DELETE_DOCTOR_LIST_PROGRESS,
  payload: payload,
});

export const deleteDoctorListInSuccess = (payload) => ({
  type: DELETE_DOCTOR_LIST_SUCCESS,
  payload: payload,
});

export const deleteDoctorListInError = (payload) => ({
  type: DELETE_DOCTOR_LIST_ERROR,
  payload: payload,
});

export const getDataUpdateInProgress = (payload) => {
  return {
    type: GET_DOCTOR_UPDATE_LIST_PROGRESS,
    payload: payload,
  };
};
export const getDataUpdateSuccess = (payload) => {
  return {
    type: GET_DOCTOR_UPDATE_LIST_SUCCESS,
    payload: payload,
  };
};

export const getDataUpdateError = () => {
  return {
    type: GET_DOCTOR_UPDATE_LIST_ERROR,
  };
};

export const doctorCSVUploadInProgress = (payload) => {
  return {
    type: DOCTOR_CSV_UPLOAD_IN_PROGRESS,
    payload: payload,
  };
};
export const doctorCSVUploadSuccess = (payload) => {
  return {
    type: DOCTOR_CSV_UPLOAD_IN_SUCCESS,
    payload: payload,
  };
};

export const doctorCSVUploadError = (payload) => {
  return {
    type: DOCTOR_CSV_UPLOAD_IN_FAILURE,
    payload: payload,
  };
};
export const getReasonListInprogress = (payload) => {
  return {
    type: GET_REASON_INPROGRESS,
    payload: payload,
  };
};
export const getReasonListSuccess = (payload) => {
  return {
    type: GET_REASON_SUCCESS,
    payload: payload,
  };
};
export const getReasonListError = (payload) => {
  return {
    type: GET_REASON_ERROR,
    payload: payload,
  };
};
export const addReasonInprogress = (payload) => {
  return {
    type: ADD_REASON_INPROGRESS,
    payload: payload,
  };
};
export const addReasonSuccess = (payload) => {
  return {
    type: ADD_REASON_SUCCESS,
    payload: payload,
  };
};
export const addReasonError = (payload) => {
  return {
    type: ADD_REASON_ERROR,
    payload: payload,
  };
};
export const updateReasonInprogress = (payload) => {
  return {
    type: UPDATE_REASON_INPROGRESS,
    payload: payload,
  };
};
export const updateReasonSuccess = (payload) => {
  return {
    type: UPDATE_REASON_SUCCESS,
    payload: payload,
  };
};
export const updateReasonError = (payload) => {
  return {
    type: UPDATE_REASON_ERROR,
    payload: payload,
  };
};
export const deleteReasonInprogress = (payload) => {
  return {
    type: DELETE_REASON_INPROGRESS,
    payload: payload,
  };
};
export const deleteReasonSuccess = (payload) => {
  return {
    type: DELETE_REASON_SUCCESS,
    payload: payload,
  };
};
export const deleteReasonError = (payload) => {
  return {
    type: DELETE_REASON_ERROR,
    payload: payload,
  };
};
export const getSymptomListInprogress = (payload) => {
  return {
    type: GET_SYMPTOM_INPROGRESS,
    payload: payload,
  };
};
export const getSymptomListSuccess = (payload) => {
  return {
    type: GET_SYMPTOM_SUCCESS,
    payload: payload,
  };
};

export const getSymptomListError = (payload) => {
  return {
    type: GET_SYMPTOM_ERROR,
    payload: payload,
  };
};
export const addSymptomInprogress = (payload) => {
  return {
    type: ADD_SYMPTOM_INPROGRESS,
    payload: payload,
  };
};
export const addSymptomSuccess = (payload) => {
  return {
    type: ADD_SYMPTOM_SUCCESS,
    payload: payload,
  };
};
export const addSymptomError = (payload) => {
  return {
    type: ADD_SYMPTOM_ERROR,
    payload: payload,
  };
};
export const updateSymptomInprogress = (payload) => {
  return {
    type: UPDATE_SYMPTOM_INPROGRESS,
    payload: payload,
  };
};
export const updateSymptomSuccess = (payload) => {
  return {
    type: UPDATE_SYMPTOM_SUCCESS,
    payload: payload,
  };
};
export const updateSymptomError = (payload) => {
  return {
    type: UPDATE_SYMPTOM_ERROR,
    payload: payload,
  };
};
export const deleteSymptomInprogress = (payload) => {
  return {
    type: DELETE_SYMPTOM_INPROGRESS,
    payload: payload,
  };
};
export const deleteSymptomSuccess = (payload) => {
  return {
    type: DELETE_SYMPTOM_SUCCESS,
    payload: payload,
  };
};
export const deleteSymptomError = (payload) => {
  return {
    type: DELETE_SYMPTOM_ERROR,
    payload: payload,
  };
};
export const getOutDoctorListInprogress = (payload) => {
  return {
    type: GET_OUT_DOCTORLIST_INPROGRESS,
    payload: payload,
  };
};
export const getOutDoctorListSuccess = (payload) => {
  return {
    type: GET_OUT_DOCTORLIST_SUCCESS,
    payload: payload,
  };
};
export const getOutDoctorListError = (payload) => {
  return {
    type: GET_OUT_DOCTORLIST_ERROR,
    payload: payload,
  };
};
