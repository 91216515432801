import React from "react";
import { Col, Row, Divider } from "antd";

import { CloseOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import IntlMessages from "../../../util/IntlMessages";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import { APP_ROUTES } from "../../../constants/Routes";
import { useHistory } from "react-router-dom";

const ProfileHeader = () => {
  const history = useHistory();
  return (
    <div>
      <Row className="p-10 Add-button">
        <Col>
          <p className="header-tag">
            {<IntlMessages id={"Profile.myprofile"} />}
          </p>
        </Col>
        <Col>
          <ButtonComponent
            onClick={() => {
              history.push("/");
            }}
            label={"CANCEL"}
            type={"danger"}
            rightIcon={<CloseOutlined />}
          />
        </Col>
      </Row>

      <Divider />
    </div>
  );
};

export default ProfileHeader;
