import { call, put, takeLatest } from "@redux-saga/core/effects";
import { message } from "antd";
import {
  ADD_DOCTOR_LIST_PROGRESS,
  ADD_REASON_INPROGRESS,
  ADD_SYMPTOM_INPROGRESS,
  DELETE_DOCTOR_LIST_PROGRESS,
  DELETE_REASON_INPROGRESS,
  DELETE_SYMPTOM_INPROGRESS,
  DOCTOR_CSV_UPLOAD_IN_PROGRESS,
  GET_DOCTOR_LIST_PROGRESS,
  GET_DOCTOR_UPDATE_LIST_PROGRESS,
  GET_OUT_DOCTORLIST_INPROGRESS,
  GET_REASON_INPROGRESS,
  GET_SYMPTOM_INPROGRESS,
  UPDATE_DOCTOR_LIST_PROGRESS,
  UPDATE_REASON_INPROGRESS,
  UPDATE_SYMPTOM_INPROGRESS,
} from "../../../../constants/ActionTypes";
import { getErrorMessageFromAPI } from "../../../../util/utilz";
import {
  addDoctorsListEffect,
  addReasonEffect,
  addSymptomEffect,
  deleteDoctorsListEffect,
  deleteReasonEffect,
  deleteSymptomEffect,
  doctorFileUploadEffect,
  getDoctorlistUpdateEffect,
  getDoctorsListEffect,
  getReasonListEffect,
  getSymptomListEffect,
  outDoctorListEffect,
  updateDoctorsListEffect,
  updateReasonEffect,
  updateSymptomEffect,
} from "../../../admin/Doctor/sagas/effects/doctorEffect";
import {
  addDoctorListInError,
  addDoctorListInSuccess,
  addReasonError,
  addReasonSuccess,
  addSymptomError,
  addSymptomSuccess,
  deleteDoctorListInError,
  deleteDoctorListInSuccess,
  deleteReasonError,
  deleteReasonSuccess,
  deleteSymptomError,
  deleteSymptomSuccess,
  doctorCSVUploadError,
  doctorCSVUploadSuccess,
  getDataUpdateError,
  getDataUpdateSuccess,
  getDoctorListInError,
  getDoctorListInSuccess,
  getOutDoctorListError,
  getOutDoctorListSuccess,
  getReasonListError,
  getReasonListSuccess,
  getSymptomListError,
  getSymptomListSuccess,
  updateDoctorListInError,
  updateDoctorListInSuccess,
  updateReasonError,
  updateReasonSuccess,
  updateSymptomError,
  updateSymptomSuccess,
} from "../actions/Doctors";
import Message from "../../../../components/Message/Message";

function* getDoctorList({ payload }) {
  try {
    const token = localStorage.getItem("user_id");
    const { data, status } = yield call(getDoctorsListEffect, payload, token);

    yield put(
      getDoctorListInSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
  } catch (e) {
    yield put(
      getDoctorListInError({ message: getErrorMessageFromAPI(e), error: true })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}
function* addDoctorList({ payload }) {
  try {
    const token = localStorage.getItem("user_id");
    let { data } = yield call(addDoctorsListEffect, payload.formData, token);

    yield put(
      addDoctorListInSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    message.success(data["message"]);
    setTimeout(() => {
      message.info("Password will be shared on your registered email-id");
    }, 3500);
  } catch (e) {
    yield put(
      addDoctorListInError({
        error: true,
        message: getErrorMessageFromAPI(e),
      })
    );
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
  }
}
function* doctorFileUpload({ payload }) {
  const token = localStorage.getItem("user_id");
  try {
    let { data } = yield call(doctorFileUploadEffect, payload, token);

    yield put(
      doctorCSVUploadSuccess({
        data: data,
        message: data["message"],
        Success: true,
      })
    );
    message.success(data["message"]);
  } catch (e) {
    yield put(
      doctorCSVUploadError({
        Error: true,
        message: getErrorMessageFromAPI(e),
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}
function* deleteDoctorList({ payload }) {
  try {
    const token = localStorage.getItem("user_id");
    let { data } = yield call(deleteDoctorsListEffect, payload, token);

    yield put(
      deleteDoctorListInSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    if (payload.handleSuccess) {
      payload.handleSuccess();
    }
    message.success(data["message"]);
  } catch (e) {
    yield put(
      deleteDoctorListInError({
        error: true,
        message: getErrorMessageFromAPI(e),
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}

function* UpdatedoctorSaga({ payload }) {
  try {
    const token = localStorage.getItem("user_id");

    let { data, status } = yield call(updateDoctorsListEffect, payload, token);
    if (status == "500") {
      message.loading("Please try again later!");
    } else if (status == "pending") {
      message.info("Please wait a moment!");
    }
    if (payload.handleSuccess) {
      payload.handleSuccess();
    }
    yield put(
      updateDoctorListInSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    message.success(data["message"]);
  } catch (e) {
    yield put(
      updateDoctorListInError({
        error: true,
        message: getErrorMessageFromAPI(e),
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}

function* GetDoctorUpdateList({ payload }) {
  try {
    const token = localStorage.getItem("user_id");

    let { data } = yield call(getDoctorlistUpdateEffect, payload, token);

    yield put(
      getDataUpdateSuccess({
        data: data,
        message: "Success",
        success: true,
      })
    );
  } catch (e) {
    yield put(
      getDataUpdateError({
        error: true,
        message: getErrorMessageFromAPI(e),
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}
function* getReasonListWatcher({ payload }) {
  try {
    const token = localStorage.getItem("user_id");

    let { data } = yield call(getReasonListEffect, payload, token);

    yield put(
      getReasonListSuccess({
        data: data,
        message: "Success",
        success: true,
      })
    );
  } catch (e) {
    yield put(
      getReasonListError({
        error: true,
        message: getErrorMessageFromAPI(e),
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}
function* addReasonWatcher({ payload }) {
  try {
    const token = localStorage.getItem("user_id");

    let { data } = yield call(addReasonEffect, payload, token);

    yield put(
      addReasonSuccess({
        data: data,
        message: "Success",
        success: true,
      })
    );
    message.success(data["message"]);
  } catch (e) {
    yield put(
      addReasonError({
        error: true,
        message: getErrorMessageFromAPI(e),
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}
function* updateReasonWatcher({ payload }) {
  try {
    const token = localStorage.getItem("user_id");

    let { data } = yield call(updateReasonEffect, payload, token);

    yield put(
      updateReasonSuccess({
        data: data,
        message: "Success",
        success: true,
      })
    );
    message.success(data["message"]);
  } catch (e) {
    yield put(
      updateReasonError({
        error: true,
        message: getErrorMessageFromAPI(e),
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}
function* deleteReasonWatcher({ payload }) {
  try {
    const token = localStorage.getItem("user_id");

    let { data } = yield call(deleteReasonEffect, payload, token);

    yield put(
      deleteReasonSuccess({
        data: data,
        message: "Success",
        success: true,
      })
    );
    message.success(data["message"]);
  } catch (e) {
    yield put(
      deleteReasonError({
        error: true,
        message: getErrorMessageFromAPI(e),
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}
function* getSymptomListWatcher({ payload }) {
  try {
    const token = localStorage.getItem("user_id");

    let { data } = yield call(getSymptomListEffect, payload, token);

    yield put(
      getSymptomListSuccess({
        data: data,
        message: "Success",
        success: true,
      })
    );
  } catch (e) {
    yield put(
      getSymptomListError({
        error: true,
        message: getErrorMessageFromAPI(e),
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}
function* addSymptomWatcher({ payload }) {
  try {
    const token = localStorage.getItem("user_id");

    let { data } = yield call(addSymptomEffect, payload, token);

    yield put(
      addSymptomSuccess({
        data: data,
        message: "Success",
        success: true,
      })
    );
    message.success(data["message"]);
  } catch (e) {
    yield put(
      addSymptomError({
        error: true,
        message: getErrorMessageFromAPI(e),
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}
function* updateSymptomWatcher({ payload }) {
  try {
    const token = localStorage.getItem("user_id");

    let { data } = yield call(updateSymptomEffect, payload, token);

    yield put(
      updateSymptomSuccess({
        data: data,
        message: "Success",
        success: true,
      })
    );
    message.success(data["message"]);
  } catch (e) {
    yield put(
      updateSymptomError({
        error: true,
        message: getErrorMessageFromAPI(e),
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}
function* deleteSymptomWatcher({ payload }) {
  try {
    const token = localStorage.getItem("user_id");

    let { data } = yield call(deleteSymptomEffect, payload, token);

    yield put(
      deleteSymptomSuccess({
        data: data,
        message: "Success",
        success: true,
      })
    );
    message.success(data["message"]);
  } catch (e) {
    yield put(
      deleteSymptomError({
        error: true,
        message: getErrorMessageFromAPI(e),
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}
function* getOutDoctorListWatcher({ payload }) {
  try {
    const token = localStorage.getItem("user_id");

    let { data } = yield call(outDoctorListEffect, payload, token);

    yield put(
      getOutDoctorListSuccess({
        data: data,
        message: "Success",
        success: true,
      })
    );
    message.success(data["message"]);
  } catch (e) {
    yield put(
      getOutDoctorListError({
        error: true,
        message: getErrorMessageFromAPI(e),
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}
export function* DoctorSaga() {
  yield takeLatest(GET_DOCTOR_LIST_PROGRESS, getDoctorList);
  yield takeLatest(ADD_DOCTOR_LIST_PROGRESS, addDoctorList);
  yield takeLatest(DELETE_DOCTOR_LIST_PROGRESS, deleteDoctorList);
  yield takeLatest(UPDATE_DOCTOR_LIST_PROGRESS, UpdatedoctorSaga);
  yield takeLatest(GET_DOCTOR_UPDATE_LIST_PROGRESS, GetDoctorUpdateList);
  yield takeLatest(DOCTOR_CSV_UPLOAD_IN_PROGRESS, doctorFileUpload);
  yield takeLatest(GET_SYMPTOM_INPROGRESS, getSymptomListWatcher);
  yield takeLatest(GET_REASON_INPROGRESS, getReasonListWatcher);
  yield takeLatest(ADD_REASON_INPROGRESS, addReasonWatcher);
  yield takeLatest(ADD_SYMPTOM_INPROGRESS, addSymptomWatcher);
  yield takeLatest(UPDATE_REASON_INPROGRESS, updateReasonWatcher);
  yield takeLatest(DELETE_REASON_INPROGRESS, deleteReasonWatcher);
  yield takeLatest(UPDATE_SYMPTOM_INPROGRESS, updateSymptomWatcher);
  yield takeLatest(DELETE_SYMPTOM_INPROGRESS, deleteSymptomWatcher);
  yield takeLatest(GET_OUT_DOCTORLIST_INPROGRESS, getOutDoctorListWatcher);
}
