import React, { useEffect, useState } from "react";
import { Card, Row, Col, Form, Spin, Typography } from "antd";
import { CreditCardOutlined, LoadingOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { APP_ROUTES } from "../../../constants/Routes";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import FormInput from "../../../components/Input/FormInput";
import IntlMessages from "../../../util/IntlMessages";
import FormSelectBox from "../../../components/SelectBox/SelectBox";
import { useDispatch, useSelector } from "react-redux";
import {
  addBlockInProgress,
  editBlockInProgress,
} from "../../../appRedux/Ward/WardAction";
import moment from "moment";
import TextArea from "antd/lib/input/TextArea";

const Confirmation = ({
  setconfirmationDetails,
  setOtherDetails,
  otherDetails,
}) => {
  const [form] = Form.useForm();
  const currentDate = moment(new Date()).format("YYYY-MM-DD");
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const onFinish = (datas) => {
    console.log("datas", datas);
    setconfirmationDetails(datas);
    setOtherDetails(true);
  };

  const [Loading, setLoading] = useState(false);

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );
  useEffect(() => {
    form.setFieldValue("check_in_date", currentDate);
  }, []);
  return (
    <>
      <Spin indicator={antIcon} spinning={Loading}>
        <Card>
          <Form
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
            initialValues={{
              remember: true,
            }}
            colon={"true"}
            onFinish={onFinish}
            autoComplete="off"
            form={form}
            onFinishFailed={onFinishFailed}
          >
            <Row className="register-row">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Typography className="header-tag">
                  <IntlMessages id={"label.otherDetails"} />
                </Typography>
              </Col>
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <Row>
                  <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                    <FormInput
                      labelCol={"24"}
                      wrapperCol={"24"}
                      type="text"
                      name={"check_in_date"}
                      label={
                        <IntlMessages id={"Addnew.ward.label.checkinDate"} />
                      }
                      rules={[
                        {
                          required: true,
                          message: (
                            <IntlMessages
                              id={"Addnew.ward.validator.checkinDate"}
                            />
                          ),
                        },
                      ]}
                    />
                  </Col>{" "}
                  <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                    <FormInput
                      labelCol={"24"}
                      wrapperCol={"24"}
                      type="text"
                      name={"check_in_incharge"}
                      label={
                        <IntlMessages
                          id={"Addnew.ward.label.check_in_incharge"}
                        />
                      }
                      rules={[
                        {
                          required: true,
                          message: (
                            <IntlMessages
                              id={"Addnew.ward.validator.check_in_incharge"}
                            />
                          ),
                        },
                      ]}
                    />
                  </Col>{" "}
                  <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                    <Form.Item
                      name={"check_in_reason"}
                      label={
                        <IntlMessages
                          id={"Addnew.ward.label.check_in_reason"}
                        />
                      }
                      rules={[
                        {
                          required: true,
                          message: (
                            <IntlMessages
                              id={"Addnew.ward.validator.check_in_reason"}
                            />
                          ),
                        },
                      ]}
                      style={{ width: "100%" }}
                    >
                      <TextArea rows={4} />
                    </Form.Item>
                  </Col>
                  <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                    <Form.Item
                      name={"follow_procedure"}
                      label={
                        <IntlMessages
                          id={"Addnew.ward.label.follow_procedure"}
                        />
                      }
                      rules={[
                        {
                          required: true,
                          message: (
                            <IntlMessages
                              id={"Addnew.ward.validator.follow_procedure"}
                            />
                          ),
                        },
                      ]}
                      style={{ width: "100%" }}
                    >
                      <TextArea rows={4} />
                    </Form.Item>
                  </Col>
                  <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                    <FormInput
                      labelCol={"24"}
                      wrapperCol={"24"}
                      type="text"
                      name={"checkin_by"}
                      label={
                        <IntlMessages id={"Addnew.ward.label.checkin_by"} />
                      }
                      rules={[
                        {
                          required: true,
                          message: (
                            <IntlMessages
                              id={"Addnew.ward.validator.checkin_by"}
                            />
                          ),
                        },
                      ]}
                    />
                  </Col>{" "}
                  <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                    <FormInput
                      labelCol={"24"}
                      wrapperCol={"24"}
                      type="number"
                      name={"price_per_day"}
                      label={
                        <IntlMessages id={"Addnew.ward.label.price_per_day"} />
                      }
                      rules={[
                        {
                          required: true,
                          message: (
                            <IntlMessages
                              id={"Addnew.ward.validator.price_per_day"}
                            />
                          ),
                        },
                      ]}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col
                xl={23}
                align="right"
                className="button-alignment-pharmacy cancel-btn"
              >
                <Link to={APP_ROUTES.BLOCK_LIST}>
                  <ButtonComponent
                    type="danger"
                    label="Cancel"
                    className="btn-cancel secondary-color"
                  />
                </Link>

                <ButtonComponent
                  icon={<CreditCardOutlined />}
                  label={"Submit Other Details"}
                  htmlType="submit"
                  type="primary"
                  disabled={otherDetails}
                />
              </Col>
            </Row>
          </Form>
        </Card>
      </Spin>
    </>
  );
};

export default Confirmation;
