import { Card } from "antd";
import React from "react";
import SalesReturnHeader from "./SalesReturnHeader";
import SalesReturnTable from "./SalesReturnTable";

const SalesReturnScreen = () => {
  return (
    <Card>
      <SalesReturnHeader />
      <SalesReturnTable />
    </Card>
  );
};

export default SalesReturnScreen;
