import React from "react";
import { Card } from "antd";

import BillingPackageHeader from "./component/BillingPackageHeader";
import BillingpackageTable from "./component/BillingpackageTable";
import Auxiliary from "../../util/Auxiliary";

const BillingPackageScreen = () => {
  return (
    <Auxiliary>
      <Card>
        <BillingPackageHeader />
        <BillingpackageTable />
      </Card>
    </Auxiliary>
  );
};

export default BillingPackageScreen;
