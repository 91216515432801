import {
  ADD_SPECIALIZATION_FAILURE,
  ADD_SPECIALIZATION_IN_PROGRESS,
  ADD_SPECIALIZATION_SUCCESS,
  DELETE_SPECIALIZATION_FAILURE,
  DELETE_SPECIALIZATION_IN_PROGRESS,
  DELETE_SPECIALIZATION_SUCCESS,
  GETDATA_SPECIALIZATION_FAILURE,
  GETDATA_SPECIALIZATION_IN_PROGRESS,
  GETDATA_SPECIALIZATION_SUCCESS,
  GET_SPECIALIZATION_FAILURE,
  GET_SPECIALIZATION_IN_PROGRESS,
  GET_SPECIALIZATION_SUCCESS,
  UPDATE_SPECIALIZATION_FAILURE,
  UPDATE_SPECIALIZATION_IN_PROGRESS,
  UPDATE_SPECIALIZATION_SUCCESS,
} from "../../../constants/ActionTypes";

export const getSpecializationInProgress = (payload) => {
  return {
    type: GET_SPECIALIZATION_IN_PROGRESS,
    payload: payload,
  };
};
export const getSpecializationSuccess = (payload) => {
  return {
    type: GET_SPECIALIZATION_SUCCESS,
    payload: payload,
  };
};
export const getSpecializationError = () => {
  return {
    type: GET_SPECIALIZATION_FAILURE,
  };
};
export const addSpecializationInProgress = (payload) => {
  return {
    type: ADD_SPECIALIZATION_IN_PROGRESS,
    payload: payload,
  };
};
export const addSpecializationSuccess = (payload) => {
  return {
    type: ADD_SPECIALIZATION_SUCCESS,
    payload: payload,
  };
};
export const addSpecializationError = () => {
  return {
    type: ADD_SPECIALIZATION_FAILURE,
  };
};
export const deleteSpecializationInProgress = (payload) => {
  return {
    type: DELETE_SPECIALIZATION_IN_PROGRESS,
    payload: payload,
  };
};
export const deleteSpecializationSuccess = (payload) => {
  return {
    type: DELETE_SPECIALIZATION_SUCCESS,
    payload: payload,
  };
};
export const deleteSpecializationError = () => {
  return {
    type: DELETE_SPECIALIZATION_FAILURE,
  };
};
export const getDataSpecializationInProgress = (payload) => {
  return {
    type: GETDATA_SPECIALIZATION_IN_PROGRESS,
    payload: payload,
  };
};
export const getDataSpecializationSuccess = (payload) => {
  return {
    type: GETDATA_SPECIALIZATION_SUCCESS,
    payload: payload,
  };
};
export const getDataSpecializationError = () => {
  return {
    type: GETDATA_SPECIALIZATION_FAILURE,
  };
};
export const updateSpecializationInProgress = (payload) => {
  return {
    type: UPDATE_SPECIALIZATION_IN_PROGRESS,
    payload: payload,
  };
};
export const updateSpecializationSuccess = (payload) => {
  return {
    type: UPDATE_SPECIALIZATION_SUCCESS,
    payload: payload,
  };
};
export const updateSpecializationError = () => {
  return {
    type: UPDATE_SPECIALIZATION_FAILURE,
  };
};
