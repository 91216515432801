export const options = [
  { label: "10", value: "10" },
  { label: "25", value: "25" },
  { label: "50", value: "50" },
  { label: "100", value: "100" },
  { label: "All", value: "All" },
];

export const data = [
  { label: "Gynaecology", value: "Gynaecology" },
  { label: "Dermatology", value: "Dermatology" },
  { label: "Paediatrics", value: "Paediatrics" },
];

export const list = [
  {
    label: "The Hive Women & Fertility Clinic (Head)",
    value: "The Hive Women & Fertility Clinic (Head)",
  },
  { label: "Golden - CLI20210318001", value: "Golden - CLI20210318001" },
  { label: "Pearl - CLI20210318002", value: "Pearl - CLI20210318002" },
  { label: "Gem - CLI20210318003", value: "Gem - CLI20210318003" },
  {
    label: "Madipakkam - CLI20210318004",
    value: "Madipakkam - CLI20210318004",
  },
];
export const pharmacyList = [
  {
    label: "AF_KIT",
    value: "AF_KIT",
  },
  { label: "ALGERA 120", value: "ALGERA 120" },
  { label: "CALCIGEN D3", value: "CALCIGEN D3" },
  { label: "Gem - CLI20210318003", value: "Gem - CLI20210318003" },
  {
    label: "CABOLIN",
    value: "CABOLIN",
  },
];
export const purchaseTypeList = [
  {
    label: "With purchase",
    value: "1",
  },
  {
    label: "WithOut purchase",
    value: "2",
  },
];
export const salesTypeList = [
  {
    label: "In Patient",
    value: "1",
  },
  {
    label: "Out Patient",
    value: "2",
  },
];
export const salesIdList = [
  {
    label: "SALE2024001",
    value: "SALE2024001",
  },
  {
    label: "SALE2024002",
    value: "SALE2024002",
  },
  {
    label: "SALE2024003",
    value: "SALE2024003",
  },
  {
    label: "SALE2024004",
    value: "SALE2024004",
  },
  {
    label: "SALE2024005",
    value: "SALE2024005",
  },
  {
    label: "SALE2024006",
    value: "SALE2024006",
  },
];
export const categoryList = [
  {
    label: "Non Sedative Drugs",
    value: "Non Sedative Drugs",
  },
  {
    label: "SUTURES",
    value: "SUTURES",
  },
  {
    label: "IV FLUIDS",
    value: "IV FLUIDS",
  },
  {
    label: "SURGICALS",
    value: "SURGICALS",
  },
  {
    label: "INJECTIONS",
    value: "INJECTIONS",
  },
  {
    label: "OINMENTS",
    value: "OINMENTS",
  },
  {
    label: "SUPPOSITORIES",
    value: "SUPPOSITORIES",
  },
  {
    label: "OTHERS",
    value: "OTHERS",
  },
];
export const gstList = [
  {
    label: "0.00",
    value: 0.0,
  },
  {
    label: "5.00",
    value: 5.0,
  },
  {
    label: "12.00",
    value: 12.0,
  },
  {
    label: "17.00",
    value: 17.0,
  },
];
export const productTypeList = [
  {
    label: "H",
    value: "H",
  },
  {
    label: "H1",
    value: "H1",
  },
  {
    label: "NO",
    value: "NO",
  },
];
export const productUnitList = [
  {
    label: "Tablets",
    value: "Tablets",
  },
  {
    label: "KG",
    value: "KG",
  },
  {
    label: "VIAL",
    value: "VIAL",
  },
  {
    label: "BOTTLE",
    value: "BOTTLE",
  },
  {
    label: "PFS",
    value: "PFS",
  },
  {
    label: "EAR DROPS",
    value: "EAR DROPS",
  },
  {
    label: "EYE DROPS",
    value: "EYE DROPS",
  },
];
export const paymentTypeList = [
  // "1- card, 2- cash,3-online,4-dd,5-cheque,6-wallet,7-others",
  {
    label: "Card",
    value: "1",
  },
  {
    label: "Cash",
    value: "2",
  },
  {
    label: "Online",
    value: "3",
  },
  { label: "dd", value: "4" },
  {
    label: "Checque",
    value: "5",
  },
  {
    label: "Wallet",
    value: "6",
  },
  {
    label: "Others",
    value: "7",
  },
];
export const batchNoList = [
  {
    label: "001",
    value: "001",
  },
  {
    label: "002",
    value: "002",
  },
  {
    label: "003",
    value: "003",
  },
  {
    label: "004",
    value: "004",
  },
  {
    label: "005",
    value: "005",
  },
  {
    label: "006",
    value: "006",
  },
];
export const departmentList = [
  {
    label: "Pearl Pharmacy",
    value: "Pearl Pharmacy",
  },
  {
    label: "Pearl",
    value: "Pearl",
  },
  {
    label: "HIVE OT",
    value: "HIVE OT",
  },
  {
    label: "HIVE IUI LAB",
    value: "HIVE IUI LAB",
  },
  {
    label: "HIVE OP AND SCAN",
    value: "HIVE OP AND SCAN",
  },
  {
    label: "COVID WARD",
    value: "COVID WARD",
  },
  {
    label: "GOLDEN PHARMACY",
    value: "GOLDEN PHARMACY",
  },
];
export const itemTransferList = [
  {
    label: "Hospital",
    value: "Hospital",
  },
  {
    label: "Pharmacy",
    value: "Pharmacy",
  },
];
export const pharmacyItemTransferList = [
  {
    label: "GOLDEN-PHA2024001",
    value: "GOLDEN-PHA2024001",
  },
  {
    label: "PEARL- PHA 2024002",
    value: "PEARL- PHA 2024002",
  },
  {
    label: "MADIPAKKAM-PHA2024003",
    value: "MADIPAKKAM-PHA2024003",
  },
];

export const pharmacyStatus = [
  {
    label: "Not Sent",
    value: 1,
  },
  {
    label: "Doctor To Pharamcy",
    value: 2,
  },
  {
    label: "Pharamcy Seen",
    value: 3,
  },
  {
    label: "Patient Received",
    value: 4,
  },
];
export const renewalPeriodList = [
  {
    label: "1 year",
    value: "1",
  },
  {
    label: "2 Years",
    value: "2",
  },
  {
    label: "3 Years",
    value: "3",
  },
  { label: "4 Years", value: "4" },
];
export const appoinmentType = [
  {
    label: "Scan",
    value: "1",
  },
  {
    label: "Test",
    value: "2",
  },
  {
    label: "Ref Doctor",
    value: "4",
  },
  { label: "Doctor",
     value: "3" },
];

export const trackerStatus = [
  {
    label: "Not Seen Yet",
    value: "1",
  },
  {
    label: "Seen",
    value: "2",
  },
  {
    label: "Completed",
    value: "3",
  },
 
];

export const subPackageType = [
  {
    label: "Scan",
    value: "1",
  },
  {
    label: "Test",
    value: "2",
  }
];