import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  REQUEST_VIDEO_CALL_FAILURE,
  REQUEST_VIDEO_CALL_IN_PROGRESS,
  REQUEST_VIDEO_CALL_SUCCESS,
  SET_REGISTER_SUCCESS,
  SHOW_MESSAGE,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER_SUCCESS,
} from "../../../constants/ActionTypes";
const defaultStatus = {
  inProgress: false,
  success: false,
  error: false,
};
const INIT_STATE = {
  loader: false,
  alertMessage: "",
  showMessage: false,
  initURL: "",
  authUser: {},
  videoError: false,
  videoSuccess: false,
  videoProgress: false,
  VideoToken: "",
  registerdata: {},
  registerSuccess: false,
  userLogin: { ...defaultStatus },
};

const initialState = {};
const AuthReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SIGNUP_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        registerdata: action.payload,
        registerSuccess: true,
      };
    }
    case SIGNIN_USER_SUCCESS: {
      // return {
      //   ...state,
      //   loader: false,
      //   authUser: action.payload,
      //   authSuccess: true,
      //   // ...action.payload,
      // };
      return {
        ...state,
        authUser: action.payload,
        userLogin: {
          loader: false,
          ...defaultStatus,
          success: true,
        },
      };
    }

    case INIT_URL: {
      return {
        ...state,
        initURL: action.payload,
      };
    }
    case SIGNOUT_USER_SUCCESS: {
      return {
        ...state,
        authUser: null,
        initURL: "/",
        loader: false,
      };
    }

    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false,
      };
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: "",
        showMessage: false,
        loader: false,
      };
    }

    case ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true,
      };
    }
    case ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false,
      };
    }
    case REQUEST_VIDEO_CALL_IN_PROGRESS:
      return {
        ...state,
        videoCallProgress: true,
        ...action.payload,
      };
    case REQUEST_VIDEO_CALL_SUCCESS:
      return {
        ...state,
        videoCallSuccess: true,
        VideoToken: action.payload,
      };
    case REQUEST_VIDEO_CALL_FAILURE:
      return {
        ...state,
        videoCallSuccess: false,
        videoCallError: true,
      };
    case SET_REGISTER_SUCCESS:
      return {
        ...state,
        registerSuccess: false,
      };
    default:
      return state;
  }
};

export default AuthReducer;
