import {
  FAMILY_MEMBER_LIST_ERROR,
  FAMILY_MEMBER_LIST_IN_PROGRESS,
  FAMILY_MEMBER_LIST_SUCCESS,
} from "../../../constants/ActionTypes";

// Get All FamilyMember
export const getFamilyMemberListInProgress = (payload) => ({
  type: FAMILY_MEMBER_LIST_IN_PROGRESS,
  payload: payload,
});

export const getFamilyMemberListInSuccess = (payload) => ({
  type: FAMILY_MEMBER_LIST_SUCCESS,
  payload: payload,
});

export const getFamilyMemberListInError = (payload) => ({
  type: FAMILY_MEMBER_LIST_ERROR,
  payload: payload,
});
