import React, { useEffect, useState } from "react";
import { Row, Col, Spin, Modal, Typography, Tooltip, Form, Button } from "antd";
import {
  DeleteFilled,
  LoadingOutlined,
  EditFilled,
  EyeFilled,
} from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import { access } from "../../../util/utilz";
import {
  addReasonInprogress,
  deleteReasonInprogress,
  getReasonListInprogress,
  updateReasonInprogress,
} from "../../../appRedux/admin/Doctor/actions/Doctors";
import TableComponent from "../../../components/Table/TableComponent";
import ButtonComponent from "../../../components/Button/ButtonComponent";

import IntlMessages from "../../../util/IntlMessages";
import FormInput from "../../../components/Input/FormInput";

const ReasonTable = () => {
  const [reasonId, setReasonId] = useState("");
  const [reasonType, setReasonType] = useState("Add Reason");
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [viewReason, setViewReason] = useState(false);
  const [form] = Form.useForm();
  const [filteredUsers, setFilteredUsers] = useState("");
  const dispatch = useDispatch();
  const { reasonList, addReason, updateReason, deleteReason } = useSelector(
    (state) => state?.doctor
  );
  const [addReasonModal, setAddReasonModal] = useState(false);
  const reasonListData = reasonList?.data;

  const totalRecords = reasonList?.totalRecords;
  console.log("reasonListData", reasonList?.totalRecords);
  const handleDelete = (id) => {
    setDeleteModalOpen(true);
    setReasonId(id);
  };
  const handleDeleteModalOk = () => {
    setDeleteModalOpen(false);
    dispatch(deleteReasonInprogress({ id: reasonId }));
  };
  const handleDeleteModalCancel = () => {
    setDeleteModalOpen(false);
  };
  const handleEditReason = (record) => {
    setAddReasonModal(true);
    form.setFieldValue("reason", record?.name);
    setReasonId(record?.id);
    setReasonType("Edit Reason");
  };
  const handleViewReason = (record) => {
    setViewReason(true);
    form.setFieldValue("reason", record?.name);
    setReasonId(record?.id);
    setReasonType("View Reason");
  };
  const columns = [
    {
      title: "S.No",
      dataIndex: "sno",
      render: (text, record, index) => index + 1,
    },

    {
      title: " Name",
      dataIndex: "name",
      sortDirections: ["ascend", "descend"],
      key: "name",
      sorter: (a, b) => {
        if (a && b && a.name && b.name) {
          return a.name.localeCompare(b.name);
        }
        return 0;
      },
    },

    {
      title: "Action",
      dataIndex: "Action",
      render: (_, record) => {
        return (
          <Row className="table-button">
            <Col className="button-edit">
              <Tooltip placement="top" title={"View"}>
                <ButtonComponent
                  size="small"
                  type="primary"
                  icon={<EyeFilled />}
                  onClick={() => handleViewReason(record)}
                ></ButtonComponent>
              </Tooltip>
            </Col>

            <Col className="button-edit">
              <Tooltip placement="top" title={"Edit"}>
                <ButtonComponent
                  size="small"
                  className="secondary-color"
                  icon={<EditFilled />}
                  onClick={() => handleEditReason(record)}
                ></ButtonComponent>
              </Tooltip>
            </Col>

            <Col className="button-edit">
              {" "}
              <Tooltip placement="top" title={"Delete"}>
                <Typography>
                  {" "}
                  <ButtonComponent
                    size="small"
                    type="danger"
                    icon={<DeleteFilled />}
                    onClick={() => handleDelete(record.id)}
                  ></ButtonComponent>
                </Typography>
              </Tooltip>
            </Col>
          </Row>
        );
      },
    },
  ];

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );
  const handleReasonModalClose = () => {
    setAddReasonModal(false);
    form.resetFields();
  };
  const handleViewReasonModalClose = () => {
    setViewReason(false);
    form.resetFields();
  };
  const handlePage = (page) => {
    const payload = {
      pagination: true,
      page: page,
      pageSize: 10,
    };
    dispatch(getReasonListInprogress(payload));
  };

  const handleSelectChange = (value) => {
    if (value === "all") {
      setFilteredUsers(reasonListData);
    } else {
      const newData = reasonListData.slice(0, value);
      setFilteredUsers(newData);
    }
  };

  useEffect(() => {
    const payload = {
      pagination: true,
      page: 1,
      pageSize: 10,
    };
    dispatch(getReasonListInprogress(payload));
  }, []);
  useEffect(() => {
    if (addReason?.success || updateReason?.success || deleteReason?.success) {
      const payload = {
        pagination: true,
        page: 1,
        pageSize: 10,
      };
      dispatch(getReasonListInprogress(payload));
    }
  }, [addReason, updateReason, deleteReason]);
  const onFinish = (datas) => {
    const payload = {
      name: datas?.reason,
    };
    if (reasonType === "Edit Reason") {
      dispatch(updateReasonInprogress({ data: payload, id: reasonId }));
    } else {
      dispatch(addReasonInprogress(payload));
    }
  };
  useEffect(() => {
    if (addReason?.success || updateReason?.success) {
      setAddReasonModal(false);
      form.resetFields();
    }
  }, [addReason, updateReason]);
  console.log("reasonType", reasonType);
  return (
    <Row className="p-10 Add-button">
      <Col>
        <p className="header-tag">
          {<IntlMessages id={"Label.reason.heading"} />}
        </p>
      </Col>
      <Col>
        <ButtonComponent
          type="primary"
          label={<IntlMessages id={"Addnew.reasons"} />}
          onClick={() => setAddReasonModal(true)}
        />
      </Col>
      <Col xs={24} sm={24}>
        <Spin indicator={antIcon} spinning={reasonList?.progressing}>
          <TableComponent
            columns={columns}
            dataSource={filteredUsers ? filteredUsers : reasonListData}
            rowselector={handleSelectChange}
            itemList={reasonListData}
            filteredUsers={filteredUsers}
            setFilteredUsers={setFilteredUsers}
            pagination={{
              total: totalRecords,
              onChange: (page) => handlePage(page),
            }}
            field={"name"}
            type={"reasonList"}
            documentTitle="Reason List"
          />
          <Modal
            title="Delete Data"
            open={deleteModalOpen}
            onOk={handleDeleteModalOk}
            onCancel={handleDeleteModalCancel}
            cancelButtonProps={{ className: "secondary-color" }}
          >
            <p>Are you sure, you want to delete the Data</p>
          </Modal>
          <Modal
            title={
              <Typography className="cancel-sales"> {reasonType}</Typography>
            }
            open={addReasonModal}
            width={600}
            closable={false}
            footer={[]}
          >
            <Spin
              indicator={antIcon}
              spinning={addReason?.progressing || updateReason?.progressing}
            >
              <Form
                labelCol={{
                  span: 24,
                }}
                wrapperCol={{
                  span: 24,
                }}
                initialValues={{
                  remember: true,
                }}
                colon={"true"}
                onFinish={onFinish}
                autoComplete="off"
                form={form}
              >
                <Row className="register-row">
                  <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <FormInput
                      labelCol={"24"}
                      wrapperCol={"24"}
                      type="text"
                      name={"reason"}
                      readOnly={reasonType === "View Reason" ? true : false}
                      label={<IntlMessages id={"Addnew.label.reason"} />}
                      rules={[
                        {
                          required: true,
                          message: <IntlMessages id={"Enter the reason "} />,
                        },
                      ]}
                    />
                  </Col>
                </Row>
                <Row justify={"end"}>
                  <Button key="back" type="primary" htmlType="submit">
                    Save
                  </Button>

                  <Button
                    key="back"
                    className="secondary-color"
                    onClick={handleReasonModalClose}
                  >
                    Close
                  </Button>
                </Row>
              </Form>
            </Spin>
          </Modal>
          <Modal
            title="Delete Data"
            open={deleteModalOpen}
            onOk={handleDeleteModalOk}
            onCancel={handleDeleteModalCancel}
          >
            <p>Are you sure, you want to delete the Data</p>
          </Modal>{" "}
          <Modal
            title={
              <Typography className="cancel-sales"> {reasonType}</Typography>
            }
            open={viewReason}
            width={600}
            closable={false}
            footer={[]}
          >
            <Form
              labelCol={{
                span: 24,
              }}
              wrapperCol={{
                span: 24,
              }}
              initialValues={{
                remember: true,
              }}
              colon={"true"}
              onFinish={onFinish}
              autoComplete="off"
              form={form}
            >
              <Row className="register-row">
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                  <FormInput
                    labelCol={"24"}
                    wrapperCol={"24"}
                    type="text"
                    name={"reason"}
                    readOnly={true}
                    label={<IntlMessages id={"Addnew.label.reason"} />}
                    rules={[
                      {
                        required: true,
                        message: <IntlMessages id={"Enter the reason "} />,
                      },
                    ]}
                  />
                </Col>
              </Row>
              <Row justify={"end"}>
                <Button
                  key="back"
                  className="secondary-color"
                  onClick={handleViewReasonModalClose}
                >
                  Close
                </Button>
              </Row>
            </Form>
          </Modal>
        </Spin>
      </Col>
    </Row>
  );
};

export default ReasonTable;
