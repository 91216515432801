import { call, put, takeLatest } from "@redux-saga/core/effects";
import { Modal, message } from "antd";
import {
  APPOINMENT_RESCHEDULE_PROGRESS,
  GET_APPOINTMENTS_LIST_IN_PROGRESS,
  GET_DOCTOR_APPOINTMENT_LIST_IN_PROGRESS,
  GET_DOCTOR_APPOINTMENT_UPCOMING_IN_PROGRESS,
  GET_UPCOMING_APPOINTMENT_LIST_INPROGRESS,
  UPDATE_APPOINTMENT_STATUS_LIST_IN_PROGRESS,
} from "../../../constants/ActionTypes";
import { getErrorMessageFromAPI } from "../../../util/utilz";
import {
  appointmentStatusUpdateInFailure,
  appointmentStatusUpdateInSuccess,
  getAppointmentsListInError,
  getAppointmentsListInSuccess,
  getDoctorAppointmentListInError,
  getDoctorAppointmentListInSuccess,
  getDoctorUpcomingAppointmentListInError,
  getDoctorUpcomingAppointmentListInSuccess,
  getUpcomingAppointmentError,
  getUpcomingAppointmentSuccess,
  reScheduleAppointmentByIdInFailure,
  reScheduleAppointmentByIdInSuccess,
} from "../actions/Appointment";
import {
  getAppointmentListEffect,
  getDoctorAppointmentListEffect,
  getDoctorUpcomingAppointmentListEffect,
  reScheduleAppointment,
  statusUpdateAppointment,
} from "./effects/Appointment";
import Message from "../../../components/Message/Message";
import moment from "moment";

function* getAppointmentsList({ payload }) {
  try {
    const token = localStorage.getItem("user_id");
    const { data } = yield call(getAppointmentListEffect, payload, token);

    yield put(
      getAppointmentsListInSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
  } catch (e) {
    yield put(
      getAppointmentsListInError({
        message: getErrorMessageFromAPI(e),
        error: true,
      })
    );

    message.error(getErrorMessageFromAPI(e));
  }
}
function* getUpcomingAppointmentsListWatcher({ payload }) {
  try {
    const token = localStorage.getItem("user_id");
    const { data } = yield call(getAppointmentListEffect, payload, token);

    yield put(
      getUpcomingAppointmentSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
  } catch (e) {
    yield put(
      getUpcomingAppointmentError({
        message: getErrorMessageFromAPI(e),
        error: true,
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}
function* getDoctorAppointmentList({ payload }) {
  try {
    const { data } = yield call(getDoctorAppointmentListEffect, payload);

    yield put(
      getDoctorAppointmentListInSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
  } catch (e) {
    yield put(
      getDoctorAppointmentListInError({
        message: getErrorMessageFromAPI(e),
        error: true,
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}

function* getDoctorUpcomingAppointmentList({ payload }) {
  try {
    const token = localStorage.getItem("user_id");
    const doctorid = localStorage.getItem("doctor_id");

    const { data } = yield call(
      getDoctorUpcomingAppointmentListEffect,
      payload,
      token,
      doctorid
    );

    yield put(
      getDoctorUpcomingAppointmentListInSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
  } catch (e) {
    yield put(
      getDoctorUpcomingAppointmentListInError({
        message: getErrorMessageFromAPI(e),
        error: true,
      })
    );
    yield put({
      type: "ADD_TEAM_MEMBER_ERROR_MESSAGE",
      payload: getErrorMessageFromAPI(e),
    });
  }
}

function* reScheduleAppointmentByDoctor({ payload }) {
  try {
    const { data } = yield call(reScheduleAppointment, payload);
    yield put(
      reScheduleAppointmentByIdInSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    if (data) {
      Modal.success({
        content: ` This Appoinment has been rescheduled on
          ${data?.appointmentInfo?.date
            ? moment(data?.appointmentInfo?.date).format("DD/MM/YYYY")
            : ""
          }
          ,
          ${data?.appointmentInfo?.start_time
            ? moment(data?.appointmentInfo?.start_time, ["h:mm A"]).format(
              "hh:mm A"
            )
            : ""
          }`,
      });
    }
    // yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield put(
      reScheduleAppointmentByIdInFailure({
        message: getErrorMessageFromAPI(e),
        error: true,
      })
    );
    yield put({
      type: "ADD_TEAM_MEMBER_ERROR_MESSAGE",
      payload: getErrorMessageFromAPI(e),
    });
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
  }
}

function* statusUpdateAppointmentByDoctor({ payload }) {
  try {
    const { data } = yield call(statusUpdateAppointment, payload);
    yield put(
      appointmentStatusUpdateInSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    if (data) {
      Modal.success({
        content: ` This Appoinment has been Completed`,
      });
    }
    // yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield put(
      appointmentStatusUpdateInFailure({
        message: getErrorMessageFromAPI(e),
        error: true,
      })
    );
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
  }
}
export function* AppointmentSaga() {
  yield takeLatest(GET_APPOINTMENTS_LIST_IN_PROGRESS, getAppointmentsList);
  yield takeLatest(
    GET_DOCTOR_APPOINTMENT_LIST_IN_PROGRESS,
    getDoctorAppointmentList
  );
  yield takeLatest(
    GET_DOCTOR_APPOINTMENT_UPCOMING_IN_PROGRESS,
    getDoctorUpcomingAppointmentList
  );
  yield takeLatest(
    APPOINMENT_RESCHEDULE_PROGRESS,
    reScheduleAppointmentByDoctor
  );
  yield takeLatest(
    GET_UPCOMING_APPOINTMENT_LIST_INPROGRESS,
    getUpcomingAppointmentsListWatcher
  );
  yield takeLatest(UPDATE_APPOINTMENT_STATUS_LIST_IN_PROGRESS,statusUpdateAppointmentByDoctor)
}
