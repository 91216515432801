import React, { useEffect, useState } from "react";
import { OrganisationEffect } from "../../../appRedux/doctor/sagas/effects/OrganisationEffect";
import DoctorSelectBox from "./DoctorSelectBox";

const OrganizationSelector = ({
  placeholder = "Select Organization",
  label = "",
  rules = [],
  name,
  editMode,
  margin = "mb-0",
}) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    getStatusList();
  }, []);

  const getStatusList = async () => {
    try {
      const { data } = await OrganisationEffect();
      const organList = data?.data;
      let options = organList.map((row) => ({
        label: row.name,
        value: row.id,
      }));
      setOptions(options);
    } catch (error) {
      setOptions([]);
    }
  };

  return (
    <DoctorSelectBox
      options={options}
      placeholder={placeholder}
      label={label}
      margin={margin}
      rules={rules}
      name={name}
      editMode={editMode}
    />
  );
};

export default OrganizationSelector;
