import React, { useState, useEffect } from "react";
import {
  Area,
  Line,
  Bar,
  Column,
  Base,
  BidirectionalBar,
  Funnel,
  Gauge,
  Heatmap,
  Histogram,
  Liquid,
  Mix,
  Pie,
  Radar,
  RadialBar,
  Rose,
  Sankey,
  Scatter,
  Stock,
  Sunburst,
  Tiny,
  Treemap,
  Venn,
  Violin,
  Waterfall,
  WordCloud,
  measureTextWidth,
} from "@ant-design/charts";

const DemoArea = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    // Using dummy data instead of fetching from an external API
    const dummyData = [
      { city: "New York", sales: 1500 },
      { city: "London", sales: 1200 },
      { city: "Tokyo", sales: 1800 },
      { city: "Paris", sales: 1400 },
      { city: "Sydney", sales: 1600 },
      { city: "Berlin", sales: 1700 },
      { city: "San Francisco", sales: 1300 },
    ];
    setData(dummyData);
  }, []);

  const config = {
    title: {
      visible: true,
      text: "Basic Area Chart - Slider Axis",
    },
    description: {
      visible: true,
      text: "The slider interaction is suitable for large datasets where users want to focus on a specific range of data.",
    },
    data,
    xField: "city",
    xAxis: {
      visible: true,
      label: {
        visible: true,
        autoHide: true,
      },
    },
    yField: "sales",
    yAxis: {
      label: {
        formatter: (v) =>
          `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
      },
    },
    interactions: [
      {
        type: "slider",
        cfg: {
          start: 0.5,
          end: 0.55,
        },
      },
    ],
    height: 300,
  };

  return <Area {...config} />;
};

export default DemoArea;
