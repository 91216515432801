import {
  DOCTOR_LIST_FAILURE,
  DOCTOR_LIST_IN_PROGRESS,
  DOCTOR_LIST_SUCCESS,
  GET_DOCTOR_ERROR,
  GET_DOCTOR_IN_PROGRESS,
  GET_DOCTOR_SUCCESS,
} from "../../../constants/ActionTypes";

const defaultStatus = {
  inProgress: false,
  success: false,
  error: false,
  data: null,
};
const initialState = {
  doctorList: { ...defaultStatus },
  getDoctor: { ...defaultStatus },
};

const DoctorListReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case DOCTOR_LIST_IN_PROGRESS:
      return {
        ...state,
        doctorList: { ...defaultStatus, ...payload, inProgress: true },
      };
    case DOCTOR_LIST_SUCCESS:
      return {
        ...state,
        doctorList: { ...defaultStatus, ...payload, success: true },
      };
    case DOCTOR_LIST_FAILURE:
      return {
        ...state,
        doctorList: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case GET_DOCTOR_IN_PROGRESS:
      return {
        ...state,
        getDoctor: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case GET_DOCTOR_SUCCESS:
      return {
        ...state,
        getDoctor: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
        },
      };
    case GET_DOCTOR_ERROR:
      return {
        ...state,
        getDoctor: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    default:
      return state;
  }
};

export default DoctorListReducer;
