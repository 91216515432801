import { Card } from "antd";
import React from "react";

import AppointmentQuestionScreenHeader from "./AppointmentQuestionScreenHeader";
import AppointmentQuestionTable from "./AppointmentQuestionTable";

function AppointmentquestionScreen() {
  return (
    <div>
      {" "}
      <Card>
        <AppointmentQuestionScreenHeader />
        <AppointmentQuestionTable />
      </Card>
    </div>
  );
}

export default AppointmentquestionScreen;
