import {
  ADD_PAYMENT_IN_PROGRESS,
  ADD_PAYMENT_IN_SUCCESS,
  ADD_PACKAGE_IN_ERROR,
  GET_BILLING_LIST_IN_PROGRESS,
  GET_BILLING_LIST_SUCCESS,
  GET_BILLING_LIST_ERROR,
  ADD_BILLING_IN_PROGRESS,
  ADD_BILLING_SUCCESS,
  ADD_BILLING_ERROR,
  UPDATE_BILLING_IN_PROGRESS,
  UPDATE_BILLING_SUCCESS,
  UPDATE_BILLING_ERROR,
  DELETE_BILLING_IN_PROGRESS,
  DELETE_BILLING_SUCCESS,
  DELETE_BILLING_ERROR,
  GET_BILLING_BY_INVOICE_LIST_IN_PROGRESS,
  GET_BILLING_BY_INVOICE_LIST_SUCCESS,
  GET_BILLING_BY_INVOICE_LIST_ERROR,
  GET_BILLING_BY_PATIENT_LIST_IN_PROGRESS,
  GET_BILLING_BY_PATIENT_LIST_SUCCESS,
  GET_BILLING_BY_PATIENT_LIST_ERROR,
  GET_DUE_BY_PATIENT_IN_PROGRESS,
  GET_DUE_BY_PATIENT_SUCCESS,
  GET_DUE_BY_PATIENT_ERROR,
  UNDO_BILL_SUCCESS,
  UNDO_BILL_IN_PROGRESS,
  UNDO_BILL_ERROR,
} from "../../../constants/ActionTypes";

// Get All Billing
export const getBillingListInProgress = (payload) => ({
  type: GET_BILLING_LIST_IN_PROGRESS,
  payload: payload,
});

export const getBillingListInSuccess = (payload) => ({
  type: GET_BILLING_LIST_SUCCESS,
  payload: payload,
});

export const getBillingListInError = (payload) => ({
  type: GET_BILLING_LIST_ERROR,
  payload: payload,
});
// addPayment Billing Payment
export const addPaymentInProgress = (payload) => ({
  type: ADD_PAYMENT_IN_PROGRESS,
  payload: payload,
});

export const addPaymentInInSuccess = (payload) => ({
  type: ADD_PAYMENT_IN_SUCCESS,
  payload: payload,
});

export const addPaymentInError = (payload) => ({
  type: ADD_PACKAGE_IN_ERROR,
  payload: payload,
});

// add Billing Payment
export const addBillingInProgress = (payload) => ({
  type: ADD_BILLING_IN_PROGRESS,
  payload: payload,
});

export const addBillingInSuccess = (payload) => ({
  type: ADD_BILLING_SUCCESS,
  payload: payload,
});

export const addBillingInError = (payload) => ({
  type: ADD_BILLING_ERROR,
  payload: payload,
});
// UPDATE Billing Payment
export const updateBillingInProgress = (payload) => ({
  type: UPDATE_BILLING_IN_PROGRESS,
  payload: payload,
});

export const updateBillingInSuccess = (payload) => ({
  type: UPDATE_BILLING_SUCCESS,
  payload: payload,
});

export const updateBillingInError = (payload) => ({
  type: UPDATE_BILLING_ERROR,
  payload: payload,
});

// Delete Billing Payment
export const deleteBillingInProgress = (payload) => ({
  type: DELETE_BILLING_IN_PROGRESS,
  payload: payload,
});

export const deleteBillingSuccess = (payload) => ({
  type: DELETE_BILLING_SUCCESS,
  payload: payload,
});

export const deleteBillingError = (payload) => ({
  type: DELETE_BILLING_ERROR,
  payload: payload,
});

//  Billing Payment by invoice
export const getBillingByInvoiceListInProgress = (payload) => ({
  type: GET_BILLING_BY_INVOICE_LIST_IN_PROGRESS,
  payload: payload,
});

export const getBillingByInvoiceListInSuccess = (payload) => ({
  type: GET_BILLING_BY_INVOICE_LIST_SUCCESS,
  payload: payload,
});

export const getBillingByInvoiceListInError = (payload) => ({
  type: GET_BILLING_BY_INVOICE_LIST_ERROR,
  payload: payload,
});

//  Billing Payment by PATIENT
export const getBillingByPatientListInProgress = (payload) => ({
  type: GET_BILLING_BY_PATIENT_LIST_IN_PROGRESS,
  payload: payload,
});

export const getBillingByPatientListInSuccess = (payload) => ({
  type: GET_BILLING_BY_PATIENT_LIST_SUCCESS,
  payload: payload,
});

export const getBillingByPatientListInError = (payload) => ({
  type: GET_BILLING_BY_PATIENT_LIST_ERROR,
  payload: payload,
});

//  DUEt by PATIENT
export const getDueByPatientInProgress = (payload) => ({
  type: GET_DUE_BY_PATIENT_IN_PROGRESS,
  payload: payload,
});

export const getDueByPatientInSuccess = (payload) => ({
  type: GET_DUE_BY_PATIENT_SUCCESS,
  payload: payload,
});

export const getDueByPatientInError = (payload) => ({
  type: GET_DUE_BY_PATIENT_ERROR,
  payload: payload,
});

export const undoBillInProgress = (payload) => ({
  type: UNDO_BILL_IN_PROGRESS,
  payload: payload,
});

export const undoBillInSuccess = (payload) => ({
  type: UNDO_BILL_SUCCESS,
  payload: payload,
});

export const undoBillInError = (payload) => ({
  type: UNDO_BILL_ERROR,
  payload: payload,
});
