import React, { useEffect, useState } from "react";
import { Form, Select, Row, Col, Input, Spin } from "antd";
import Auxiliary from "../../../util/Auxiliary";
import DoctorSelectAppointment from "../../Appoinment/DoctorSelectBox";
import SelectorsAppointment from "../../Appoinment/Selectors";
import {
  getMainPackagesListInProgress,
  getSubPackagesListInProgress,
} from "../../../appRedux/doctor/actions/billingPackage";
import { useDispatch, useSelector } from "react-redux";
import AddPayment from "./AddPayment";
import FormInput from "../../../components/Input/FormInput";
import { getDueByPatientInProgress } from "../../../appRedux/doctor/actions/addPayment";
import { disablePastDate } from "../../../util/utilz";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import { APP_ROUTES } from "../../../constants/Routes";
const { Option } = Select;
const NewBillingPayment = ({
  form,
  onFinish,
  editData,
  subPackageId,
  doctorID,
  patientID,
}) => {
  const dispatch = useDispatch();
  const organization_id = localStorage.getItem("org_id");
  const [subPackagesId, setSubPackagesId] = useState(
    subPackageId ? subPackageId : []
  );
  const [counter, setCounter] = useState(1);
  const [key, setKey] = useState();
  const [patientId, setPatientID] = useState();
  const [pacakge, setPacakge] = useState([]);
  const { billingPackages, billingPaymentreducer } = useSelector(
    (state) => state
  );
  const totalRecords = billingPackages?.mainPackagesList?.data?.data || [];
  const subPackageList = billingPackages?.subPackagesList?.data?.data || [];
  const PatientID = form?.getFieldValue("patient_id");

  useEffect(() => {
    if (doctorID || patientID) {
      form.setFieldsValue({ patient_id: patientID });
      form.setFieldsValue({ doctor_id: doctorID });
    }
  }, [patientID, patientID]);

  useEffect(() => {
    form.setFieldsValue({
      old_due_amount: billingPaymentreducer?.due?.data?.totalDue,
    });
  }, [billingPaymentreducer?.due?.data?.totalDue]);

  useEffect(() => {
    setPatientID(PatientID);
  }, [PatientID]);

  useEffect(() => {
    dispatch(getSubPackagesListInProgress({ organization_id }));
    dispatch(getMainPackagesListInProgress({ organization_id, pageNo: 1 }));
  }, []);

  useEffect(() => {
    dispatch(getDueByPatientInProgress(PatientID));
  }, [patientId]);

  useEffect(() => {
    if (subPackagesId && subPackageList?.length !== 0) {
      const selectedOptionsData = subPackagesId.map((optionId) =>
        subPackageList.find((o) => o.id === optionId)
      );
      const selete = subPackagesId.map((id) =>
        pacakge.find((o) => o?.sub_package_id === id)
      );
      const updateData = selectedOptionsData.map((option) => {
        const matchingPackage = selete.find(
          (item) => item?.sub_package_id === option?.id
        );
        if (matchingPackage) {
          return { ...option, ...matchingPackage };
        }
        return option;
      });

      const newInputData = updateData.map((option, index) => ({
        key: counter + index,
        sub_package_id: option?.id,
        package_name: option?.package_name,
        quantity: option?.quantity,
        price: option?.price,
      }));

      setPacakge(newInputData);

      let subTotal = 0;
      newInputData.forEach((value) => {
        subTotal += Number(value?.quantity) * Number(value?.price);
      });
      form.setFieldsValue({ billingPackage: subPackagesId });
      form.setFieldsValue({ PatientBillingSubPackages: newInputData });
      form.setFieldsValue({ sub_total: subTotal });
      setCounter(newInputData.length);
    }
  }, [subPackagesId, subPackageList]);

  useEffect(() => {
    if (Number(key) >= 0) {
      const subPackages = form?.getFieldValue("PatientBillingSubPackages");
      let subTotal = 0;
      subPackages.forEach((value) => {
        subTotal += Number(value?.quantity) * Number(value?.price);
      });
      setPacakge(subPackages);
      form.setFieldsValue({ sub_total: subTotal });
    }
    setKey();
  }, [key]);

  const handleQuantity = (e, key) => {
    const quantity = e.target.value;
    if (quantity) {
      form.setFieldValue(
        ["PatientBillingSubPackages", key, "quantity"],
        quantity
      );
      setKey(key);
    }
  };
  const handleBillingSingleSelect = (selected) => {
    const selectedMainPackage = totalRecords.find((obj) => obj.id === selected);
    form.setFieldsValue({ billingPackage: subPackagesId });
    const subPackageId = selectedMainPackage?.subPackages.map((id) => {
      return id?.sub_package_id;
    });
    handleBillingMultipleSelect(subPackageId);
  };
  const handleBillingMultipleSelect = (selected) => {
    setSubPackagesId(selected);
  };

  const handlePatient = (e) => {
    setPatientID(e?.value);
  };

  return (
    <Auxiliary>
      <Spin
        spinning={
          billingPaymentreducer?.addBilling?.inProgress === true ||
          billingPaymentreducer?.updateBilling?.inProgress === true
        }
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          initialValues={{
            remember: true,
            BillingPayments: [
              {
                Deposit_type: "",
                ref_no: "",
                paid_amount: 0,
              },
            ],
          }}
        >
          <Row style={{ flexDirection: "row" }}>
            <Col xs={24} sm={24} md={12} lg={8}>
              <SelectorsAppointment
                margin="mb-10"
                label={"Patient"}
                name={"patient_id"}
                placeholder={"Select  Patient"}
                editMode={true}
                rules={[
                  {
                    required: true,
                    message: "Please enter your patient",
                  },
                ]}
                onChange={handlePatient}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={8}>
              <DoctorSelectAppointment
                margin="mb-30"
                label={"Doctor"}
                name={"doctor_id"}
                placeholder={"Enter doctor"}
                editMode={true}
                rules={[
                  {
                    required: true,
                    message: "Please enter your Doctor",
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={8}>
              <DoctorSelectAppointment
                margin="mb-30"
                label={"Ref By Doctor"}
                name={"ref_by_doctor"}
                placeholder={"Enter  referring doctor"}
                editMode={true}
                rules={[
                  {
                    required: true,
                    message: "Please enter your referring Doctor",
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={8}>
              <FormInput
                type="date"
                placeholder="date"
                name="next_review_date"
                min={disablePastDate()}
                label={"Next Review Date"}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={8}>
              <FormInput
                type="number"
                placeholder=""
                name="old_due_amount"
                readOnly
                label={"Old Due Amount"}
                // rules={[
                //   {
                //     required: false,
                //     message: "Please Enter amount",
                //   },
                // ]}
                defaultValue={0}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={8}>
              <Form.Item label="Main Packages" name="mainPackagesId">
                <Select
                  style={{ width: "100%" }}
                  placeholder="Select Main package"
                  onChange={handleBillingSingleSelect}
                >
                  {totalRecords.map((obj) => (
                    <Option key={obj.id} value={obj.id}>
                      {obj.package_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                label="Billing Packages"
                name="billingPackage"
                rules={[
                  {
                    required: true,
                    message: "Please select your billingPackage",
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  style={{ width: "100%" }}
                  options={subPackageList.map((option) => ({
                    value: option?.id,
                    label: option?.package_name,
                  }))}
                  onChange={handleBillingMultipleSelect}
                  defaultValue={subPackagesId?.map(
                    (val) =>
                      subPackageList.find((vals) => vals?.id === val)
                        ?.package_name
                  )}
                  value={subPackagesId?.map(
                    (val) =>
                      subPackageList.find((vals) => vals?.id === val)
                        ?.package_name
                  )}
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.List name="PatientBillingSubPackages">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, fieldKey, ...restField }) => (
                      <>
                        <Row style={{ flexDirection: "row" }}>
                          <Col xs={24} sm={24} md={8} lg={8} xl={10}>
                            <Form.Item
                              {...restField}
                              name={[name, "package_name"]}
                              fieldKey={[fieldKey, "package_name"]}
                              rules={[
                                { required: true, message: "Missing category" },
                              ]}
                            >
                              <Input placeholder="Category" readOnly />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={8} lg={8} xl={7}>
                            <Form.Item
                              {...restField}
                              name={[name, "quantity"]}
                              fieldKey={[fieldKey, "quantity"]}
                              rules={[
                                { required: true, message: "Missing quantity" },
                              ]}
                            >
                              <Input
                                placeholder="Quantity"
                                type="number"
                                onChange={(e) => {
                                  handleQuantity(e, key);
                                }}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={8} lg={8} xl={7}>
                            <Form.Item
                              {...restField}
                              name={[name, "price"]}
                              fieldKey={[fieldKey, "price"]}
                              rules={[
                                { required: true, message: "Missing price" },
                              ]}
                            >
                              <Input
                                placeholder="Price"
                                type="number"
                                readOnly
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </>
                    ))}
                  </>
                )}
              </Form.List>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <AddPayment form={form} />
            </Col>
          </Row>{" "}
          <Row className="button-row-right">
            <Col>
              <Link to={APP_ROUTES.BILLINGS}>
                <ButtonComponent
                  label="Cancel"
                  className="btn-cancel secondary-color"
                />
              </Link>

              <ButtonComponent
                type="primary"
                label={editData ? "Save" : "Submit"}
                htmlType="submit"
              />
            </Col>
          </Row>
          {/* <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item> */}
        </Form>
      </Spin>
    </Auxiliary>
  );
};
export default NewBillingPayment;
