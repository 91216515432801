import {
  PATIENT_LIST_IN_FAILURE,
  PATIENT_LIST_IN_PROGRESS,
  PATIENT_LIST_IN_SUCCESS,
} from "../../../constants/ActionTypes";

export const patientListInProgress = (payload) => {
  return {
    type: PATIENT_LIST_IN_PROGRESS,
    payload: payload,
  };
};
export const patientListSuccess = (payload) => {
  return {
    type: PATIENT_LIST_IN_SUCCESS,
    payload: payload,
  };
};
export const patientListError = () => {
  return {
    type: PATIENT_LIST_IN_FAILURE,
  };
};
