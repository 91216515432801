import {
    TRACKER_APPOINTMENT_LIST_IN_PROGRESS,
    TRACKER_APPOINTMENT_LIST_IN_SUCCESS,
    TRACKER_APPOINTMENT_LIST_IN_ERROR,
    TRACKER_ADD_IN_PROGRESS,
    TRACKER_ADD_IN_SUCCESS,
    TRACKER_ADD_IN_ERROR,
    TRACKER_LIST_BY_ORGANIZATION_IN_PROGRESS,
    TRACKER_LIST_BY_ORGANIZATION_IN_SUCCESS,
    TRACKER_LIST_BY_ORGANIZATION_IN_ERROR,
    TRACKER_UPDATE_BY_REPORT_PROGRESS,
    TRACKER_UPDATE_BY_REPORT_SUCCESS,
    TRACKER_UPDATE_BY_REPORT_ERROR,
    TRACKER_UPDATE_BY_STATUS_PROGRESS,
    TRACKER_UPDATE_BY_STATUS_SUCCESS,
    TRACKER_UPDATE_BY_STATUS_ERROR, 
  } from "../../../constants/ActionTypes";
  
  const defaultStatus = {
    inProgress: false,
    success: false,
    error: false,
    data: null,
  };
  const initialState = {
    trackerByAppointment: { ...defaultStatus },
    addTracker :{ ...defaultStatus},
    trackerByOrganizations :{ ...defaultStatus},
    updateTRackerReport :{ ...defaultStatus},
    updateTrackerStaut :{ ...defaultStatus}
  };
  
  const TrackerReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
      case TRACKER_APPOINTMENT_LIST_IN_PROGRESS:
        return {
          ...state,
          trackerByAppointment: { ...defaultStatus, inProgress: true, ...payload },
        };
      case TRACKER_APPOINTMENT_LIST_IN_SUCCESS:
        return {
          ...state,
          trackerByAppointment: { ...defaultStatus, ...payload, success: true },
        };
      case TRACKER_APPOINTMENT_LIST_IN_ERROR:
        return {
          ...state,
          trackerByAppointment: {
            ...defaultStatus,
            ...payload,
            error: true,
          },
        };
      case TRACKER_ADD_IN_PROGRESS:
          return {
            ...state,
            addTracker : { ...defaultStatus, inProgress: true, ...payload },
          };
      case TRACKER_ADD_IN_SUCCESS:
          return {
            ...state,
            addTracker : { ...defaultStatus, ...payload, success: true },
          };
      case TRACKER_ADD_IN_ERROR:
          return {
            ...state,
            addTracker  : {
              ...defaultStatus,
              ...payload,
              error: true,
            },
          };
      case TRACKER_LIST_BY_ORGANIZATION_IN_PROGRESS:
            return {
              ...state,
              trackerByOrganizations: { ...defaultStatus, inProgress: true, ...payload },
            };
      case TRACKER_LIST_BY_ORGANIZATION_IN_SUCCESS:
            return {
              ...state,
              trackerByOrganizations: { ...defaultStatus, ...payload, success: true },
            };
      case TRACKER_LIST_BY_ORGANIZATION_IN_ERROR:
            return {
              ...state,
              trackerByOrganizations: {
                ...defaultStatus,
              
                error: true,
              },
            };     
      case TRACKER_UPDATE_BY_REPORT_PROGRESS:
              return {
                ...state,
                updateTRackerReport: { ...defaultStatus, inProgress: true, ...payload },
              };
      case TRACKER_UPDATE_BY_REPORT_SUCCESS:
              return {
                ...state,
                updateTRackerReport: { ...defaultStatus, ...payload, success: true },
              };
      case TRACKER_UPDATE_BY_REPORT_ERROR:
              return {
                ...state,
                updateTRackerReport: {
                  ...defaultStatus,
                
                  error: true,
                },
              };
      case TRACKER_UPDATE_BY_STATUS_PROGRESS :
                return {
                  ...state,
                  updateTrackerStaut: { ...defaultStatus, inProgress: true, ...payload },
                };
      case TRACKER_UPDATE_BY_STATUS_SUCCESS :
                return {
                  ...state,
                  updateTrackerStaut: { ...defaultStatus, ...payload, success: true },
                };
      case TRACKER_UPDATE_BY_STATUS_ERROR :
                return {
                  ...state,
                  updateTrackerStaut: {
                    ...defaultStatus,
                  
                    error: true,
                  },
                };
      default:
        return state;
    }
  };
  
  export default TrackerReducer;
  