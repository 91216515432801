import React from "react";
import { DatePicker } from "antd";
const FormDatePicker = ({
  className,
  format,
  name,
}) => {
  return (
    
   <DatePicker  className={ className} format={format} name={name} />  
  
  );
};
export default FormDatePicker;