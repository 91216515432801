import React, { useEffect } from "react";
import { Button, Form, Input, Col, Row, Card } from "antd";
import { useHistory, useParams, Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import { message } from "antd/lib/index";

import {
  ForgetPasswordInProgress,
  hideAuthLoader,
  showAuthLoader,
} from "../appRedux/doctor/actions/Auth";
import DocmozIcon from "../assets/icons/final-logo1 1.png";
import DocmozCar2 from "../common/images/loginpage3.png";

const FormItem = Form.Item;

const ForgetPassword = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  let slug = useParams();
  const { loader, alertMessage, showMessage } = useSelector(({ auth }) => auth);
  const updatePasswordData = useSelector((state) => state.register);
  const updatePasswordSuccess = updatePasswordData?.forgetpassword?.success;
  const onFinishFailed = (errorInfo) => {};

  const onFinish = (values) => {
    dispatch(showAuthLoader());
    let formData = new URLSearchParams();

    formData.append("email", slug.email);
    formData.append("password", values.password);
    const payload = { email: slug.email, password: values.password };
    dispatch(ForgetPasswordInProgress(payload));
  };
  useEffect(() => {
    dispatch(hideAuthLoader());
  }, []);
  useEffect(() => {
    if (updatePasswordSuccess === true) {
      history.push("/signin");
    }
  }, [updatePasswordSuccess]);
  return (
    <div className=" dashbord-logo-content">
      <>
        <div
          className=" left-side "
          // style={{ background: "#F6F5FB", color: "black" }}
        >
          <div className="carousel-content-main ">
            <img alt="example" src={DocmozCar2} className="carousel-content" />
          </div>
        </div>
        <div className=" right-side">
          <Card className="card-width card-effect">
            <div className="gx-app-logo login-logo">
              <img alt="example" src={DocmozIcon} />
            </div>

            <Form
              initialValues={{ remember: true }}
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="gx-signin-form gx-form-row0"
            >
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
              >
                <Input.Password placeholder="Password" />
              </Form.Item>
              <Form.Item
                name="confirm"
                dependencies={["password"]}
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "The two passwords that you entered do not match!"
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password placeholder="Confirm Password" />
              </Form.Item>

              <Row>
                <Col span={24} className="opt-form mb-10">
                  <Link to="/" className="gx-login-form-forgot form-forgot ">
                    SignIn
                  </Link>
                </Col>
                <Col span={24} className="opt-form">
                  {" "}
                  <Button type="primary" className="gx-mb-4" htmlType="submit">
                    change password
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card>
        </div>
      </>
      {/* )} */}
      {showMessage ? message.error(alertMessage.toString()) : null}
    </div>
  );
};

export default ForgetPassword;
