import React, { useEffect, useState } from "react";
// import TableComponent from "../../../components/Table/TableComponent";
// import Auxiliary from "../../../util/Auxiliary";
import {
  Button,
  Col,
  Modal,
  Popconfirm,
  Row,
  Spin,
  Tooltip,
  Typography,
  message,
} from "antd";
import { Link } from "react-router-dom";
import {
  CloseSquareOutlined,
  DeleteFilled,
  EditFilled,
  ExclamationCircleOutlined,
  LoadingOutlined,
  PrinterOutlined,
  UndoOutlined,
} from "@ant-design/icons";
// import ButtonComponent from "../../../components/Button/ButtonComponent";

import Icon from "@ant-design/icons/lib/components/Icon";
// import CollectPaymentModal from "../CollectPaymentModal";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteBillingInProgress,
  getBillingListInProgress,
  undoBillInProgress,
} from "../../../appRedux/doctor/actions/addPayment";
// import { APP_ROUTES } from "../../../constants/Routes";
// import Message from "../../../components/Message/Message";
// import CancleModal from "../";
import Message from "../../../components/Message/Message";

import ButtonComponent from "../../../components/Button/ButtonComponent";
import Auxiliary from "../../../util/Auxiliary";
import TableComponent from "../../../components/Table/TableComponent";
import { APP_ROUTES } from "../../../constants/Routes";
import {
  deleteSubpackagesInProgress,
  getSubPackagesListInProgress,
} from "../../../appRedux/doctor/actions/billingPackage";
import {
  deleteROLESInProgress,
  getRolesListInProgress,
} from "../../../appRedux/admin/roles/action/rolesAction";
import { render } from "less";

const RolesTable = () => {
  const organization_id = localStorage.getItem("org_id");

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedBillingId, setSelectedBillingId] = useState(null);
  const [deleteId, setDeleteId] = useState();
  const dispatch = useDispatch();
  const [navigate, setNavigate] = useState(false);
  const { billingPackages, billingPaymentreducer, accessRoles } = useSelector(
    (state) => state
  );
  const rolesLoading = accessRoles?.rolesList?.progressing;
  const totalRecords = accessRoles?.rolesList?.data?.totalRecords;
  const rolesList = accessRoles?.rolesList?.data?.data || [];
  const showModal = (record) => {
    setSelectedBillingId(record);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    const paylod = {
      organization_id: organization_id,
      pageNo: 1,
      pagination_required: "true",
      pagesize: 10,
    };

    dispatch(getRolesListInProgress(paylod));
  }, []);

  const handlePage = (page) => {
    const paylod = {
      organization_id: organization_id,
      pageNo: page,
      pagination_required: "true",
      pagesize: 10,
    };
    console.log("pagination-oayload", paylod);
    dispatch(getRolesListInProgress(paylod));
  };
  const handleModalSubmit = () => {
    setIsModalVisible(false);
  };
  const handleDelete = (deleteData) => {
    const handleSuccess = () => {
      const paylod = {
        organization_id: organization_id,
        pageNo: 1,
      };
      dispatch(getRolesListInProgress({ paylod }));
    };
    const payload = {
      id: deleteData.id,
      handleSuccess,
    };
    dispatch(deleteROLESInProgress(payload));
  };
  const handleConformCancle = () => {
    setIsModalOpen(false);
  };
  const handleSubmit = (data) => {
    const payload = {
      id: deleteId,
      reason: data?.reason,
    };
    dispatch(deleteBillingInProgress(payload));

    setNavigate(true);
  };
  const handleUndo = (data) => {
    dispatch(undoBillInProgress(data?.id));
    setNavigate(true);
  };
  const cancel = () => {
    Message.error("Cancelled");
  };
  useEffect(() => {
    if (
      (billingPaymentreducer?.deleteBilling?.success && navigate) ||
      (billingPaymentreducer?.addPayments?.success && navigate) ||
      (billingPaymentreducer?.undo?.success && navigate)
    ) {
      window.location.reload();
      setNavigate(false);
    }
  }, [
    billingPaymentreducer?.deleteBilling?.success && navigate,
    billingPaymentreducer?.addPayments?.success && navigate,
    billingPaymentreducer?.undo?.success && navigate,
  ]);

  const columns = [
    {
      title: "Role ID",
      dataIndex: "roles_serial_no",
    },

    {
      title: "Roles",
      dataIndex: "name",
    },
    {
      title: "Role Type",
      dataIndex: "role_id",
      render: (data) =>
        Number(data) === 2
          ? "Doctor"
          : Number(data) === 4
          ? "Pharmacy"
          : "Others",
    },
    {
      title: "Status",
      dataIndex: "Status",
    },

    {
      title: "Action",
      dataIndex: "Action",
      // width: 150,
      render: (_, record) => {
        return (
          <>
            {/* <Row>
                  <Col xs={24}> */}{" "}
            <Tooltip placement="top" title={"Edit"}>
              {" "}
              <Link
                to={{
                  pathname: APP_ROUTES.ADDROLES,
                  state: record,
                  type: "edit",
                }}
              >
                <ButtonComponent
                  icon={<EditFilled />}
                  size="small"
                  type="primary"
                  onClick={() => {
                    showModal(record);
                  }}
                />
              </Link>
            </Tooltip>
            {/* </Col> */}
            {/* <Col xs={12}>
                      <Popconfirm
                        title="Are you sure to delete?"
                        onConfirm={() => {
                          handleDelete(record);
                        }}
                        onCancel={cancel}
                        okText="Yes"
                        cancelText="No"
                      >
                        <ButtonComponent
                          icon={<DeleteFilled />}
                          size="small"
                          type="danger"
                        />
                      </Popconfirm>
                    </Col> */}
            {/* </Row> */}
          </>
        );
      },
    },
  ];
  const rows = rolesList?.map((role, index) => ({
    ...role,
    roles_serial_no: role?.roles_serial_no,
    id: role.id,
    Status: "ACTIVE", // You may need to get this value from your role object
    name: role?.name,
    permissions: role?.permissions,
    isPrimary: role?.isPrimary,
  }));
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );
  return (
    <Auxiliary>
      {" "}
      <Spin indicator={antIcon} spinning={rolesLoading}>
        <TableComponent
          columns={columns}
          dataSource={rows}
          pagination={{
            total: totalRecords,
            onChange: (page) => handlePage(page),
          }}
          documentTitle={"Roles List "}
        />
      </Spin>
    </Auxiliary>
  );
};

export default RolesTable;
