import { Card, Col, Divider, Row, Table, Typography } from "antd";
import React, { useRef } from "react";

import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import FreeInvoice from "../Payment/FreeInvoice";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import {
  ArrowLeftOutlined,
  DownloadOutlined,
  EnvironmentFilled,
  PhoneFilled,
} from "@ant-design/icons";
import moment from "moment";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import logo from "../../../assets/images/DocMotez/logo.png";
const HospitalReport = () => {
  const location = useLocation();
  const history = useHistory();
  const hospitalData = location.state?.hospitalData;
  const goBackHandler = () => {
    const selectedDates = location.state?.selectedDates;

    history.push({
      pathname: "/organization/reportList",
      state: {
        selectedDates,
        orgId: location?.state?.orgId,
        orgName: location?.state?.orgName,
      },
    });
  };
  console.log("location.state", location.state);
  const printRef = useRef();

  const downloadPDF = async () => {
    const input = printRef.current;
    const canvas = await html2canvas(input, {
      scale: 2,
    });
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("portrait", "pt", "a4");
    const imgProps = pdf.getImageProperties(imgData);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("invoice.pdf");
  };
  const currentDate = moment().format("DD/MM/YYYY");
  const currentTime = moment().format("hh:mm A");
  console.log("hospitalData", hospitalData);
  const calculateAge = (dob) => {
    if (!dob) return null;

    const birthDate = new Date(dob);
    const today = new Date();

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  };

  return (
    <Card className="mx-20">
      <Row>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Row className="print-button-invoice mx-20">
            <Col>
              <ButtonComponent
                type="success"
                icon={<DownloadOutlined />}
                onClick={() => downloadPDF()}
                className={"print-button"}
              ></ButtonComponent>
              <ButtonComponent
                type="danger"
                icon={<ArrowLeftOutlined />}
                onClick={goBackHandler}
              ></ButtonComponent>
            </Col>
          </Row>

          <Col xl={20} lg={20} md={24} sm={24} xs={24}>
            <Row ref={printRef} className="mt-30 px-20">
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <Row>
                  <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <img src={logo} width={150} alt="image" />
                  </Col>{" "}
                  <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Row className="px-10">
                      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <Typography className="doctor-name">
                          Dr.{hospitalData?.doctor_name}
                        </Typography>
                      </Col>
                      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <Typography className="doctor-details py-5">
                          {hospitalData?.specialization_name}
                        </Typography>
                      </Col>
                      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <Typography className="doctor-details py-5">
                          {hospitalData?.qualification}
                        </Typography>
                      </Col>
                    </Row>
                  </Col>{" "}
                  <Col
                    xl={22}
                    lg={22}
                    md={22}
                    sm={24}
                    xs={24}
                    className="mt-5 mb-5"
                  >
                    <Divider
                      style={{
                        borderColor: "black",
                      }}
                    />
                  </Col>
                </Row>{" "}
                <Row>
                  <Col
                    xl={24}
                    lg={24}
                    md={22}
                    sm={24}
                    xs={24}
                    className="my-10"
                  >
                    <Row className="px-20 mt-20 ">
                      <Col xl={7} lg={6} md={8} sm={12} xs={24}>
                        <Typography className="doctor-details mt-10">
                          <span className="list-header1 mr-5">
                            {" "}
                            Patient Name :
                          </span>
                          {hospitalData?.patient_name}
                        </Typography>
                      </Col>

                      <Col xl={5} lg={7} md={8} sm={12} xs={24}>
                        <Typography className="doctor-details mt-10">
                          <span className="list-header1 mr-5">Age :</span>{" "}
                          {calculateAge(hospitalData?.patient_dob)} years
                        </Typography>
                      </Col>
                      <Col xl={4} lg={7} md={7} sm={12} xs={24}>
                        <Typography className="doctor-details mt-10">
                          <span className="list-header1 mr-5">Sex :</span>{" "}
                          {hospitalData?.patient_gender === "M"
                            ? "Male"
                            : "Female"}
                        </Typography>
                      </Col>
                      <Col xl={8} lg={8} md={10} sm={12} xs={24}>
                        <Typography className="doctor-details mt-10">
                          <span className="list-header1 mr-5">
                            Blood Group :
                          </span>
                          {hospitalData?.patient_blood_group}
                        </Typography>
                      </Col>
                    </Row>
                  </Col>
                  {/* <Col
                    xl={22}
                    lg={22}
                    md={22}
                    sm={24}
                    xs={24}
                    className="mt-5 mb-5"
                  >
                    <Divider
                      style={{
                        borderColor: "black",
                      }}
                    />
                  </Col> */}
                </Row>
              </Col>
              <Col
                xl={24}
                lg={24}
                md={24}
                sm={24}
                xs={24}
                // display="flex"
                // justify="center"
                className="justify-center"
              >
                <Row className="mt-10 justify-center">
                  <Col xl={24} lg={24} md={24} sm={24} xs={24} className="ml-5">
                    <Row>
                      <Col
                        xl={5}
                        lg={5}
                        md={5}
                        sm={10}
                        xs={18}
                        className="py-5"
                      >
                        <Typography className="list-header1 ml-10">
                          Appoinment Date
                        </Typography>
                      </Col>{" "}
                      <Col
                        xl={1}
                        lg={1}
                        md={1}
                        sm={0.5}
                        xs={0.5}
                        className="my-10"
                      >
                        <Typography className="list-header1">:</Typography>
                      </Col>
                      <Col
                        xl={16}
                        lg={10}
                        md={10}
                        sm={10}
                        xs={20}
                        className="my-10"
                      >
                        <Typography className="list-item">
                          {hospitalData?.date}
                        </Typography>
                      </Col>{" "}
                      <Col
                        xl={5}
                        lg={5}
                        md={5}
                        sm={10}
                        xs={18}
                        className="py-5"
                      >
                        <Typography className="list-header1 ml-10">
                          Time
                        </Typography>
                      </Col>{" "}
                      <Col
                        xl={1}
                        lg={1}
                        md={1}
                        sm={0.5}
                        xs={0.5}
                        className="py-5"
                      >
                        <Typography className="list-header1">:</Typography>
                      </Col>
                      <Col
                        xl={16}
                        lg={10}
                        md={10}
                        sm={10}
                        xs={20}
                        className="my-10"
                      >
                        <Typography className="list-item">
                          {hospitalData?.start_time} - {hospitalData?.end_time}
                        </Typography>
                      </Col>{" "}
                      <Col
                        xl={5}
                        lg={5}
                        md={5}
                        sm={10}
                        xs={18}
                        className="py-5"
                      >
                        <Typography className="list-header1 ml-10">
                          Consultation Type
                        </Typography>
                      </Col>
                      <Col
                        xl={1}
                        lg={1}
                        md={1}
                        sm={0.5}
                        xs={0.5}
                        className="my-10"
                      >
                        <Typography className="list-header1">:</Typography>
                      </Col>
                      <Col
                        xl={16}
                        lg={10}
                        md={10}
                        sm={10}
                        xs={20}
                        className="my-10"
                      >
                        <Typography className="list-item">
                          {hospitalData?.consultation_type === "1"
                            ? "In Person Consultation"
                            : "Tele Consultation"}
                        </Typography>
                      </Col>{" "}
                      <Col
                        xl={5}
                        lg={5}
                        md={5}
                        sm={10}
                        xs={18}
                        className="py-5"
                      >
                        <Typography className="list-header1 ml-10">
                          Consultation Fee
                        </Typography>
                      </Col>
                      <Col
                        xl={1}
                        lg={1}
                        md={1}
                        sm={0.5}
                        xs={0.5}
                        className="my-10"
                      >
                        <Typography className="list-header1">:</Typography>
                      </Col>
                      <Col
                        xl={16}
                        lg={10}
                        md={10}
                        sm={10}
                        xs={20}
                        className="my-10"
                      >
                        <Typography className="list-item">
                          {" "}
                          {hospitalData?.consultation_fee}
                        </Typography>
                      </Col>
                      <Col
                        xl={5}
                        lg={5}
                        md={5}
                        sm={10}
                        xs={18}
                        className="py-5"
                      >
                        <Typography className="list-header1 ml-10">
                          Appoinment Status
                        </Typography>
                      </Col>
                      <Col
                        xl={1}
                        lg={1}
                        md={1}
                        sm={0.5}
                        xs={0.5}
                        className="my-10"
                      >
                        <Typography className="list-header1">:</Typography>
                      </Col>
                      <Col
                        xl={16}
                        lg={10}
                        md={10}
                        sm={10}
                        xs={20}
                        className="my-10"
                      >
                        <Typography className="list-item">
                          {hospitalData?.appointment_status_name}
                        </Typography>
                      </Col>{" "}
                      <Col
                        xl={5}
                        lg={5}
                        md={5}
                        sm={10}
                        xs={18}
                        className="py-5"
                      >
                        <Typography className="list-header1 ml-10">
                          reason for visiting
                        </Typography>
                      </Col>
                      <Col
                        xl={1}
                        lg={1}
                        md={1}
                        sm={0.5}
                        xs={0.5}
                        className="my-10"
                      >
                        <Typography className="list-header1">:</Typography>
                      </Col>
                      <Col
                        xl={16}
                        lg={10}
                        md={10}
                        sm={10}
                        xs={20}
                        className="my-10"
                      >
                        <Typography className="list-item">
                          {hospitalData?.reason}
                        </Typography>
                      </Col>{" "}
                      <Col
                        xl={5}
                        lg={5}
                        md={5}
                        sm={10}
                        xs={18}
                        className="py-5"
                      >
                        <Typography className="list-header1 ml-10">
                          Symptoms
                        </Typography>
                      </Col>
                      <Col
                        xl={1}
                        lg={1}
                        md={1}
                        sm={0.5}
                        xs={0.5}
                        className="my-10"
                      >
                        <Typography className="list-header1">:</Typography>
                      </Col>
                      <Col
                        xl={16}
                        lg={10}
                        md={10}
                        sm={10}
                        xs={20}
                        className="my-10"
                      >
                        <Typography className="list-item">
                          {hospitalData?.symptom}
                        </Typography>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>{" "}
              <Col
                xl={20}
                lg={20}
                md={20}
                sm={24}
                xs={24}
                className="mt-20 px-20"
                style={{
                  display: "flex",

                  justifyItems: "center",
                  justifyContent: "center",
                }}
              >
                <Row
                  style={{
                    display: "flex",

                    justifyItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Col xl={24} lg={24} md={24} sm={24} sx={24}>
                    <Typography className="report-item justify-center mt-10">
                      This is to certify that Mr/Mrs/Ms
                      _________________________________________was diagnosed in
                      _________________________________________________on
                      ____________________________
                    </Typography>
                  </Col>
                  <Col
                    xl={24}
                    lg={24}
                    md={24}
                    sm={24}
                    sx={24}
                    style={{
                      display: "flex",

                      justifyContent: "flex-start",
                    }}
                  >
                    <Typography className="report-item justify-center mt-20">
                      Conclusion
                    </Typography>
                  </Col>
                  <Col xl={24} lg={24} md={24} sm={24} sx={24}>
                    <Typography className="report-item justify-center mt-20">
                      _____________________________________________
                      ________________________________________________________
                      ____________________________
                    </Typography>
                  </Col>
                </Row>
              </Col>{" "}
              <Col
                xl={20}
                lg={20}
                md={24}
                sm={24}
                xs={24}
                className="mt-30 px-10"
                style={{
                  display: "flex",

                  justifyContent: "flex-end",
                }}
              >
                <Typography className="report-item">
                  {hospitalData?.doctor_name}
                </Typography>
              </Col>
            </Row>
          </Col>
        </Col>
      </Row>
    </Card>
  );
};

export default HospitalReport;
