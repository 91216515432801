import React, { Fragment, useState, useRef, useEffect } from "react";
import {
  Button,
  Checkbox,
  Col,
  Dropdown,
  Menu,
  Row,
  Table,
  Tooltip,
  Typography,
} from "antd";

import FormSelectBox from "../SelectBox/SelectBox";
import ButtonComponent from "../Button/ButtonComponent";
import SearchBox from "../SearchBox";
import IntlMessages from "../../util/IntlMessages";
import ReactDOM from "react-dom";
import { Table_Row } from "../../constants/Patient";
import jsPDF from "jspdf";
import { useDispatch, useSelector } from "react-redux";
import "jspdf-autotable";
import { CSVDownload, CSVLink } from "react-csv";
import ExcelJS from "exceljs";
import Papa from "papaparse";

import moment from "moment";
import PrintTable from "./PrintTable";
import "../../styles/modules/doctor.less";

import { getOrgNameInProgress } from "../../appRedux/doctor/actions/organisation";

import { answerType } from "../../screens/AppointmentQuestions/data";
import { salesTypeList } from "../../screens/Doctor/data";
import {
  DownloadOutlined,
  DownOutlined,
  FileExcelOutlined,
  FilePdfOutlined,
  MenuUnfoldOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
const TableComponent = ({
  columns,
  dataSource,
  pagination,
  bordered,
  size,
  onChange,
  patientapi,
  patientFilter,
  doctorapi,
  doctorFilter,
  rowselector,
  doctorRowselectors,
  itemList,
  setFilteredUsers,
  filteredUsers,
  filteredData,
  field,
  Allappointments,
  AllappointmentsFilter,
  type,
  headers,
  pdf,
  disableTableHeaderButtons,
  documentTitle,
  disableTablePageRow,
  handleDownloadPDF,
  title,
}) => {
  const [visibleColumns, setVisibleColumns] = useState(columns);
  const tableRef = useRef();
  const orgList = useSelector((state) => state.org);
  const qualificationList = useSelector((state) => state.qualification);
  const designationList = useSelector((state) => state.designation);
  const specializationList = useSelector((state) => state.specialization);
  const allusersData = useSelector((state) => state.Users);
  const usersList = allusersData?.userslist?.data?.data;
  const orgListData = orgList?.org?.data?.data;
  const qualificationListData =
    qualificationList?.qualificationlist?.data?.data;
  const designationListData = designationList?.designation?.data?.data;
  const specializationListData = specializationList?.Specialization?.data?.data;

  const generateCsvContent = (data, columns) => {
    // Filter out columns titled "Options"
    const exportableColumns = columns.filter(
      (column) => column.title !== "Options"
    );

    // Extracting CSV headers
    const csvHeaders = exportableColumns
      .map((column) => column.title)
      .join(",");

    // Extracting CSV data
    const csvData = data
      .map((row, rowIndex) =>
        exportableColumns
          .map((column) => {
            if (column.title === "S.No") {
              return rowIndex + 1;
            }
            if (
              column.title === "Block Name" &&
              documentTitle === "Total Room List"
            ) {
              return row.Block?.block_name;
            } else if (
              column.title === "Ward Name" &&
              documentTitle === "Total Room List"
            ) {
              return row.Ward?.ward_name;
            } else if (column.title === "Block Status") {
              return row?.block_status === "1" ? "Active" : "Inactive";
            }
            return row[column.dataIndex];
          })
          .join(",")
      )
      .join("\n");

    // Creating CSV content
    return `${csvHeaders}\n${csvData}`;
  };

  const handleClick = () => {
    if (type === "patientbillingReport") {
      handleDownloadPDF();
    }
    // if (type === "doctor" || type === "doctor_list") {
    //   exportDoctorPDF();
    // }
    //  else if (type === "Appointment") {
    //   exportAppointmentPDF();
    // }
    else if (orgList?.org?.success) {
      exportORG();
    } else if (qualificationList?.qualificationlist?.success) {
      exportQUALIFICATION();
    } else if (designationList?.designation?.success) {
      exportDESIGNATION();
    } else if (specializationList?.Specialization?.success) {
      exportSPECIALIZATION();
    } else if (allusersData?.userslist?.success) {
      exportAllusers();
    } else if ((type = "patientBilling")) {
      downloadPDF();
    } else {
      downloadPDF();
    }
  };

  const exportORG = () => {
    const unit = "pt";
    const size = "A4";
    const orientation = "landscape";
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(15);
    const title = "Organization Details";
    const headers = [
      ["Name", "Email", "Contact", "Alternate_contact", "Address"],
    ];
    const dataValues = orgListData.map((d) => [
      d.name,
      d.email,
      d.contact,
      d.alternate_contact,
      d.address,
    ]);
    const content = {
      startY: 50,
      head: headers,
      body: dataValues,
    };
    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("organisation.pdf");
  };
  const exportQUALIFICATION = () => {
    const unit = "pt";
    const size = "A4";
    const orientation = "landscape";
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(15);
    const title = "Qualification Details";
    const headers = [["Name", "Description"]];
    const dataValues = qualificationListData.map((d) => [
      d.name,
      d.description,
    ]);
    const content = {
      startY: 50,
      head: headers,
      body: dataValues,
    };
    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("qualification.pdf");
  };
  const exportDESIGNATION = () => {
    const unit = "pt";
    const size = "A4";
    const orientation = "landscape";
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(15);
    const title = "Designation Details";
    const headers = [["Name", "CreatedAt", "UpdatedAt"]];
    const dataValues = designationListData.map((d) => [
      d.Name,
      d.createdAt,
      d.updatedAt,
    ]);
    const content = {
      startY: 50,
      head: headers,
      body: dataValues,
    };
    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("designation.pdf");
  };
  const exportSPECIALIZATION = () => {
    const unit = "pt";
    const size = "A4";
    const orientation = "landscape";
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(15);
    const title = "Specialization Details";
    const headers = [["ID", "Name", "CreatedAt", "UpdatedAt"]];
    const dataValues = specializationListData.map((d) => [
      d.id,
      d.Name,
      d.createdAt,
      d.updatedAt,
    ]);
    const content = {
      startY: 50,
      head: headers,
      body: dataValues,
    };
    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("specialization.pdf");
  };
  const exportAllusers = () => {
    const unit = "pt";
    const size = "A4";
    const orientation = "landscape";
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(15);
    const title = "All Users Details";
    const headers = [
      [
        "FirstName",
        "LastName",
        "Contact",
        "Email",
        "Address1",
        "Address2",
        "City",
        "State",
        "Country Code",
        "Rolename",
        "Status",
      ],
    ];
    const dataValues = usersList.map((d) => [
      d.firstName,
      d.lastName,
      d.contact,
      d.email,
      d.address1,
      d.address2,
      d.city,
      d.state,
      d.country_code,
      d.role_name,
      d.status_name,
    ]);
    const content = {
      startY: 50,
      head: headers,
      body: dataValues,
      columnStyles: {
        0: {
          columnWidth: 70,
        },
        1: {
          columnWidth: 70,
        },
        2: {
          columnWidth: 70,
        },
        3: {
          columnWidth: 70,
        },
        4: {
          columnWidth: 70,
        },
        5: {
          columnWidth: 70,
        },
        6: {
          columnWidth: 70,
        },
        7: {
          columnWidth: 70,
        },
        8: {
          columnWidth: 70,
        },
        9: {
          columnWidth: 70,
        },
        10: {
          columnWidth: 70,
        },
        11: {
          columnWidth: 70,
        },
      },
    };
    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("AllUsers.pdf");
  };

  const exportCSV = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet1");

    if (dataSource?.length > 0) {
      if (type === "doctor_list") {
        const columnsToIncludeStudents = [
          { header: "First Name", key: "firstName" },
          { header: "Last Name", key: "lastName" },
          { header: "Date of Birth", key: "dob" },
          { header: "Email", key: "email" },
          { header: "Contact", key: "contact" },
          { header: "Address 1", key: "address1" },
          { header: "Address 2", key: "address2" },
          { header: "City", key: "city" },
          { header: "State", key: "state" },
          { header: "Country Code", key: "country_code" },
          { header: "Qualification", key: "qualification" },

          { header: "License Number", key: "license_no" },
          ,
          { header: "Gender Type", key: "gender_type" },
          { header: "Experience", key: "experience" },

          { header: "In-person Consultation", key: "inperson_consultation" },
          { header: "Tele Consultation", key: "tele_consultation" },

          { header: "Specialization Name", key: "specialization_name" },
          { header: "Organization Name", key: "organization_name" },
          { header: "Designation Name", key: "designation_name" },
          {
            header: "Organization Serial Number",
            key: "organization_serial_no",
          },
          { header: "Profile Image", key: "profile_image" },
          { header: "Doctor Experience", key: "doctor_experience" },
          { header: "Is Video Call Allowed", key: "isVideoCallAllowed" },
        ];
        // Adding column headers
        worksheet.columns = columnsToIncludeStudents.map((col) => ({
          header: col.header,
          key: col.key,
          width: 15,
        }));

        // Adding data rows
        dataSource.forEach((row, rowIndex) => {
          const rowData = {};
          columnsToIncludeStudents.forEach((col) => {
            rowData[col.key] = row[col.key];
          });
          worksheet.addRow(rowData);
        });

        worksheet.getRow(1).font = { bold: true };
        worksheet.eachRow({ includeEmpty: false }, (row) => {
          row.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        });

        workbook.xlsx.writeBuffer().then((buffer) => {
          const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "doctors.xlsx";
          a.click();
          window.URL.revokeObjectURL(url);
        });
      } else if (type === "teamMember") {
        const columnsToIncludeMembers = [
          { header: "Access Role ID", key: "access_role_id" },
          { header: "Access Role Name", key: "access_role_name" },
          { header: "Address", key: "address" },
          { header: "Created At", key: "createdAt" },

          { header: "Date of Birth", key: "dob" },
          { header: "Email", key: "email" },
          { header: "First Name", key: "firstName" },
          { header: "Gender Type", key: "gender_type" },

          { header: "Last Name", key: "lastName" },
          { header: "Members Serial Number", key: "members_serial_no" },

          { header: "Phone", key: "phone" },
        ];

        // Adding column headers
        worksheet.columns = columnsToIncludeMembers.map((col) => ({
          header: col.header,
          key: col.key,
          width: 15,
        }));

        // Adding data rows
        dataSource.forEach((row, rowIndex) => {
          const rowData = {};
          columnsToIncludeMembers.forEach((col) => {
            rowData[col.key] = row[col.key];
          });
          worksheet.addRow(rowData);
        });

        worksheet.getRow(1).font = { bold: true };
        worksheet.eachRow({ includeEmpty: false }, (row) => {
          row.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        });

        workbook.xlsx.writeBuffer().then((buffer) => {
          const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "teamMembers.xlsx";
          a.click();
          window.URL.revokeObjectURL(url);
        });
      } else if (type === "patientlist") {
        const columnsToIncludePatients = [
          { header: "Address 1", key: "address1" },
          { header: "Address 2", key: "address2" },
          { header: "Blood Group", key: "blood_group" },
          { header: "Blood Group ID", key: "blood_group_id" },
          { header: "City", key: "city" },
          { header: "Contact", key: "contact" },
          { header: "Country Code", key: "country_code" },
          { header: "Created At", key: "createdAt" },
          { header: "Delete At", key: "deleteAt" },
          { header: "Date of Birth", key: "dob" },
          { header: "Doctor Name", key: "doctorName" },
          { header: "Email", key: "email" },
          { header: "First Name", key: "firstName" },
          { header: "Gender Type", key: "gender_type" },
          { header: "ID", key: "id" },
          { header: "Is Prescription Added", key: "isPrescriptionAdded" },
          { header: "Last Name", key: "lastName" },
          { header: "Patient Serial Number", key: "patient_serial_no" },
          { header: "Profile Image", key: "profile_image" },
          { header: "Profile URL", key: "profile_url" },
          { header: "Role ID", key: "role_id" },
          { header: "State", key: "state" },
          { header: "Updated At", key: "updatedAt" },
          { header: "User ID", key: "user_id" },
        ];
        worksheet.columns = columnsToIncludePatients.map((col) => ({
          header: col.header,
          key: col.key,
          width: 15,
        }));

        // Adding data rows
        dataSource.forEach((row, rowIndex) => {
          const rowData = {};
          columnsToIncludePatients.forEach((col) => {
            rowData[col.key] = row[col.key];
          });
          worksheet.addRow(rowData);
        });

        worksheet.getRow(1).font = { bold: true };
        worksheet.eachRow({ includeEmpty: false }, (row) => {
          row.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        });

        workbook.xlsx.writeBuffer().then((buffer) => {
          const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "patientsList.xlsx";
          a.click();
          window.URL.revokeObjectURL(url);
        });
      } else if (type === "patientBilling") {
        const columnsToIncludeBillingPayments = [
          { header: "Date", key: "DATE" },

          { header: "Discount", key: "discount" },
          { header: "Doctor ID", key: "doctor_id" },
          { header: "Doctor Name", key: "doctor_name" },
          { header: "Doctor Serial Number", key: "doctor_serial_no" },

          { header: "Invoice Number", key: "invoice_no" },
          { header: "Main Packages ID", key: "mainPackagesId" },
          { header: "Next Review Date", key: "next_review_date" },
          { header: "Old Due Amount", key: "old_due_amount" },
          { header: "Organization ID", key: "organization_id" },
          { header: "Paid Amount", key: "paid_amount" },
          { header: "Patient Address 1", key: "patient_address1" },
          { header: "Patient Address 2", key: "patient_address2" },
          { header: "Patient City", key: "patient_city" },
          { header: "Patient ID", key: "patient_id" },
          { header: "Patient Name", key: "patient_name" },
          { header: "Patient Serial Number", key: "patient_serial_no" },
          { header: "Patient State", key: "patient_state" },
          { header: "Reason", key: "reason" },
          { header: "Referred By Doctor", key: "ref_by_doctor" },
          { header: "Referring Doctor Name", key: "ref_doc_name" },
          { header: "Remarks", key: "remarks" },

          { header: "Due", key: "due" },
          { header: "Due Pending", key: "duePending" },
          { header: "Sub Total", key: "sub_total" },
          { header: "Grand Total", key: "grand_total" },
        ];

        worksheet.columns = columnsToIncludeBillingPayments.map((col) => ({
          header: col.header,
          key: col.key,
          width: 15,
        }));

        // Adding data rows
        dataSource.forEach((row, rowIndex) => {
          const rowData = {};
          columnsToIncludeBillingPayments.forEach((col) => {
            rowData[col.key] = row[col.key];
          });
          worksheet.addRow(rowData);
        });

        worksheet.getRow(1).font = { bold: true };
        worksheet.eachRow({ includeEmpty: false }, (row) => {
          row.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        });

        workbook.xlsx.writeBuffer().then((buffer) => {
          const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "biilingList.xlsx";
          a.click();
          window.URL.revokeObjectURL(url);
        });
      } else if (type === "Appointment") {
        const columnsToIncludeAppointments = [
          { header: "Week Day", key: "week_day" },
          { header: "Start Time", key: "start_time" },
          { header: "End Time", key: "end_time" },
          { header: "Date", key: "date" },
          { header: "Reason", key: "reason" },
          { header: "Symptom", key: "symptom" },
          { header: "Is Cancel", key: "is_cancel" },
          { header: "Consultation Type", key: "consultation_type" },
          { header: "Consultation Fee", key: "consultation_fee" },
          { header: "Appointment Status", key: "appointment_status" },

          { header: "Doctor Serial Number", key: "doctor_serial_no" },
          { header: "Doctor Name", key: "doctor_name" },
          { header: "Patient Serial Number", key: "patient_serial_no" },
          { header: "Patient Name", key: "patient_name" },
          { header: "Patient Date of Birth", key: "patient_dob" },
          { header: "Patient Gender", key: "patient_gender" },
          { header: "Qualification", key: "qualification" },
          { header: "Specialization Name", key: "specialization_name" },
          { header: "Doctor Gender", key: "doctor_gender" },
          { header: "Designations Name", key: "designations_name" },
          { header: "Organizations Name", key: "organizations_name" },
          {
            header: "Organization Serial Number",
            key: "organization_serial_no",
          },
          { header: "Consultation Name", key: "consultation_name" },
          { header: "Patient Blood Group", key: "patient_blood_group" },
          { header: "Appointment Status Name", key: "appointment_status_name" },
          { header: "Is Previous", key: "isPrev" },
        ];
        worksheet.columns = columnsToIncludeAppointments.map((col) => ({
          header: col.header,
          key: col.key,
          width: 15,
        }));

        // Adding data rows
        dataSource.forEach((row, rowIndex) => {
          const rowData = {};
          columnsToIncludeAppointments.forEach((col) => {
            rowData[col.key] = row[col.key];
          });
          worksheet.addRow(rowData);
        });

        worksheet.getRow(1).font = { bold: true };
        worksheet.eachRow({ includeEmpty: false }, (row) => {
          row.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        });

        workbook.xlsx.writeBuffer().then((buffer) => {
          const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "appointmentList.xlsx";
          a.click();
          window.URL.revokeObjectURL(url);
        });
      } else if (type === "appointmentQuestions") {
        const columnsToIncludeAnswers = [
          { header: "Answers Type", key: "answers_type" },

          { header: "Disease", key: "disease" },
          { header: "Disease ID", key: "disease_id" },

          { header: "Questions", key: "questions" },
        ];
        worksheet.columns = columnsToIncludeAnswers.map((col) => ({
          header: col.header,
          key: col.key,
          width: 15,
        }));

        dataSource.forEach((row, rowIndex) => {
          const rowData = {};
          columnsToIncludeAnswers.forEach((col) => {
            rowData[col.key] = row[col.key];
          });
          worksheet.addRow(rowData);
        });

        worksheet.getRow(1).font = { bold: true };
        worksheet.eachRow({ includeEmpty: false }, (row) => {
          row.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        });

        workbook.xlsx.writeBuffer().then((buffer) => {
          const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "appointmentQuestionList.xlsx";
          a.click();
          window.URL.revokeObjectURL(url);
        });
      } else if (type === "billingPackages") {
        const columnsToIncludePackages = [
          { header: "Discount", key: "discount" },
          { header: "Gross Total", key: "gross_total" },

          { header: "Organization ID", key: "organization_id" },
          { header: "Package Name", key: "package_name" },
          { header: "Status", key: "status" },

          { header: "Total Amount", key: "total_amount" },
        ];
        worksheet.columns = columnsToIncludePackages.map((col) => ({
          header: col.header,
          key: col.key,
          width: 15,
        }));

        dataSource.forEach((row, rowIndex) => {
          const rowData = {};
          columnsToIncludePackages.forEach((col) => {
            rowData[col.key] = row[col.key];
          });
          worksheet.addRow(rowData);
        });

        worksheet.getRow(1).font = { bold: true };
        worksheet.eachRow({ includeEmpty: false }, (row) => {
          row.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        });

        workbook.xlsx.writeBuffer().then((buffer) => {
          const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "billingPackages.xlsx";
          a.click();
          window.URL.revokeObjectURL(url);
        });
      } else if (type === "PackageItems") {
        const columnsToIncludePackages = [
          { header: "quantity", key: "quantity" },

          { header: "Package Name", key: "package_name" },

          { header: "Total Amount", key: "amount" },
        ];
        worksheet.columns = columnsToIncludePackages.map((col) => ({
          header: col.header,
          key: col.key,
          width: 15,
        }));

        dataSource.forEach((row, rowIndex) => {
          const rowData = {};
          columnsToIncludePackages.forEach((col) => {
            rowData[col.key] = row[col.key];
          });
          worksheet.addRow(rowData);
        });

        worksheet.getRow(1).font = { bold: true };
        worksheet.eachRow({ includeEmpty: false }, (row) => {
          row.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        });

        workbook.xlsx.writeBuffer().then((buffer) => {
          const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "PackageItems.xlsx";
          a.click();
          window.URL.revokeObjectURL(url);
        });
      } else {
        const csvContent = generateCsvContent(dataSource, columns);
        exportToCsv(csvContent, "dockmotez.csv");
      }
    }
  };

  const exportToCsv = (csvData, fileName) => {
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const handlePrint = () => {
    // dispatch(getOrgNameInProgress(orgId));

    const tableData = dataSource.map((row, rowIndex) =>
      columns.map((col, colIndex) => {
        if (col.title === "S.No") {
          return rowIndex + 1;
        } else if (col.title === "Room Type") {
          return row.room_type === "1" ? "AC" : "NON-AC";
        } else if (col.title === "Room Status") {
          return row.room_status === "1" ? "Active" : "Inactive";
        } else if (col.title === "Room Price") {
          return row.room_type === "1"
            ? row.ac_room_price
            : row.non_ac_room_price;
        } else if (
          col.title === "Block Name" &&
          documentTitle === "Total Room List"
        ) {
          return row?.Block?.block_name;
        } else if (
          col.title === "Ward Name" &&
          documentTitle === "Total Room List"
        ) {
          return row?.Ward?.ward_name;
        } else if (col.title === "Answer") {
          console.log(" row.answers", row.answers);

          if (Array.isArray(row.answers)) {
            return (
              <div>
                {row.answers.map((ans, i) => (
                  <Typography key={i} className="text-wrap">
                    {`${i + 1}. ${ans?.answer}`}
                  </Typography>
                ))}
              </div>
            );
          } else {
            return "No Data";
          }
        } else if (col.title === "Answer Method") {
          const answerMethod = answerType.find(
            (val) => val.value === row?.answers_type
          );
          return answerMethod ? answerMethod.label : "Unknown";
        } else if (col.title === "Period") {
          return row?.Subscription?.periods;
        } else if (col.title === "Hospital Name") {
          return row?.Organization?.name;
        } else if (
          col.title === "Package Name" &&
          documentTitle === "Organization List"
        ) {
          if (row?.Subscription === null) {
            return "";
          } else if (row?.Subscription?.name) return row?.Subscription?.name;
        } else if (
          col.title === "Supplier Company Name" &&
          documentTitle === "Purchase Request List"
        ) {
          return row?.Supplier.company_name;
        } else if (
          col.title === "Contact Person" &&
          documentTitle === "Purchase Request List"
        ) {
          return row?.Supplier.contact_person;
        } else if (col.title === "Medicine" && documentTitle === "Sales List") {
          if (Array.isArray(row.sales_details)) {
            return (
              <div>
                {row.sales_details.map((ans, i) => (
                  <Typography key={i} className="text-wrap">
                    {`${i + 1}. ${ans?.product_name}`}
                  </Typography>
                ))}
              </div>
            );
          } else {
            return "No Data";
          }
        } else if (
          col.title === "Patient Type" &&
          documentTitle === "Sales List"
        ) {
          return salesTypeList.find(
            (data) => Number(data.value) === Number(row?.patient_type)
          )?.label;
        } else if (
          col.title === "Packages" &&
          documentTitle === "Billing Packages List"
        ) {
          if (Array.isArray(row.subPackages)) {
            const answersList = row.subPackages.map(
              (data) => data?.package_name
            );
            console.log("answersList", answersList);
            return answersList;
          } else {
            return "No Data";
          }
        } else if (
          col.title === "Sales Id" &&
          documentTitle === "Sales  Return List"
        ) {
          return row?.Sale?.sales_code;
        } else if (
          col.title === "Return Medicines" &&
          documentTitle === "Sales  Return List"
        ) {
          return row?.sales_return_details?.length;
        } else if (
          col.title === "DOB" ||
          col.title === "Expiry Date" ||
          col.title === "Registered At" ||
          col.title === "Date" ||
          col.title === "Purchase Date" ||
          col.title === "Order Date" ||
          col?.title === "Invoice Date"
        ) {
          console.log("row?.dob", row?.dob);
          return moment(
            row?.dob ||
              row?.start_date ||
              row?.expiry_date ||
              row?.date ||
              row?.createdAt ||
              row?.invoice_date
          ).format("DD-MM-YYYY");
        } else if (
          col.title === "Address" &&
          documentTitle === "Organization List"
        ) {
          return (
            (row?.address ? row?.address : "") +
            (row?.city ? ", " + row?.city : "") +
            (row?.state ? ", " + row?.state : "") +
            (row?.country ? ", " + row?.country : "")
          );
        } else if (
          col.title === "Address" &&
          documentTitle === "Patient Due List"
        ) {
          return (
            (row?.address1 ? row?.address1 : "") +
            (row?.address2 ? ", " + row?.address2 : "") +
            (row?.city ? ", " + row?.city : "") +
            (row?.state ? ", " + row?.state : "") +
            (row?.country_code ? ", " + row?.country_code : "")
          );
        } else {
          return row[col.dataIndex];
        }
      })
    );
    const exportableColumns = columns.filter((col) => col.title !== "Action");
    const tableColumnHeaders = exportableColumns.map((col) => col.title);
    const selectedColumn = columns
      .filter(
        (column) => column.title !== "Options" && column.title !== "Action"
      )
      .map((column) => ({
        title: tableColumnHeaders,
        dataIndex: tableData,
        key: column.key,
      }));

    const newWindow = window.open("", "_blank");
    if (newWindow) {
      newWindow.document.write("<html><head><title>Print</title>");
      newWindow.document.write(
        "<link rel='stylesheet'  href='../../styles/modules/doctor.less'>"
      );
      newWindow.document.write("</head><body>");
      newWindow.document.write("<div id='print-content'>");
      ReactDOM.render(
        <PrintTable
          dataSource={tableData}
          columns={tableColumnHeaders}
          documentTitle={documentTitle}
        />,
        newWindow.document.getElementById("print-content")
      );

      newWindow.document.write("</div>");
      newWindow.document.write("</body></html>");
      newWindow.document.close();
      newWindow.onload = () => {
        newWindow.print();
      };
    } else {
      console.error("Failed to open new window for printing.");
    }
  };
  const downloadPDF = () => {
    const doc = new jsPDF();
    const title = documentTitle;
    console.log("dataSource", dataSource);
    // doc.text("My Table Title", 14, 22);
    // Extract the data from Ant Design table
    const tableData = dataSource.map((row, rowIndex) =>
      columns.map((col, colIndex) => {
        if (col.title === "S.No") {
          return rowIndex + 1;
        } else if (col.title === "Room Type") {
          return row.room_type === "1" ? "AC" : "NON-AC";
        } else if (col.title === "Room Status") {
          return row.room_status === "1" ? "Active" : "Inactive";
        } else if (col.title === "Room Price") {
          return row.room_type === "1"
            ? row.ac_room_price
            : row.non_ac_room_price;
        } else if (
          col.title === "Block Name" &&
          documentTitle === "Total Room List"
        ) {
          return row?.Block?.block_name;
        } else if (
          col.title === "Ward Name" &&
          documentTitle === "Total Room List"
        ) {
          return row?.Ward?.ward_name;
        } else if (col.title === "Block Status") {
          return row.block_status === "1" ? "Active" : "Inactive";
        } else if (col.title === "Answer") {
          if (Array.isArray(row.answers)) {
            // Create a list of answers from row.answers
            const answersList = row.answers.map((data) => data?.answer);
            console.log("answersList", answersList);
            return answersList;
          } else {
            return "No Data";
          }
        } else if (col.title === "Answer Method") {
          const answerMethod = answerType.find(
            (val) => val.value === row?.answers_type
          );
          return answerMethod ? answerMethod.label : "Unknown";
        } else if (col.title === "Period") {
          return row?.Subscription?.periods;
        } else if (col.title === "Hospital Name") {
          return row?.Organization?.name;
        } else if (
          col.title === "Package Name" &&
          documentTitle === "Organization List"
        ) {
          return row?.Subscription?.name;
        } else if (
          col.title === "Supplier Company Name" &&
          documentTitle === "Purchase Request List"
        ) {
          return row?.Supplier.company_name;
        } else if (
          col.title === "Contact Person" &&
          documentTitle === "Purchase Request List"
        ) {
          return row?.Supplier.contact_person;
        } else if (col.title === "Medicine" && documentTitle === "Sales List") {
          if (Array.isArray(row.sales_details)) {
            const answersList = row.sales_details.map(
              (data) => data?.product_name
            );
            console.log("answersList", answersList);
            return answersList;
          } else {
            return "No Data";
          }
        } else if (
          col.title === "Patient Type" &&
          documentTitle === "Sales List"
        ) {
          return salesTypeList.find(
            (data) => Number(data.value) === Number(row?.patient_type)
          )?.label;
        } else if (
          col.title === "Packages" &&
          documentTitle === "Billing Packages List"
        ) {
          console.log("row", row);
          if (Array.isArray(row.subPackages)) {
            const answersList = row.subPackages.map(
              (data) => data?.package_name
            );
            console.log("answersList", answersList);
            return answersList;
          } else {
            return "No Data";
          }
        } else if (
          col.title === "Sales Id" &&
          documentTitle === "Sales  Return List"
        ) {
          return row?.Sale?.sales_code;
        } else if (
          col.title === "Return Medicines" &&
          documentTitle === "Sales  Return List"
        ) {
          return row?.sales_return_details?.length;
        } else if (
          col.title === "DOB" ||
          col.title === "Expiry Date" ||
          col.title === "Registered At" ||
          col.title === "Date" ||
          col.title === "Purchase Date" ||
          col.title === "Order Date" ||
          col?.title === "Invoice Date"
        ) {
          console.log("row?.dob", row?.createdAt);
          return moment(
            row?.dob ||
              row?.start_date ||
              row?.expiry_date?.row?.date ||
              row?.createdAt ||
              row?.invoice_date
          ).format("DD-MM-YYYY");
        } else if (
          col.title === "Address" &&
          documentTitle === "Patient Due List"
        ) {
          return (
            (row?.address1 ? row?.address1 : "") +
            (row?.address2 ? ", " + row?.address2 : "") +
            (row?.city ? ", " + row?.city : "") +
            (row?.state ? ", " + row?.state : "") +
            (row?.country_code ? ", " + row?.country_code : "")
          );
        } else if (
          col.title === "Address" &&
          documentTitle === "Organization List"
        ) {
          return (
            (row?.address ? row?.address : "") +
            (row?.city ? ", " + row?.city : "") +
            (row?.state ? ", " + row?.state : "") +
            (row?.country ? ", " + row?.country : "")
          );
        } else {
          return row[col.dataIndex];
        }
      })
    );
    const exportableColumns = columns.filter((col) => col.title !== "Action");
    const tableColumnHeaders = exportableColumns.map((col) => col.title);

    doc.text(title, 10, 10);
    doc.autoTable({
      head: [tableColumnHeaders],
      body: tableData,
    });

    doc.save(`${documentTitle}.pdf`);
  };
  const handleColumnToggle = (columnTitle) => {
    setVisibleColumns((prevColumns) => {
      const isVisible = prevColumns.some((col) => col.title === columnTitle);
      if (isVisible) {
        return prevColumns.filter((col) => col.title !== columnTitle);
      } else {
        return [
          ...prevColumns,
          columns.find((col) => col.title === columnTitle),
        ];
      }
    });
  };

  const menu = (
    <Menu>
      {columns.map((column) => (
        <Menu.Item key={column.title}>
          <Checkbox
            checked={visibleColumns.some((col) => col.title === column.title)}
            onChange={() => handleColumnToggle(column.title)}
          >
            {column.title}
          </Checkbox>
        </Menu.Item>
      ))}
    </Menu>
  );

  const menus = (
    <Menu>
      <Menu.Item>
        <ButtonComponent
          type="text"
          className="button-tools m-5"
          onClick={exportCSV}
          size="small"
          label={<IntlMessages id={"Addnew.patient.csv"} />}
        />
      </Menu.Item>
      <Menu.Item>
        <ButtonComponent
          type="text"
          className="button-tools m-5"
          onClick={handleClick}
          size="small"
          label={<IntlMessages id={"Addnew.patient.pdf"} />}
        />
      </Menu.Item>
      <Menu.Item>
        <ButtonComponent
          type="text"
          className="button-tools m-5"
          onClick={handlePrint}
          size="small"
          label={<IntlMessages id={"Addnew.patient.print"} />}
        />
      </Menu.Item>
    </Menu>
  );
  const defaultTitle = () => {
    return (
      <>
        {title ? title : null}
        <Row>
          <Col xs={12} style={{ alignContent: "end" }}>
            <Dropdown overlay={menu} trigger={["click"]}>
              <Button type="link" className="mb-0 pl-0">
                <MenuUnfoldOutlined /> Show/Hide Columns
              </Button>
            </Dropdown>
            <Dropdown overlay={menus} trigger={["click"]}>
              <Button type="link" className="mb-0 pl-0">
                <DownloadOutlined /> Export
              </Button>
            </Dropdown>
          </Col>
          <Col xs={12} style={{ alignContent: "end", justifyItems: "end" }}>
            <SearchBox
              styleName="gx-lt-icon-search-bar search-btn"
              placeholder="Search in app..."
              itemList={itemList}
              setFilteredUsers={setFilteredUsers}
              field={field}
              dataSource={
                visibleColumns.length === 0 ? visibleColumns : dataSource
              }
            />
          </Col>
        </Row>
      </>
    );
  };
  const tableProps = {
    // bordered,
    title: !disableTableHeaderButtons ? defaultTitle : undefined,
    // loading,
    // size: "small",
    // expandable,
    // title: showTitle ? defaultTitle : undefined,
    // showHeader,
    // footer: showfooter ? defaultFooter : undefined,
    // rowSelection,
    // scroll,
    // tableLayout,
  };
  return (
    <Fragment>
      <Table
        {...tableProps}
        columns={visibleColumns}
        dataSource={visibleColumns?.length === 0 ? visibleColumns : dataSource}
        pagination={pagination}
        bordered={false}
        size={size}
        scroll={{ x: true }} // Adjust based on your content
        rowClassName="card-row"
        onChange={onChange}
        ref={tableRef}
        onHeaderRow={() => {
          return {
            className: "card-header-row",
          };
        }}
      />
    </Fragment>
  );
};

export default TableComponent;
