import React from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import AddPurchase from "./AddPurchase";

const ViewPurchase = () => {
  const location = useLocation();
  const viewPurchaseData = location.state?.purchaseData;
  return <AddPurchase viewData="true" viewPurchaseData={viewPurchaseData} />;
};

export default ViewPurchase;
