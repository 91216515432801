import {
  GET_DOCTOR_DASHBOARD_ERROR,
  GET_DOCTOR_DASHBOARD_PROGRESS,
  GET_DOCTOR_DASHBOARD_SUCCESS,
} from "../../../constants/ActionTypes";

// Get DashBoard
export const getDoctorDashBoardProgress = (payload) => ({
  type: GET_DOCTOR_DASHBOARD_PROGRESS,
  payload: payload,
});

export const getDoctorDashBoardInSuccess = (payload) => ({
  type: GET_DOCTOR_DASHBOARD_SUCCESS,
  payload: payload,
});

export const getDoctorDashBoardInError = (payload) => ({
  type: GET_DOCTOR_DASHBOARD_ERROR,
  payload: payload,
});
