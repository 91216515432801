import { DeleteFilled, EditFilled } from "@ant-design/icons";
import { Col, message, Popconfirm, Row } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";

import ButtonComponent from "../../../components/Button/ButtonComponent";
import TableComponent from "../../../components/Table/TableComponent";
import "../../../styles/modules/reference.less";
import { APP_ROUTES } from "../../../constants/Routes";
import { datas } from "../data";
import Auxiliary from "../../../util/Auxiliary";

const RefDoctorTable = () => {
  const [dummy, setDummy] = useState(datas);

  const Delete = () => {
    dummy &&
      dummy.map((data) => {
        console.log("DTTT", data);
      });
    message.success("Deleted Successfully");
  };
  const Edit = () => {
    console.log("Edit Data", datas);
  };
  const cancel = () => {
    message.error("Cancelled");
  };
  const columns = [
    {
      title: "S.No",
      dataIndex: "S.No",
    },
    {
      title: "Doctor",
      dataIndex: "Doctor",
    },
    {
      title: "Description",
      dataIndex: "Description",
    },
    {
      title: "Actions",
      dataIndex: "Actions",
      render: () => {
        return (
          <Row className="btn-edit">
            <Col>
              <Link to={APP_ROUTES.REFERENCEDOCTOREDIT}>
                <ButtonComponent
                  icon={<EditFilled />}
                  type="primary"
                  onClick={Edit}
                />
              </Link>
            </Col>
            <Col span={2}>
              <Popconfirm
                title="Are you sure to delete?"
                onConfirm={Delete}
                onCancel={cancel}
                okText="Yes"
                cancelText="No"
              >
                <ButtonComponent icon={<DeleteFilled />} type="primary" />
              </Popconfirm>
            </Col>
          </Row>
        );
      },
    },
  ];

  return (
    <Auxiliary>
      <TableComponent columns={columns} dataSource={datas} />
    </Auxiliary>
  );
};

export default RefDoctorTable;
