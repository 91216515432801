import { Col, message, Modal, Row, Spin, Tooltip, Typography } from "antd";
import React, { useEffect, useState } from "react";

import ButtonComponent from "../../../components/Button/ButtonComponent";
import TableComponent from "../../../components/Table/TableComponent";
import { EditFilled, DeleteOutlined, LoadingOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "../../../styles/modules/doctor.less";
import {
  deleteDoctorListInProgress,
  getDoctorListInProgress,
} from "../../../appRedux/admin/Doctor/actions/Doctors";
import Auxiliary from "../../../util/Auxiliary";
import moment from "moment";
import { access } from "../../../util/utilz";
const DoctorTable = () => {
  const [loading, setLoading] = useState(false);
  const [deleteId, setId] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const getlist = useSelector((state) => state.doctor.getlist);
  const roleId = localStorage.getItem("role_id");

  const doctorTotalRecords = getlist.data?.totalRecords;

  const deletelist = useSelector((state) => state.doctor.deletelist);
  const showModal = (id) => {
    setIsModalOpen(true);
    setId(id);
  };
  const handleOk = () => {
    dispatch(deleteDoctor(deleteId));
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const columns = [
    { title: "Id", dataIndex: "doctor_serial_no" },
    {
      title: "Name",
      dataIndex: "firstName",
      sortDirections: ["ascend", "descend"],
      key: "firstName",
      render: (_, record) => `${record.firstName} ${record.lastName}`,
      sorter: (a, b) => a.firstName.localeCompare(b.firstName),
    },

    {
      title: "DOB",
      dataIndex: "dob",
      render: (date) => {
        return moment(date).format("DD-MM-YYYY");
      },
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Contact",
      dataIndex: "contact",
    },
    {
      title: "Address ",
      dataIndex: "address1",
      render: (_, record) =>
        `${record.address1}, ${record.address2},${record.city},${record.state},${record.country_code}`,
    },

    {
      title: "Qualification",
      dataIndex: "qualification",
    },

    {
      title: "Action",
      dataIndex: "Action",
      render: (_, record) => {
        return (
          <>
            {Number(roleId) === 1 ? (
              <Row>
                {access("doctors", "edit") && (
                  <Col className="btn-edit">
                    <Tooltip placement="top" title={"Edit"}>
                      <Link to={`/doctor/edit/${record.id}`}>
                        <ButtonComponent
                          icon={<EditFilled />}
                          type="primary"
                          size="small"
                        />
                      </Link>
                    </Tooltip>
                  </Col>
                )}
                {access("doctors", "delete") && (
                  <Col className="btn-edit">
                    <Tooltip placement="top" title={"Delete"}>
                      <Typography>
                        <ButtonComponent
                          type="danger"
                          icon={<DeleteOutlined />}
                          onClick={() => showModal(record.id)}
                          size="small"
                        />
                      </Typography>
                    </Tooltip>
                  </Col>
                )}{" "}
              </Row>
            ) : (
              // {/* <Row>
              //   <Col className="btn-edit">
              //     <Link
              //       to={{
              //         pathname: "/doctor/history",
              //         state: record,
              //       }}
              //     >
              //       <ButtonComponent icon={<EyeOutlined />} type="primary" />
              //     </Link>
              //   </Col>
              // </Row> */}
              ""
            )}
          </>
        );
      },
    },
  ];
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );
  const dispatch = useDispatch();
  useEffect(() => {
    handlePage(1);
  }, [deletelist]);

  const doctorsLists = getlist?.data?.data;

  const deleteDoctor = (id) => {
    const handleSuccess = () => {
      setIsModalOpen(false);
    };
    let data =
      doctorsLists && doctorsLists.filter((filterData) => filterData.id === id);
    const payload = {
      id: data[0].id,
      handleSuccess,
    };
    dispatch(deleteDoctorListInProgress(payload));
  };
  useEffect(() => {
    if (getlist?.progressing) {
      setLoading(true);
    } else if (getlist?.success) {
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [getlist?.progressing, getlist?.success]);
  const handlePage = (page) => {
    dispatch(getDoctorListInProgress(page));
  };
  // const [filteredDataDoctor, setFilteredDataDoctor] = useState(doctorsLists);

  // const updateFilteredData = (filteredDataDoctor) => {
  //   setFilteredDataDoctor(filteredDataDoctor);
  // }
  const [filteredUsers, setFilteredUsers] = useState("");

  const handleDoctorSelectChange = (value) => {
    if (value === "all") {
      setFilteredUsers(doctorsLists);
    } else {
      const newData = doctorsLists.slice(0, parseInt(value, 10));
      setFilteredUsers(newData);
    }
  };

  return (
    <Spin indicator={antIcon} spinning={loading}>
      <Auxiliary>
        {access("doctors", "view") && (
          <TableComponent
            columns={columns}
            dataSource={filteredUsers ? filteredUsers : doctorsLists}
            pagination={{
              total: doctorTotalRecords,
              onChange: (page) => handlePage(page),
            }}
            type={`doctor_list`}
            // doctorapi={doctorsLists}
            // doctorFilter={updateFilteredData}
            doctorRowselectors={handleDoctorSelectChange}
            itemList={doctorsLists}
            filteredUsers={filteredUsers}
            setFilteredUsers={setFilteredUsers}
            field={"firstName"}
            documentTitle={"Doctor"}
          />
        )}
      </Auxiliary>{" "}
      <Modal
        title="Delete Data"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelButtonProps={{ className: "secondary-color" }}
      >
        <p>Are you sure, you want to delete the Data</p>
      </Modal>
    </Spin>
  );
};

export default DoctorTable;
