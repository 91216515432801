import React from "react";

import Widget from "../../components/Widget";
import { pharmacyList } from "../../screens/Doctor/data";
import { Col, Row, Typography } from "antd";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { APP_ROUTES } from "../../constants/Routes";
import TicketItem from "../../components/card/TicketItem";
const DoctorList = ({ recentAppointments }) => {
  return (
    <Widget
      title={
        <Typography className="h4 gx-text-capitalize gx-ml-2">
          Doctor List
        </Typography>
      }
      styleName="gx-card-ticketlist"
      extra={
        <Link to={APP_ROUTES.APPOINMENT}>
          <Typography className="gx-text-primary gx-mb-0 gx-pointer gx-d-none gx-d-sm-block">
            All Appoinments
            <i className="icon icon-long-arrow-right gx-fs-xxl gx-ml-2 gx-d-inline-flex gx-vertical-align-middle" />
          </Typography>
        </Link>
      }
    >
      <Row>
        <Col xl={24} lg={24} md={24} xs={24} sm={24}>
          {recentAppointments &&
            recentAppointments?.map((ticket, index) => (
              <TicketItem index={index} data={ticket} />
            ))}
        </Col>
      </Row>
    </Widget>
  );
};

export default DoctorList;
