import React from "react";

import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

import AddSalesReturn from "./AddSalesReturn";

const EditSalesReturn = () => {
  const location = useLocation();
  const salesData = location.state?.salesData;

  return <AddSalesReturn salesData={salesData} editSales="true" />;
};

export default EditSalesReturn;
