// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.new-button {
  margin-top: 25px;
}
.select-box {
  margin-top: -15px !important;
}
.text-warp {
  text-wrap: nowrap;
}
.display-class {
  display: flex;
  align-items: end;
  justify-content: flex-end;
}
.prescriptions {
  border: none !important;
  margin-bottom: 0 !important;
  color: #7fff00 !important;
}
.display-flex {
  display: flex !important;
  padding-bottom: 0 !important;
  justify-content: space-between;
}
.align-items {
  align-items: center !important;
}
.display-flex1 {
  display: flex !important;
  align-items: baseline !important;
}
.display-inline {
  display: block !important;
  text-wrap: nowrap !important;
}
`, "",{"version":3,"sources":["webpack://./src/screens/Pharmacy/purchase/purchase.less"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;AACA;EACE,4BAA4B;AAC9B;AACA;EACE,iBAAiB;AACnB;AACA;EACE,aAAa;EACb,gBAAgB;EAChB,yBAAyB;AAC3B;AACA;EACE,uBAAuB;EACvB,2BAA2B;EAC3B,yBAAyB;AAC3B;AACA;EACE,wBAAwB;EACxB,4BAA4B;EAC5B,8BAA8B;AAChC;AACA;EACE,8BAA8B;AAChC;AACA;EACE,wBAAwB;EACxB,gCAAgC;AAClC;AACA;EACE,yBAAyB;EACzB,4BAA4B;AAC9B","sourcesContent":[".new-button {\n  margin-top: 25px;\n}\n.select-box {\n  margin-top: -15px !important;\n}\n.text-warp {\n  text-wrap: nowrap;\n}\n.display-class {\n  display: flex;\n  align-items: end;\n  justify-content: flex-end;\n}\n.prescriptions {\n  border: none !important;\n  margin-bottom: 0 !important;\n  color: #7fff00 !important;\n}\n.display-flex {\n  display: flex !important;\n  padding-bottom: 0 !important;\n  justify-content: space-between;\n}\n.align-items {\n  align-items: center !important;\n}\n.display-flex1 {\n  display: flex !important;\n  align-items: baseline !important;\n}\n.display-inline {\n  display: block !important;\n  text-wrap: nowrap !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
