// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-symptoms {
  padding: 5%;
}
.add-tablet-head {
  display: flex;
  justify-content: flex-start;
}
.btn-back-cancle {
  display: flex;
  justify-content: flex-end;
}
`, "",{"version":3,"sources":["webpack://./src/styles/modules/telemedicine.less"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;AACA;EACE,aAAa;EACb,2BAA2B;AAC7B;AACA;EACE,aAAa;EACb,yBAAyB;AAC3B","sourcesContent":[".form-symptoms {\n  padding: 5%;\n}\n.add-tablet-head {\n  display: flex;\n  justify-content: flex-start;\n}\n.btn-back-cancle {\n  display: flex;\n  justify-content: flex-end;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
