import { message } from "antd";
import { call, put, takeLatest } from "redux-saga/effects";
import Message from "../../../components/Message/Message";
import {
  UPDATE_DESIGNATION_IN_PROGRESS,
  ADD_DESIGNATION_IN_PROGRESS,
  DELETE_DESIGNATION_IN_PROGRESS,
  GETDATA_DESIGNATION_IN_PROGRESS,
  GET_DESIGNATION_IN_PROGRESS,
} from "../../../constants/ActionTypes";
import { getErrorMessageFromAPI } from "../../../util/utilz";

import {
  addDesignationError,
  addDesignationSuccess,
  deleteDesignationError,
  deleteDesignationSuccess,
  getDataDesignationError,
  getDataDesignationSuccess,
  getDesignationError,
  getDesignationSuccess,
  updateDesignationError,
  updateDesignationSuccess,
} from "../actions/Designation";
import {
  AddDesignationEffect,
  DeleteDesignationEffect,
  DesignationEffect,
  GetDataDesignationEffect,
  UpdateDesignationEffect,
} from "./effects/designationEffect";

function* DesignationWatcher({ payload }) {
  try {
    let { data } = yield call(DesignationEffect, payload);

    yield put(
      getDesignationSuccess({
        data: data,
        message: data["message"],
        authSuccess: true,
      })
    );
  } catch (e) {
    yield put(
      getDesignationError({
        authError: true,
        message: e["message"],
      })
    );
  }
}
function* AddDesignationWatcher({ payload }) {
  try {
    const token = localStorage.getItem("user_id");
    let { data } = yield call(AddDesignationEffect, payload, token);

    yield put(
      addDesignationSuccess({
        data: data,
        message: data["message"],
        authSuccess: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield put(
      addDesignationError({
        authError: true,
        message: getErrorMessageFromAPI(e),
      })
    );
    message.error( getErrorMessageFromAPI(e))
  }
}

function* DeleteDesignationWatcher({ payload }) {
  try {
    const token = localStorage.getItem("user_id");
    let { data } = yield call(DeleteDesignationEffect, payload, token);

    yield put(
      deleteDesignationSuccess({
        data: data,
        message: data["message"],
        authSuccess: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield put(
      deleteDesignationError({
        authError: true,
        message: e["message"],
      })
    );
    yield call(Message, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
  }
}

function* GetDataDesignationWatcher({ payload }) {
  try {
    let { data } = yield call(GetDataDesignationEffect, payload);

    yield put(
      getDataDesignationSuccess({
        data: data,
        message: data["message"],
        authSuccess: true,
      })
    );
  } catch (e) {
    yield put(
      getDataDesignationError({
        authError: true,
        message: e["message"],
      })
    );
  }
}

function* UpdateDesignationWatcher({ payload }) {
  try {
    const token = localStorage.getItem("user_id");
    let { data } = yield call(UpdateDesignationEffect, payload, token);
    yield put(
      updateDesignationSuccess({
        data: data,
        message: data["message"],
        authSuccess: true,
      })
    );
    yield call(Message, { type: "success", content: data.message });
  } catch (e) {
    yield put(
      updateDesignationError({
        authError: true,
        message: getErrorMessageFromAPI(e),
      })
    );
    message.error( getErrorMessageFromAPI(e))
  }
}
export default function* DesignationSaga() {
  yield takeLatest(GET_DESIGNATION_IN_PROGRESS, DesignationWatcher);
  yield takeLatest(ADD_DESIGNATION_IN_PROGRESS, AddDesignationWatcher);
  yield takeLatest(DELETE_DESIGNATION_IN_PROGRESS, DeleteDesignationWatcher);
  yield takeLatest(GETDATA_DESIGNATION_IN_PROGRESS, GetDataDesignationWatcher);
  yield takeLatest(UPDATE_DESIGNATION_IN_PROGRESS, UpdateDesignationWatcher);
}
