import { Col, Divider, Row, Spin, Table, Typography } from "antd";
import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  prescriptionByPatientInProgress,
  PrescriptionGetIdInProgress,
} from "../../../appRedux/doctor/actions/Prescription";

import {
  ArrowLeftOutlined,
  BranchesOutlined,
  DownloadOutlined,
  EnvironmentFilled,
  LoadingOutlined,
  PhoneFilled,
  PrinterFilled,
} from "@ant-design/icons";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import { pharmacyStatus } from "../../Doctor/data";
import { drawDOM, exportPDF } from "@progress/kendo-drawing";
import { saveAs } from "@progress/kendo-file-saver";
import { useLocation } from "react-router-dom";
import { APP_ROUTES } from "../../../constants/Routes";
import { formatDate } from "../../../util/utilz";

export default function PrescriptionData(props) {
  const locations = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const pdfContentRef = React.useRef(null);

  const [printingDone, setPrintingDone] = useState(false);
  const [PDFDone, setPDFDone] = useState(false);
  const details = props.location.state?.record || locations?.state?.record;
  const PrescriptionData = useSelector((state) => state?.prescription);
  const prescriptionDetails =
    PrescriptionData?.prescriptionGetByPatient?.data?.data;
  const prescriptionState = PrescriptionData?.prescriptionGetByPatient;

  const { prescriptionGetByPatient, prescriptionGetById } = useSelector(
    (state) => state?.prescription
  );

  const role = localStorage.getItem("role_id");

  const columns = [
    {
      title: "Tablet Name",
      dataIndex: "medicine_name",
    },
    {
      title: "Medicine Type",
      dataIndex: "medicine_type",
    },
    {
      title: "Type",
      dataIndex: "type",
      render: (data) => (data === "BF" ? "BEFORE FOOD" : "AFTER FOOD"),
    },
    {
      title: "Dosage",
      dataIndex: "dosage",
    },
    {
      title: "Morning",
      dataIndex: "morning",
      render: (data) => (data === 1 ? "YES" : "NO"),
    },
    {
      title: "Lunch",
      dataIndex: "lunch",
      render: (data) => (data === 1 ? "YES" : "NO"),
    },
    {
      title: "Evening",
      dataIndex: "evening",
      render: (data) => (data === 1 ? "YES" : "NO"),
    },
    {
      title: "Night",
      dataIndex: "night",
      render: (data) => (data === 1 ? "YES" : "NO"),
    },
    {
      title: "NO OF DAY",
      dataIndex: "days",
      // render: () => 5,
    },
  ];

  useEffect(() => {
    if (details?.prescription_id) {
      dispatch(PrescriptionGetIdInProgress(details?.prescription_id));
    } else {
      dispatch(
        prescriptionByPatientInProgress(
          details.appointment_id ? details.appointment_id : details.id
        )
      );
    }
  }, [details]);

  useEffect(() => {
    if (prescriptionDetails && prescriptionDetails.length === 0)
      history.push({
        pathname: `/prescription/add/${details.id}/${details.patient_id}`,
      });
  }, [prescriptionDetails]);

  const handlePrint = () => {
    setPrintingDone(true);
  };
  useEffect(() => {
    if (printingDone) {
      window.print();
    }
    setPrintingDone(false);
  }, [printingDone]);
  const goBackHandler = () => {
    history.goBack();
  };
  useEffect(() => {
    const pdf = async () => {
      const content = pdfContentRef.current;

      const options = {
        paperSize: "A4",
        scale: 1,
        margin: { left: "1cm", right: "1cm", top: "1cm" }, // Adjust margins as needed
        landscape: true,
        // Set a fixed DPI for screen resolution, e.g., 96 DPI
        pdf: {
          autoTable: { useCss: true },
          avoidLinks: true,
          format: "A4",
          scale: 1,
        },
      };

      const group = await drawDOM(content, options);
      exportPDF(group).then((data) => {
        saveAs(data, "download.pdf");
      });
    };
    if (PDFDone) {
      pdf();
    }
    setPDFDone(false);
  }, [PDFDone]);

  const handleDownloadPDF = () => {
    setPDFDone(true);
  };
  const handleReadyPrescription = () => {
    const record = {
      doctor_name: prescriptionGetByPatient?.data?.data?.[0]?.doctor_name,
      patient_name: prescriptionGetByPatient?.data?.data?.[0]?.patient_name,
      patient_mobile:
        prescriptionGetByPatient?.data?.data?.[0]?.patient_contact,
      doctor_id: prescriptionGetByPatient?.data?.data?.[0]?.doctor_id,
      patient_id: prescriptionGetByPatient?.data?.data?.[0]?.patient_id,
      patient_type: 1,
      sales_details:
        prescriptionGetByPatient?.data?.data?.[0]?.PrescriptionDetails?.map(
          (data) => {
            const time =
              Number(data?.lunch) +
              Number(data?.evening) +
              Number(data?.morning) +
              Number(data?.night);
            const day = data?.days ? Number(data?.days) : 1;
            const quantity = time * day;

            return {
              product_name: data?.medicine_name,
              tablet_id: data?.medicine_id,
              quantity: quantity,
            };
          }
        ),
      prescriptionDetails:
        prescriptionGetByPatient?.data?.data?.[0]?.PrescriptionDetails,
    };

    history.push({
      pathname: `${APP_ROUTES.ADDSALES}`,
      state: { record },
    });
  };
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );
  const prescriptionData =
    prescriptionGetById?.data?.data ||
    prescriptionGetByPatient?.data?.data?.[0];
  const PrescriptionDetails =
    prescriptionGetByPatient?.data?.data?.flatMap(
      (prescription) => prescription?.PrescriptionDetails
    ) || [];

  console.log("prescriptionData", prescriptionData);
  return (
    <>
      <div>
        <Spin
          spinning={
            prescriptionState?.progressing || prescriptionGetById?.progressing
          }
          indicator={antIcon}
        >
          <Row>
            <Col xl={18} lg={18} md={14} sm={14} xs={24}></Col>
            <Col xl={6} lg={6} md={10} sm={10} xs={24}>
              {!printingDone && (
                <Row
                  // style={{ marginLeft: 0, marginRight: 0 }}
                  justify="end"
                  className="view-grid"
                >
                  <ButtonComponent
                    type="primary"
                    icon={<DownloadOutlined />}
                    onClick={handleDownloadPDF}
                  ></ButtonComponent>
                  <ButtonComponent
                    type="success"
                    icon={<PrinterFilled />}
                    className={"print-button"}
                    onClick={handlePrint}
                  ></ButtonComponent>

                  <ButtonComponent
                    type="danger"
                    icon={<ArrowLeftOutlined />}
                    onClick={goBackHandler}
                  ></ButtonComponent>
                </Row>
              )}
            </Col>
          </Row>
          <div ref={pdfContentRef}>
            <Row>
              <Col xl={24} lg={24} md={24} xs={24} sm={24}>
                <Row
                  style={{
                    marginLeft: 0,
                    marginRight: 0,
                    marginBottom: 20,
                  }}
                  justify="start"
                  gutter={[0, 10]}
                >
                  <Col xs={24} align="left">
                    <Typography align="center" className="view-name">
                      {prescriptionData?.organization_name}
                    </Typography>
                  </Col>
                  <Col xs={24}>
                    <Typography align="center" justify="center">
                      {!PDFDone ? <EnvironmentFilled /> : null}{" "}
                      {prescriptionData?.organization_address},
                      {prescriptionData?.organization_state},
                      {prescriptionData?.organization_country}
                    </Typography>
                  </Col>
                  <Col xs={24}>
                    <Typography align="center">
                      {!PDFDone ? <PhoneFilled /> : null}

                      {prescriptionData?.organization_contact}
                    </Typography>
                  </Col>
                </Row>
              </Col>
              <Col xl={12} lg={12} md={12} xs={24} sm={12}>
                <Row
                  style={{
                    marginLeft: 0,
                    marginRight: 0,
                    marginBottom: 20,
                  }}
                  justify="start"
                  gutter={[0, 10]}
                >
                  <Col xs={24} align="left">
                    <Typography className="view-list-details text-warp py-5">
                      Doctor : {prescriptionData?.doctor_name} [
                      {prescriptionData?.specialization_name}]
                    </Typography>
                    <Typography className="text-warp  py-5">
                      Patient : {prescriptionData?.patient_name}
                    </Typography>
                    <Typography className="text-warp  py-5">
                      Patient Mobile : {prescriptionData?.patient_contact}
                    </Typography>
                  </Col>
                </Row>
              </Col>
              <Col xl={12} lg={12} md={12} xs={24} sm={12}>
                <Row
                  style={{
                    marginLeft: 0,
                    marginRight: 0,
                    marginBottom: 20,
                  }}
                  justify="end"
                  gutter={[0, 10]}
                >
                  <Col xs={24} align="right">
                    <Typography className="text-warp  py-5">
                      Date : {formatDate(prescriptionData?.appointment_date)}
                    </Typography>
                  </Col>
                  <Col xs={24} align="right">
                    <Typography className="text-warp  py-5">
                      Status :{" "}
                      {
                        pharmacyStatus.find(
                          (val) =>
                            val.value === prescriptionData?.pharmacy_status
                        )?.label
                      }
                    </Typography>
                  </Col>
                </Row>
              </Col>
              <Col xs={24}>
                <Divider />
              </Col>
            </Row>
            <div>
              <Table
                columns={columns}
                dataSource={
                  (PrescriptionDetails.length !== 0 && PrescriptionDetails) ||
                  prescriptionData?.PrescriptionDetails ||
                  []
                }
                pagination={false}
              />
            </div>

            <Row>
              <Col xl={12} lg={12} md={12} xs={24} sm={24}>
                <Row
                  style={{
                    marginLeft: 0,
                    marginRight: 0,
                    marginBottom: 20,
                    marginTop: 20,
                  }}
                  justify="start"
                  gutter={[0, 10]}
                >
                  <Col xs={24} align="left">
                    <Typography className=" text-warp pt-10 py-5">
                      Symptom : {prescriptionData?.symptom}{" "}
                    </Typography>
                    <Typography className="text-warp  py-5">
                      Reason : {prescriptionData?.reason}
                    </Typography>
                    <Typography className="text-warp  py-5">
                      Instructions : {prescriptionData?.special_instructions}
                    </Typography>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>

          <Row>
            <Col xl={18} lg={18} md={14} sm={14} xs={24}></Col>
            <Col xl={6} lg={6} md={10} sm={10} xs={24}>
              {Number(role) === 4 && (
                <>
                  {!printingDone && (
                    <Row
                      // style={{ marginLeft: 0, marginRight: 0 }}
                      justify="end"
                      className="view-grid"
                    >
                      <ButtonComponent
                        type="success"
                        icon={<BranchesOutlined />}
                        className={"print-button"}
                        onClick={handleReadyPrescription}
                        label={" Ready prescription"}
                      />
                    </Row>
                  )}
                </>
              )}
            </Col>
          </Row>
        </Spin>
      </div>
    </>
  );
}
