import React, { useEffect, useState } from "react";
import { Card, Row, Col, Form, Typography } from "antd";
import "../../../styles/modules/pharmacy.less";

import { useDispatch, useSelector } from "react-redux";
import { DeleteFilled } from "@ant-design/icons";
import FormInput from "../../../components/Input/FormInput";
import IntlMessages from "../../../util/IntlMessages";
import FormSelectBox from "../../../components/SelectBox/SelectBox";
import ButtonComponent from "../../../components/Button/ButtonComponent";

import { getTabletsIdInProgress } from "../../../appRedux/Pharmacy/PharmacyAction";
import { currentDate } from "../../../util/utilz";
import ProductTable from "../StockAdjustment/ProductTable";

const ItemTransferProductTable = ({
  editItemTransfer,
  editItemTransferData,
  form,
  setTotalQuantity,
  setTotalItems,
}) => {
  const makeRow = (counter) => ({
    key: counter,
    name: counter,
    quantity: counter,
    batch_no: counter,
    expiry_date: counter,
    total_items: counter,
    total_quantity: counter,
    purchase_code: counter,
  });

  const [selectedRowKeyForNewBatch, setSelectedRowKeyForNewBatch] =
    useState(null);

  const [input, setInput] = useState([makeRow(0)]);
  const [showExpandableColumn, setShowExpandableColumn] = useState(false);

  const [key, setKey] = useState();
  const [errorMessages, setErrorMessages] = useState({});
  const [availableStock, setAvailableStock] = useState({});
  const [counter, setCounter] = useState(1);
  const [batchNoList, setBatchNoList] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState({});
  const [quantities, setQuantities] = useState([]);
  const [formData, setFormData] = useState();
  const dispatch = useDispatch();
  const tabletsBatchNo = useSelector((state) => state?.pharmacy?.getTabletsId);
  const tabletListData = tabletsBatchNo?.data?.response?.data || [];
  const values = form.getFieldsValue();
  const handleBatchNoSelect = (value, renderKey) => {
    const selectedbatchNo = batchNoList.find(
      (product) => `${product.batch_no} (${product.available_stock})` === value
    );

    const isBatchNumberSelected = Object.values(selectedProducts).some(
      (selectedValue, key) => selectedValue === value && key !== renderKey
    );
    if (isBatchNumberSelected) {
      form.setFieldsValue({
        [`item_transfer_details[${renderKey}]`]: {
          ...form.getFieldValue()[`item_transfer_details[${renderKey}]`],
          batch_no: {
            value,
            errors: ["Please select a different Batch No"],
          },
        },
      });
    } else {
      setKey(renderKey);

      if (value === "new") {
        setSelectedRowKeyForNewBatch(renderKey);
        setShowExpandableColumn(true);
      } else {
        setSelectedRowKeyForNewBatch(null);
        onChangeTablet(selectedbatchNo, renderKey);
      }
      setSelectedProducts({
        ...selectedProducts,
        [renderKey]: value && value?.replace(/\s*\([^)]*\)/, ""),
      });
    }
    setAvailableStock((prevState) => ({
      ...prevState,
      [renderKey]: selectedbatchNo?.available_stock,
    }));
  };
  const validateFile = (renderKey, value) => {
    const batchNumber = value && value?.replace(/\s*\([^)]*\)/, "");

    const isBatchNumberSelected = Object.values(selectedProducts).some(
      (selectedValue, key) => selectedValue === batchNumber && key !== renderKey
    );

    setErrorMessages((prevErrors) => ({
      ...prevErrors,
      [renderKey]: isBatchNumberSelected,
    }));
    if (isBatchNumberSelected) {
      return Promise.resolve();
    } else {
      return Promise.resolve();
    }
  };
  const validateQuantity = (renderKey, value) => {
    const selectedBatchNo = form.getFieldValue([
      `item_transfer_details`,
      renderKey,
      "batch_no",
    ]);

    const selectedProduct = batchNoList?.find(
      (product) =>
        `${product.batch_no} (${product.available_stock})` === selectedBatchNo
    );

    const availableStockForIndex = availableStock[renderKey];

    const enteredQuantity = parseInt(value);
    if (enteredQuantity > availableStockForIndex) {
      return Promise.reject(
        `Entered quantity exceeds available stock (${availableStockForIndex})`
      );
    } else {
      return Promise.resolve();
    }
  };

  const handleQuantityChange = (value, key) => {
    const updatedQuantities = [...quantities];
    const rowIndex = input.findIndex((row) => row.key === key);

    if (rowIndex !== -1 && !isNaN(value)) {
      updatedQuantities[rowIndex] = { quantity: parseInt(value) };
      setQuantities(updatedQuantities);
    }

    validateQuantity(key, value);
  };

  const onChangeTablet = (data, key) => {
    form.setFieldValue(
      [`item_transfer_details`, key, "tablet_id"],
      data?.tablet_id
    );
    form.setFieldValue(
      [`item_transfer_details`, key, "product_name"],
      data?.tablet_id
    );
    form.setFieldValue(
      [`item_transfer_details`, key, "quantity"],
      data?.quanity || 0
    );

    form.setFieldValue(
      [`item_transfer_details`, key, "expiry_date"],
      data?.expiry_date
    );
    form.setFieldValue(
      [`item_transfer_details`, key, "purchase_code"],
      data?.purchase_code
    );
    const batchNumbers = editItemTransferData?.itemTransferInformations?.map(
      (value) => value.batch_no
    );
    // handleProductSelect(data?.tablet_id, key);
    const updatedSelectedProducts = { ...selectedProducts };
    batchNumbers?.forEach((batchNo, index) => {
      updatedSelectedProducts[index] = batchNo;
    });

    setSelectedProducts(updatedSelectedProducts);
  };

  const addRow = () => {
    setInput([...input, makeRow(counter)]);
    setCounter((prev) => Number(prev) + 1);
    // setTotalItems((prev) => Number(prev) + 1);
  };
  const deleteRow = (key) => {
    const updatedInput = input.filter((row) => row.key !== key);
    setTotalItems((prev) => prev - 1);
    setInput(updatedInput);
    const updatedQuantities = [...quantities];
    updatedQuantities.splice(key, 1);
    setQuantities(updatedQuantities);
    const updatedSelectedProducts = { ...selectedProducts };

    setSelectedProducts({
      ...selectedProducts,
      [key]: null,
    });

    if (formData) {
      const formDataCopy = { ...formData, ...values };
      formDataCopy.item_transfer_details[key] = null;
      setFormData(formDataCopy);
    } else {
      const formDataCopy = { ...values };
      formDataCopy.item_transfer_details[key] = null;
      setFormData(formDataCopy);
    }
  };
  const handleProductSelect = (value, key) => {
    const batchNo = form
      .getFieldValue([`item_transfer_details`, key, "batch_no"])
      ?.replace(/\s*\([^)]*\)/, "");

    const isBatchNumberSelected =
      Object.values(selectedProducts).includes(batchNo);
    if (isBatchNumberSelected) {
      form.setFieldValue([`item_transfer_details`, key, "batch_no"], "0");
    }

    validateFile(key, 0);

    const selectedProduct = tabletListData.find(
      (product) => product.id === value
    );

    setBatchNoList(selectedProduct?.Store_details);

    setKey(key);
  };

  useEffect(() => {
    const sum = quantities.reduce((acc, item) => acc + item?.quantity, 0);

    setTotalQuantity(sum);

    if (quantities[0]?.quantity === 0) {
      setTotalItems(0);
    } else if (quantities?.length > 0) {
      setTotalItems(quantities.length);
    }
  }, [quantities, input]);

  const renderBatchNoOptions = (batchNoList) => {
    return (
      batchNoList?.map((pack, index) => ({
        label: `${pack.batch_no} (${pack.available_stock})`,
        value: `${pack.batch_no} (${pack.available_stock})`,
      })) || []
    );
  };
  const columns = [
    {
      title: "Product Name",
      dataIndex: "product_name",
      render: (_, render) => (
        <Form.Item
          name={[`${render.key}`, "product_name"]}
          rules={[
            {
              required: true,
              message: <IntlMessages id={"select the  tablet"} />,
            },
          ]}
        >
          <FormSelectBox
            options={
              tabletListData?.map((pack, i) => {
                return { label: pack.medicine_name, value: pack.id };
              }) || []
            }
            placeholder="Select Product Name"
            className="sel select-box"
            onChange={(e) => {
              handleProductSelect(e, render.key);
            }}
          />
        </Form.Item>
      ),
    },
    {
      title: "Batch No",
      dataIndex: "batch_no",
      key: "batch_no",
      render: (_, render) => (
        <Row>
          <Col xl={24}>
            <Form.Item
              name={[`${render.key}`, "batch_no"]}
              rules={[
                {
                  required: true,
                  message: "Please select Batch No",
                },
                {
                  validator: (_, value) => validateFile(render.key, value),
                },
              ]}
            >
              <FormSelectBox
                value={selectedProducts[render.key]}
                options={renderBatchNoOptions(batchNoList)}
                placeholder="Select Batch.No"
                className="sel select-box"
                onChange={(value) => handleBatchNoSelect(value, render.key)}
              />
            </Form.Item>
            {errorMessages[render.key] && (
              <Typography.Text type="danger">
                Batch Number already selected
              </Typography.Text>
            )}
            {/* {selectedRowKeyForNewBatch === render.key && (
              <Col xs={24} sm={24}>
                <Space.Compact>
                  <Form.Item shouldUpdate>
                    <FormInput
                      name={[`${render.key}`, "batch_no_new"]}
                      value={batchNoNewValue}
                      onChange={(e) => handleBatchNoNewChange(e.target.value)}
                    />
                  </Form.Item>
                  <ButtonComponent
                    icon={<PlusOutlined />}
                    size="small"
                    type="primary"
                    onClick={() => handleAddClick(render.key)}
                  />
                </Space.Compact>
              </Col>
            )} */}
          </Col>
        </Row>
      ),
    },

    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (_, render) => (
        <Form.Item>
          <FormInput
            min={1}
            name={[`${render.key}`, "quantity"]}
            onChange={(e) => handleQuantityChange(e.target.value, render.key)}
            className="input len"
            type="number"
            rules={[
              {
                required: true,
                message: <IntlMessages id={"Enter the quantity"} />,
              },
              {
                validator: (_, value) => validateQuantity(render.key, value),
              },
            ]}
          />
        </Form.Item>
      ),
    },

    {
      title: "Expiry date",
      dataIndex: "expiry_date",
      key: "expiry_date",
      render: (_, render) => (
        <Form.Item shouldUpdate>
          <FormInput
            name={[`${render.key}`, "expiry_date"]}
            className="input len"
            type="date"
            placeholder="date"
            // min={disablePastDate()}
            defaultValue={currentDate}
            rules={[
              {
                required: false,
                message: <IntlMessages id={"select the expiry date"} />,
              },
            ]}
          />
        </Form.Item>
      ),
    },

    {
      title: "Actions",
      dataIndex: "Actions",
      render: (_, render) => (
        <Form.Item>
          {render.key !== 0 && (
            <ButtonComponent
              size="small"
              icon={<DeleteFilled />}
              className="item-button"
              type="danger"
              onClick={() => deleteRow(render.key)}
            />
          )}
        </Form.Item>
      ),
    },
  ];

  useEffect(() => {
    const fromData = { pagination_required: false, page: 1 };
    dispatch(getTabletsIdInProgress(fromData));
  }, []);

  useEffect(() => {
    if (editItemTransfer) {
      const newInput = editItemTransferData?.itemTransferInformations?.map(
        (value, index) => {
          onChangeTablet(value, index);
          const selectedProduct = tabletListData?.find(
            (product) => product?.medicine_name === value?.Tablet?.medicine_name
          );

          if (selectedProduct) {
            const matchingBatch = selectedProduct?.Store_details.find(
              (batch) => {
                return batch?.purchase_code === value?.purchase_code;
              }
            );

            if (matchingBatch) {
              const batchValue = `${matchingBatch?.batch_no} (${matchingBatch?.available_stock})`;

              setAvailableStock((prevState) => ({
                ...prevState,
                [index]: matchingBatch?.available_stock,
              }));
              // setSelectedProducts(batchValue);
              form.setFieldValue(
                [`item_transfer_details`, index, "batch_no"],
                batchValue
              );
            }
          }
          // setSelectedProducts({
          //   ...selectedProducts,
          //   [key]: value?.batch_no,
          // });
          return makeRow(index);
        }
      );

      if (newInput && newInput.length > 0) {
        setInput(newInput);
        setCounter(newInput.length);
      }

      if (
        editItemTransferData &&
        editItemTransferData.itemTransferInformations
      ) {
        const updatedInput = editItemTransferData.itemTransferInformations.map(
          (row) => {
            return { ...quantities, quantity: Number(row?.quanity) };
          }
        );

        const updatedQuantities =
          editItemTransferData.itemTransferInformations.map((row) =>
            parseInt(row.quantity || 0)
          );

        setQuantities(updatedInput);
      }

      setTotalItems(
        editItemTransferData?.total_items?.length === 0
          ? 1
          : editItemTransferData?.total_items
      );
    }
  }, [editItemTransferData]);
  // useEffect(() => {
  //   setTotalQuantity((prev) => prev + 1);
  // }, []);
  return (
    <Card>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Form.List name="item_transfer_details">
            {(fields, { add }) => (
              <ProductTable
                addRow={addRow}
                add={add}
                columns={columns}
                input={input}
              />
            )}
          </Form.List>
        </Col>
      </Row>
    </Card>
  );
};

export default ItemTransferProductTable;
