import React from "react";
import { Card, Result } from "antd";

import "../../styles/modules/telemedicine.less";
import { data1 } from "./components/TimeSlot";

const Symptoms = () => {
  return (
    <div>
      <Card style={{ width: "80%" }}>
        <Result status="success" title="Appoinment booked successfully " />
      </Card>
    </div>
  );
};

export default Symptoms;
