// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.grey-color {
  background-color: #d3d3d3;
}
.list-header1 {
  font-size: medium;
  font-weight: 500;
}
.list-item {
  font-size: medium;
  font-weight: 400;
}
.print-button-invoice {
  display: flex;
  justify-content: end !important;
}
.invoice-logo {
  display: flex;
  justify-content: space-between;
}
@media screen and (min-width: 320px) and (max-width: 500px) {
  .invoice-logo {
    display: flex;
    justify-content: none !important;
    flex-direction: row !important;
  }
}
.invoice-footer {
  display: flex;
  justify-content: end;
}
.justify-center {
  display: flex;
  justify-content: center;
}
.invoice-header {
  font-size: 30px;
  color: #038fde !important;
  font-weight: 500;
}
.view-name-report {
  font-size: 25px !important;
  font-weight: 500;
  color: #008e81;
  text-wrap: wrap;
}
.report-item {
  font-size: x-large !important;
}
.doctor-name {
  font-size: xx-large !important;
  color: #008e81 !important;
}
.doctor-details {
  font-size: medium !important;
}
`, "",{"version":3,"sources":["webpack://./src/styles/modules/superAdmin.less"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;AACA;EACE,iBAAiB;EACjB,gBAAgB;AAClB;AACA;EACE,iBAAiB;EACjB,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,+BAA+B;AACjC;AACA;EACE,aAAa;EACb,8BAA8B;AAChC;AACA;EACE;IACE,aAAa;IACb,gCAAgC;IAChC,8BAA8B;EAChC;AACF;AACA;EACE,aAAa;EACb,oBAAoB;AACtB;AACA;EACE,aAAa;EACb,uBAAuB;AACzB;AACA;EACE,eAAe;EACf,yBAAyB;EACzB,gBAAgB;AAClB;AACA;EACE,0BAA0B;EAC1B,gBAAgB;EAChB,cAAc;EACd,eAAe;AACjB;AACA;EACE,6BAA6B;AAC/B;AACA;EACE,8BAA8B;EAC9B,yBAAyB;AAC3B;AACA;EACE,4BAA4B;AAC9B","sourcesContent":[".grey-color {\n  background-color: #d3d3d3;\n}\n.list-header1 {\n  font-size: medium;\n  font-weight: 500;\n}\n.list-item {\n  font-size: medium;\n  font-weight: 400;\n}\n.print-button-invoice {\n  display: flex;\n  justify-content: end !important;\n}\n.invoice-logo {\n  display: flex;\n  justify-content: space-between;\n}\n@media screen and (min-width: 320px) and (max-width: 500px) {\n  .invoice-logo {\n    display: flex;\n    justify-content: none !important;\n    flex-direction: row !important;\n  }\n}\n.invoice-footer {\n  display: flex;\n  justify-content: end;\n}\n.justify-center {\n  display: flex;\n  justify-content: center;\n}\n.invoice-header {\n  font-size: 30px;\n  color: #038fde !important;\n  font-weight: 500;\n}\n.view-name-report {\n  font-size: 25px !important;\n  font-weight: 500;\n  color: #008e81;\n  text-wrap: wrap;\n}\n.report-item {\n  font-size: x-large !important;\n}\n.doctor-name {\n  font-size: xx-large !important;\n  color: #008e81 !important;\n}\n.doctor-details {\n  font-size: medium !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
