import React, { useState } from "react";
import { Row, Col, Spin, Card, Typography, Modal } from "antd";
import { LoadingOutlined, EditFilled, DeleteFilled } from "@ant-design/icons";

import { Link } from "react-router-dom";

import TableComponent from "../../../components/Table/TableComponent";

import ButtonComponent from "../../../components/Button/ButtonComponent";
import PackageHeader from "./PackageHeader";

const PackageTable = () => {
  const [deleteId, setId] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState("");
  const totalRecords = 10;
  const showModal = (id) => {
    setIsModalOpen(true);
    setId(id);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    // dispatch(deleteItemTransferInProgress(deleteId));
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const columns = [
    {
      title: "S.No",
      dataIndex: "sno",
      render: (text, record, index) => index + 1,
    },

    {
      title: "Package Name",
      dataIndex: "package_name",
      key: "package_name",
    },
    {
      title: "Patient Limit",
      dataIndex: "patient_limit",
      key: "patient_limit",
    },
    {
      title: "Doctor Limit",
      dataIndex: "doctor_limit",
      key: "doctor_limit",
    },
    {
      title: "Permittted Modules",
      dataIndex: "permitted_modules",
      key: "permitted_modules",
      render: (text, record) => {
        return (
          <div>
            {record.permitted_modules.map((module, index) => (
              <Typography key={index} style={{ textAlign: "center" }}>
                {module}
              </Typography>
            ))}
          </div>
        );
      },
    },
    {
      title: "Restricted Modules",
      dataIndex: "resticted_modules",
      key: "resticted_modules",
      render: (text, record) => {
        return (
          <div>
            {record.resticted_modules.map((module, index) => (
              <Typography key={index} style={{ textAlign: "center" }}>
                {module}
              </Typography>
            ))}
          </div>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "Action",
      render: (_, record) => {
        return (
          <Row className="table-button">
            <Col className="button-edit">
              <Link
                to={{
                  pathname: `/packages/edit`,
                  state: { packageData: record },
                }}
              >
                <ButtonComponent
                  size="small"
                  className="secondary-color"
                  icon={<EditFilled />}
                ></ButtonComponent>
              </Link>
            </Col>
            <Col className="button-edit">
              <ButtonComponent
                size="small"
                type="danger"
                icon={<DeleteFilled />}
                onClick={() => showModal(record.id)}
              ></ButtonComponent>
            </Col>
          </Row>
        );
      },
    },
  ];
  const handlePage = (page) => {
    const payload = { pagination: true, pageSize: "10", pageNo: page };
    // dispatch(getitemTransferListInProgress(payload));
  };
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );

  const handleSelectChange = (value) => {
    if (value === "all") {
      setFilteredUsers(hospitalList);
    } else {
      const newData = hospitalList.slice(0, value);
      setFilteredUsers(newData);
    }
  };
  const hospitalList = [
    {
      package_name: "manually",
      patient_limit: "899",
      doctor_limit: "100",
      price: "2000",
      permitted_modules: ["Doctor", "Accountant", "Donor", "Sales"],
      resticted_modules: ["Department", "Nurse", "Email", "Notice"],
    },
  ];
  return (
    <Card>
      <PackageHeader />
      <Spin indicator={antIcon} spinning={Loading}>
        <TableComponent
          columns={columns}
          dataSource={filteredUsers ? filteredUsers : hospitalList}
          rowselector={handleSelectChange}
          itemList={hospitalList}
          filteredUsers={filteredUsers}
          setFilteredUsers={setFilteredUsers}
          field={"package_name"}
        />
      </Spin>
      <Modal
        title="Delete Data"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        pagination={{
          total: totalRecords,
          onChange: (page) => handlePage(page),
        }}
      >
        <p>Are you sure, you want to delete the Data</p>
      </Modal>
    </Card>
  );
};

export default PackageTable;
