import {
  GET_SUB_PACKAGE_IN_PROGRESS,
  GET_SUB_PACKAGE_LIST_SUCCESS,
  GET_SUB_PACKAGE_LIST_ERROR,
  ADD_PACKAGE_IN_PROGRESS,
  ADD_PACKAGE_IN_SUCCESS,
  ADD_CONSULTATION_ERROR,
  GET_MAIN_PACKAGE_LIST_IN_PROGRESS,
  GET_MAIN_PACKAG_LIST_SUCCESS,
  GET_MAIN_PACKAG_LIST_ERROR,
  UPDATE_PACKAGE_IN_PROGRESS,
  UPDATE_PACKAGE_SUCCESS,
  UPDATE_PACKAGE_ERROR,
  DELETE_PACKAGE_IN_PROGRESS,
  DELETE_PACKAGE_SUCCESS,
  DELETE_PACKAGE_ERROR,
  ADD_SUBPACKAGE_IN_PROGRESS,
  ADD_SUBPACKAGE_IN_SUCCESS,
  ADD_SUBPACKAGE_IN_ERROR,
  UPDATE_SUBPACKAGE_IN_PROGRESS,
  UPDATE_SUBPACKAGE_SUCCESS,
  UPDATE_SUBPACKAGE_ERROR,
  DELETE_SUBPACKAGE_IN_PROGRESS,
  DELETE_SUBPACKAGE_SUCCESS,
  DELETE_SUBPACKAGE_ERROR,
} from "../../../constants/ActionTypes";

// Get All BillingPackages
export const getSubPackagesListInProgress = (payload) => ({
  type: GET_SUB_PACKAGE_IN_PROGRESS,
  payload: payload,
});

export const getSubPackagesListInSuccess = (payload) => ({
  type: GET_SUB_PACKAGE_LIST_SUCCESS,
  payload: payload,
});

export const getSubPackagesListInError = (payload) => ({
  type: GET_SUB_PACKAGE_LIST_ERROR,
  payload: payload,
});

export const addPackagesInProgress = (payload) => ({
  type: ADD_PACKAGE_IN_PROGRESS,
  payload: payload,
});

export const addPackagesInSuccess = (payload) => ({
  type: ADD_PACKAGE_IN_SUCCESS,
  payload: payload,
});

export const addPackagesInError = (payload) => ({
  type: ADD_CONSULTATION_ERROR,
  payload: payload,
});

export const getMainPackagesListInProgress = (payload) => ({
  type: GET_MAIN_PACKAGE_LIST_IN_PROGRESS,
  payload: payload,
});

export const getMainPackagesListInSuccess = (payload) => ({
  type: GET_MAIN_PACKAG_LIST_SUCCESS,
  payload: payload,
});

export const getMainPackagesListInError = (payload) => ({
  type: GET_MAIN_PACKAG_LIST_ERROR,
  payload: payload,
});

export const updatePackageInProgress = (payload) => ({
  type: UPDATE_PACKAGE_IN_PROGRESS,
  payload: payload,
});

export const updatePackageInSuccess = (payload) => ({
  type: UPDATE_PACKAGE_SUCCESS,
  payload: payload,
});

export const updatePackageInError = (payload) => ({
  type: UPDATE_PACKAGE_ERROR,
  payload: payload,
});

export const deletePackageInProgress = (payload) => ({
  type: DELETE_PACKAGE_IN_PROGRESS,
  payload: payload,
});

export const deletePackageInSuccess = (payload) => ({
  type: DELETE_PACKAGE_SUCCESS,
  payload: payload,
});

export const deletePackageInError = (payload) => ({
  type: DELETE_PACKAGE_ERROR,
  payload: payload,
});
export const addSubpackagesInProgress = (payload) => ({
  type: ADD_SUBPACKAGE_IN_PROGRESS,
  payload: payload,
});

export const addSubpackagesInSuccess = (payload) => ({
  type: ADD_SUBPACKAGE_IN_SUCCESS,
  payload: payload,
});

export const addSubpackagesInError = (payload) => ({
  type: ADD_SUBPACKAGE_IN_ERROR,
  payload: payload,
});
export const updateSubpackagesInProgress = (payload) => ({
  type: UPDATE_SUBPACKAGE_IN_PROGRESS,
  payload: payload,
});

export const updateSubpackagesInSuccess = (payload) => ({
  type: UPDATE_SUBPACKAGE_SUCCESS,
  payload: payload,
});

export const updateSubpackagesInError = (payload) => ({
  type: UPDATE_SUBPACKAGE_ERROR,
  payload: payload,
});
export const deleteSubpackagesInProgress = (payload) => ({
  type: DELETE_SUBPACKAGE_IN_PROGRESS,
  payload: payload,
});

export const deleteSubpackagesInSuccess = (payload) => ({
  type: DELETE_SUBPACKAGE_SUCCESS,
  payload: payload,
});

export const deleteSubpackagesInError = (payload) => ({
  type: DELETE_SUBPACKAGE_ERROR,
  payload: payload,
});
