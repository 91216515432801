import React from "react";
import { Card } from "antd";
import SpecializationHeader from "./components/SpecializationHeader";
import SpecializationTable from "./components/SpecializationTable";

const SpecializationScreen = () => {
  return (
    <Card>
      <SpecializationHeader />
      <SpecializationTable />
    </Card>
  );
};

export default SpecializationScreen;
