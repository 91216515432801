import React, { useEffect, useState } from "react";
import { Row, Col, Spin, Card, Typography } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import TableComponent from "../../../components/Table/TableComponent";
import IntlMessages from "../../../util/IntlMessages";
import { useDispatch, useSelector } from "react-redux";
import { expiryStockInProgress } from "../../../appRedux/Pharmacy/PharmacyAction";

const ExpiryStockTable = () => {
  const moment = require("moment");
  const dispatch = useDispatch();
  const currentDate = moment();
  const formattedDate = currentDate.format("YYYY-MM-DD");

  const [Loading, setLoading] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState("");
  const expiryStockData = useSelector((state) => state?.pharmacy?.expiryStock);
  const expiryStockList = expiryStockData?.data?.data;

  const columns = [
    {
      title: "S.No",
      dataIndex: "sno",
      render: (text, record, index) => index + 1,
    },

    {
      title: "Product Name",
      dataIndex: "product_name",
      sortDirections: ["ascend", "descend"],
      key: "personName",
      sorter: (a, b) => {
        if (a && b && a.productName && b.productName) {
          return a.productName.localeCompare(b.productName);
        }
        return 0;
      },
    },
    {
      title: "Batch No.",
      dataIndex: "batch_no",
      sortDirections: ["ascend", "descend"],
      key: "batchNo",
      sorter: (a, b) => {
        if (a && b && a.batchNo && b.batchNo) {
          return a.batchNo.localeCompare(b.batchNo);
        }
        return 0;
      },
    },

    {
      title: "Purchase Date",
      dataIndex: "createdAt",
      render: (createdAt) => moment(createdAt).format("DD-MM-YYYY"),
      sortDirections: ["ascend", "descend"],
      key: "createdAt",
      sorter: (a, b) => {
        if (a && b && a.createdAt && b.createdAt) {
          return a.createdAt.localeCompare(b.createdAt);
        }
        return 0;
      },
    },
    {
      title: "Expiry Date",
      dataIndex: "expiry_date",
      sortDirections: ["ascend", "descend"],
      render: (text, record) => {
        return moment(text).format("DD-MM-YYYY");
      },
      key: "expiry_date",

      sorter: (a, b) => {
        if (a && b && a.expiry_date && b.expiry_date) {
          return a.expiry_date.localeCompare(b.expiry_date);
        }
        return 0;
      },
    },
    {
      title: "Stock",
      dataIndex: "available_stock",
      sortDirections: ["ascend", "descend"],
      key: "stock",
      sorter: (a, b) => {
        if (a && b && a.stock && b.stock) {
          return a.stock.localeCompare(b.stock);
        }
        return 0;
      },
    },
  ];

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );

  const handleSelectChange = (value) => {
    if (value === "all") {
      setFilteredUsers(expiryStockList);
    } else {
      const newData = expiryStockList.slice(0, value);
      setFilteredUsers(newData);
    }
  };

  useEffect(() => {
    dispatch(expiryStockInProgress({ formattedDate }));
  }, []);
  useEffect(() => {
    if (expiryStockData?.inProgress) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [expiryStockData]);
  return (
    <Card>
      <Spin indicator={antIcon} spinning={Loading}>
        <Row className="p-10 Add-button">
          <Col>
            <Typography className="header-tag">
              {<IntlMessages id={"label.expiryStock"} />}
            </Typography>
          </Col>
        </Row>
        <TableComponent
          columns={columns}
          dataSource={filteredUsers ? filteredUsers : expiryStockList}
          rowselector={handleSelectChange}
          itemList={expiryStockList}
          filteredUsers={filteredUsers}
          setFilteredUsers={setFilteredUsers}
          field={"product_name"}
          documentTitle={"Expiry Stock List"}
        />
      </Spin>
    </Card>
  );
};

export default ExpiryStockTable;
