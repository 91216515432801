import React from "react";
import { connect } from "react-redux";
import { THEME_TYPE_DARK } from "../../constants/ThemeSetting";
import Widget from "../Widget";
import "./card.less";
const IconWithTextCard = (props) => {
  const { size, title, subTitle, cardColor, image, height } = props;
  let { iconColor } = props;
  if (props.themeType === THEME_TYPE_DARK) {
    iconColor = "white";
  }

  return (
    <Widget styleName={`${cardColor} widget`}>
      <div className="gx-media gx-align-items-left gx-flex-nowrap">
        <div className="gx-mr-lg-4 gx-mr-3">
          {/* <i className="card-icon" style={{ fontSize: 45 }}>
            {icon}
          </i>
          <i
            className={`icon icon-${icon} gx-fs-xlxl gx-text-${iconColor} gx-d-flex`}
            style={{ fontSize: 35 }}
          /> */}
          <img
            src={image}
            style={{ width: size, height: height ? height : size }}
          />
        </div>
        <div className="">
          <h1 className="gx-fs-xxl gx-font-weight-medium  card-header">
            {subTitle}
          </h1>
          <p className="gx-text-grey gx-mb-0 card-header"> {title}</p>
        </div>
      </div>
    </Widget>
  );
};

const mapStateToProps = ({ settings }) => {
  const { themeType } = settings;
  return { themeType };
};
export default connect(mapStateToProps, null)(IconWithTextCard);
