import { LoadingOutlined } from "@ant-design/icons";
import { Col, Form, Input, Row, Select, Spin, Switch } from "antd";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getDoctorProfileInProgress,
  updateDoctorProfileInProgress,
} from "../../../appRedux/doctor/actions/Profile";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import FormInput from "../../../components/Input/FormInput";
import Auxiliary from "../../../util/Auxiliary";
import IntlMessages from "../../../util/IntlMessages";
import CitySelector from "../../Doctor/component/CitySelector";
import CountrySelector from "../../Doctor/component/CountrySelector";

import StateSelector from "../../Doctor/component/StateSelector";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import FormItem from "antd/es/form/FormItem";
import DesignationSelector from "../../Doctor/component/DesignationSelector";
import SpecializationSelector from "../../Doctor/component/SpecializationSelector";
import OrganizationSelector from "../../Doctor/component/OrganizationSelector";
import QualificationSelector from "../../Doctor/component/QualificationSelector";

const DoctorProfile = () => {
  const [double, setDouble] = useState(false);
  const { Option } = Select;
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const history = useHistory();
  const [country, setCountry] = useState("");
  const selectedCountryID = useRef("");
  const [state, setState] = useState("");
  const selectedStateID = useRef("");
  const [city, setCity] = useState("");
  const [doctorStatus, setDoctorStatus] = useState();
  const [photo123, setphoto123] = useState(null);

  const [image, setImage] = useState(null);

  const { getprofile } = useSelector((state) => state.profile);

  const getDoctorData = useSelector((state) => state.profile?.getDoctorProfile);
  const updateDoctorData = useSelector(
    (state) => state.profile?.updateDoctorProfile
  );
  const doctorDetails = getDoctorData?.data?.data;

  const profileData = useSelector((state) => state.profile);
  const profileSuccess = profileData?.getDoctorProfile;

  const dispatch = useDispatch();

  const onFinish = (datas) => {
    const requestPayload = {
      profile_image: image ? image : datas?.profile_url,
      firstName: datas?.firstName,
      lastName: datas?.lastName,
      email: datas?.email,
      dob: datas?.dob,
      contact: datas?.contact,
      qualification: datas?.qualification,
      address1: datas?.address1,
      address2: datas?.address2,
      city: datas?.city,
      state: datas?.state,
      country_code: datas?.country_code,
      organization_id: datas?.organization_id,
      specialization_id: datas?.specialization_id,
      designation_id: datas?.designation_id,
      license_no: datas?.license_no,
      user_id: doctorDetails?.user_id,
      role_id: "2",
      gender_type: datas?.gender_type,
      id: doctorDetails?.id,
      status: doctorDetails?.status,
    };

    dispatch(
      updateDoctorProfileInProgress({
        requestPayload,
      })
    );
  };
  useEffect(() => {
    if (updateDoctorData?.progressing || getDoctorData?.progressing) {
      setLoading(true);
    } else if (updateDoctorData?.success || getDoctorData?.success) {
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [updateDoctorData, getDoctorData]);

  const genderOptions = [
    { value: "M", label: "Male" },
    { value: "F", label: "Female" },
    { value: "O", label: "Other" },
  ];
  const onChangeCountry = (value) => {
    setCountry(value);
  };
  const onChangeCity = (value) => {
    setCity(value);
  };
  const onChangeState = (value) => {
    setState(value);
  };
  useEffect(() => {
    selectedCountryID.current = country;
  }, [country]);
  useEffect(() => {
    selectedStateID.current = state;
  }, [state]);
  const onChangeStatus = (checked) => {
    setDoctorStatus(checked);
    form.setFieldsValue({ status: checked });
  };
  console.log("doctorDetails", doctorDetails);
  useEffect(() => {
    if (doctorDetails) {
      form.setFieldsValue({
        firstName: doctorDetails?.firstName,
        lastName: doctorDetails?.lastName,
        email: doctorDetails?.email,
        contact: doctorDetails?.contact,
        address1: doctorDetails?.address1,
        address2: doctorDetails?.address2,
        org_name: doctorDetails?.organization_name,
        status: doctorDetails?.status,
        role_name: "Doctor",
        dob: doctorDetails?.dob,
        qualification: doctorDetails?.qualification,
        organization_id: doctorDetails?.organization_id,
        specialization_id: doctorDetails?.specialization_id,
        designation_id: doctorDetails?.designation_id,
        license_no: doctorDetails?.license_no,

        gender_type: doctorDetails?.gender_type,
        country_code: doctorDetails?.country_code,
        city: doctorDetails?.city,
        state: doctorDetails?.state,
      });
      form.setFieldValue("profile_url", doctorDetails?.profile_image);
      setphoto123(doctorDetails?.profile_image);
      setCountry(doctorDetails?.country_code);
      setCity(doctorDetails?.city);
      setState(doctorDetails?.state);
      setDoctorStatus(doctorDetails?.status);
    }
  }, [form, doctorDetails]);

  useEffect(() => {
    dispatch(getDoctorProfileInProgress());
  }, []);
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );
  function encodeImageFileAsURL(e) {
    var file = e.target.files[0];
    setphoto123(URL.createObjectURL(e.target.files[0]));
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = function () {
      var base64 = reader.result.replace(/^data:image\/\w+;base64,/, "");
      setImage(base64);
    };
  }
  return (
    <Auxiliary>
      <Spin indicator={antIcon} spinning={loading}>
        <Form
          name="basic"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          autoComplete="off"
          form={form}
          onFinish={onFinish}
        >
          <Row>
            {" "}
            <Col
              xl={23}
              lg={23}
              md={23}
              sm={23}
              xs={23}
              className="justify-center"
            >
              <Col className="img">
                <img className="img-btn" src={photo123} />
              </Col>
            </Col>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <FormItem
                label="Profile"
                rules={[
                  {
                    required: true,
                    message: "Please upload your image!",
                  },
                ]}
                name="profile_url"
                valuePropName="fileList"
              >
                <Input
                  type={"file"}
                  accept="image/*"
                  name="profile_url"
                  onChange={(e) => encodeImageFileAsURL(e)}
                />
              </FormItem>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormInput
                type="text"
                placeholder="First Name"
                name="firstName"
                labelCol={"24"}
                wrapperCol={"24"}
                label={
                  <IntlMessages id={"Profile.myprofile.label.firstName"} />
                }
                rules={[
                  {
                    required: true,
                    message: (
                      <IntlMessages
                        id={"Profile.myprofile.validator.firstName"}
                      />
                    ),
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormInput
                type="text"
                placeholder="Last Name"
                name="lastName"
                labelCol={"24"}
                wrapperCol={"24"}
                label={<IntlMessages id={"Profile.myprofile.label.lastName"} />}
                rules={[
                  {
                    required: true,
                    message: (
                      <IntlMessages
                        id={"Profile.myprofile.validator.lastName"}
                      />
                    ),
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormInput
                type="text"
                placeholder="Email"
                name="email"
                labelCol={"24"}
                wrapperCol={"24"}
                label={<IntlMessages id={"Profile.myprofile.label.email"} />}
                rules={[
                  {
                    required: true,
                    message: (
                      <IntlMessages id={"Profile.myprofile.validator.email"} />
                    ),
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormInput
                type="text"
                placeholder="Contact"
                name="contact"
                labelCol={"24"}
                wrapperCol={"24"}
                label={<IntlMessages id={"Profile.myprofile.label.contact"} />}
                rules={[
                  {
                    required: true,
                    message: (
                      <IntlMessages
                        id={"Profile.myprofile.validator.contact"}
                      />
                    ),
                  },
                  {
                    pattern: /^[0-9]{10}$/,
                    message: "Please enter a valid 10-digit phone number",
                  },
                ]}
              />
            </Col>{" "}
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormInput
                type="date"
                placeholder="DOB"
                name="dob"
                labelCol={"24"}
                wrapperCol={"24"}
                label={<IntlMessages id={"Addnew.doctor.label.dob"} />}
                rules={[
                  {
                    required: true,
                    message: (
                      <IntlMessages id={"Addnew.doctor.validator.dob"} />
                    ),
                  },
                ]}
              />
            </Col>{" "}
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormInput
                placeholder="Email"
                name="email"
                labelCol={"24"}
                wrapperCol={"24"}
                label={<IntlMessages id={"Addnew.doctor.label.email"} />}
                rules={[
                  {
                    type: "email",
                    message: (
                      <IntlMessages id={"Addnew.doctor.validator.emailtype"} />
                    ),
                  },
                  {
                    required: true,
                    message: (
                      <IntlMessages id={"Addnew.doctor.validator.email"} />
                    ),
                  },
                ]}
              />
            </Col>{" "}
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <QualificationSelector
                margin="mb-30"
                label={"Qualification"}
                name={"qualification"}
                placeholder={"Select Your Qualification"}
                editMode={true}
                rules={[
                  {
                    required: true,
                    message: "Please select your qualification",
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormInput
                placeholder="License No"
                name="license_no"
                labelCol={"24"}
                wrapperCol={"24"}
                label={<IntlMessages id={"Addnew.doctor.label.license_no"} />}
                rules={[
                  {
                    type: "text",
                    message: (
                      <IntlMessages id={"Addnew.doctor.validator.license_no"} />
                    ),
                  },
                  {
                    required: true,
                    message: (
                      <IntlMessages id={"Addnew.doctor.validator.license_no"} />
                    ),
                  },
                ]}
              />
            </Col>
            <Col xs={0}>
              <OrganizationSelector
                margin="mb-30"
                label={"Organization"}
                name={"organization_id"}
                placeholder={"Select Your Organization"}
                editMode={false}
                rules={[
                  {
                    required: true,
                    message: "Please select your organization",
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <SpecializationSelector
                margin="mb-30"
                label={"Specialization"}
                name={"specialization_id"}
                placeholder={"Select Your Specialization"}
                editMode={true}
                rules={[
                  {
                    required: true,
                    message: "Please select your specialization",
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <DesignationSelector
                margin="mb-30"
                label={"Designation"}
                name={"designation_id"}
                placeholder={"Select Your Designation"}
                editMode={true}
                rules={[
                  {
                    required: true,
                    message: "Please select your designation",
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                label="Gender"
                name="gender_type"
                rules={[
                  {
                    required: true,
                    message: "Please select gender",
                  },
                ]}
              >
                <Select placeholder="Select gender">
                  {genderOptions.map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormInput
                type="text"
                placeholder="Address 1"
                name="address1"
                labelCol={"24"}
                wrapperCol={"24"}
                label={<IntlMessages id={"Profile.myprofile.label.address1"} />}
                rules={[
                  {
                    required: true,
                    message: (
                      <IntlMessages
                        id={"Profile.myprofile.validator.address1"}
                      />
                    ),
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormInput
                type="text"
                placeholder="Address 2"
                name="address2"
                labelCol={"24"}
                wrapperCol={"24"}
                label={<IntlMessages id={"Profile.myprofile.label.address2"} />}
                rules={[
                  {
                    required: false,
                    message: (
                      <IntlMessages
                        id={"Profile.myprofile.validator.address2"}
                      />
                    ),
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CountrySelector
                label={"Country Name"}
                name={"country_code"}
                onChange={onChangeCountry}
                country={country}
                rules={[
                  {
                    required: true,
                    message: "Please select your country!",
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <StateSelector
                label={"State"}
                name={"state"}
                onChange={onChangeState}
                country={state}
                countryId={country?.id}
                rules={[
                  {
                    required: true,
                    message: "Please select your state!",
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CitySelector
                label={"City"}
                name={"city"}
                onChange={onChangeCity}
                country={city}
                StateId={state?.id}
                rules={[
                  {
                    required: true,
                    message: "Please select your state!",
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormInput
                type="text"
                placeholder="Role Name"
                name="role_name"
                labelCol={"24"}
                wrapperCol={"24"}
                label={<IntlMessages id={"Profile.myprofile.label.roleName"} />}
                readOnly={true}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormInput
                type="text"
                placeholder="Organization Name"
                name="org_name"
                labelCol={"24"}
                wrapperCol={"24"}
                label={<IntlMessages id={"Profile.myprofile.label.orgName"} />}
                readOnly={true}
              />
            </Col>{" "}
            {/* <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                labelCol={{
                  span: 24,
                }}
                wrapperCol={{
                  span: 24,
                }}
                label={<IntlMessages id={"Addnew.admin.label.Status"} />}
                name={"status"}
              >
                <Switch
                  checkedChildren="Active"
                  unCheckedChildren="Inactive"
                  onChange={onChangeStatus}
                  checked={doctorStatus}
                />
              </Form.Item>
            </Col> */}
          </Row>
          <Row className="space">
            <Col className="btn-pos">
              {/* <ButtonComponent type="danger" htmlType="reset" label="Reset" /> */}
              <ButtonComponent
                type="primary"
                htmlType="submit"
                label="Update Profile"
              />
            </Col>
          </Row>
        </Form>
      </Spin>
    </Auxiliary>
  );
};

export default DoctorProfile;
