import { message } from "antd";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  ADD_BLOOD_GLUCOSE_INPROGRESS,
  ADD_BMI_INPROGRESS,
  ADD_INSULIN_INPROGRESS,
  ADD_PATIENT_HEALTH_RECORDS_IN_PROGRESS,
  ADD_VITALS_INPROGRESS,
  DELETE_PATIENT_HEALTH_RECORDS_IN_PROGRESS,
  DOCTOR_CONSULTATION_IN_PROGRESS,
  EDIT_PATIENT_HEALTH_RECORDS_IN_PROGRESS,
  GET_APPOINTMENT_BY_DOCTOR_PATIENT_IN_PROGRESS,
  GET_BLOOD_GLUCOSE_LIST_INPROGRESS,
  GET_BMI_LIST_INPROGRESS,
  GET_INSULIN_LIST_INPROGRESS,
  GET_PATIENT_APPOINMENT_BY_LIST_IN_PROGRESS,
  GET_PATIENT_APPOINTMENT_BY_ID_IN_PROGRESS,
  GET_PATIENT_HEALTH_RECORDS_IN_PROGRESS,
  GET_SCHEDULE_IN_PROGRESS,
  GET_SINGLE_PATIENT_HEALTH_RECORDS_IN_PROGRESS,
  GET_UPCOMING_PATIENT_APPOINMENT_BY_LIST_IN_PROGRESS,
  GET_VITALS_LIST_INPROGRESS,
  GET_VITALS_LIST_TYPE_INPROGRESS,
  PATIENT_ADD_APPOINTMENT_LIST_IN_PROGRESS,
  PATIENT_DASHBOARD_IN_PROGRESS,
  PATIENT_DELETE_APPOINTMENT_LIST_IN_PROGRESS,
  PATIENT_UPCOMING_DELETE_APPOINTMENT_LIST_IN_PROGRESS,
  PATIENT_UPDATE_APPOINTMENT_LIST_IN_PROGRESS,
} from "../../../constants/ActionTypes";
// import { getErrorMessageFromAPI } from "../../../util/patient";
import {
  addBloodGlucoseError,
  addBloodGlucoseSuccess,
  addBmiError,
  addBmiInprogress,
  addBmiSuccess,
  addInsulinError,
  addInsulinSuccess,
  addPatientHeathRecordsFailure,
  addPatientHeathRecordsSuccess,
  addVitalsError,
  addVitalsSuccess,
  deletePatientHeathRecordsFailure,
  deletePatientHeathRecordsSuccess,
  doctorConsultationFeeInFailure,
  doctorConsultationFeeInSuccess,
  editPatientHeathRecordsFailure,
  editPatientHeathRecordsSuccess,
  getAppointmentByDoctorPatientFailure,
  getAppointmentByDoctorPatientSuccess,
  getBloodGlucoseListError,
  getBloodGlucoseListSuccess,
  getInsulinListError,
  getInsulinListSuccess,
  getPatientAppoinmentListInFailure,
  getPatientAppoinmentListInSuccess,
  getPatientAppointmentByIdInFailure,
  getPatientAppointmentByIdInSuccess,
  getPatientHeathRecordsFailure,
  getPatientHeathRecordsSuccess,
  getScheduleInFailure,
  getScheduleInSuccess,
  getSinglePatientHeathRecordsFailure,
  getSinglePatientHeathRecordsSuccess,
  getUpcomingPatientAppoinmentListInFailure,
  getUpcomingPatientAppoinmentListInSuccess,
  getVitalsByTypeError,
  getVitalsByTypeSuccess,
  getVitalsListError,
  getVitalsListSuccess,
  patientAddAppointmentInFailure,
  patientAddAppointmentInSuccess,
  patientDashboardError,
  patientDashboardSuccess,
  patientDeleteAppointmentListInFailure,
  patientDeleteAppointmentListInSuccess,
  patientUpcomingDeleteAppointmentListInFailure,
  patientUpcomingDeleteAppointmentListInSuccess,
  patientUpdateAppointmentInFailure,
  patientUpdateAppointmentInSuccess,
} from "../Action/Action";
import {
  addBloodGlucoseEffect,
  addBmiEffect,
  addInsulinEffect,
  addPatientAppointmentEffect,
  addPatientHealthRecordsEffect,
  addVitalEffect,
  deletePatientAppointmentListEffect,
  deletePatientHealthRecordsEffect,
  deleteUpcomingPatientAppointmentListEffect,
  doctorConsultationFeeEffect,
  editPatientHealthRecordsEffect,
  getAppoinmentByDoctorPatientEffect,
  getBloodGlucoseListEffect,
  getBmiListEffect,
  getInsulinListEffect,
  getpatientAppointmentByIdEffect,
  getpatientAppointmentListEffect,
  getPatientDashboardEffcet,
  getPatientHealthRecordsEffect,
  getscheduleListEffect,
  getSinglePatientHealthRecordsEffect,
  getUpcomingPatientAppointmentListEffect,
  getVitalListEffect,
  getVitalsByTypeEffect,
  updatePatientAppointmentEffect,
} from "./Effect/patientAppointmentEffect";
import { getErrorMessageFromAPI } from "../../../util/utilz";

function* getPatientAppointmentList({ payload }) {
  try {
    const token = localStorage.getItem("user_id");
    const patient_id = localStorage.getItem("patient_id");
    let { data } = yield call(
      getpatientAppointmentListEffect,
      payload,
      token,
      patient_id
    );

    yield put(
      getPatientAppoinmentListInSuccess({
        data: data,
        message: data["message"],
        Success: true,
      })
    );
  } catch (e) {
    yield put(
      getPatientAppoinmentListInFailure({
        Error: true,
        message: e["message"],
      })
    );
  }
}
function* getUpcomingPatientAppointmentList({ payload }) {
  try {
    const token = localStorage.getItem("user_id");
    const patient_id = localStorage.getItem("patient_id");

    let { data } = yield call(
      getUpcomingPatientAppointmentListEffect,
      payload,
      token,
      patient_id
    );

    yield put(
      getUpcomingPatientAppoinmentListInSuccess({
        data: data,
        message: data["message"],
        Success: true,
      })
    );
  } catch (e) {
    yield put(
      getUpcomingPatientAppoinmentListInFailure({
        Error: true,
        message: e["message"],
      })
    );
  }
}
function* addPatientAppointment({ payload }) {
  try {
    let { data } = yield call(addPatientAppointmentEffect, payload);
    yield put(
      patientAddAppointmentInSuccess({
        data: data,
        message: data["message"],
        Success: true,
      })
    );
    message.success(data["message"]);
  } catch (e) {
    yield put(
      patientAddAppointmentInFailure({
        Error: true,
        message: getErrorMessageFromAPI(e),
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}
function* deletePatientAppointmentList({ payload }) {
  try {
    const token = localStorage.getItem("user_id");

    let { data } = yield call(
      deletePatientAppointmentListEffect,
      payload,
      token
    );
    yield put(
      patientDeleteAppointmentListInSuccess({
        data: data,
        message: data["message"],
        Success: true,
      })
    );
  } catch (e) {
    yield put(
      patientDeleteAppointmentListInFailure({
        Error: true,
        message: e["message"],
      })
    );
  }
}
function* deleteUpcomingPatientAppointmentList({ payload }) {
  try {
    const token = localStorage.getItem("user_id");

    let { data } = yield call(
      deleteUpcomingPatientAppointmentListEffect,
      payload,
      token
    );
    yield put(
      patientUpcomingDeleteAppointmentListInSuccess({
        data: data,
        message: data["message"],
        Success: true,
      })
    );
  } catch (e) {
    yield put(
      patientUpcomingDeleteAppointmentListInFailure({
        Error: true,
        message: e["message"],
      })
    );
  }
}
function* updatePatientAppointment({ payload }) {
  try {
    const token = localStorage.getItem("user_id");
    let { data } = yield call(updatePatientAppointmentEffect, payload, token);

    yield put(
      patientUpdateAppointmentInSuccess({
        data: data,
        message: data["message"],
        Success: true,
      })
    );
    message.success(data["message"]);
  } catch (e) {
    yield put(
      patientUpdateAppointmentInFailure({
        Error: true,
        message: e["message"],
      })
    );
    message.error(e["message"]);
  }
}
function* getPatientAppointmentById({ payload }) {
  try {
    const token = localStorage.getItem("user_id");

    let { data } = yield call(getpatientAppointmentByIdEffect, payload, token);

    yield put(
      getPatientAppointmentByIdInSuccess({
        data: data,
        message: data["message"],
        Success: true,
      })
    );
    // message.success(data["message"]);
  } catch (e) {
    yield put(
      getPatientAppointmentByIdInFailure({
        Error: true,
        message: e["message"],
      })
    );
    message.success(e["message"]);
  }
}
function* getSchedule({ payload }) {
  try {
    const token = localStorage.getItem("user_id");

    let { data } = yield call(getscheduleListEffect, payload, token);

    yield put(
      getScheduleInSuccess({
        data: data,
        message: data["message"],
        Success: true,
      })
    );
    // message.success(data["message"])
  } catch (e) {
    yield put(
      getScheduleInFailure({
        Error: true,
        message: getErrorMessageFromAPI(e),
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}
function* getConsultationFee({ payload }) {
  try {
    const token = localStorage.getItem("user_id");
    let { data } = yield call(doctorConsultationFeeEffect, payload, token);

    yield put(
      doctorConsultationFeeInSuccess({
        data: data,
        message: data["message"],
        Success: true,
      })
    );
    // message.success(data["message"])
  } catch (e) {
    yield put(
      doctorConsultationFeeInFailure({
        Error: true,
        message: getErrorMessageFromAPI(e),
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}
function* getPatientHealthRecords({ payload }) {
  try {
    const token = localStorage.getItem("user_id");
    let { data } = yield call(getPatientHealthRecordsEffect, payload, token);

    yield put(
      getPatientHeathRecordsSuccess({
        data: data,
        message: data["message"],
        Success: true,
      })
    );
    // message.success(data["message"])
  } catch (e) {
    yield put(
      getPatientHeathRecordsFailure({
        Error: true,
        message: getErrorMessageFromAPI(e),
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}
function* addPatientHealthRecords({ payload }) {
  try {
    const token = localStorage.getItem("user_id");
    let { data } = yield call(addPatientHealthRecordsEffect, payload, token);

    yield put(
      addPatientHeathRecordsSuccess({
        data: data,
        message: data["message"],
        Success: true,
      })
    );
    message.success(data["message"]);
  } catch (e) {
    yield put(
      addPatientHeathRecordsFailure({
        Error: true,
        message: getErrorMessageFromAPI(e),
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}
function* editPatientHealthRecords({ payload }) {
  try {
    const token = localStorage.getItem("user_id");
    let { data } = yield call(editPatientHealthRecordsEffect, payload, token);

    yield put(
      editPatientHeathRecordsSuccess({
        data: data,
        message: data["message"],
        Success: true,
      })
    );
    message.success(data["message"]);
  } catch (e) {
    yield put(
      editPatientHeathRecordsFailure({
        Error: true,
        message: getErrorMessageFromAPI(e),
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}
function* deletePatientHealthRecords({ payload }) {
  try {
    const token = localStorage.getItem("user_id");
    let { data } = yield call(deletePatientHealthRecordsEffect, payload, token);

    yield put(
      deletePatientHeathRecordsSuccess({
        data: data,
        message: data["message"],
        Success: true,
      })
    );
    message.success(data["message"]);
  } catch (e) {
    yield put(
      deletePatientHeathRecordsFailure({
        Error: true,
        message: getErrorMessageFromAPI(e),
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}
function* getSinglePatientHealthRecords({ payload }) {
  try {
    const token = localStorage.getItem("user_id");
    let { data } = yield call(
      getSinglePatientHealthRecordsEffect,
      payload,
      token
    );

    yield put(
      getSinglePatientHeathRecordsSuccess({
        data: data,
        message: data["message"],
        Success: true,
      })
    );
    message.success(data["message"]);
  } catch (e) {
    yield put(
      getSinglePatientHeathRecordsFailure({
        Error: true,
        message: getErrorMessageFromAPI(e),
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}
function* getAppoitmentByDoctorPatient({ payload }) {
  try {
    const token = localStorage.getItem("user_id");
    let { data } = yield call(
      getAppoinmentByDoctorPatientEffect,
      payload,
      token
    );

    yield put(
      getAppointmentByDoctorPatientSuccess({
        data: data,
        message: data["message"],
        Success: true,
      })
    );
  } catch (e) {
    yield put(
      getAppointmentByDoctorPatientFailure({
        Error: true,
        message: getErrorMessageFromAPI(e),
      })
    );
    // message.error(getErrorMessageFromAPI(e));
  }
}
function* getPatientDashboardWatcher({ payload }) {
  try {
    const token = localStorage.getItem("user_id");
    let { data } = yield call(getPatientDashboardEffcet, payload, token);

    yield put(
      patientDashboardSuccess({
        data: data,
        message: data["message"],
        Success: true,
      })
    );
    message.success(data["message"]);
  } catch (e) {
    yield put(
      patientDashboardError({
        Error: true,
        message: getErrorMessageFromAPI(e),
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}
function* getVitalListWatcher({ payload }) {
  try {
    const token = localStorage.getItem("user_id");
    let { data } = yield call(getVitalListEffect, payload, token);

    yield put(
      getVitalsListSuccess({
        data: data,
        message: data["message"],
        Success: true,
      })
    );
  } catch (e) {
    yield put(
      getVitalsListError({
        Error: true,
        message: getErrorMessageFromAPI(e),
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}
function* addVitalWatcher({ payload }) {
  try {
    const token = localStorage.getItem("user_id");
    let { data } = yield call(addVitalEffect, payload, token);

    yield put(
      addVitalsSuccess({
        data: data,
        message: data["message"],
        Success: true,
      })
    );
    message.success(data["message"]);
  } catch (e) {
    yield put(
      addVitalsError({
        Error: true,
        message: getErrorMessageFromAPI(e),
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}
function* getBmiListWatcher({ payload }) {
  try {
    const token = localStorage.getItem("user_id");
    let { data } = yield call(getBmiListEffect, payload, token);

    yield put(
      addBmiSuccess({
        data: data,
        message: data["message"],
        Success: true,
      })
    );
  } catch (e) {
    yield put(
      addBmiError({
        Error: true,
        message: getErrorMessageFromAPI(e),
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}
function* addBmiWatcher({ payload }) {
  try {
    const token = localStorage.getItem("user_id");
    let { data } = yield call(addBmiEffect, payload, token);

    yield put(
      addBmiInprogress({
        data: data,
        message: data["message"],
        Success: true,
      })
    );
    message.success(data["message"]);
  } catch (e) {
    yield put(
      addBmiError({
        Error: true,
        message: getErrorMessageFromAPI(e),
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}
function* getBloodGlucoseListWatcher({ payload }) {
  try {
    const token = localStorage.getItem("user_id");
    let { data } = yield call(getBloodGlucoseListEffect, payload, token);

    yield put(
      getBloodGlucoseListSuccess({
        data: data,
        message: data["message"],
        Success: true,
      })
    );
  } catch (e) {
    yield put(
      getBloodGlucoseListError({
        Error: true,
        message: getErrorMessageFromAPI(e),
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}
function* addBloodGlucoseWatcher({ payload }) {
  try {
    const token = localStorage.getItem("user_id");
    let { data } = yield call(addBloodGlucoseEffect, payload, token);

    yield put(
      addBloodGlucoseSuccess({
        data: data,
        message: data["message"],
        Success: true,
      })
    );
    message.success(data["message"]);
  } catch (e) {
    yield put(
      addBloodGlucoseError({
        Error: true,
        message: getErrorMessageFromAPI(e),
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}
function* getInsulinListWatcher({ payload }) {
  try {
    const token = localStorage.getItem("user_id");
    let { data } = yield call(getInsulinListEffect, payload, token);

    yield put(
      getInsulinListSuccess({
        data: data,
        message: data["message"],
        Success: true,
      })
    );
  } catch (e) {
    yield put(
      getInsulinListError({
        Error: true,
        message: getErrorMessageFromAPI(e),
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}
function* addInsulinWatcher({ payload }) {
  try {
    const token = localStorage.getItem("user_id");
    let { data } = yield call(addInsulinEffect, payload, token);

    yield put(
      addInsulinSuccess({
        data: data,
        message: data["message"],
        Success: true,
      })
    );
    message.success(data["message"]);
  } catch (e) {
    yield put(
      addInsulinError({
        Error: true,
        message: getErrorMessageFromAPI(e),
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}
function* getVitalsByTypeWatcher({ payload }) {
  try {
    const token = localStorage.getItem("user_id");
    let { data } = yield call(getVitalsByTypeEffect, payload, token);

    yield put(
      getVitalsByTypeSuccess({
        data: data,
        message: data["message"],
        Success: true,
      })
    );
  } catch (e) {
    yield put(
      getVitalsByTypeError({
        Error: true,
        message: getErrorMessageFromAPI(e),
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}
export default function* patientAppointmentSaga() {
  yield takeLatest(
    GET_PATIENT_APPOINMENT_BY_LIST_IN_PROGRESS,
    getPatientAppointmentList
  );
  yield takeLatest(
    PATIENT_DELETE_APPOINTMENT_LIST_IN_PROGRESS,
    deletePatientAppointmentList
  );
  yield takeLatest(
    PATIENT_ADD_APPOINTMENT_LIST_IN_PROGRESS,
    addPatientAppointment
  );
  yield takeLatest(
    PATIENT_UPDATE_APPOINTMENT_LIST_IN_PROGRESS,
    updatePatientAppointment
  );
  yield takeLatest(
    GET_PATIENT_APPOINTMENT_BY_ID_IN_PROGRESS,
    getPatientAppointmentById
  );
  yield takeLatest(
    GET_UPCOMING_PATIENT_APPOINMENT_BY_LIST_IN_PROGRESS,
    getUpcomingPatientAppointmentList
  );
  yield takeLatest(
    PATIENT_UPCOMING_DELETE_APPOINTMENT_LIST_IN_PROGRESS,
    deleteUpcomingPatientAppointmentList
  );
  yield takeLatest(GET_SCHEDULE_IN_PROGRESS, getSchedule);
  yield takeLatest(DOCTOR_CONSULTATION_IN_PROGRESS, getConsultationFee);
  yield takeLatest(
    GET_PATIENT_HEALTH_RECORDS_IN_PROGRESS,
    getPatientHealthRecords
  );
  yield takeLatest(
    ADD_PATIENT_HEALTH_RECORDS_IN_PROGRESS,
    addPatientHealthRecords
  );
  yield takeLatest(
    EDIT_PATIENT_HEALTH_RECORDS_IN_PROGRESS,
    editPatientHealthRecords
  );
  yield takeLatest(
    DELETE_PATIENT_HEALTH_RECORDS_IN_PROGRESS,
    deletePatientHealthRecords
  );
  yield takeLatest(
    GET_SINGLE_PATIENT_HEALTH_RECORDS_IN_PROGRESS,
    getSinglePatientHealthRecords
  );
  yield takeLatest(
    GET_APPOINTMENT_BY_DOCTOR_PATIENT_IN_PROGRESS,
    getAppoitmentByDoctorPatient
  );
  yield takeLatest(PATIENT_DASHBOARD_IN_PROGRESS, getPatientDashboardWatcher);
  yield takeLatest(GET_VITALS_LIST_INPROGRESS, getVitalListWatcher);
  yield takeLatest(GET_BMI_LIST_INPROGRESS, getBmiListWatcher);
  yield takeLatest(
    GET_BLOOD_GLUCOSE_LIST_INPROGRESS,
    getBloodGlucoseListWatcher
  );
  yield takeLatest(GET_INSULIN_LIST_INPROGRESS, getInsulinListWatcher);
  yield takeLatest(ADD_INSULIN_INPROGRESS, addInsulinWatcher);
  yield takeLatest(ADD_BMI_INPROGRESS, addBmiWatcher);
  yield takeLatest(ADD_BLOOD_GLUCOSE_INPROGRESS, addBloodGlucoseWatcher);
  yield takeLatest(ADD_VITALS_INPROGRESS, addVitalWatcher);
  yield takeLatest(GET_VITALS_LIST_TYPE_INPROGRESS, getVitalsByTypeWatcher);
}
