import React, { useEffect, useState } from "react";

import { Button, Col, Modal, Row, Spin, Tooltip, Typography } from "antd";
import { Link } from "react-router-dom";
import {
  DeleteFilled,
  EditFilled,
  ExclamationCircleOutlined,
  LoadingOutlined,
  UndoOutlined,
} from "@ant-design/icons";

import { useDispatch, useSelector } from "react-redux";
import TableComponent from "../../../components/Table/TableComponent";
import Auxiliary from "../../../util/Auxiliary";
import ButtonComponent from "../../../components/Button/ButtonComponent";

import Message from "../../../components/Message/Message";
import {
  deleteTeamMemberInProgress,
  getTeamMemberListInProgress,
} from "../../../appRedux/admin/teamMembers/action/teamMemberAction";
import { APP_ROUTES } from "../../../constants/Routes";
const TeamMemberTable = () => {
  const organization_id = localStorage.getItem("org_id");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [deleteId, setDeleteId] = useState();

  const dispatch = useDispatch();
  const [navigate, setNavigate] = useState(false);
  const { billingPackages, billingPaymentreducer, teamMember } = useSelector(
    (state) => state
  );

  const tableData = billingPaymentreducer?.biilingList?.data?.data;
  const totalRecords = billingPackages?.subPackagesList?.data?.totalRecords;
  const teamMemberList = teamMember?.teamMemberList?.data?.data || [];
  const teamMemberLoading = teamMember?.teamMemberList?.progressing;

  useEffect(() => {
    dispatch(getTeamMemberListInProgress({ organization_id }));
  }, []);
  const handlePage = (page) => {
    const paylod = {
      organization_id: organization_id,
      pageNo: page,
    };
    // dispatch(getSubPackagesListInProgress({ paylod }));
  };
  const handleModalSubmit = () => {
    setIsModalVisible(false);
  };
  const handleDelete = (deleteData) => {
    const handleSuccess = () => {
      const paylod = {
        organization_id: organization_id,
        pageNo: 1,
      };
      dispatch(getTeamMemberListInProgress({ organization_id }));
      setIsModalOpen(false);
    };
    const payload = {
      id: deleteId,
      handleSuccess,
    };
    dispatch(deleteTeamMemberInProgress(payload));
  };
  const handleConformCancle = () => {
    setIsModalOpen(false);
  };
  const showModal = (id) => {
    setIsModalOpen(true);
    setDeleteId(id);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = (data) => {
    const payload = {
      id: deleteId,
      reason: data?.reason,
    };

    setNavigate(true);
  };

  const cancel = () => {
    Message.error("Cancelled");
  };
  useEffect(() => {
    if (
      (billingPaymentreducer?.deleteBilling?.success && navigate) ||
      (billingPaymentreducer?.addPayments?.success && navigate) ||
      (billingPaymentreducer?.undo?.success && navigate)
    ) {
      window.location.reload();
      setNavigate(false);
    }
  }, [
    billingPaymentreducer?.deleteBilling?.success && navigate,
    billingPaymentreducer?.addPayments?.success && navigate,
    billingPaymentreducer?.undo?.success && navigate,
  ]);

  const columns = [
    {
      title: "Member Id",
      dataIndex: "members_serial_no",
    },

    {
      title: "First Name",
      dataIndex: "firstName",
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
    },

    {
      title: "Action",
      dataIndex: "Action",
      // width: 150,
      render: (_, record) => {
        const isCancle = tableData?.find((d) => Number(d?.status) === 3);
        return (
          <Row>
            {Number(record?.status) === 3 ? (
              <>
                <Col xs={24}>
                  {" "}
                  <Typography className="cancled-text">
                    This payment was cancelled!.
                  </Typography>
                </Col>
                <div style={{ display: "flex" }}>
                  <Col>
                    <ButtonComponent
                      icon={<UndoOutlined />}
                      size="large"
                      // onClick={() => {
                      //   handleUndo(record);
                      // }}
                      label="Undo"
                      style={{ background: "rgb(0,128,0)", color: "white" }}
                    />
                  </Col>
                  <Col span={1}>
                    <Tooltip placement="top" title={record?.reason}>
                      <Button
                        icon={<ExclamationCircleOutlined />}
                        size="large"
                        type="primary"
                      >
                        Reason
                      </Button>
                    </Tooltip>
                  </Col>
                </div>
              </>
            ) : (
              <>
                <Col xs={isCancle ? 5 : 0}></Col>
                <Col xs={isCancle ? 15 : 24}>
                  <Row>
                    <Col xs={7}>
                      {" "}
                      <Tooltip placement="top" title={"Edit"}>
                        <Link
                          to={{
                            pathname: APP_ROUTES.TEAMMEMBERSFORM,
                            state: record,
                            type: "edit",
                          }}
                        >
                          <ButtonComponent
                            icon={<EditFilled />}
                            size="small"
                            type="primary"
                            onClick={() => {
                              showModal(record);
                            }}
                          />
                        </Link>
                      </Tooltip>
                    </Col>

                    <Col xs={12}>
                      {/* <Popconfirm
                        title="Are you sure to delete?"
                        onConfirm={() => {
                          handleDelete(record);
                        }}
                        onCancel={cancel}
                        okText="Yes"
                        cancelText="No"
                      > */}

                      <Tooltip placement="top" title={"Delete"}>
                        <Typography>
                          {" "}
                          <ButtonComponent
                            icon={<DeleteFilled />}
                            size="small"
                            type="danger"
                            onClick={() => showModal(record.id)}
                          />
                        </Typography>
                      </Tooltip>

                      {/* </Popconfirm> */}
                    </Col>
                  </Row>
                </Col>
                <Col xs={isCancle ? 5 : 0}></Col>
              </>
            )}
          </Row>
        );
      },
    },
  ];
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );
  return (
    <Auxiliary>
      <Spin indicator={antIcon} spinning={teamMemberLoading}>
        {" "}
        <TableComponent
          // type="teamMember"
          columns={columns}
          dataSource={teamMemberList}
          pagination={{
            total: totalRecords,
            onChange: (page) => handlePage(page),
          }}
          documentTitle={"Team Member List"}
        />
        {/* <CancleModal
          isModalOpen={isModalOpen}
          handleConformCancle={handleConformCancle}
          handleSubmit={handleSubmit}
        />
        `` */}
      </Spin>
      <Modal
        title="Delete Data"
        open={isModalOpen}
        onOk={handleDelete}
        onCancel={handleCancel}
        cancelButtonProps={{ className: "secondary-color" }}
      >
        <p>Are you sure, you want to delete the Data</p>
      </Modal>
    </Auxiliary>
  );
};

export default TeamMemberTable;
