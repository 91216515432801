import { Card } from "antd";
import DoctorListHeader from "./DoctorListHeader";
import DoctorListTable from "./DoctorListTable";

const DoctorListScreen = () => {
  return (
    <Card>
      <DoctorListHeader />
      <DoctorListTable />
    </Card>
  );
};

export default DoctorListScreen;
