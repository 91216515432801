import { message } from "antd";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  ADD_QUALIFICATION_LIST_IN_PROGRESS,
  DELETE_QUALIFICATION_LIST_IN_PROGRESS,
  GET_QUALIFICATION_LIST_IN_PROGRESS,
  GET_QUALIFICATION_UPDATE_LIST_PROGRESS,
  UPDATE_QUALIFICATION_LIST_IN_PROGRESS,
} from "../../../constants/ActionTypes";
import { getErrorMessageFromAPI } from "../../../util/utilz";
import {
  addQualificationListInError,
  addQualificationListInSuccess,
  deleteQualificationListInError,
  deleteQualificationListInSuccess,
  getQualificationListInError,
  getQualificationListInSuccess,
  getQualificationUpdateListError,
  getQualificationUpdateListSuccess,
  updateQualificationListInError,
  updateQualificationListInSuccess,
} from "../actions/Qualification";
import {
  addQualificationListEffect,
  deleteQualificationListEffect,
  getQualificationListEffect,
  getQualificationUpdateListEffect,
  updateQualificationListEffect,
} from "./effects/QualificationEffect";

function* getQualificationList({ payload }) {
  try {
    const token = localStorage.getItem("user_id");
    const { data } = yield call(getQualificationListEffect, token, payload);

    yield put(
      getQualificationListInSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
  } catch (e) {
    yield put(
      getQualificationListInError({
        message: getErrorMessageFromAPI(e),
        error: true,
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}

function* deleteQualificationList({ payload }) {
  try {
    const token = localStorage.getItem("user_id");
    let { data } = yield call(deleteQualificationListEffect, payload, token);

    yield put(
      deleteQualificationListInSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    message.success(data["message"]);
  } catch (e) {
    yield put(
      deleteQualificationListInError({
        error: true,
        message: getErrorMessageFromAPI(e),
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}

function* addQualificationList({ payload }) {
  try {
    const token = localStorage.getItem("user_id");
    let { data } = yield call(addQualificationListEffect, payload, token);

    yield put(
      addQualificationListInSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    message.success(data["message"]);
  } catch (e) {
    yield put(
      addQualificationListInError({
        error: true,
        message: getErrorMessageFromAPI(e),
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}

function* UpdateQualificationSaga({ payload }) {
  try {
    const token = localStorage.getItem("user_id");

    let { data } = yield call(updateQualificationListEffect, payload, token);

    yield put(
      updateQualificationListInSuccess({
        data: data,
        message: data["message"],
        success: true,
      })
    );
    message.success(data["message"]);
  } catch (e) {
    yield put(
      updateQualificationListInError({
        error: true,
        message: getErrorMessageFromAPI(e),
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}

function* GetQualificationUpdateList({ payload }) {
  try {
    const token = localStorage.getItem("user_id");

    let { data } = yield call(getQualificationUpdateListEffect, payload, token);

    yield put(
      getQualificationUpdateListSuccess({
        data: data,
        message: "Success",
        success: true,
      })
    );
  } catch (e) {
    yield put(
      getQualificationUpdateListError({
        error: true,
        message: getErrorMessageFromAPI(e),
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}

export function* QualificationSaga() {
  yield takeLatest(GET_QUALIFICATION_LIST_IN_PROGRESS, getQualificationList);
  yield takeLatest(
    DELETE_QUALIFICATION_LIST_IN_PROGRESS,
    deleteQualificationList
  );
  yield takeLatest(ADD_QUALIFICATION_LIST_IN_PROGRESS, addQualificationList);
  yield takeLatest(
    UPDATE_QUALIFICATION_LIST_IN_PROGRESS,
    UpdateQualificationSaga
  );
  yield takeLatest(
    GET_QUALIFICATION_UPDATE_LIST_PROGRESS,
    GetQualificationUpdateList
  );
}
