import React, { useEffect, useState } from "react";
import { Card, Row, Col, Form, Spin } from "antd";
import "../../../styles/modules/pharmacy.less";
import { APP_ROUTES } from "../../../constants/Routes";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import FormInput from "../../../components/Input/FormInput";
import IntlMessages from "../../../util/IntlMessages";
import {
  addSupplierInProgress,
  editSupplierInProgress,
} from "../../../appRedux/Pharmacy/PharmacyAction";

const AddSupplier = ({ editSupplier, supplierData }) => {
  const orgId = localStorage.getItem("org_id");
  const [form] = Form.useForm();
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const history = useHistory();
  const dispatch = useDispatch();
  const addSupplierData = useSelector((state) => state.pharmacy?.addSupplier);
  const editSupplierData = useSelector((state) => state.pharmacy?.editSupplier);
  const onFinish = (datas) => {
    const formData = {
      organization_id: orgId,
      company_name: datas?.company_name,
      contact_person: datas?.contact_person,
      email: datas?.email,
      mobile: datas?.mobile,
      address: datas?.address,
      city: datas?.city,
      state: datas?.state,
      country: datas?.country,
      zipcode: datas?.zipcode,
      gst_number: datas?.gst_number,
      fax: datas?.fax,
      bank_name: datas?.bank_name,
      branch: datas?.branch,
      account_number: datas?.account_number,
      ifsc_code: datas?.ifsc_code,
      payable_at: datas?.payable_at,
    };
    if (editSupplier) {
      dispatch(editSupplierInProgress({ formData, id: supplierData?.id }));
    } else {
      dispatch(addSupplierInProgress(formData));
    }
  };
  const patientData = useSelector((state) => state.patient);

  const [Loading, setLoading] = useState(false);
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );
  useEffect(() => {
    form.setFieldsValue(supplierData);
  }, [supplierData]);
  useEffect(() => {
    if (addSupplierData?.success || editSupplierData?.success) {
      history.push("/supplier");
    }
    if (addSupplierData?.inProgress || editSupplierData?.inProgress) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [addSupplierData, editSupplierData]);

  return (
    <Spin indicator={antIcon} spinning={Loading}>
      <Card>
        <Form
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          initialValues={{
            remember: true,
          }}
          colon={"true"}
          onFinish={onFinish}
          autoComplete="off"
          form={form}
        >
          <Row className="register-row">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <p className="header-tag">
                {editSupplier ? (
                  <IntlMessages id={"label.editSupplier"} />
                ) : (
                  <IntlMessages id={"label.addSupplier"} />
                )}
              </p>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormInput
                labelCol={"24"}
                wrapperCol={"24"}
                type="text"
                name={"company_name"}
                label={
                  <IntlMessages id={"Addnew.pharmacy.label.companyName"} />
                }
                rules={[
                  {
                    required: true,
                    message: (
                      <IntlMessages
                        id={"Addnew.pharmacy.validator.companyName"}
                      />
                    ),
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormInput
                labelCol={"24"}
                wrapperCol={"24"}
                type="text"
                name={"contact_person"}
                label={
                  <IntlMessages id={"Addnew.pharmacy.label.contactPerson"} />
                }
                rules={[
                  {
                    required: true,
                    message: (
                      <IntlMessages
                        id={"Addnew.pharmacy.validator.contactPerson"}
                      />
                    ),
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormInput
                labelCol={"24"}
                wrapperCol={"24"}
                type="text"
                name={"email"}
                label={<IntlMessages id={"Addnew.pharmacy.label.email"} />}
                rules={[
                  {
                    required: true,
                    message: (
                      <IntlMessages id={"Addnew.pharmacy.validator.email"} />
                    ),
                  },
                  {
                    pattern: emailRegex,
                    message: "Please enter a valid email address",
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormInput
                labelCol={"24"}
                wrapperCol={"24"}
                type="text"
                name={"mobile"}
                label={<IntlMessages id={"Addnew.pharmacy.label.mobile"} />}
                rules={[
                  {
                    required: true,
                    message: (
                      <IntlMessages id={"Addnew.pharmacy.validator.mobile"} />
                    ),
                  },
                  {
                    pattern: /^[6789][0-9]{9}$/,
                    message: "Please enter a valid 10-digit mobile number!",
                  },
                ]}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <p className="subheading">
                    {
                      <IntlMessages
                        id={"Addnew.pharmacy.label.addressSection"}
                      />
                    }
                  </p>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <FormInput
                    labelCol={"24"}
                    wrapperCol={"24"}
                    type="text"
                    name={"address"}
                    label={
                      <IntlMessages id={"Addnew.pharmacy.label.address"} />
                    }
                    rules={[
                      {
                        required: true,
                        message: (
                          <IntlMessages
                            id={"Addnew.pharmacy.validator.address"}
                          />
                        ),
                      },
                    ]}
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <FormInput
                    labelCol={"24"}
                    wrapperCol={"24"}
                    type="text"
                    name={"city"}
                    label={<IntlMessages id={"Addnew.pharmacy.label.city"} />}
                    rules={[
                      {
                        required: true,
                        message: (
                          <IntlMessages id={"Addnew.pharmacy.validator.city"} />
                        ),
                      },
                    ]}
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <FormInput
                    labelCol={"24"}
                    wrapperCol={"24"}
                    type="text"
                    name={"state"}
                    label={<IntlMessages id={"Addnew.pharmacy.label.state"} />}
                    rules={[
                      {
                        required: true,
                        message: (
                          <IntlMessages
                            id={"Addnew.pharmacy.validator.state"}
                          />
                        ),
                      },
                    ]}
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <FormInput
                    labelCol={"24"}
                    wrapperCol={"24"}
                    type="text"
                    name={"country"}
                    label={
                      <IntlMessages id={"Addnew.pharmacy.label.country"} />
                    }
                    rules={[
                      {
                        required: true,
                        message: (
                          <IntlMessages
                            id={"Addnew.pharmacy.validator.country"}
                          />
                        ),
                      },
                    ]}
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <FormInput
                    labelCol={"24"}
                    wrapperCol={"24"}
                    type="text"
                    name={"zipcode"}
                    label={
                      <IntlMessages id={"Addnew.pharmacy.label.zipCode"} />
                    }
                    rules={[
                      {
                        required: true,
                        message: (
                          <IntlMessages
                            id={"Addnew.pharmacy.validator.zipcode"}
                          />
                        ),
                      },
                    ]}
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <FormInput
                    labelCol={"24"}
                    wrapperCol={"24"}
                    type="text"
                    name={"gst_number"}
                    label={
                      <IntlMessages id={"Addnew.pharmacy.label.gstNumber"} />
                    }
                    rules={[
                      {
                        required: true,
                        message: (
                          <IntlMessages
                            id={"Addnew.pharmacy.validator.gstNumber"}
                          />
                        ),
                      },
                    ]}
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <FormInput
                    labelCol={"24"}
                    wrapperCol={"24"}
                    type="text"
                    name={"fax"}
                    label={<IntlMessages id={"Addnew.pharmacy.label.fax"} />}
                    rules={[
                      {
                        required: true,
                        message: (
                          <IntlMessages id={"Addnew.pharmacy.validator.fax"} />
                        ),
                      },
                    ]}
                  />
                </Col>{" "}
              </Row>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <p className="subheading">
                    {
                      <IntlMessages
                        id={"Addnew.pharmacy.label.bankInformation"}
                      />
                    }
                  </p>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <FormInput
                    labelCol={"24"}
                    wrapperCol={"24"}
                    type="text"
                    name={"bank_name"}
                    label={
                      <IntlMessages id={"Addnew.pharmacy.label.bankName"} />
                    }
                    rules={[
                      {
                        required: true,
                        message: (
                          <IntlMessages
                            id={"Addnew.pharmacy.validator.bankName"}
                          />
                        ),
                      },
                    ]}
                  />
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <FormInput
                    labelCol={"24"}
                    wrapperCol={"24"}
                    type="text"
                    name={"account_number"}
                    label={
                      <IntlMessages
                        id={"Addnew.pharmacy.label.accountNumber"}
                      />
                    }
                    rules={[
                      {
                        required: true,
                        message: (
                          <IntlMessages
                            id={"Addnew.pharmacy.validator.accountNumber"}
                          />
                        ),
                      },
                    ]}
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <FormInput
                    labelCol={"24"}
                    wrapperCol={"24"}
                    type="text"
                    name={"branch"}
                    label={<IntlMessages id={"Addnew.pharmacy.label.branch"} />}
                    rules={[
                      {
                        required: true,
                        message: (
                          <IntlMessages
                            id={"Addnew.pharmacy.validator.branch"}
                          />
                        ),
                      },
                    ]}
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <FormInput
                    labelCol={"24"}
                    wrapperCol={"24"}
                    type="text"
                    name={"ifsc_code"}
                    label={
                      <IntlMessages id={"Addnew.pharmacy.label.ifscCode"} />
                    }
                    rules={[
                      {
                        required: true,
                        message: (
                          <IntlMessages
                            id={"Addnew.pharmacy.validator.ifscCode"}
                          />
                        ),
                      },
                    ]}
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <FormInput
                    labelCol={"24"}
                    wrapperCol={"24"}
                    type="text"
                    name={"payable_at"}
                    rules={[
                      {
                        required: true,
                        message: (
                          <IntlMessages
                            id={"Addnew.pharmacy.validator.payableAt"}
                          />
                        ),
                      },
                    ]}
                    label={
                      <IntlMessages id={"Addnew.pharmacy.label.payableAt"} />
                    }
                  />
                  {/* <Form.Item
                      name={"orderDate"}
                      label={<IntlMessages id={"Addnew.pharmacy.label.date"} />}
                      rules={[
                        {
                          type: "object",
                          required: true,
                          message: (
                            <IntlMessages id={"Medicine.validator.add_date"} />
                          ),
                        },
                      ]}
                    >
                      <DatePicker
                        className="calender-responsive"
                        placeholder="Add Date"
                        format={"D/MM/YYYY"}
                        onChange={onChangeDate}
                      />
                    </Form.Item> */}
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="button-row-right" gutter={[8, 4]}>
            <Col>
              <Link to={APP_ROUTES.SUPPLIERS}>
                <ButtonComponent
                  label="Cancel"
                  className="btn-cancel secondary-color"
                />
              </Link>
              <ButtonComponent
                type="primary"
                label={editSupplier ? "Save" : "Submit"}
                htmlType="submit"
              />
            </Col>
          </Row>
        </Form>
      </Card>
    </Spin>
  );
};

export default AddSupplier;
