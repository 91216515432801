import React from "react";

import { Card } from "antd";
import ScheduleHeader from "./components/ScheduleHeader";
import ScheduleTable from "./components/ScheduleTable";

const ScheduleScreen = () => {
  return (
    <Card>
      <ScheduleHeader />
      <ScheduleTable />
    </Card>
  );
};

export default ScheduleScreen;
