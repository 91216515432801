import React, { Fragment } from "react";
import { Tabs } from "antd";
import ButtonComponent from "../../../../components/Button/ButtonComponent";
import FormComponent from "../../../../components/Table/FormComponent";
import { CardData } from "../../data";
import Timeline from "./Timeline";

const { TabPane } = Tabs;
const CalendarHistory = ({ patientname }) => {
  const AppoinmentColumn = [
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "Time Sot",
      dataIndex: "time",
    },
    {
      title: "Doctor",
      dataIndex: "doctorname",
    },

    {
      title: "",
      dataIndex: "",
      render: () => (
        <ButtonComponent type="primary" label="Edit"></ButtonComponent>
      ),
    },
  ];
  const CaseHistoryColumn = [
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "Title",
      dataIndex: "title",
    },
    {
      title: "Description",
      dataIndex: "discription",
    },
  ];
  const PresciptionColumn = [
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "Doctor",
      dataIndex: "doctor",
    },
    {
      title: "Medicine",
      dataIndex: "medicine",
    },
    {
      title: "Options",
      dataIndex: "options",
    },
  ];
  const LabColumn = [
    {
      title: "Id",
      dataIndex: "id",
    },
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "Doctor",
      dataIndex: "doctor",
    },

    {
      title: "Options",
      dataIndex: "options",
    },
  ];
  const BedColumn = [
    {
      title: "Bed Id",
      dataIndex: "bedid",
    },
    {
      title: "Alloted Time",
      dataIndex: "allotedtime",
    },
    {
      title: "Discharge Time",
      dataIndex: "dischargetime",
    },
  ];
  return (
    <Fragment>
      <h1> History | {patientname}</h1>

      <Tabs tabPosition={"top"}>
        <TabPane tab="Appoinments" key="1">
          <FormComponent
            columns={AppoinmentColumn}
            dataSource={CardData}
            size="small"
          />
        </TabPane>
        <TabPane tab="Case History" key="2">
          <FormComponent columns={CaseHistoryColumn} />
        </TabPane>
        <TabPane tab="Prescription" key="3">
          <FormComponent columns={PresciptionColumn} />
        </TabPane>
        <TabPane tab="Lab" key="4">
          <FormComponent columns={LabColumn} />
        </TabPane>
        <TabPane tab="Documents" key="5">
          Documents
        </TabPane>
        <TabPane tab="Bed" key="6">
          <FormComponent columns={BedColumn} />
        </TabPane>
        <TabPane tab="Time Line" key="8">
          <Timeline />
        </TabPane>
      </Tabs>
    </Fragment>
  );
};

export default CalendarHistory;
