import React, { useEffect, useRef, useState } from "react";
import { Steps, Form } from "antd";
import "../../styles/modules/appoinment.less";
import Card from "antd/lib/card/Card";
import { Link } from "react-router-dom";
import ButtonComponent from "../../components/Button/ButtonComponent";
import { APP_ROUTES } from "../../constants/Routes";
import FinalInfo from "./components/FinalInfo";
import AppoinmentType from "./components/AppoinmentType";
import TimeSlot from "./components/TimeSlot";
const { Step } = Steps;
const steps = [
  {
    title: "1.Appoinment Type",
    content: "Appoinment Type",
  },
  {
    title: "2. Time Slot",
    content: "Time Slot",
  },
  {
    title: "3.Final Info",
    content: "Final Info",
  },
];
const Telemedicine = () => {
  const [current, setCurrent] = useState(0);
  const [Buttonlabel, setButtonlabel] = useState("NEXT");
  const currentValue = useRef();
  const [form] = Form.useForm();
  const prev = () => {
    setCurrent(current - 1);
  };

  const AppoinmentStatus = ({ form }) => {
    switch (current) {
      case 0:
        return <AppoinmentType />;
      case 1:
        return <TimeSlot form={form} />;
      case 2:
        return <FinalInfo />;

      default:
        return <AppoinmentType />;
    }
  };

  useEffect(() => {
    currentValue.current = current;
  }, [current]);
  const onFinish = (datas) => {
    setCurrent(current + 1);
    console.log("DATA", datas);
  };
  return (
    <Card>
      <Form
        labelAlign="right"
        layout="horizontal"
        onFinish={onFinish}
        form={form}
      >
        <Steps current={current} className="parent-step">
          {steps.map((item) => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
        <div>
          <AppoinmentStatus status={steps[current].content} form={form} />
        </div>
        <div className="steps-action">
          {/* {current < steps.length - 1 ? (
            <ButtonComponent
              type="primary"
              label={Buttonlabel}
              htmlType="submit"
            />
          ) : current === steps.length - 1 ? (
            <Link to={APP_ROUTES.SYMPTOMS} className="done-button">
              <ButtonComponent type="primary" label="Done" htmlType="submit" />
            </Link>
          ) : current > 0 ? (
            <ButtonComponent
              type="primary"
              label="Previous"
              onClick={() => prev()}
            />
          ) : (
            ""
          )} */}
          {current < steps.length - 1 ? (
            <ButtonComponent type="primary" label={"NEXT"} htmlType="submit" />
          ) : (
            <Link to={APP_ROUTES.SYMPTOMS} className="done-button">
              <ButtonComponent type="primary" label="Done" htmlType="submit" />
            </Link>
          )}

          {current > 0 && (
            <ButtonComponent
              type="primary"
              label="Previous"
              onClick={() => prev()}
            />
          )}
        </div>
      </Form>
    </Card>
  );
};

export default Telemedicine;
