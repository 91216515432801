import React, { useState } from "react";
import { Col, Row, Divider, Button, Modal, Typography } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { APP_ROUTES } from "../../../../constants/Routes";
import "../../../../styles/modules/patient.less";
import IntlMessages from "../../../../util/IntlMessages";
import ButtonComponent from "../../../../components/Button/ButtonComponent";
import { access } from "../../../../util/utilz";

const PurchaseReturnHeader = () => {
  return (
    <div>
      <Row className="p-10 Add-button">
        <Col>
          <Typography className="header-tag">
            {<IntlMessages id={"label.purchasereturn"} />}
          </Typography>
        </Col>
        <Col>
          {access("purchaseReturn", "add") && (
            <Link to={APP_ROUTES.ADDPURCHASERETURN}>
              <ButtonComponent
                type="primary"
                label={<IntlMessages id={"Addnew.patient.addnew"} />}
                icon={<PlusCircleOutlined />}
              />
            </Link>
          )}
        </Col>
      </Row>

      <Divider />
    </div>
  );
};

export default PurchaseReturnHeader;
