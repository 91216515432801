import { Card, Col, Divider, Row } from "antd";
import React from "react";

import "../../styles/modules/doctor.less";
import Auxiliary from "../../util/Auxiliary";
import IntlMessages from "../../util/IntlMessages";
import DoctorForm from "./component/DoctorForm";

const DoctorAddNewScreen = () => {
  return (
    <Auxiliary>
      <Card>
        <Row>
          <Col span={24}>
            <h1 className="text">
              {<IntlMessages id={"Addnew.doctor.label.adddoctor"} />}
            </h1>
          </Col>
          <Divider />
        </Row>
        <DoctorForm />
      </Card>
    </Auxiliary>
  );
};

export default DoctorAddNewScreen;
