import React, { useEffect, useState } from "react";
import { Modal, Form, Button, Col, Spin, Row } from "antd";
import { useDispatch } from "react-redux";
import FormInput from "../../components/Input/FormInput";
import { useSelector } from "react-redux";
import IntlMessages from "../../util/IntlMessages";
import { appointmentStatusUpdateInProgress } from "../../appRedux/doctor/actions/Appointment";

const CompletedModel = ({ isVisible, onCancel, onSubmit, currentData }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { appointmentStatus } = useSelector((state) => state.appointment);

  const [navigate, setNavigate] = useState(false);
  useEffect(() => {
    if (appointmentStatus?.success && navigate) {
      setNavigate(false);
      onSubmit();
    }
  }, [appointmentStatus?.success]);
  const handleFinish = (data) => {
    data.appointment_status = 4;
    const payload = {
      id: currentData?.id,
      data,
    };
    dispatch(appointmentStatusUpdateInProgress(payload));
    setNavigate(true);
  };

  return (
    <Modal
      title="Complete The Appointment"
      visible={isVisible}
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" htmlType="submit" form="refer-form">
          Submit
        </Button>,
      ]}
      className={isVisible ? "slide-down" : ""}
    >
      <Spin spinning={appointmentStatus?.progressing}>
        <Form form={form} onFinish={handleFinish} id="refer-form">
          <Row>
            <Col xs={24}>
              <FormInput
                labelCol={"24"}
                wrapperCol={"24"}
                type="text"
                placeholder="Instruction"
                name="reason"
                label={<IntlMessages id={"Instruction"} />}
                rules={[
                  {
                    required: true,
                    message: (
                      <IntlMessages id={"Please Enter the Instruction"} />
                    ),
                  },
                ]}
              />
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
};

export default CompletedModel;
