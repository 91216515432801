import React from "react";
import { Col, Row, Divider } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import "../../../styles/modules/patient.less";
import "../../../styles/modules/patient.less";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import IntlMessages from "../../../util/IntlMessages";
import { access } from "../../../util/utilz";

const ScheduleHeader = () => {
  return (
    <div>
      <Row className="p-10 Add-button">
        <Col>
          <p className="header-tag">
            {<IntlMessages id={"Addnew.schedule"} />}
          </p>
        </Col>
        <Col>
          {access("schedule", "add") && (
            <Link to={`/schedule/add`}>
              <ButtonComponent
                type="primary"
                label={<IntlMessages id={"Addnew.schedule.addnew"} />}
                icon={<PlusCircleOutlined />}
              />
            </Link>
          )}
        </Col>
      </Row>

      <Divider />
    </div>
  );
};

export default ScheduleHeader;
