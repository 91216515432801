import {
  FORGET_PASSWORD_FAILURE,
  FORGET_PASSWORD_IN_PROGRESS,
  FORGET_PASSWORD_SUCCESS,
  GET_DESIGNATION_LIST_FAILURE,
  GET_DESIGNATION_LIST_IN_PROGRESS,
  GET_DESIGNATION_LIST_SUCCESS,
  GET_ORG_LIST_FAILURE,
  GET_ORG_LIST_IN_PROGRESS,
  GET_ORG_LIST_SUCCESS,
  GET_SPECIALIZATION_LIST_FAILURE,
  GET_SPECIALIZATION_LIST_IN_PROGRESS,
  GET_SPECIALIZATION_LIST_SUCCESS,
  OTP_SEND_FAILURE,
  OTP_SEND_IN_PROGRESS,
  OTP_SEND_SUCCESS,
  SET_FORGET_SUCCESS,
  SET_OTP,
  SET_VERIFICATION_OTP,
  VERIFY_OTP_FAILURE,
  VERIFY_OTP_IN_PROGRESS,
  VERIFY_OTP_SUCCESS,
} from "../../../constants/ActionTypes";

const defaultStatus = {
  inProgress: false,
  success: false,
  error: false,
  loader: false,
  data: null,
};
const initialState = {
  otpsend: { ...defaultStatus },
  verifyotp: { ...defaultStatus },
  forgetpassword: { ...defaultStatus },
  orgList: { ...defaultStatus },
  speccializationList: { ...defaultStatus },
  designationList: { ...defaultStatus },
};

const RegisterReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case OTP_SEND_IN_PROGRESS:
      return {
        ...state,
        otpsend: {
          ...defaultStatus,
          ...payload,
          inProgress: true,
          loader: false,
        },
      };
    case OTP_SEND_SUCCESS:
      return {
        ...state,
        otpsend: { ...defaultStatus, ...payload, success: true, loader: false },
      };
    case OTP_SEND_FAILURE:
      return {
        ...state,
        otpsend: {
          ...defaultStatus,
          ...payload,
          error: true,
          loader: false,
        },
      };
    case VERIFY_OTP_IN_PROGRESS:
      return {
        ...state,
        verifyotp: { ...defaultStatus, ...payload, inProgress: true },
      };
    case VERIFY_OTP_SUCCESS:
      return {
        ...state,
        verifyotp: { ...defaultStatus, ...payload, success: true },
      };
    case VERIFY_OTP_FAILURE:
      return {
        ...state,
        verifyotp: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case FORGET_PASSWORD_IN_PROGRESS:
      return {
        ...state,
        forgetpassword: { ...defaultStatus, ...payload, inProgress: true },
      };
    case FORGET_PASSWORD_SUCCESS:
      return {
        ...state,
        forgetpassword: { ...defaultStatus, ...payload, success: true },
      };
    case FORGET_PASSWORD_FAILURE:
      return {
        ...state,
        forgetpassword: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };

    case SET_OTP:
      return {
        ...state,
        otpsend: { ...defaultStatus, success: false },
      };
    case SET_VERIFICATION_OTP:
      return {
        ...state,
        verifyotp: { ...defaultStatus, success: false },
      };
    case SET_FORGET_SUCCESS:
      return {
        ...state,
        forgetpassword: { ...defaultStatus, success: false },
      };
    case GET_ORG_LIST_IN_PROGRESS:
      return {
        ...state,
        orgList: { ...defaultStatus, inProgress: true },
      };
    case GET_ORG_LIST_SUCCESS:
      return {
        ...state,
        orgList: { ...defaultStatus, ...payload, success: true },
      };
    case GET_ORG_LIST_FAILURE:
      return {
        ...state,
        orgList: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case GET_SPECIALIZATION_LIST_IN_PROGRESS:
      return {
        ...state,
        specializationList: { ...defaultStatus, inProgress: true },
      };
    case GET_SPECIALIZATION_LIST_SUCCESS:
      return {
        ...state,
        specializationList: { ...defaultStatus, ...payload, success: true },
      };
    case GET_SPECIALIZATION_LIST_FAILURE:
      return {
        ...state,
        specializationList: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    case GET_DESIGNATION_LIST_IN_PROGRESS:
      return {
        ...state,
        designationList: { ...defaultStatus, inProgress: true },
      };
    case GET_DESIGNATION_LIST_SUCCESS:
      return {
        ...state,
        designationList: { ...defaultStatus, ...payload, success: true },
      };
    case GET_DESIGNATION_LIST_FAILURE:
      return {
        ...state,
        designationList: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    default:
      return state;
  }
};

export default RegisterReducer;
