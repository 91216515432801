import {
  ADD_DESIGNATION_FAILURE,
  ADD_DESIGNATION_IN_PROGRESS,
  ADD_DESIGNATION_SUCCESS,
  DELETE_DESIGNATION_FAILURE,
  DELETE_DESIGNATION_IN_PROGRESS,
  DELETE_DESIGNATION_SUCCESS,
  GETDATA_DESIGNATION_FAILURE,
  GETDATA_DESIGNATION_IN_PROGRESS,
  GETDATA_DESIGNATION_SUCCESS,
  GET_DESIGNATION_FAILURE,
  GET_DESIGNATION_IN_PROGRESS,
  GET_DESIGNATION_SUCCESS,
  UPDATE_DESIGNATION_FAILURE,
  UPDATE_DESIGNATION_IN_PROGRESS,
  UPDATE_DESIGNATION_SUCCESS,
} from "../../../constants/ActionTypes";

export const getDesignationInProgress = (payload) => {
  return {
    type: GET_DESIGNATION_IN_PROGRESS,
    payload: payload,
  };
};
export const getDesignationSuccess = (payload) => {
  return {
    type: GET_DESIGNATION_SUCCESS,
    payload: payload,
  };
};
export const getDesignationError = () => {
  return {
    type: GET_DESIGNATION_FAILURE,
  };
};
export const addDesignationInProgress = (payload) => {
  return {
    type: ADD_DESIGNATION_IN_PROGRESS,
    payload: payload,
  };
};
export const addDesignationSuccess = (payload) => {
  return {
    type: ADD_DESIGNATION_SUCCESS,
    payload: payload,
  };
};
export const addDesignationError = () => {
  return {
    type: ADD_DESIGNATION_FAILURE,
  };
};
export const deleteDesignationInProgress = (payload) => {
  return {
    type: DELETE_DESIGNATION_IN_PROGRESS,
    payload: payload,
  };
};
export const deleteDesignationSuccess = (payload) => {
  return {
    type: DELETE_DESIGNATION_SUCCESS,
    payload: payload,
  };
};
export const deleteDesignationError = () => {
  return {
    type: DELETE_DESIGNATION_FAILURE,
  };
};
export const getDataDesignationInProgress = (payload) => {
  return {
    type: GETDATA_DESIGNATION_IN_PROGRESS,
    payload: payload,
  };
};
export const getDataDesignationSuccess = (payload) => {
  return {
    type: GETDATA_DESIGNATION_SUCCESS,
    payload: payload,
  };
};
export const getDataDesignationError = () => {
  return {
    type: GETDATA_DESIGNATION_FAILURE,
  };
};
export const updateDesignationInProgress = (payload) => {
  return {
    type: UPDATE_DESIGNATION_IN_PROGRESS,
    payload: payload,
  };
};
export const updateDesignationSuccess = (payload) => {
  return {
    type: UPDATE_DESIGNATION_SUCCESS,
    payload: payload,
  };
};
export const updateDesignationError = () => {
  return {
    type: UPDATE_DESIGNATION_FAILURE,
  };
};
