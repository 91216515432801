import React from "react";
import { Row, Col, Card, Progress } from "antd";
import {
  SmileOutlined,
  ScissorOutlined,
  UserAddOutlined,
  DollarCircleOutlined,
} from "@ant-design/icons";
import "./Dashboard.less";

const DashboardHeaderSection = () => {
  return (
    <div className="dashboard">
      <Row gutter={16}>
        <Col span={6}>
          <CardDashboard
            icon={<SmileOutlined />}
            color="#A066FF" // Purple for the icon and progress bar
            title="Appointments"
            value="650"
          />
        </Col>
        <Col span={6}>
          <CardDashboard
            icon={<DollarCircleOutlined />}
            color="#47B3FF"
            title="Service"
            value="$20,125"
          />
        </Col>
        <Col span={6}>
          <CardDashboard
            icon={<UserAddOutlined />}
            color="#44C27F" // Green for the icon and progress bar
            title="New Patients"
            value="129"
          />
        </Col>
        <Col span={6}>
          <CardDashboard
            icon={<DollarCircleOutlined />}
            color="#47B3FF" // Blue for the icon and progress bar
            title="Earnings"
            value="$20,125"
          />
        </Col>{" "}
      </Row>
    </div>
  );
};

const CardDashboard = ({ icon, color, title, value }) => {
  // Set a fixed progress percentage as an example (can be dynamically set)
  const progressPercentage = Math.min(
    parseInt(value.replace(/[^\d]/g, "")) % 100,
    100
  );

  return (
    <Card className="card">
      <div className="card-header">
        <div className="icon-s" style={{ color: color }}>
          {icon}
        </div>
        <div className="content">
          <h4>{title}</h4>
          <h3>{value}</h3>
        </div>
      </div>
      <div className="progress-container">
        <Progress
          percent={progressPercentage}
          strokeColor={color} // The color for the progress bar matches the icon color
          strokeWidth={8}
          showInfo={false} // Hide the percentage text
          status="active"
        />
      </div>
    </Card>
  );
};

export default DashboardHeaderSection;
