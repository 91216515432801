import {
  ADD_TEAM_MEMBER_IN_ERROR,
  ADD_TEAM_MEMBER_IN_PROGRESS,
  ADD_TEAM_MEMBER_IN_SUCCESS,
  DELETE_TEAM_MEMBER_IN_ERROR,
  DELETE_TEAM_MEMBER_IN_PROGRESS,
  DELETE_TEAM_MEMBER_IN_SUCCESS,
  GET_TEAM_MEMBER_IN_ERROR,
  GET_TEAM_MEMBER_IN_PROGRESS,
  GET_TEAM_MEMBER_IN_SUCCESS,
  UPDATE_TEAM_MEMBER_IN_ERROR,
  UPDATE_TEAM_MEMBER_IN_PROGRESS,
  UPDATE_TEAM_MEMBER_IN_SUCCESS,
} from "../../../../constants/ActionTypes";

const defaultStatus = {
  progressing: false,
  success: false,
  error: false,
  data: [],
};
const initialState = {
  teamMemberList: { ...defaultStatus },
  addTeamMember: { ...defaultStatus },

  updateTeamMember: { ...defaultStatus },
  deleteTeamMember: { ...defaultStatus },
};
const teamMemberReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    //teamMemberList
    case GET_TEAM_MEMBER_IN_PROGRESS:
      return {
        ...state,
        teamMemberList: { ...defaultStatus, ...payload, progressing: true },
      };
    case GET_TEAM_MEMBER_IN_SUCCESS:
      return {
        ...state,
        teamMemberList: { ...defaultStatus, ...payload, success: true },
      };
    case GET_TEAM_MEMBER_IN_ERROR:
      return {
        ...state,
        teamMemberList: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };
    //addTeamMember
    case ADD_TEAM_MEMBER_IN_PROGRESS:
      return {
        ...state,
        addTeamMember: { ...defaultStatus, ...payload, progressing: true },
      };
    case ADD_TEAM_MEMBER_IN_SUCCESS:
      return {
        ...state,
        addTeamMember: { ...defaultStatus, ...payload, success: true },
      };
    case ADD_TEAM_MEMBER_IN_ERROR:
      return {
        ...state,
        addTeamMember: { ...defaultStatus, ...payload, error: true },
      };

    //updateTeamMember
    case UPDATE_TEAM_MEMBER_IN_PROGRESS:
      return {
        ...state,
        updateTeamMember: { ...defaultStatus, ...payload, progressing: true },
      };
    case UPDATE_TEAM_MEMBER_IN_SUCCESS:
      return {
        ...state,
        updateTeamMember: { ...defaultStatus, ...payload, success: true },
      };
    case UPDATE_TEAM_MEMBER_IN_ERROR:
      return {
        ...state,
        updateTeamMember: { ...defaultStatus, ...payload, error: true },
      };
    //deleteTeamMember
    case DELETE_TEAM_MEMBER_IN_PROGRESS:
      return {
        ...state,
        deleteTeamMember: { ...defaultStatus, ...payload, progressing: true },
      };
    case DELETE_TEAM_MEMBER_IN_SUCCESS:
      return {
        ...state,
        deleteTeamMember: { ...defaultStatus, ...payload, success: true },
      };
    case DELETE_TEAM_MEMBER_IN_ERROR:
      return {
        ...state,
        deleteTeamMember: { ...defaultStatus, ...payload, error: true },
      };
    default:
      return state;
  }
};

export default teamMemberReducer;
