import { Card, Col, Divider, Row } from 'antd'
import React from 'react'
import Auxiliary from '../../util/Auxiliary'
import IntlMessages from '../../util/IntlMessages'
import EditPatient from './components/EditPatient'

function PatientEditScreen() {
  return (
    <Auxiliary>
      <Card>
        <Row className="p-10 Add-button">
          <Col span={24}>
            {/* <h1 className="text"> */}
            <p className="header-tag">{<IntlMessages id={"Addnew.patient.label.editPatient"} />}</p>  
            {/* </h1> */}
          </Col>
          <Divider />
        </Row>
        <EditPatient />
      </Card>
    </Auxiliary>
  )
}

export default PatientEditScreen