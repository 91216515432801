import React from "react";

import { Card } from "antd";
import HolidaysTable from "./Components/HolidaysTable";
import HolidaysHeader from "./Components/HolidaysHeader";

const HolidaysScreen = () => {
  return (
    <Card>
      <HolidaysHeader />
      <HolidaysTable />
    </Card>
  );
};

export default HolidaysScreen;
