import React from "react";
import { Card } from "antd";
import PatientTable from "./components/PatientTable/PatientTable";
import PatientHeader from "./components/PatientHeader/PatientHeader";

const PatientScreen = () => {
  return (
    <Card>
      <PatientHeader />
      <PatientTable />
    </Card>
  );
};

export default PatientScreen;
