import React, { useEffect, useState } from "react";
import { Modal, Form, Button, Col, Select, Spin, Row } from "antd";
import { useDispatch } from "react-redux";
import FormSelectBox from "../../components/SelectBox/SelectBox";
import FormInput from "../../components/Input/FormInput";
import { useSelector } from "react-redux";
import { categoriesPlaceHolder, categoriesType } from "./data";
import CategoriesSelect from "./CategoriesSelect";
import IntlMessages from "../../util/IntlMessages";
import SpecialistSelects from "./SpecialistSelects";
import { addTrackerProgress } from "../../appRedux/doctor/actions/Trackers";

const ReferModel = ({ isVisible, onCancel, onSubmit, currentData }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [loadingModel, setLoadingModel] = React.useState(false);
  const [type, setType] = useState();
  const [specialistID, setSpecialistID] = useState();
  const { addTracker } = useSelector((state) => state?.tracker);
  const [navigate, setNavigate] = useState(false);
  useEffect(() => {
    form.setFieldValue("specialization_id");
    form.setFieldValue("subpackagescategory_id");
    form.setFieldValue("type");
    form.setFieldValue("reason");
    setType();
    setSpecialistID();
  }, [currentData]);

  useEffect(() => {
    if (addTracker?.success && navigate) {
      setNavigate(false);
      onSubmit();
    }
  }, [addTracker?.success]);
  const handleFinish = (data) => {
    const payload = {
      patient_id: currentData?.patient_id,
      doctor_id: currentData?.doctor_id,
      appointment_id: currentData?.id,
      organization_id: currentData?.organization_id,
      type: data?.type,
      specialization_id: data?.specialization_id
        ? data?.specialization_id
        : null,
      references_doctor_id: data?.references_doctor_id
        ? data?.references_doctor_id
        : null,
      subpackagescategory_id: data?.subpackagescategory_id
        ? data?.subpackagescategory_id
        : null,
      reason: data?.reason,
    };
    dispatch(addTrackerProgress(payload));
    setNavigate(true);
  };
  const handlePage = (data) => {
    form.setFieldValue("references_doctor_id");
    if (type === 3) {
      setSpecialistID(data?.value);
    }
  };
  const handleSpecialist = () => {};
  return (
    <Modal
      title="REFER TO"
      visible={isVisible}
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" htmlType="submit" form="refer-form">
          Submit
        </Button>,
      ]}
      className={isVisible ? "slide-down" : ""}
    >
      <Spin spinning={loadingModel || addTracker?.inProgress}>
        <Form form={form} onFinish={handleFinish} id="refer-form">
          <Row>
            {" "}
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="type"
                rules={[
                  {
                    required: true,
                    message: "Please select the type!",
                  },
                ]}
                label={"Categories"}
                labelCol={{
                  span: 24,
                }}
                wrapperCol={{
                  span: 24,
                }}
                className={"mb-10"}
              >
                <FormSelectBox
                  options={categoriesType}
                  placeholder="Select"
                  onChange={(val) => {
                    form.setFieldValue("specialization_id");
                    form.setFieldValue("subpackagescategory_id");
                    setType(Number(val));
                    setSpecialistID();
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              {type && (
                <CategoriesSelect
                  margin="mb-10"
                  label={
                    categoriesType.find((data) => data.value === type).label
                  }
                  name={
                    type === 3 ? "specialization_id" : "subpackagescategory_id"
                  }
                  placeholder={categoriesPlaceHolder[type]}
                  editMode={true}
                  rules={[
                    {
                      required: true,
                      message: (
                        <IntlMessages id={categoriesPlaceHolder[type]} />
                      ),
                    },
                  ]}
                  onChange={handlePage}
                  type={type}
                  setLoadingModel={setLoadingModel}
                />
              )}
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              {specialistID && (
                <SpecialistSelects
                  margin="mb-10"
                  label={"Doctor"}
                  name={"references_doctor_id"}
                  placeholder={"Select the Doctor"}
                  editMode={true}
                  rules={[
                    {
                      required: true,
                      message: <IntlMessages id={"Select the Doctor"} />,
                    },
                  ]}
                  onChange={handleSpecialist}
                  specialization_id={specialistID}
                  setLoadingModel={setLoadingModel}
                />
              )}
            </Col>
            <Col xs={24}>
              <FormInput
                labelCol={"24"}
                wrapperCol={"24"}
                type="text"
                placeholder="Reason For Visit"
                name="reason"
                label={<IntlMessages id={"Reason For Visit"} />}
                rules={[
                  {
                    required: true,
                    message: <IntlMessages id={"Please Enter the Reason"} />,
                  },
                ]}
              />
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
};

export default ReferModel;
