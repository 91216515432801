import {
  ADD_DISEASES_ERROR,
  ADD_DISEASES_PROGRESS,
  ADD_DISEASES_SUCCESS,
  ADD_QUESTIONS_ANSWER_ERROR,
  ADD_QUESTIONS_ANSWER_PROGRESS,
  ADD_QUESTIONS_ANSWER_SUCCESS,
  DELETE_DISEASES_ERROR,
  DELETE_DISEASES_PROGRESS,
  DELETE_DISEASES_SUCCESS,
  DELETE_QUESTIONS_ANSWER_ERROR,
  DELETE_QUESTIONS_ANSWER_PROGRESS,
  DELETE_QUESTIONS_ANSWER_SUCCESS,
  GET_DISEASES_LIST_ERROR,
  GET_DISEASES_LIST_PROGRESS,
  GET_DISEASES_LIST_SUCCESS,
  GET_QUESTIONS_ANSWER_BY_DISEASES_LIST_ERROR,
  GET_QUESTIONS_ANSWER_BY_DISEASES_LIST_PROGRESS,
  GET_QUESTIONS_ANSWER_BY_DISEASES_LIST_SUCCESS,
  GET_QUESTIONS_ANSWER_LIST_ERROR,
  GET_QUESTIONS_ANSWER_LIST_PROGRESS,
  GET_QUESTIONS_ANSWER_LIST_SUCCESS,
  UPDATE_DISEASES_ERROR,
  UPDATE_DISEASES_PROGRESS,
  UPDATE_DISEASES_SUCCESS,
  UPDATE_QUESTIONS_ANSWER_ERROR,
  UPDATE_QUESTIONS_ANSWER_PROGRESS,
  UPDATE_QUESTIONS_ANSWER_SUCCESS,
} from "../../../constants/ActionTypes";

const defaultStatus = {
  progressing: false,
  success: false,
  error: false,
  data: [],
};
const initialState = {
  addDiseases: { ...defaultStatus },
  getListDiseases: { ...defaultStatus },
  updateDiseases: { ...defaultStatus },
  deleteDiseases: { ...defaultStatus },
  addQuestionAnswer: { ...defaultStatus },
  getListQuestionAnswer: { ...defaultStatus },
  updateQuestionAnswer: { ...defaultStatus },
  deleteQuestionAnswer: { ...defaultStatus },
  getListQuestionAnswerByDiseases: { ...defaultStatus },
};

const appointmentQuestionsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ADD_DISEASES_PROGRESS:
      return {
        ...state,
        addDiseases: {
          ...defaultStatus,
          ...payload,
          progressing: true,
        },
      };
    case ADD_DISEASES_SUCCESS:
      return {
        ...state,
        addDiseases: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case ADD_DISEASES_ERROR:
      return {
        ...state,
        addDiseases: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };

    case GET_DISEASES_LIST_PROGRESS:
      return {
        ...state,
        getListDiseases: {
          ...defaultStatus,
          ...payload,
          progressing: true,
        },
      };
    case GET_DISEASES_LIST_SUCCESS:
      return {
        ...state,
        getListDiseases: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case GET_DISEASES_LIST_ERROR:
      return {
        ...state,
        getListDiseases: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };

    case UPDATE_DISEASES_PROGRESS:
      return {
        ...state,
        updateDiseases: {
          ...defaultStatus,
          ...payload,
          progressing: true,
        },
      };
    case UPDATE_DISEASES_SUCCESS:
      return {
        ...state,
        updateDiseases: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case UPDATE_DISEASES_ERROR:
      return {
        ...state,
        updateDiseases: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };

    case DELETE_DISEASES_PROGRESS:
      return {
        ...state,
        deleteDiseases: {
          ...defaultStatus,
          ...payload,
          progressing: true,
        },
      };
    case DELETE_DISEASES_SUCCESS:
      return {
        ...state,
        deleteDiseases: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case DELETE_DISEASES_ERROR:
      return {
        ...state,
        deleteDiseases: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };

    case ADD_QUESTIONS_ANSWER_PROGRESS:
      return {
        ...state,
        addQuestionAnswer: {
          ...defaultStatus,
          ...payload,
          progressing: true,
        },
      };
    case ADD_QUESTIONS_ANSWER_SUCCESS:
      return {
        ...state,
        addQuestionAnswer: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case ADD_QUESTIONS_ANSWER_ERROR:
      return {
        ...state,
        addQuestionAnswer: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };

    case GET_QUESTIONS_ANSWER_LIST_PROGRESS:
      return {
        ...state,
        getListQuestionAnswer: {
          ...defaultStatus,
          ...payload,
          progressing: true,
        },
      };
    case GET_QUESTIONS_ANSWER_LIST_SUCCESS:
      return {
        ...state,
        getListQuestionAnswer: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case GET_QUESTIONS_ANSWER_LIST_ERROR:
      return {
        ...state,
        getListQuestionAnswer: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };

    case UPDATE_QUESTIONS_ANSWER_PROGRESS:
      return {
        ...state,
        updateQuestionAnswer: {
          ...defaultStatus,
          ...payload,
          progressing: true,
        },
      };
    case UPDATE_QUESTIONS_ANSWER_SUCCESS:
      return {
        ...state,
        updateQuestionAnswer: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case UPDATE_QUESTIONS_ANSWER_ERROR:
      return {
        ...state,
        updateQuestionAnswer: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };

    case DELETE_QUESTIONS_ANSWER_PROGRESS:
      return {
        ...state,
        deleteQuestionAnswer: {
          ...defaultStatus,
          ...payload,
          progressing: true,
        },
      };
    case DELETE_QUESTIONS_ANSWER_SUCCESS:
      return {
        ...state,
        deleteQuestionAnswer: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case DELETE_QUESTIONS_ANSWER_ERROR:
      return {
        ...state,
        deleteQuestionAnswer: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };

    case GET_QUESTIONS_ANSWER_BY_DISEASES_LIST_PROGRESS:
      return {
        ...state,
        getListQuestionAnswerByDiseases: {
          ...defaultStatus,
          ...payload,
          progressing: true,
        },
      };
    case GET_QUESTIONS_ANSWER_BY_DISEASES_LIST_SUCCESS:
      return {
        ...state,
        getListQuestionAnswerByDiseases: {
          ...defaultStatus,
          ...payload,
          success: true,
        },
      };
    case GET_QUESTIONS_ANSWER_BY_DISEASES_LIST_ERROR:
      return {
        ...state,
        getListQuestionAnswerByDiseases: {
          ...defaultStatus,
          ...payload,
          error: true,
        },
      };

    default:
      return state;
  }
};

export default appointmentQuestionsReducer;
