import React, { useEffect, useState } from "react";
import { Form, Col, Row, Divider, Radio, Spin, Typography, Button } from "antd";

import "../../styles/modules/appoinment.less";
import Card from "antd/lib/card/Card";

import ButtonComponent from "../../components/Button/ButtonComponent";
import { useHistory } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import {
  doctorConsultationFeeInProgress,
  getScheduleInDefault,
  getScheduleInProgress,
  patientAddAppointmentInProgress,
} from "../../appRedux/Patient/Action/Action";
import FormInput from "../../components/Input/FormInput";
import IntlMessages from "../../util/IntlMessages";
import DoctorSelectAppointment from "./DoctorSelectBox";

import SelectorsAppointment from "./Selectors";

import { LoadingOutlined, MessageOutlined } from "@ant-design/icons";

import SelectBoxAppointment from "./SelectBox";
import moment from "moment";
import SelectFamilyMember from "../FamilyMember/SelectFamilyMember";
import { upperCase } from "lodash";
import { getDiseaseListInProgress } from "../../appRedux/doctor/actions/appointmentQuestions";
import Modal from "antd/lib/modal/Modal";

import { useLocation } from "react-router-dom";
import AppointmentQuestionsAnswerModel from "./AppointmentQuestionsAnswerModel";
import {
  getReasonListInprogress,
  getSymptomListInprogress,
} from "../../appRedux/admin/Doctor/actions/Doctors";

const AddNewAppoinment = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [date, setDate] = useState("");
  const [slot, setSlot] = useState();
  const [reason, setReason] = useState();
  const [symptom, setSymptom] = useState();
  const [reasonId, setOtherReasonId] = useState();
  const [reasonName, setReasonName] = useState(false);
  const [symptomName, setsymptomName] = useState(false);
  const [symptomId, setSymptomId] = useState();
  const [doctorId, setDoctorId] = useState("");
  const [patientId, setPatientId] = useState("");
  const [availableDays, setAvailableDays] = useState([]);
  const [addDiseaseModal, setAddDiseaseModal] = useState(false);
  const [doctorList, setDoctorList] = useState([]);
  const [navigate, setNavigate] = useState(false);
  const patient_id = localStorage.getItem("patient_id");
  const doctor_id = localStorage.getItem("doctor_id");
  const organization_id = localStorage.getItem("org_id");
  const [familyMemberId, setFamilyMemberId] = useState();

  useEffect(() => {
    const payLoad = {
      pagination: false,
      page: 1,
    };
    dispatch(getDiseaseListInProgress(payLoad));
  }, []);

  useEffect(() => {
    if (patient_id != "null") {
      setPatientId(patient_id);
      form.setFieldValue("patient_id", patient_id);
    }
  }, [patient_id]);
  useEffect(() => {
    if (doctor_id != "null") {
      setDoctorId(doctor_id);
      form.setFieldValue("doctor_id", doctor_id);
    }
  }, [doctor_id]);
  const { addAppointmentList } = useSelector(
    (state) => state.patientAppointment
  );
  const { consultationFee } = useSelector((state) => state.patientAppointment);

  const { reasonList, symptomList } = useSelector((state) => state.doctor);
  console.log("reasonListreasonList", reasonList);
  const infee =
    consultationFee?.data?.data?.inperson_consultation_fee.toString();
  const telefee = consultationFee?.data?.data?.tele_consultation_fee.toString();
  const { getSchedule } = useSelector((state) => state.patientAppointment);
  const reasonListData = reasonList?.data;
  const symptomListData = symptomList?.data;
  const slotList = getSchedule?.data?.data || [];
  useEffect(() => {
    setSlot();
  }, [slotList]);
  console.log("getValue", form.getFieldValue("reason"));
  const onFinish = (datas) => {
    const formValues = {
      reason: form.getFieldValue("reason"),
      symptom: form.getFieldValue("symptom"),
    };

    datas.slot_id = slot;
    let formValue = new URLSearchParams();
    formValue.append("date", datas.date);
    formValue.append("patient_id", datas.patient_id);
    formValue.append("doctor_id", datas.doctor_id);
    formValue.append("slot_id", datas.slot_id);
    formValue.append("start_time", datas.start_time);
    formValue.append("end_time", datas.end_time);
    formValue.append("consultation_type", datas.consultation_type);
    formValue.append("family_member_id", datas.family_member_id);
    formValue.append("organization_id", organization_id);

    if (location?.state?.disease_id) {
      formValue.append("qusetion_answer", location?.state?.qusetion_answer);
      formValue.append("disease_id", location?.state?.disease_id);
    }
    if (reasonName) {
      formValue.append("reason", formValues?.reason);
    } else {
      formValue.append("reason", reasonId);
    }
    if (symptomName) {
      formValue.append("symptom", formValues?.symptom);
    } else {
      formValue.append("symptom", symptomId);
    }

    console.log("formData", formValue);
    dispatch(patientAddAppointmentInProgress(formValue));
    setNavigate(true);
  };
  const currentDate = moment().format("YYYY-MM-DD");
  const defaultDate = moment().format("YYYY-MM-DD");
  const [currentTime, setCurrentTime] = useState(moment().format("hh:mm A"));
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment().format("hh:mm A"));
    }, 1000);
    dispatch(getScheduleInDefault());
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (date && doctorId) {
      const doctor_id = doctorId;
      dispatch(
        getScheduleInProgress({
          date,
          doctor_id,
          currentDate,
          currentTime,
          familyMemberId,
        })
      );

      dispatch(doctorConsultationFeeInProgress(doctor_id));
    }
  }, [date, doctorId]);
  useEffect(() => {
    handleClearField();
  }, [patientId]);
  const handleClearField = () => {
    form.setFieldValue("family_member_id");
  };
  useEffect(() => {
    handleClearFieldDate();
  }, [doctorId]);
  const handleClearFieldDate = () => {
    form.setFieldValue("slot_id");
    form.setFieldValue("appointment_date");
    form.setFieldValue("consultation_type");
  };
  useEffect(() => {
    handleClearFieldSlot();
  }, [date]);

  const handleClearFieldSlot = () => {
    form.setFieldValue("slot_id");
  };

  const handlePage = () => {
    const formValue = form.getFieldsValue();
    setPatientId(formValue.patient_id);
    setDate(formValue.date);
    setDoctorId(formValue.doctor_id);
    setFamilyMemberId(formValue?.family_member_id);
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 72,
      }}
      spin
    />
  );

  useEffect(() => {
    if (addAppointmentList?.success && navigate) {
      history.push("/appointments");
      setNavigate(false);
    }
  }, [addAppointmentList?.success && navigate]);

  // useEffect(() => {
  //   if (getSchedule?.Error) {
  //     form.resetFields();
  //   }
  // }, [getSchedule?.Error]);
  const onChange = (e) => {
    setSlot(e);
  };
  const onChangeReason = (e) => {
    if (e?.name === "Others") {
      form.setFieldValue("reason", "");
      setReason(e.name);
      setOtherReasonId(e.name);
      setReasonName(true);
    } else {
      form.setFieldValue("reason", e.name);
      setReason("");
      setOtherReasonId(e.name);
      setReasonName(false);
    }
    console.log("reasonDataaaa", reason, reasonId);
  };
  console.log("dddddddd", form.getFieldValue("reason"), reasonId);
  const onChangeSymptom = (e) => {
    if (e?.name === "Others") {
      form.setFieldValue("symptom", "");
      setSymptom(e.name);
      setSymptomId(e?.name);
      setsymptomName(true);
    } else {
      form.setFieldValue("symptom", e?.name);
      setSymptom("");
      setSymptomId(e?.name);
      setsymptomName(false);
    }
    console.log("symptom", symptom, e);
    console.log("symptomId", symptomId);
  };
  const handleAnswerType = (e) => {
    setAddDiseaseModal(true);
    // setDiseaseName(e.label);
  };

  const getMaxDate = () => {
    return moment().add(2, "days").format("YYYY-MM-DD");
  };
  const maxDate = getMaxDate();
  const minDate = defaultDate;
  const tomorrow = moment().add(1, "days");
  const tomorrowFormatted = tomorrow.format("YYYY-MM-DD");
  useEffect(() => {
    form.setFieldValue("date", minDate);
    const payload = { pagination: "false" };
    dispatch(getReasonListInprogress(payload));
    dispatch(getSymptomListInprogress(payload));
  }, []);

  console.log("availableDays", availableDays, doctorId, doctorList);
  useEffect(() => {
    if (doctorId) {
      const doctorData = doctorList.find((doctor) => doctor.id === doctorId);
      console.log("doctorData", doctorData, doctorId);
      if (doctorData && doctorData.Schedules) {
        setAvailableDays(doctorData.Schedules);
      }
    }
  }, [doctorId, doctorList]);
  return (
    <div
      className={
        getSchedule.success
          ? "app-with-card app-with-card-success"
          : "app-with-card app-with-card-failure"
      }
    >
      <Spin
        indicator={antIcon}
        spinning={addAppointmentList.inProgress || getSchedule.inProgress}
      >
        <Card className="appoinment-card">
          <Form
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
            labelAlign="right"
            layout="horizontal"
            onFinish={onFinish}
            // title={"Addnew.appointment.label.AddNewAppointment"}
            form={form}
          >
            <div>
              <Row className="px-10 pt-10 Add-button">
                <Col>
                  <p className="header-tag mb-0">
                    {
                      <IntlMessages
                        id={"Addnew.appointment.label.AddNewAppointment"}
                      />
                    }
                  </p>
                </Col>
                <Col>
                  {location?.state?.qusetion_answer && (
                    <ButtonComponent
                      label={"Answers"}
                      onClick={handleAnswerType}
                    />
                  )}
                </Col>
              </Row>
              <Divider />
            </div>
            <Col xs={24} sm={24}>
              <SelectorsAppointment
                disabled={patient_id != "null" ? true : false}
                margin="mb-10"
                label={"Patient"}
                name={"patient_id"}
                placeholder={"Select your patient"}
                editMode={true}
                rules={[
                  {
                    required: true,
                    message: "Please enter your patient",
                  },
                ]}
                onChange={handlePage}
              />
            </Col>
            {patientId && (
              <Col xs={24} sm={24}>
                <SelectFamilyMember
                  margin="mb-10"
                  label={"Family member"}
                  name={"family_member_id"}
                  placeholder={"Select your family"}
                  editMode={true}
                  rules={[
                    {
                      required: true,
                      message: (
                        <IntlMessages id={"Please enter your family member"} />
                      ),
                    },
                  ]}
                  onChange={handlePage}
                  patient_id={patientId}
                />
              </Col>
            )}
            <Col xs={24} sm={24}>
              <DoctorSelectAppointment
                disable={doctor_id !== "null" ? true : false}
                margin="mb-10"
                label={"Doctor"}
                name={"doctor_id"}
                placeholder={"Select your doctor"}
                editMode={true}
                setDoctorList={setDoctorList}
                rules={[
                  {
                    required: true,
                    message: (
                      <IntlMessages id={"Addnew.appointment.validator.date"} />
                    ),
                  },
                ]}
                onChange={handlePage}
              />
            </Col>
            {availableDays?.length > 0 && (
              <Col xs={24} sm={24}>
                <Row gutter={[16, 16]}>
                  <Col xs={9} sm={9} xl={9}>
                    Available days:
                  </Col>
                  <Col xs={15} sm={15} xl={15}>
                    <Typography>
                      {availableDays
                        .map((day) => day.week_day.slice(0, 3).toUpperCase())
                        .join(", ")}
                    </Typography>
                  </Col>
                </Row>
                {/* <Typography>
                  Available days:
                  {availableDays
                    .map((day) => day.week_day.slice(0, 3).toUpperCase())
                    .join(", ")}
                </Typography> */}
              </Col>
            )}

            <Col xs={24} sm={24}>
              <FormInput
                labelCol={"24"}
                wrapperCol={"24"}
                type="date"
                placeholder="date"
                name="date"
                min={defaultDate}
                max={maxDate}
                label={<IntlMessages id={"Addnew.appointment.label.date"} />}
                rules={[
                  {
                    required: true,
                    message: (
                      <IntlMessages id={"Addnew.appointment.validator.date"} />
                    ),
                  },
                ]}
                onChange={handlePage}
              />{" "}
            </Col>
            <div>
              {getSchedule.success ? (
                <div>
                  <Col xs={24} sm={24}>
                    <label>Slots</label>
                    <div className="slot-scroll">
                      <Radio.Group
                        name={"slot_id"}
                        className={"timeslot-container"}
                      >
                        {slotList.map((row) => (
                          <Radio.Button
                            name="slot_id"
                            disabled={upperCase(row?.isBooked) === "YES"}
                            className={`slot-button ${
                              slot === row.id ? "selected" : "shake"
                            } ${
                              upperCase(row?.isBooked) === "YES"
                                ? "disabled"
                                : ""
                            }`}
                            onClick={() => onChange(row.id)}
                          >
                            {moment(row?.start_time, ["h:mm A"]).format(
                              "h:mm A"
                            )}{" "}
                            -
                            {moment(row?.end_time, ["h:mm A"]).format("h:mm A")}
                          </Radio.Button>
                        ))}
                      </Radio.Group>
                    </div>

                    {!slot && (
                      <p style={{ color: "red" }}>Select the slot time</p>
                    )}
                    {/* <SelectBoxAppointment
                      margin="mb-10"
                      label={"Slot"}
                      name={"slot_id"}
                      placeholder={"Select your Slot"}
                      editMode={true}
                      rules={[
                        {
                          required: true,
                          message: (
                            <IntlMessages id={"please enter your slot"} />
                          ),
                        },
                      ]}
                      options={slotList.map((row) => ({
                        label: `${moment(row?.start_time, ["h:mm A"]).format(
                          "h:mm A"
                        )} - ${moment(row?.end_time, ["h:mm A"]).format(
                          "h:mm A"
                        )}`,
                        value: row?.id,
                        disabled: upperCase(row?.isBooked) === "YES",
                      }))}
                      onChange={handlePage}
                    /> */}
                  </Col>
                  <Col xs={24} sm={24}>
                    <FormInput
                      labelCol={"24"}
                      wrapperCol={"24"}
                      type="text"
                      placeholder="reason"
                      disabled={reason !== "Others"}
                      name="reason"
                      label={
                        <IntlMessages id={"Addnew.appoinment.label.reason"} />
                      }
                      rules={[
                        {
                          required: true,
                          message: (
                            <IntlMessages
                              id={"Addnew.appoinment.validator.reason"}
                            />
                          ),
                        },
                      ]}
                    />
                  </Col>{" "}
                  <Col xs={24} sm={24}>
                    <label>reaons</label>
                    <div className="slot-scroll">
                      <Radio.Group
                        name={"reason_id"}
                        className={"timeslot-container"}
                      >
                        {reasonListData.map((row) => (
                          <Radio.Button
                            name="reason_id"
                            className={`slot-button reason-container ${
                              reasonId === row?.name ? "selected" : "shake"
                            }`}
                            onClick={() => onChangeReason(row)}
                          >
                            {row?.name}
                          </Radio.Button>
                        ))}
                      </Radio.Group>
                    </div>

                    {!reasonId && (
                      <p style={{ color: "red" }}>Select the reason</p>
                    )}
                  </Col>
                  <Col xs={24} sm={24}>
                    <FormInput
                      labelCol={"24"}
                      wrapperCol={"24"}
                      type="text"
                      placeholder="symptoms"
                      name="symptom"
                      disabled={symptom !== "Others"}
                      label={
                        <IntlMessages id={"Addnew.appoinment.label.symptoms"} />
                      }
                      rules={[
                        {
                          required: true,
                          message: (
                            <IntlMessages
                              id={"Addnew.appointment.validator.symptoms"}
                            />
                          ),
                        },
                      ]}
                    />
                  </Col>{" "}
                  <Col xs={24} sm={24}>
                    <label>symptoms</label>
                    <div className="slot-scroll">
                      <Radio.Group
                        name={"symptom_id"}
                        className={"timeslot-container"}
                      >
                        {symptomListData.map((row) => (
                          <Radio.Button
                            name="symptom_id"
                            disabled={upperCase(row?.isBooked) === "YES"}
                            className={`slot-button ${
                              symptomId === row?.name ? "selected" : "shake"
                            }`}
                            onClick={() => onChangeSymptom(row)}
                          >
                            {row?.name}
                          </Radio.Button>
                        ))}{" "}
                        {/* <Radio.Button
                          value="other"
                          className={`slot-button ${
                            symptom === "other" ? "selected" : "shake"
                          }`}
                          onClick={() => setSymptom("other")}
                        >
                          other
                        </Radio.Button> */}
                      </Radio.Group>
                    </div>

                    {!symptomId && (
                      <p style={{ color: "red" }}>Select the symptom</p>
                    )}
                  </Col>
                  <Col xs={24} sm={24}>
                    <SelectBoxAppointment
                      margin="mb-10"
                      label={"Consultation Fees"}
                      name={"consultation_type"}
                      placeholder={"Enter your consultation fee"}
                      editMode={true}
                      rules={[
                        {
                          required: true,
                          message: (
                            <IntlMessages
                              id={"please enter your consultation fees"}
                            />
                          ),
                        },
                      ]}
                      options={[
                        {
                          value: "1",
                          label:
                            "inperson consultation fee is" + "     " + infee,
                        },
                        {
                          value: "2",
                          label: "tele consultation fee" + "    " + telefee,
                        },
                      ]}
                      onChange={handlePage}
                    />
                  </Col>
                  <Row className="space my-0 ">
                    <Col className="btn-pos">
                      <ButtonComponent
                        type="primary"
                        htmlType="Submit"
                        label="save"
                        className={"mb-0"}
                        disabled={!slot}
                      />
                      {/* 
                      <Link to={APP_ROUTES.ADDAPPOINTMENT}  >
                        <ButtonComponent type="primary" htmlType="Submit"  label="Next" />
                        </Link> */}
                    </Col>
                  </Row>
                </div>
              ) : (
                <span style={{ padding: "25px" }}>
                  -select doctor and date for schedule slot-
                </span>
              )}
            </div>
          </Form>
        </Card>
      </Spin>
      <Modal
        title={
          <Typography className="cancel-sales">
            {" "}
            <MessageOutlined /> &nbsp;
            {location?.state?.diseaseName}
          </Typography>
        }
        open={addDiseaseModal}
        width={"auto"}
        closable={false}
        footer={[
          <Button
            key="back"
            type="danger"
            onClick={() => {
              setAddDiseaseModal(false);
            }}
          >
            Close
          </Button>,
        ]}
      >
        <AppointmentQuestionsAnswerModel
          qusetion={location?.state?.qusetion_answer}
        />
      </Modal>
    </div>
  );
};

export default AddNewAppoinment;
