export const APP_ROUTES = {
  DASHBOARD: "/",
  DOCTOR: "/doctor",
  DOCTORADDNEW: "/doctor/add",
  DOCTOREDIT: "/doctor/edit/:id",
  VIEWDOCTORHISTORY: "/doctor/history",
  REFERENCEDOCTOR: "/reference",
  REFERENCEDOCTORADDNEW: "/reference/add",
  REFERENCEDOCTOREDIT: "/reference/edit",
  PATIENT: "/patient",
  DOCTORAPPOINTMENT: "/appoinment/doctorAppointment",
  ADDNEWPATIENT: "/patient/add",
  BILLINGPACKAGES: "/billingPackages",
  ADDBILLINGPACKAGES: "/billingPackages/add",
  EDITBILLINGPACKAGES: "/billingPackages/edit",
  APPOINMENT: "/appointments",
  APPOINMENTVIEW: "/appointments/view",
  ADDNEWAPPOINMENT: "/appoinment/add",
  PATIENTAPPOINMENTS: "/appoinments/patient",
  ADDPATIENTAPPOINTMENTS: "/appointments/add",
  TODAYAPPOINMENT: "/appointment/today",
  APPOINMENTSUPCOMING: "/appoinments/upcoming",
  APPOINMENTSREQUEST: "/appoinments/request",
  APPOINMENTSCALENDER: "/appoinments/calender",
  VIDEOCALL: "/appoinments/videocall",
  TELEMEDICINE: "/telemedicine",
  SYMPTOMS: "/telemedicine/symptoms",
  // ORGANISATION: "/organisation",
  // ADDNEWORGANISATION: "/organisation/add",
  // EDITORGANISATION: "/organisation/edit",
  DESIGNATION: "/designation",
  ADDNEWDESIGNATION: "/designation/add",
  EDITDESIGNATION: "/designation/edit",
  SPECIALIZATION: "/specialization",
  ADDNEWSPECIALIZATION: "/specialization/add",
  EDITSPECIALIZATION: "/specialization/edit",
  EDITPATIENT: "/patient/edit/:id",
  EDITPATIENTAPPOINTMENT: "/patientAppointment/edit/:id",
  QUALIFICATION: "/qualification",
  ADDNEWQUALIFICATION: "/qualification/add",
  EDITQUALIFICATION: "/qualification/edit/:id",
  PROFILE: "/profile",
  SCHEDULE: "/schedule",
  ADDSCHEDULE: "/schedule/add",
  EDITSCHEDULE: "/schedule/edit",
  VIEWSCHEDULE: "/schedule/view",
  ADDAPPOINTMENT: "/appointment/add/next",
  PREVIOUSADDAPPOINTMENT: "/appoinment/add",
  HOLIDAYS: "/holidays",
  ADDNEWHOLIDAYS: "/holidays/add",
  EDITHOLIDAYS: "/holidays/edit",
  GETUSERS: "/admin/getusers",
  UPDATEUSER: "/admin/getusers/update/:id",
  ADMINDASHBOARD: "/admin",
  PATIENT1: "/patient1",
  VIEWPATIENT1: "/patient/view",
  VIEWPATIENTHEALTHRECORD1: "/patient/view/healthRecord",
  VIEWPATIENTAPPOINTMENT1: "/patient/view/appointment",
  ADDPRESCRIPTION: "/prescription/add",
  PRESCRIPTIONDATA: "/prescription/data",
  PRESCRIPTIONVIEW: "/prescription/view",
  PRESCRIPTION: "/prescription",
  CONFIRMPASSWORD: "/confirmpassword",
  CANCELPATIENTUPDATE: "/patient",
  EXPORTCSV: "/exportcsv",
  // CONFIRMPASSWORD: "/changepassword",
  DOCTORLIST: "/doctorlist",
  LOGOUT: "/logout",
  CONSULTATION: "/consultation",
  TABLETLIST: "/tabletlist",
  ADDTABLET: "/tablet/add",
  EDITTABLET: "/tablet/edit",
  DOCTORAPPOINMENTLIST: "/doctor/appoinment",
  SUPPLIERS: "/supplier",
  ADDSUPPLIER: "/supplier/add",
  SALES: "/sales",
  SALESRETURN: "/sales/return",
  ADDSALESRETURN: "/sales/return/add",
  VIEWSALESRETURN: "/sales/return/view",
  EDITSALESRETURN: "/sales/return/edit",
  ADDSALES: "/sales/add",
  EDITSALES: "/sales/edit",
  VIEWSALES: "/sales/view",
  PURCHASEREQUEST: "/purchase/request",
  ADDPURCHASEREQUEST: "/purchase/request/add",
  EDITPURCHASEREQUEST: "/purchase/request/edit",
  VIEWPURCHASEREQUEST: "/purchase/request/view",
  PURCHASE: "/purchase",
  ADDPURCHASE: "/purchase/add",
  EDITPURCHASE: "/purchase/edit",
  VIEWPURCHASE: "/purchase/view",
  PURCHASERETURN: "/purchase/return",
  ADDPURCHASERETURN: "/purchase/return/add",
  EDITPURCHASERETURN: "/purchase/return/edit",
  VIEWPURCHASERETURN: "/purchase/return/view",
  PRINTPURCHASERETURN: "/purchase/return/print",
  EDITSUPPLIER: "/supplier/edit",
  VIEWSUPPLIER: "/supplier/view",
  MEDICINELIST: "/medicine",
  BULKMEDICINE: "/medicine/bulk",
  ADDMEDICINE: "/medicine/add",
  EDITMEDICINE: "/medicine/edit",
  MEDICINECATEGORY: "/medicinecategory",
  ADDMEDICINECATEGORY: "/medicineCategory/add",
  BULKMEDICINECATEGORY: "/medicineCategory/bulk",
  EDITMEDICINECATEGORY: "/medicineCategory/edit",
  ITEMTRANSFER: "/itemtransfer",
  ADDITEMTRANSFER: "/itemtransfer/add",
  EDITITEMTRANSFER: "/itemtransfer/edit",
  VIEWITEMTRANSFER: "/itemtransfer/view",
  EXPIRYSTOCK: "/expiryStock",
  STOCKADJUSTMENT: "/stockadjustment",
  ADDSTOCKADJUSTMENT: "/stockadjustment/add",
  EDITSTOCKADJUSTMENT: "/stockadjustment/edit",
  PHARMACYPROFILE: "/myprofile",
  PHARMACYCHANGEPASSWORD: "/changepassword",
  BILLINGS: "/billings",
  ADDBILLINGS: "/billings/add",
  EDITBILLINGS: "/billings/edit",
  INVOICEBILLING: "/billings/invoice",
  PATIENTHEALTHRECORD: "/patient/healthrecord",
  ADDPATIENTHEALTHRECORD: "/patient/healthrecord/add",
  EDITPATIENTHEALTHRECORD: "/patient/healthrecord/edit",
  PRINTPURCHASE: "/print",
  SUBPACKAGECATEGORY: "/subPackageCategory",
  ROLES: "/roles",
  ADDROLES: "/roles/add",
  TEAMMEMBERS: "/teamMembers",
  TEAMMEMBERSFORM: "/teamMembers/add",
  PATIENTDUE: "/patientDue",
  VIEWDUEDETAILS: "/patientDue/dueDetails",
  HOSPITAL: "/organization",
  ADDHOSPITAL: "/organization/add",
  EDITHOSPITAL: "/organization/edit",
  PACKAGES: "/packages",
  ADDPACKAGE: "/packages/add",
  EDITPACKAGE: "/packages/edit",
  APPOINMENTQUESTIONS: "/appointmentQuestion/add",
  APPOINMENTQUESTIONSCREEN: "/appointmentQuestion",
  APPOINMENTQUESTIONSVIEW: "/appointmentQuestion/view",
  APPOINMENTQUESTIONSEDIT: "/appointmentQuestion/edit",
  APPOINMENTANSWER: "/appoinmentanswer",
  PAYMENT: "/payment",
  PAYMENT_ADD: "/payment/add",
  PAYMENT_HISTORY: "/payment/history",
  PAYMENT_INVOICE: "/payment/invoice",
  BULKPURCHASE: "/purchase/bulk-insert",
  SUBSCRIPTION: "/subscription",
  ADD_SUBSCRIPTION: "/subscription/add",
  EDIT_SUBSCRIPTION: "/subscription/edit",
  WARD_MANAGEMENT: "/ward-management",
  BLOCK_LIST: "/block",
  ADD_BLOCK: "/block/add",
  EDIT_BLOCK: "/block/edit",
  WARD_LIST: "/ward",
  ADD_WARD: "/ward/add",
  EDIT_WARD: "/ward/edit",
  ROOM_LIST: "/rooms",
  ADD_ROOM: "/rooms/add",
  EDIT_ROOM: "/rooms/edit",
  BOOK_ROOM: "/book-room",
  AVAILABLE_ROOM: "/available-room",
  BOOKED_ROOMS: "/booked-rooms",
  TOTAL_ROOMS: "/total-rooms",
  SHIFT_ROOMS: "/shift-room",
  DISCHARGE_ROOM: "/discharge-room",
  HEALTH_MANAGEMENT: "/health-management",
  VITAL: "/health-management/vitals",
  ADD_VITAL: "/health-management/vitals/add",
  BMI: "/health-management/bmi",
  BLOOD_GLUCOSE: "/health-management/blood-glucose",
  INSULIN: "/health-management/insulin",
  PHARMACYREPORTS: "/pharmacyreports",
  PATIENT_BILLING_REPORTS: "/patient-billing-reports",
  AVABILE_STOCK_LISTS: "/avabilestockList",
  BOOKROOM: "/bookroom",
  VIEWPATIENTPRISCRIPTION: "/patient1/view/prescription",
  SETTINGS: "/settings",
  APPOINMENTBILLING: "/appointmentbilling",
  APPOINMENTBILLINGVIEW: "/appointmentbilling/view",
  SCAN: "/scan",
  ADDSCAN: "/scan/phr",
  PDFVIEWER: "/pdfviewer",
  ORGANZATIONREPORTLIST: "/organization/reportList",
  ORGANZATIONREPORT: "/organization/report",
  REASON: "/reason",
  ADD_REASON: "/reason/add",
  SYMPTOM: "/symptom",
  ADD_SYMPTOM: "/symptom/add",
  OUT_DOCTOR_REPORTS: "/out-doctor-report",
  OUT_DOCTOR: "/out-doctor",
};

export const DOCTORMENUS = [
  {
    label: "sidebar.dashboard",
    link: APP_ROUTES.DASHBOARD,
    icon: "icon-home",
  },
  // {
  //   label: "sidebar.doctorlist",
  //   link: APP_ROUTES.DOCTORLIST,
  //   icon: "icon-listing-dbrd",
  // },
  {
    label: "sidebar.mypatient",
    link: APP_ROUTES.PATIENT1,
    icon: "icon-profile2",
  },
  {
    label: "sidebar.Billings",
    link: APP_ROUTES.BILLINGS,
    icon: " icon-calendar-new ",
  },
  {
    label: "sidebar.appoinmentquestions",
    link: APP_ROUTES.APPOINMENTQUESTIONSCREEN,
    icon: "icon-question-circle",
  },
  {
    label: "sidebar.appoinment",
    link: APP_ROUTES.APPOINMENT,
    icon: "icon-files",
  },
  {
    label: "sidebar.schedule",
    link: APP_ROUTES.SCHEDULE,
    icon: "icon-schedule",
  },
  {
    label: "sidebar.holidays",
    link: APP_ROUTES.HOLIDAYS,
    icon: "icon-calendar-new",
  },
  {
    label: "sidebar.BillingPackages",
    link: APP_ROUTES.BILLINGPACKAGES,
    icon: "icon-widgets",
  },
  {
    label: "sidebar.BillingSubcategory",
    link: APP_ROUTES.SUBPACKAGECATEGORY,
    icon: " icon-calendar-new ",
  },

  {
    label: "sidebar.consultation",
    link: APP_ROUTES.CONSULTATION,
    icon: "icon-select",
  },
  {
    label: "sidebar.ward",
    link: APP_ROUTES.WARD_MANAGEMENT,
    icon: "icon-select",
  },
  {
    label: "sidebar.profile",
    link: APP_ROUTES.PROFILE,
    icon: "icon-profile",
  },
  {
    label: "sidebar.changePassword",
    link: APP_ROUTES.PHARMACYCHANGEPASSWORD,
    icon: "icon-reset-password",
  },

  {
    label: "sidebar.logout",
    link: APP_ROUTES.LOGOUT,
    icon: "icon-lock-screen",
  },
];
export const PATIENTMENUS = [
  {
    label: "sidebar.dashboard",
    link: APP_ROUTES.DASHBOARD,
    icon: "icon-home",
  },

  {
    label: "sidebar.prescription",
    link: APP_ROUTES.PRESCRIPTION,
    icon: "icon-select",
  },
  // {
  //   label: "sidebar.patient1",
  //   link: APP_ROUTES.PATIENT1,
  //   icon: "icon-crypto",
  // },
  // {
  //   label: "sidebar.dashboard",
  //   link: APP_ROUTES.DASHBOARD,
  //   icon: "icon-crypto",
  // },

  // {
  //   label: "sidebar.organisation",
  //   link: APP_ROUTES.ORGANISATION,
  //   icon: "icon-crypto",
  // },

  // {
  //   label: "sidebar.specialization",
  //   link: APP_ROUTES.SPECIALIZATION,
  //   icon: "icon-crypto",
  // },

  {
    label: "sidebar.doctor",
    link: APP_ROUTES.DOCTOR,
    icon: "icon-listing-dbrd",
  },
  // {
  //   label: "sidebar.addprescription",
  //   link: APP_ROUTES.ADDPRESCRIPTION,
  //   icon: "icon-crypto",
  // },
  // {
  //   label: "sidebar.reference",
  //   link: APP_ROUTES.REFERENCEDOCTOR,
  //   icon: "icon-crypto",
  // },
  // {
  //   label: "sidebar.patient",
  //   link: APP_ROUTES.PATIENT,
  //   icon: "icon-crypto",
  // },

  {
    label: "sidebar.appoinment",
    icon: "icon-hotel-booking",
    link: APP_ROUTES.APPOINMENT,
    // subMenu: [
    //   {
    //     label: "sidebar.all",
    //     link: APP_ROUTES.APPOINMENT,
    //     icon: "icon-crypto",
    //   },
    //   {
    //     label: "sidebar.add",
    //     link: APP_ROUTES.ADDNEWAPPOINMENT,
    //     icon: "icon-add-circle",
    //   },
    //   {
    //     label: "sidebar.doctorappointment",
    //     link: APP_ROUTES.DOCTORAPPOINTMENT,
    //     icon: "icon-listing-dbrd",
    //   },
    //   {
    //     label: "sidebar.patientAppointments",
    //     link: APP_ROUTES.PATIENTAPPOINMENTS,
    //     icon: "icon-listing-dbrd",
    //   },

    //   {
    //     label: "sidebar.upcoming",
    //     link: APP_ROUTES.APPOINMENTSUPCOMING,
    //     icon: "icon-listing-dbrd",
    //   },
    //   {
    //     label: "sidebar.request",
    //     link: APP_ROUTES.APPOINMENTSREQUEST,
    //     icon: "icon-listing-dbrd",
    //   },
    //   {
    //     label: "sidebar.calender",
    //     link: APP_ROUTES.APPOINMENTSCALENDER,
    //     icon: "icon-calendar-new",
    //   },
    //   {
    //     label: "sidebar.videocall",
    //     link: APP_ROUTES.VIDEOCALL,
    //     icon: "icon-calendar",
    //   },
    // ],
  },
  {
    label: "sidebar.healthRecord",
    link: APP_ROUTES.PATIENTHEALTHRECORD,
    icon: "icon-reset-password",
  },
  {
    label: "sidebar.HealthManagement",
    link: APP_ROUTES.HEALTH_MANAGEMENT,
    icon: "icon-select",
    key: "healthManagement",
  },
  {
    label: "sidebar.profile",
    link: APP_ROUTES.PROFILE,
    icon: "icon-profile",
  },
  {
    label: "sidebar.changePassword",
    link: APP_ROUTES.PHARMACYCHANGEPASSWORD,
    icon: "icon-reset-password",
  },
  {
    label: "sidebar.logout",
    link: APP_ROUTES.LOGOUT,
    icon: "icon-lock-screen",
  },
];
export const ADMINMENUS = [
  {
    label: "sidebar.dashboard",
    link: APP_ROUTES.DASHBOARD,
    icon: "icon-home",
  },

  {
    label: "sidebar.doctor",
    link: APP_ROUTES.DOCTOR,
    icon: "icon-listing-dbrd",
  },
  {
    label: "sidebar.outDoctor",
    link: APP_ROUTES.OUT_DOCTOR,
    icon: "icon icon-litcoin",
  },
  {
    label: "sidebar.teamMembers",
    link: APP_ROUTES.TEAMMEMBERS,
    icon: "icon-team",
  },
  {
    label: "sidebar.patient",
    link: APP_ROUTES.PATIENT,
    icon: "icon-profile2",
  },
  {
    label: "sidebar.Billings",
    link: APP_ROUTES.BILLINGS,
    icon: " icon-calendar-new ",
    key: "Billings",
  },

  {
    label: "sidebar.appoinment",
    link: APP_ROUTES.APPOINMENT,
    icon: "icon-files",
  },
  {
    label: "sidebar.appoinmentquestions",
    link: APP_ROUTES.APPOINMENTQUESTIONSCREEN,
    icon: "icon-question-circle",
  },
  {
    label: "sidebar.reason",
    link: APP_ROUTES.REASON,
    icon: "icon-product-grid",
  },
  {
    label: "sidebar.symptom",
    link: APP_ROUTES.SYMPTOM,
    icon: "icon-queries",
  },
  {
    label: "sidebar.BillingPackages",
    link: APP_ROUTES.BILLINGPACKAGES,
    icon: "icon-widgets",
  },
  {
    label: "sidebar.BillingSubcategory",
    link: APP_ROUTES.SUBPACKAGECATEGORY,
    icon: " icon-calendar-new ",
  },
  {
    label: "sidebar.AppointmentBilling",
    link: APP_ROUTES.APPOINMENTBILLING,
    icon: "icon-visits",
    key: "appoinmentbill",
  },
  {
    label: "sidebar.scan",
    link: APP_ROUTES.SCAN,
    icon: "icon-uncheck-squire-o",
    key: "scan",
  },
  {
    label: "sidebar.reports",
    link: APP_ROUTES.PATIENT_BILLING_REPORTS,
    icon: "icon-feedback",
    key: "reports",
  },
  {
    label: "sidebar.roles",
    link: APP_ROUTES.ROLES,
    icon: "icon-important-o",
  },
  {
    label: "sidebar.profile",
    link: APP_ROUTES.PROFILE,
    icon: "icon-profile",
  },
  {
    label: "sidebar.changepassword",
    link: APP_ROUTES.PHARMACYCHANGEPASSWORD,
    icon: "icon-reset-password",
  },
  {
    label: "sidebar.SETTINGS",
    link: APP_ROUTES.SETTINGS,
    icon: "icon-setting",
  },
  {
    label: "sidebar.logout",
    link: APP_ROUTES.LOGOUT,
    icon: "icon-lock-screen",
  },
];
export const SUPERADMINMENU = [
  {
    label: "sidebar.dashboard",
    link: APP_ROUTES.DASHBOARD,
    icon: "icon-home",
  },
  {
    label: "sidebar.hospitals",
    link: APP_ROUTES.HOSPITAL,
    icon: "icon-cards-list-view",
  },
  {
    label: "sidebar.subscription",
    link: APP_ROUTES.SUBSCRIPTION,
    icon: "icon-data-display",
  },
  // {
  //   label: "sidebar.packages",
  //   link: APP_ROUTES.PACKAGES,
  //   icon: "icon-data-display",
  // },
  {
    label: "sidebar.payment",
    link: APP_ROUTES.PAYMENT,
    icon: "icon-pricing-table",
  },

  {
    label: "sidebar.profile",
    link: APP_ROUTES.PROFILE,
    icon: "icon-profile",
  },
  {
    label: "sidebar.changepassword",
    link: APP_ROUTES.PHARMACYCHANGEPASSWORD,
    icon: "icon-reset-password",
  },
  {
    label: "sidebar.logout",
    link: APP_ROUTES.LOGOUT,
    icon: "icon-lock-screen",
  },
];
export const PHARMACYMENU = [
  {
    label: "sidebar.dashboard",
    // key: "dashboard",
    link: "/",
    icon: "icon-home",
  },
  {
    label: "sidebar.itemTransfer",
    // key: "itemTransfer",
    link: "/itemtransfer",
    icon: "icon-transfer",
  },
  {
    label: "sidebar.suppliers",
    link: "/supplier",
    icon: "icon-card",
    key: "supplier",
  },
  {
    label: "sidebar.purchase",
    key: "purchase",
    icon: "icon-hotel-booking",
    subMenu: [
      {
        label: "sidebar.purchase.purchaserequest",
        link: APP_ROUTES.PURCHASEREQUEST,
        icon: "icon-refer",
        key: "purchaseRequest",
      },
      {
        label: "sidebar.purchase.purchase",
        link: APP_ROUTES.PURCHASE,
        icon: "icon-list-select-o",
        key: "purchasePurchase",
      },
      {
        label: "sidebar.purchase.purchasereturn",
        link: APP_ROUTES.PURCHASERETURN,
        icon: "icon-data-entry",
        key: "purchaseReturn",
      },
    ],
  },
  {
    label: "sidebar.sales",
    icon: "icon-shopping-cart",
    key: "sales",
    subMenu: [
      {
        label: "sidebar.sales.sales",
        link: APP_ROUTES.SALES,
        icon: "icon-product-list",
        key: "sales",
      },
      {
        label: "sidebar.sales.salesreturn",
        link: APP_ROUTES.SALESRETURN,
        icon: "icon-orders",
        key: "salesReturn",
      },
    ],
  },
  {
    label: "sidebar.patientDue",
    link: APP_ROUTES.PATIENTDUE,
    icon: "icon-widgets",
    key: "patientDue",
  },
  {
    label: "sidebar.medicineList",
    link: APP_ROUTES.MEDICINELIST,
    icon: "icon-plain-list-divider",
    key: "medicineList",
  },
  {
    label: "sidebar.medicineCategory",
    link: APP_ROUTES.MEDICINECATEGORY,
    icon: "icon-message",
    key: "medicineCategory",
  },
  {
    label: "sidebar.expiryStock",
    link: APP_ROUTES.EXPIRYSTOCK,
    icon: "icon-card",
    key: "expiryStock",
  },
  {
    label: "sidebar.stockAdjustment",
    link: APP_ROUTES.STOCKADJUSTMENT,
    icon: "icon-chart",
    key: "stockAdjustment",
  },
  {
    label: "sidebar.prescription",
    link: APP_ROUTES.PRESCRIPTION,
    icon: "icon-crypto",
    key: "prescription",
  },

  {
    label: "sidebar.profile",
    link: APP_ROUTES.PHARMACYPROFILE,
    icon: "icon-profile",
    key: "profile",
  },
  {
    label: "sidebar.changePassword",
    link: APP_ROUTES.PHARMACYCHANGEPASSWORD,
    icon: "icon-reset-password",
    key: "changePassword",
  },
  {
    label: "sidebar.logout",
    link: APP_ROUTES.LOGOUT,
    icon: "icon-lock-screen",
  },
];
export const DASHBOARD = [
  {
    label: "sidebar.dashboard",
    link: "/",
    icon: "icon-home",
    key: "dashboard",
  },
];
export const TEAMMEMBERS = [
  {
    label: "sidebar.doctor",
    link: APP_ROUTES.DOCTOR,
    icon: "icon-listing-dbrd",
    key: "doctors",
  },
  {
    label: "sidebar.patient",
    link: APP_ROUTES.PATIENT,
    icon: "icon-profile2",
    key: "patients",
  },
  {
    label: "sidebar.itemTransfer",
    link: "/itemtransfer",
    icon: "icon-transfer",
    key: "itemTransfer",
  },
  {
    label: "sidebar.suppliers",
    link: "/supplier",
    icon: "icon-card",
    key: "suppliers",
  },
  {
    label: "sidebar.purchase",
    key: "purchaseList",
    icon: "icon-hotel-booking",

    subMenu: [
      {
        label: "sidebar.purchase.purchaserequest",
        link: APP_ROUTES.PURCHASEREQUEST,
        icon: "icon-refer",
        key: "purchaseRequest",
      },
      {
        label: "sidebar.purchase.purchase",
        link: APP_ROUTES.PURCHASE,
        icon: "icon-list-select-o",
        key: "purchase",
      },
      {
        label: "sidebar.purchase.purchasereturn",
        link: APP_ROUTES.PURCHASERETURN,
        icon: "icon-data-entry",
        key: "purchaseReturn",
      },
    ],
  },
  {
    label: "sidebar.sales",
    icon: "icon-shopping-cart",
    key: "salesList",
    subMenu: [
      {
        label: "sidebar.sales.sales",
        link: APP_ROUTES.SALES,
        icon: "icon-product-list",
        key: "sales",
      },
      {
        label: "sidebar.sales.salesreturn",
        link: APP_ROUTES.SALESRETURN,
        icon: "icon-orders",
        key: "salesReturn",
      },
    ],
  },
  {
    label: "sidebar.AppointmentBilling",
    link: APP_ROUTES.APPOINMENTBILLING,
    icon: "icon-visits",
    key: "appoinmentbill",
  },
  {
    label: "sidebar.scan",
    link: APP_ROUTES.SCAN,
    icon: "icon-uncheck-squire-o",
    key: "scan",
  },
  {
    label: "sidebar.prescription",
    link: APP_ROUTES.PRESCRIPTION,
    icon: "icon-crypto",
    key: "prescription",
  },
  {
    label: "sidebar.patientDue",
    link: APP_ROUTES.PATIENTDUE,
    icon: "icon-widgets",
    key: "patientDue",
  },
  {
    label: "sidebar.medicineList",
    link: APP_ROUTES.MEDICINELIST,
    icon: "icon-plain-list-divider",
    key: "medicineList",
  },
  {
    label: "sidebar.medicineCategory",
    link: APP_ROUTES.MEDICINECATEGORY,
    icon: "icon-message",
    key: "medicineCategory",
  },
  {
    label: "sidebar.expiryStock",
    link: APP_ROUTES.EXPIRYSTOCK,
    icon: "icon-card",
    key: "expiryStock",
  },
  {
    label: "sidebar.stockAdjustment",
    link: APP_ROUTES.STOCKADJUSTMENT,
    icon: "icon-chart",
    key: "stockAdjustment",
  },

  // {
  //   label: "sidebar.mypatient",
  //   link: APP_ROUTES.PATIENT1,
  //   icon: "icon-profile2",
  //   key: "patients",
  // },
  {
    label: "sidebar.Billings",
    link: APP_ROUTES.BILLINGS,
    icon: " icon-calendar-new ",
    key: "billingPayment",
  },
  {
    label: "sidebar.appoinmentquestions",
    link: APP_ROUTES.APPOINMENTQUESTIONSCREEN,
    icon: "icon-question-circle",
    key: "appoinmentquestions",
  },
  {
    label: "sidebar.appoinment",
    link: APP_ROUTES.APPOINMENT,
    icon: "icon-files",
    key: "appointments",
  },
  {
    label: "sidebar.schedule",
    link: APP_ROUTES.SCHEDULE,
    icon: "icon-schedule",
    key: "schedule",
  },
  {
    label: "sidebar.holidays",
    link: APP_ROUTES.HOLIDAYS,
    icon: "icon-calendar-new",
    key: "holidays",
  },
  {
    label: "sidebar.BillingPackages",
    link: APP_ROUTES.BILLINGPACKAGES,
    icon: "icon-widgets",
    key: "billingPackage",
  },
  {
    label: "sidebar.BillingSubcategory",
    link: APP_ROUTES.SUBPACKAGECATEGORY,
    icon: " icon-calendar-new ",
    key: "billingSubPackage",
  },

  {
    label: "sidebar.consultation",
    link: APP_ROUTES.CONSULTATION,
    icon: "icon-select",
    key: "consultation",
  },
  {
    label: "sidebar.PharmacyReports",
    link: APP_ROUTES.PHARMACYREPORTS,
    icon: "icon-spam",
    key: "pharmacyReports",
  },
  {
    label: "sidebar.ward",
    link: APP_ROUTES.WARD_MANAGEMENT,
    icon: "icon-select",
    key: "wardManagement",
  },

  {
    label: "sidebar.bulk",
    link: APP_ROUTES.BULKPURCHASE,
    icon: "icon-lock-screen",
  },
  {
    label: "sidebar.profile",
    link: APP_ROUTES.PROFILE,
    icon: "icon-profile",
    key: "profile",
  },
  {
    label: "sidebar.profile",
    link: APP_ROUTES.PROFILE,
    icon: "icon-profile",
    key: "doctorProfile",
  },
  {
    label: "sidebar.profile",
    link: APP_ROUTES.PHARMACYPROFILE,
    icon: "icon-profile",
    key: "pharmacyProFile",
  },
];
export const PROFILE = [
  {
    label: "sidebar.changePassword",
    link: APP_ROUTES.PHARMACYCHANGEPASSWORD,
    icon: "icon-reset-password",
    key: "changePassword",
  },

  {
    label: "sidebar.logout",
    link: APP_ROUTES.LOGOUT,
    icon: "icon-lock-screen",
    key: "logout",
  },
];
export const itemsData = [
  {
    label: "sidebar.dashboard",
    key: "dashboard",
    link: "/",
    icon: "icon-reset-password",
  },
  {
    label: "sidebar.suppliers",
    link: "/suppliers",
    icon: "icon-card",
  },

  {
    label: "Navigation Three - Submenu",
    key: "SubMenu",
    icon: "icon-reset-password",

    children: [
      {
        type: "group",
        label: "Item 1",
        children: [
          {
            label: "Option 1",
            key: "setting:1",
          },
          {
            label: "Option 2",
            key: "setting:2",
          },
        ],
      },
      {
        type: "group",
        label: "Item 2",
        children: [
          {
            label: "Option 3",
            key: "setting:3",
          },
          {
            label: "Option 4",
            key: "setting:4",
          },
        ],
      },
    ],
  },
];

export const PRESCRIPTION = [
  // {
  //   label: "sidebar.AvabileStock",
  //   link: APP_ROUTES.AVABILE_STOCK_LISTS,
  //   icon: "icon-wysiwyg",
  //   key: "prescriptionReports",
  // },
  // {
  //   label: "sidebar.AppointmentBilling",
  //   link: APP_ROUTES.APPOINMENTBILLING,
  //   icon: "icon-visits",
  //   key: "appoinmentbill",
  // },
  // {
  //   label: "sidebar.scan",
  //   link: APP_ROUTES.APPOINMENTBILLING,
  //   icon: "icon-visits",
  //   key: "appoinmentbill",
  // },
  // {
  //   label: "sidebar.logout",
  //   link: APP_ROUTES.LOGOUT,
  //   icon: "icon-lock-screen",
  // },
];
